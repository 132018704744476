import React from 'react';
import { __ } from '../../helpers/TranslationService';
import { IBrandConfig } from '../../reducers/pageData';
import { useSearchCruise } from './useSearchCruise';

interface IProps {
    className?: string;
    countryCode: string;
    apiHost: string;
    brandConfig: IBrandConfig;
}

export const SearchCruise = (props: IProps) => {
    const { searchId, handleInputChange, handleSubmit } = useSearchCruise(props);

    return (
        <form
            onSubmit={handleSubmit}
            className={`search-cruise${props.className ? ' ' + props.className : ''}`}
        >
            <input
                type="text"
                value={searchId}
                onChange={(e) => handleInputChange(e.target.value)}
                placeholder={__('insert cruise ID', 'dreamlines')}
            />
            <button className="icon-magnifyingGlass" />
        </form>
    );
};
