import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function Twitter(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox || '0 0 24 24'}
            style={props.style}
            className={props.className}
        >
            <path d="M14.234 10.162 22.977 0h-2.072l-7.591 8.824L7.25 0H.258l9.168 13.343L.258 24H2.33l8.016-9.318L16.75 24h6.993l-9.508-13.838Zm-2.837 3.299-.93-1.329L3.078 1.56h3.181l5.965 8.532.93 1.329 7.753 11.09h-3.182l-6.327-9.05Z" />
        </Icon>
    );
}
