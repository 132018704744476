import * as React from 'react';
import * as Colors from '../../consts/variables';
import { __ } from '../../helpers/TranslationService';
import { MarketContext } from '../../context/markets';
import { SearchIcon } from '../Icons/SearchIcon';
import { IBrandConfig } from '../../reducers/pageData';
import { useSearchCruise } from '../SearchCruise/useSearchCruise';

const styles: Record<string, React.CSSProperties> = {
    searchCruiseFormWrapper: {
        padding: '0 5%',
        marginBottom: '15px',
    },
    searchCruiseForm: {
        border: '1px solid rgb(195, 195, 195)',
        margin: '0 auto',
        maxWidth: '290px',
    },
    inputCruiseId: {
        border: 0,
        padding: '5px 7px',
        width: '85%',
        height: '40px',
        outline: '0',
    },
    inputCruiseIdButton: {
        border: 0,
        background: 'none',
        position: 'relative',
        top: '5px',
        outline: '0',
        padding: '0',
        left: '5px',
    },
};

const IconStyle: any = {
    marginRight: '5%',
    fill: Colors.acqa,
    flex: '0 0 auto',
    alignSelf: 'flex-start',
};

interface IProps {
    countryCode: string;
    apiHost: string;
    brandConfig: IBrandConfig;
    contentEndpoint: string;
}

export const SearchCruise = (props: IProps) => {
    const { markets } = React.useContext(MarketContext);
    const { searchId, handleInputChange, handleSubmit } = useSearchCruise(props);

    const { isCruise1st } = markets;
    const IconStyles = isCruise1st ? { ...IconStyle, fill: Colors.indigo } : IconStyle;

    return (
        <div style={styles.searchCruiseFormWrapper}>
            <form style={styles.searchCruiseForm} onSubmit={handleSubmit}>
                <input
                    style={styles.inputCruiseId}
                    type="text"
                    value={searchId}
                    onChange={(e) => handleInputChange(e.target.value)}
                    placeholder={__('insert cruise ID', 'dreamlines')}
                />
                <button style={styles.inputCruiseIdButton}>
                    <SearchIcon style={IconStyles} width={24} height={24} />
                </button>
            </form>
        </div>
    );
};
