import { __ } from '../helpers/TranslationService';

export const defaultCountries = [
    { value: '', label: __('Choose a country', 'dreamlines'), disabled: true },
    { value: 'United States', label: 'United States' },
    { value: 'Germany', label: 'Germany' },
    { value: 'Netherlands', label: 'Netherlands' },
    { value: 'Italy', label: 'Italy' },
    { value: 'France', label: 'France' },
    { value: 'Australia', label: 'Australia' },
    { value: 'Russia', label: 'Russia' },
    { value: 'Others', label: 'Others' },
];

export const countriesPortalMapping = {
    'United States': 'dreamlines.com',
    Germany: 'dreamlines.de',
    Netherlands: 'dreamlines.nl',
    Italy: 'dreamlines.it',
    France: 'dreamlines.fr',
    Autralia: 'cruiseaway.com.au',
    Russia: 'dreamlines.ru',
    Others: 'dreamlines.com',
};

export const defaultHarbours = [
    { value: '', label: 'In which cruise port did you find us?', disabled: true },
    { value: 'Barcelona', label: 'Barcelona' },
    { value: 'Lisbon', label: 'Lisbon' },
    { value: 'Malaga', label: 'Malaga' },
];

export const defaultPromoters = [
    { value: '', label: 'Choose a promoter' },
    { value: '01', label: '01' },
    { value: '02', label: '02' },
    { value: '03', label: '03' },
    { value: '04', label: '04' },
    { value: '05', label: '05' },
    { value: '06', label: '06' },
];
