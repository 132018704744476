const defaultFeatureToggles = {
    showMapBox: true,
    showStaticImagesInRouteSection: false,
    showReviews: true,
};

const mapToFeaturesFormat = (featuresObject) => {
    return Object.entries(featuresObject).map(([key, value]) => ({ name: key, isActive: value }));
};

const deFeatureToggles = {};
const nlFeatureToggles = {};
const cruiseawayFeatureToggles = { showReviews: false };

const initialFeatures = {
    'dreamlines.de': mapToFeaturesFormat({ ...defaultFeatureToggles, ...deFeatureToggles }),
    'dreamlines.nl': mapToFeaturesFormat({ ...defaultFeatureToggles, ...nlFeatureToggles }),
    'cruiseaway.com.au': mapToFeaturesFormat({
        ...defaultFeatureToggles,
        ...cruiseawayFeatureToggles,
    }),
};

export default initialFeatures;
