import { IOption, Modifier } from '../components/RequestFormPersonalInfo/ControlComponents';
import { config } from '../config';
import { __ } from '../helpers/TranslationService';
import { useTypedSelector } from './useTypedSelector';

const countryCodeMap = {
    c1au: 'au',
    c1uk: 'gb',
};

const useCountryOptions = (
    selectedPhoneCountryCode?: string,
): {
    options: IOption[];
    selectedCode: string;
    selectedCountry: string;
    selectedCountryCode: string;
    selectedCountryIndex: number;
    selectedLabel: string;
} => {
    const { pageData } = useTypedSelector((state) => state);
    const { countryCodes } = pageData?.brandConfig?.requestForm;
    const countryCode =
        countryCodeMap[pageData.appConfig.countryCode] || pageData.appConfig.countryCode;
    if (!countryCodes.length) return null;

    const selectedCode = selectedPhoneCountryCode
        ? selectedPhoneCountryCode
        : countryCodes.find((c) => c.countryCode === countryCode)?.code;

    let options: IOption[] = countryCodes.map((land) => {
        return {
            value: land.code || 'unselected',
            label: land.country,
            icon: land.countryCode ? `${config.images}/common/flags/${land.countryCode}.svg` : null,
        };
    });

    const getSelectedLabel = (selectedCode: string) => {
        if (selectedCode === '-1') return '';
        const selectedCountryCode = selectedCode
            ? countryCodes.find((country) => country.code === selectedCode)?.label
            : '';
        return selectedCountryCode;
    };

    const getSelectedCountryCode = (selectedCode: string) => {
        if (selectedCode === 'unselected') return '';
        return selectedCode
            ? countryCodes.find((country) => country.code === selectedCode)?.countryCode
            : '';
    };

    const getSelectedCountry = (selectedCode: string) => {
        if (!countryCodes.length) return '';
        return countryCodes.find((country) => country.code === selectedCode)?.country;
    };

    const getSelectedCountryIndex = (selectedCode: string) => {
        if (!selectedCode) return null;
        return countryCodes
            .map(function (c) {
                return c.code;
            })
            .indexOf(selectedCode);
    };

    if (options?.length) {
        options.unshift({
            value: Modifier.Disabled,
            label: __('general.pleaseChoose', 'dreamlines'),
            modifier: Modifier.Disabled,
        });
        return {
            options,
            selectedCode,
            selectedCountry: getSelectedCountry(selectedCode),
            selectedCountryCode: getSelectedCountryCode(selectedCode),
            selectedCountryIndex: getSelectedCountryIndex(selectedCode),
            selectedLabel: getSelectedLabel(selectedCode),
        };
    }
    return null;
};

export default useCountryOptions;
