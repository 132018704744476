export const regex = {
    countryCode: /^\+\d{1,3}$/,
    country: /^[a-z]{0,3}$/,
    name: /^[a-zA-Z\s]+\s?[a-zA-Z0-9\s]*$/,
    email: /^[^@\s]+@[^@\s]+\.[^@\s]+$/,
    phone: /^\+?\d{5,15}$/,
    onlyNumbers: /^[0-9]{1,5}$/,
    nonEmpty: /.+/,
    gender: /^[1-2]$/,
    outboundAirportCode: /^(?:[a-zA-Z]{3})?$/,
};

export function validateEmail(email) {
    const mailRegExp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    return mailRegExp.test(email);
}

export function validatePhone(phone) {
    return regex.phone.test(phone);
}

export function validateDDD(phone) {
    const phoneRegExp = /^\d{2}$/;
    return phoneRegExp.test(phone);
}

export const isEmpty = (value) =>
    value === null || !Boolean(value) || Boolean(value.toString().trim().length === 0);
