import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function ExchangeRate({ width, height, viewBox, style, className }: IIcon) {
    return (
        <Icon width={width} height={height} viewBox={viewBox} style={style} className={className}>
            <g>
                <path d="M14.17,180.93l.14.18c.17.21.36.41.55.61l.35.4h0L101,267.93a13.25,13.25,0,0,0,18.74-18.73L56.57,186h426.6a13.25,13.25,0,1,0,0-26.49H56.56l62.18-62.19A13.24,13.24,0,0,0,100,78.59L15.22,163.38h0a13.61,13.61,0,0,0-1.59,1.92,13.26,13.26,0,0,0-.16,14.63A12,12,0,0,0,14.17,180.93Z" />
                <path d="M493.58,341.32l-.13-.18c-.17-.21-.37-.41-.55-.61s-.23-.27-.36-.4h0l-85.83-85.81A13.25,13.25,0,0,0,388,273.06l63.21,63.19H24.58a13.25,13.25,0,0,0,0,26.49H451.19L389,424.93a13.25,13.25,0,0,0,18.74,18.73l84.79-84.79h0a13.61,13.61,0,0,0,1.59-1.92,13.26,13.26,0,0,0,.17-14.63A12.09,12.09,0,0,0,493.58,341.32Z" />
            </g>
        </Icon>
    );
}
