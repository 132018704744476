import * as actionTypes from '../consts/actionTypes';
import { IsomorphicHelpers } from '../helpers/IsomorphicHelpers';

export const setFeaturesInStore = (payload) => {
    return { type: actionTypes.setFeatures, payload };
};

export const setSeo = (payload) => {
    if (!IsomorphicHelpers.isServerSide) {
        const canonical = document.querySelector('link[rel="canonical"]');
        if (canonical !== null) {
            // @ts-ignore
            canonical.href = payload.canonical;
        }
    }
    return { type: actionTypes.setSeo, payload };
};
