import * as actionTypes from '../../consts/actionTypes';
import { CruiseActionEnum } from '../../consts/actionTypes';
import { ICruiseReducer } from '../../interfaces';
import { defaultSeo } from '../pageData';
import {
    changeSail,
    formatCruiseData,
    setSelectedSailIndex,
    updateRates,
} from './cruisePageMappers';

export const initialState: ICruiseReducer = {
    nid: null,
    canonicalUrl: null,
    seo: { ...defaultSeo },
    nights: null,
    title: null,
    minPrice: null,
    maxDiscount: null,
    currency: null,
    images: [],
    thumbnails: [],
    videoThumbPaths: [],
    videoUrls: [],
    routeImage: null,
    ship: {
        title: null,
        category: null,
        description: null,
        factsAndFigures: null,
        nid: null,
    },
    icons: [],
    sails: [],
    specials: [],
    selectedSailIndex: -1,
    selectedCabin: null,
    hashSail: null,
    operator: {
        nid: null,
        title: null,
        logo: null,
        isDreamlinesPackage: false,
        bookingServiceCode: null,
        tariffsDescription: null,
    },
    waypoints: [],
    company: { nid: null, title: null, logo: null, bookingServiceCode: null },
    zone: null,
    cabins: null,
    dateRange: null,
    includedServices: null,
    notesAndHints: [],
    optionalServices: null,
    excludedServices: null,
    isRoundTrip: false,
    includesFlight: false,
    cheapestCabinNidForSail: null,
    rates: [],
    ratesBySail: {},
    processedCabins: null,
    breadcrumbs: [],
    doesCompanySupportDirectBooking: false,
    directBookingConfig: null,
    shouldShowDirectBooking: false,
    doesCompanySupportFlightsForDirectBooking: false,
    shouldShowRequestNow: false,
    shouldShowDirectBookingOBC: false,
    offers: null,
    airports: [],
    loadingRates: true,
    tvOfferCode: null,
    mainRegion: null,
};

export function cruisePage(state: ICruiseReducer = initialState, action): ICruiseReducer {
    switch (action.type) {
        case actionTypes.loadCruisePageData:
            return formatCruiseData(state, action.payload);
        case actionTypes.setSelectedSailIndex:
            return setSelectedSailIndex(state, action.payload);
        case CruiseActionEnum.SET_CABIN:
            return { ...state, selectedCabin: action.payload };
        case actionTypes.loadRates:
        case actionTypes.loadRatesPending:
            return { ...state, loadingRates: true };
        case actionTypes.loadRatesFulfilled:
            return updateRates(state, action.payload);
        case actionTypes.changeSail:
            return changeSail(state, action.payload);
        case actionTypes.updateCruisePageState:
            return action.payload;
        case actionTypes.updateDirectBookingConfig:
            const {
                shouldShowDirectBooking,
                shouldShowDirectBookingOBC,
                shouldShowRequestNow,
            } = action.payload;
            return {
                ...state,
                ...{
                    shouldShowDirectBooking,
                    shouldShowDirectBookingOBC,
                    shouldShowRequestNow,
                },
            };
        case actionTypes.updateCabins:
            return { ...state, ...action.payload };
        case actionTypes.loadRoute:
            return { ...state, route: action.payload };
        case actionTypes.loadDecks:
            return { ...state, decks: action.payload };
        default:
            return state;
    }
}
