import * as React from 'react';
import { Button } from '../Button/Button';
import { __ } from '../../helpers/TranslationService';

interface IProps {
    retryClickHandler: () => void;
}

export const SubmitError = (props: IProps) => (
    <>
        <h4 className="newsletter-form--submit-error">
            {__('newsletter submit error msg', 'dreamlines')}
        </h4>
        <div className="newsletter-form--retry-button-wrapper">
            <Button fullWidth onClick={props.retryClickHandler}>
                {__('Try Again', 'dreamlines')}
            </Button>
        </div>
    </>
);
