import * as React from 'react';
import { addToFavorites, removeFromFavorites } from '../../actions/favoritesPageActions';
import { ITrackingData } from '../../helpers/EventTracker/EventTracker';
import { MarkAsFavorite } from '../MarkAsFavorite';
import { Slider } from '../Slider';
import { TrackComponent } from '../TrackComponent';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Link from '../Link';

export interface IRecommendationTeaser {
    child: JSX.Element;
    companyImage?: string;
    trackingData?: ITrackingData;
    teaserTitle?: JSX.Element;
    imageSrc?: string;
    imageAlt?: string;
    shipImage?: string;
    url: string;
    nid?: number;
}

interface IProps {
    data?: IRecommendationTeaser[];
    collapsibleComponent?: boolean;
    favorites?: number[];
    addToFavorites?: typeof addToFavorites;
    removeFromFavorites?: typeof removeFromFavorites;
}

const styles: Record<string, React.CSSProperties> = {
    logo: {
        width: 50,
        height: 35,
    },
    logoContainer: {
        position: 'absolute',
        background: 'transparent',
        zIndex: 1,
        top: 10,
        left: 10,
    },
    faveWrapper: {
        position: 'absolute',
        right: 5,
        top: 5,
        zIndex: 1,
    },
    cardWrapper: {
        scrollSnapAlign: 'start',
        flex: '1 0 100%',
    },
    card: {
        display: 'block',
        overflow: 'hidden',
        borderRadius: 6,
    },
    cardHeader: {
        position: 'relative',
        paddingBottom: '66.667%', // 600x400
    },
    image: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
    },
};

export const RecommendationSlider = (props: IProps) => {
    const sliderContainerRef = React.useRef(null);
    const { router, pageData } = useTypedSelector((state) => state);

    const { slugsObj } = pageData.brandConfig;
    const searchSlug = slugsObj.searchresults || '';
    const CompanyLogo = ({ companyImage }: IRecommendationTeaser) => {
        if (!companyImage) return null;
        return (
            <div style={styles.logoContainer}>
                <img src={companyImage} style={styles.logo} loading="lazy" alt="company logo" />
            </div>
        );
    };

    const shouldShowFavoriteBtn = (item: IRecommendationTeaser): boolean =>
        !!item.nid && !!props.favorites && !!props.addToFavorites && !!props.removeFromFavorites;

    const favoriteBtn = (item: IRecommendationTeaser): JSX.Element => {
        if (!shouldShowFavoriteBtn(item)) return null;
        const isFavorite = props.favorites.indexOf(item.nid) > -1;
        return (
            <MarkAsFavorite
                cruiseNid={item.nid}
                wrapperStyle={styles.faveWrapper}
                isFavorite={isFavorite}
                addToFavorites={props.addToFavorites}
                removeFromFavorites={props.removeFromFavorites}
            />
        );
    };

    const triggerSlide = (side: number): void => {
        const sliderContainer = sliderContainerRef?.current;
        const slider = sliderContainer?.lastChild;
        const slide = slider?.firstChild;
        const slideWidth = slide?.clientWidth;
        slider?.classList.add('smooth');
        const finalScroll = slider?.scrollLeft + side * slideWidth;
        slider.scrollLeft = finalScroll;
        slider?.classList.remove('smooth');
        const leftArrow = sliderContainerRef?.current.querySelector('#slider-arrow-left');
        const rightArrow = sliderContainerRef?.current.querySelector('#slider-arrow-right');

        if (finalScroll > 10) {
            leftArrow.classList.remove('disabled');
        } else {
            leftArrow.classList.add('disabled');
        }
        if (finalScroll + slider.clientWidth >= slider.scrollWidth - 10) {
            rightArrow.classList.add('disabled');
        } else {
            rightArrow.classList.remove('disabled');
        }
    };

    const slides = props.data.map((item, index) => {
        if (!item.imageSrc) return null;
        const hasSearchUrl = item.url.includes(searchSlug);
        const TrackableLink = TrackComponent(Link, item.trackingData);
        return (
            <div key={index} className="slider-item" style={styles.cardWrapper}>
                <TrackableLink mask={hasSearchUrl} href={item.url} style={styles.card}>
                    <div style={styles.cardHeader}>
                        {CompanyLogo(item)}
                        {favoriteBtn(item)}
                        <img
                            src={item.imageSrc}
                            style={styles.image}
                            loading="lazy"
                            alt={item.imageAlt}
                            height={170} // Height of empty space before image loads
                        />
                        {item.teaserTitle}
                    </div>
                    {item.child}
                </TrackableLink>
            </div>
        );
    });
    return (
        <div ref={sliderContainerRef} className="slider_container">
            {props.data.length > 3 && (
                <>
                    <div
                        id="slider-arrow-left"
                        onClick={() => triggerSlide(-1)}
                        className="slider-arrow disabled"
                    ></div>
                    <div
                        id="slider-arrow-right"
                        onClick={() => triggerSlide(1)}
                        className="slider-arrow"
                    ></div>
                </>
            )}
            <Slider>{slides}</Slider>
        </div>
    );
};
