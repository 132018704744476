import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const BurgerButtonWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 20px;
    height: 20px;
    margin-right: 16px;
`;

const BurgerButtonRow = (theme) =>
    css`
        width: 20px;
        height: 2.5px;
        border-radius: 2px;
        background-color: ${theme.icon.brandColor1};
    `;

const BurgerButtonStyled = styled.div`
    position: relative;
    ${({ theme }) => BurgerButtonRow(theme)}
    &::after,
    &::before {
        content: '';
        position: absolute;
        right: 0;
        left: 0;
        ${({ theme }) => BurgerButtonRow(theme)}
    }
    &::before {
        top: -6px;
    }
    &::after {
        bottom: -6px;
    }
`;
interface Props {
    onClick: React.EventHandler<any>;
}

export function BurgerButton(props: Props) {
    return (
        <BurgerButtonWrapper onClick={props.onClick}>
            <BurgerButtonStyled />
        </BurgerButtonWrapper>
    );
}
