import * as actionTypes from '../consts/actionTypes';
import { IShipDetailPageData, ICabins } from '../interfaces/IShipDetailPageData';
import { IFormattedCabins } from '../interfaces';
import { getCabinLabels } from './cruisePage/cruisePageMappers';

export const initialState: IShipData = {
    shipPage: null,
};

interface IShipData {
    shipPage: IShipDetailPageData;
}

export function getCabinsForShipPage(apiCabins: ICabins): IFormattedCabins {
    const result = {
        inside: null,
        outside: null,
        balcony: null,
        suite: null,
    };
    Object.keys(apiCabins).forEach((key) => {
        const item = apiCabins[key];
        const cabinKind = {
            perPersonPrice: 0,
            cabinNid: null,
            image: { original: item.image, thumb: item.image },
            discountPercentage: 0,
            cabins: item.cabinTypes.map((item) => ({
                perPersonPrice: 0,
                discountPercentage: 0,
                catalogPerPersonPrice: 0,
                currency: '',
                cabinType: {
                    ...item,
                    image: { original: item.image, thumb: item.image },
                    location: item.deckLocation,
                    maxPassengers: item.maximumPassengers,
                    labels: { withoutPrice: item.title.replace(': ', '') },
                },
            })),
        };
        result[key] = cabinKind;
    });
    return result;
}

export function shipData(state: IShipData = initialState, action): IShipData {
    switch (action.type) {
        case actionTypes.loadShipPageData:
            return {
                ...state,
                shipPage: {
                    ...action.payload.shipPage,
                    cabins: getCabinsForShipPage(action.payload.shipPage.cabins),
                },
            };
        default:
            return state;
    }
}
