import * as React from 'react';
import { IIcon } from '../../interfaces/IIcon';
import { Icon } from '../Icon';

export function PersonExpertIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <path
                d="M435 231v0c48 19 72 44 72 76v96h-308l-11 -50l76 -65c1 -1 2 -4 1 -6s-2 -3 -4 -3l-100 -9l-15 -34c3 -2 7 -4 11 -5c48 -19 94 -29 139 -29v0c45 0 91 10 139 29zM296 174c-29 0 -53 -10 -74 -31s-31 -46 -31 -75s10 -53 31 -74s45 -32 74 -32s53 11 74 32s31 45 31 74
s-10 54 -31 75s-45 31 -74 31zM149 281v0c1 2 2 3 4 3l81 7l-62 53c-1 1 -1 3 -1 5l19 80l-71 -43h-2h-2l-70 43l18 -80c0 -2 0 -4 -1 -5l-62 -53l82 -7c2 0 2 -1 3 -3l32 -75z"
            />
        </Icon>
    );
}
