import React from 'react';
import { config } from '../../config';
import { __ } from '../../helpers/TranslationService';
import { faqPage } from '../../consts/pageTypes';

interface IProps {
    pageType: string;
    isFooter?: boolean;
}

const styles: Record<string, React.CSSProperties> = {
    container: {
        height: 'fit-content',
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
    },
    imageContainer: {
        textAlign: 'center',
        padding: '5px 5px 5px',
    },
    heading: {
        textAlign: 'center',
        padding: '20px 0px 13px 0',
    },
};

export const Seal = (props: IProps) => {
    const sealStyles: any = {
        imageSize: {
            maxWidth: props.pageType == faqPage ? '145px' : '155px',
        },
        footerSealHeading: {
            fontSize: props.isFooter ? '12px' : '15px',
            fontWeight: props.isFooter ? '100' : 'bold',
            textTransform: props.isFooter ? 'uppercase' : 'capitalize',
        },
    };

    return (
        <>
            <div style={{ ...styles.heading, ...sealStyles.footerSealHeading }}>
                {__('Unsere Auszeichnungen', 'dreamlines')}
            </div>
            <div style={styles.container}>
                <div style={styles.imageContainer}>
                    <img
                        src={`${config.images}/dreamlines.de/kreuzfahrtportal_kundenservice.jpeg`}
                        style={sealStyles.imageSize}
                        alt="Kreuzfahrt Portal Kundenservice"
                    />
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src={`${config.images}/dreamlines.de/kreuzfahrtportal_kundenzufriedenheit.jpeg`}
                        style={sealStyles.imageSize}
                        alt="Kreuzfahrt Portal Kundenzufriedenheit"
                    />
                </div>
                <div style={styles.imageContainer}>
                    <img
                        src={`${config.images}/dreamlines.de/kreuzfahrtportal_price.jpeg`}
                        style={sealStyles.imageSize}
                        alt="Kreuzfahrt Portal Price"
                    />
                </div>
            </div>
        </>
    );
};
