import * as actionTypes from '../consts/actionTypes';
import { IBreadcrumb } from '../interfaces';

export interface IDestinationOverviewPageReducer {
    headline: string;
    subHeadline: string;
    headerImage: string;
    seoTextBottom: string;
    zones: {
        areaType: string;
        discount: number;
        title: string;
        price: number;
        currency: string;
        teaserImage: string;
        teaserDescription: string;
        uri: string;
        relevance: number;
        harbours: string;
    }[];
    breadcrumbs: IBreadcrumb[];
}

export const initialState: IDestinationOverviewPageReducer = {
    headline: '',
    subHeadline: '',
    headerImage: '',
    seoTextBottom: '',
    zones: [],
    breadcrumbs: [],
};

export function destinationOverviewPage(
    state: IDestinationOverviewPageReducer = initialState,
    action,
): IDestinationOverviewPageReducer {
    switch (action.type) {
        case actionTypes.loadDestinationOverviewPageData:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
