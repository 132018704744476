import { IFooter, IFooterLink } from '../../interfaces/IFooter';

import { FooterContactInfo } from './FooterContactInfo';
import { FooterIcons } from './FooterIcons';
import { FooterLink } from './FooterLink';
import { Seal } from '../Seal/Seal';
import React from 'react';
import { __ } from '../../helpers/TranslationService';
import styled from '@emotion/styled';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { MarketContext } from '../../context/markets';

const FooterLinkGroupLi = styled.li`
    flex-grow: 1;
    width: 100%;

    @media (max-width: ${BreakpointsEnum.sm}) {
        font-size: 15px;
        margin-bottom: 10px;
        text-align: center;
    }
`;

const StyledLi = styled.li`
    @media (max-width: ${BreakpointsEnum.sm}) {
        margin: 0 10px;
        display: inline-block;
    }
`;

const StyledUl = styled.ul`
    padding: 0;
    margin: 0;
`;

const FooterLinkTitle = styled.h3`
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0;
    width: 100%;
    text-transform: uppercase;

    @media (max-width: ${BreakpointsEnum.sm}) {
        font-size: 16px;
    }
`;

const FooterLinkList = ({ list, target }: { list: IFooterLink[]; target?: string }) => (
    <StyledUl>
        {!!list &&
            list.map((item, key) => (
                <StyledLi key={key}>
                    <FooterLink {...item} target={target} />
                </StyledLi>
            ))}
    </StyledUl>
);

interface IProps {
    brandConfig: any;
    footer: IFooter;
    pageType: string;
    isMobile: boolean;
    domainLinks: IFooterLink[];
}

const FooterNavUl = styled.ul`
    display: flex;
    margin-top: 25px;

    @media (max-width: ${BreakpointsEnum.sm}) {
        flex-direction: column;
    }
`;

const FooterBorderBottom = styled.div`
    margin: 20px 0;
    border-bottom: 1px solid #c3c3c3;
`;

const Container = styled.div`
    max-width: ${({ theme }) => theme.container.maxWidth};
    padding: 0 ${({ theme }) => theme.container.gutter};
    width: 100%;
    margin: 0 auto;

    @media (max-width: ${BreakpointsEnum.sm}) {
        background: ${({ theme }) => theme.footer.newsletterBg};
        color: ${({ theme }) => theme.colors.white[100]};
    }

    @media (max-width: ${BreakpointsEnum.md}) {
        padding-bottom: 75px;
    }

    @media (max-width: ${BreakpointsEnum.xss}) {
        padding-bottom: 85px;
    }
`;

const Copyright = styled.small`
    margin: 12px auto;
    display: block;
    line-height: 21px;
`;
const FooterSeoLinks = styled.div`
    display: flex;
    margin-top: 13px;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
`;

const FooterLinkWrapper = styled.div`
    padding: 5px 17px;
    font-size: 14px;
`;
export const FooterNav = ({ brandConfig, footer, pageType, isMobile, domainLinks }: IProps) => {
    {
        const footerOrder = ['One', 'Two', 'Three', 'Four', 'Five'];
        const { markets } = React.useContext(MarketContext);
        const { isCruise1st, isDreamlinesDE } = markets;

        const showSeal = isDreamlinesDE && !isMobile;
        const hideBestPriceAward = isCruise1st;

        return (
            <Container>
                {!isMobile && (
                    <FooterIcons
                        portalId={brandConfig.portalId}
                        socialMediaIcons={footer.socialMediaIcons}
                        paymentMethods={footer.paymentMethods}
                        awards={footer.awards}
                        security={footer.security}
                        hideBestPriceAward={hideBestPriceAward}
                    />
                )}

                {showSeal && (
                    <>
                        <FooterBorderBottom />
                        <Seal pageType={pageType} isFooter={true} />
                    </>
                )}
                {isMobile && (
                    <FooterSeoLinks>
                        {footer.bottom.map((i) => (
                            <FooterLinkWrapper key={i.linkTitle}>
                                <FooterLink {...i} />
                            </FooterLinkWrapper>
                        ))}
                    </FooterSeoLinks>
                )}
                {!isMobile && <FooterBorderBottom />}
                <FooterNavUl>
                    {footerOrder.map((i) => (
                        <FooterLinkGroupLi key={i}>
                            <FooterLinkTitle>{footer[`column${i}`]?.headline}</FooterLinkTitle>
                            <FooterLinkList list={footer[`column${i}`]?.items} />
                        </FooterLinkGroupLi>
                    ))}
                    {!isMobile && (
                        <FooterContactInfo
                            {...footer.contactInfo}
                            title={__('About us', 'dreamlines')}
                        />
                    )}
                    {isMobile && (
                        <FooterLinkGroupLi>
                            <FooterLinkTitle>{__('Our Websites', 'dreamlines')}</FooterLinkTitle>
                            <FooterLinkList list={domainLinks} target="_blank" />
                        </FooterLinkGroupLi>
                    )}
                </FooterNavUl>

                <Copyright>{footer.copyright}</Copyright>
            </Container>
        );
    }
};
