import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
import styled from '@emotion/styled';
import { InfoIconNew } from '../Icons/InfoIconNew';
import { Dialog, DialogHeader, DialogHeaderTitle, DialogContent } from '../Dialog/Dialog';
import { BreakpointsEnum } from '../../helpers/breakpoints';

const FilterBox = styled.div`
    margin: 15px 0;
    padding: 15px;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border-radius: ${({ theme }) => theme.container.borderRadius};
`;

const FilterBoxHeading = styled.div`
    font-weight: bold;
    text-align: left;
    margin-bottom: 10px;
    font-size: 20px;
`;

const CheckboxWrapper = styled.div`
    margin-top: 20;
    display: flex;
    align-items: center;
`;

const CheckboxStyled = styled.input`
    &[type='checkbox'] {
        display: inline-block;
        vertical-align: top;
        margin-right: 8px;
        margin-top: 0;
        height: 22px;
        width: 22px;
    }
`;

const CheckboxLabel = styled.label`
    display: inline-block;
    vertical-align: top;
    white-space: normal;
    font-size: 15px;
    margin: 0;
`;

const InfoIconNewWrapper = styled.span`
    margin: -2px 0 0 8px;
    cursor: pointer;
`;

const InfoIconNewStyled = styled(InfoIconNew)`
    color: ${({ theme }) => theme.icon.brandColor1};
`;

const DialogStyled = styled(Dialog)`
    @media (min-width: ${BreakpointsEnum.sm}) {
        max-width: 600px;
    }
`;
const DialogDescription = styled.div`
    font-size: 14px;
    line-height: 22px;
    > p {
        margin-top: 0;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

interface IProps {
    cabinKind: number[];
    onCabinTypeChange(param: number[]): void;
}

export const CabinTypeIdsMapper = {
    inside: 19,
    outside: 20,
    balcony: 21,
    suite: 22,
    shipinship: 23,
};

export const CabinFilter: React.FC<IProps> = (props) => {
    const [isModalOpen, setIsModalOpen] = React.useState(false);

    const handleCabinChange = (typeId) => {
        if (props.cabinKind.includes(typeId)) {
            const filteredCabinIds = props.cabinKind.filter((item) => item !== typeId);
            props.onCabinTypeChange(filteredCabinIds);
        } else {
            const newCabinIds = [...props.cabinKind, typeId];
            props.onCabinTypeChange(newCabinIds);
        }
    };

    const isCabinFilterChecked = (typeId) => {
        return props.cabinKind.includes(typeId);
    };

    const cabinTypes = Object.keys(CabinTypeIdsMapper);

    return (
        <>
            <FilterBox>
                <FilterBoxHeading>{__('cabin', 'dreamlines')}</FilterBoxHeading>
                {cabinTypes.map((cabinType) => {
                    return (
                        <CheckboxWrapper key={cabinType}>
                            <CheckboxStyled
                                id={cabinType}
                                name={cabinType}
                                type="checkbox"
                                onChange={() => handleCabinChange(CabinTypeIdsMapper[cabinType])}
                                checked={isCabinFilterChecked(CabinTypeIdsMapper[cabinType])}
                            />
                            <CheckboxLabel htmlFor={cabinType}>
                                {__(cabinType, 'dreamlines')}
                            </CheckboxLabel>
                            {cabinType === 'shipinship' && (
                                <InfoIconNewWrapper onClick={() => setIsModalOpen(true)}>
                                    <InfoIconNewStyled width={16} height={16} />
                                </InfoIconNewWrapper>
                            )}
                        </CheckboxWrapper>
                    );
                })}
            </FilterBox>
            {isModalOpen && (
                <DialogStyled open onClose={() => setIsModalOpen(false)}>
                    <DialogHeader>
                        <DialogHeaderTitle>{__('shipinship', 'dreamlines')}</DialogHeaderTitle>
                    </DialogHeader>
                    <DialogContent>
                        <DialogDescription
                            dangerouslySetInnerHTML={{
                                __html: __('shipinship.description', 'dreamlines'),
                            }}
                        />
                    </DialogContent>
                </DialogStyled>
            )}
        </>
    );
};

export default CabinFilter;
