import { readFileSync } from 'fs';
import { resolve } from 'path';
import { ISentryTags } from './interfaces/ISentryConfig';

interface IConfig {
    assets: string;
    images: string;
    styles: string;
    sentryTagsNode: ISentryTags;
    bucket?: string;
}
type AWSEnvOptions = 'testing' | 'staging' | 'production';

// Webpack sets NODE_ENV from webpack.config.js based on the `mode`
const isLocalEnv = process.env.NODE_ENV === 'development';
const isTesting = process.env.NODE_ENV === 'test';
const awsEnv = (process.env.AWS_ENV as AWSEnvOptions) || 'testing';

const isServer = typeof window === 'undefined';

const local: IConfig = {
    assets: '/website-assets',
    images: '/website-assets/images',
    styles: '/website-assets/styles',
    sentryTagsNode: {
        component: 'MOBILE_BACKEND',
        environment: 'development',
        brand: '',
    },
};

const testing: IConfig = {
    bucket: 'https://website-testing-public.dreamlines.de/mobile',
    assets: 'assets',
    images: 'assets/images',
    styles: 'assets/styles',
    sentryTagsNode: {
        component: 'MOBILE_BACKEND',
        environment: 'testing',
        brand: '',
    },
};

const staging: IConfig = {
    bucket: 'https://website-staging-public.dreamlines.de/mobile',
    assets: 'assets',
    images: 'assets/images',
    styles: 'assets/styles',
    sentryTagsNode: {
        component: 'MOBILE_BACKEND',
        environment: 'staging',
        brand: '',
    },
};

const production: IConfig = {
    bucket: 'https://website-production-public.dreamlines.de/mobile',
    assets: 'assets',
    images: 'assets/images',
    styles: 'assets/styles',
    sentryTagsNode: {
        component: 'MOBILE_BACKEND',
        environment: 'production',
        brand: '',
    },
};

const getVersion = (): string | undefined => {
    if (isServer) {
        // On the server, we fetch the version from the local file
        const versionPath = resolve('version');
        try {
            return readFileSync(versionPath).toString();
        } catch (error) {
            console.error('Could not read local version file from server');
            return undefined;
        }
    } else {
        // On the client we get the version from the html meta tag 'version'.
        // Is being injected in server.tsx when the router returns the html render
        const versionMetaTag = window.document.head.children.namedItem('version');
        if (!versionMetaTag) {
            if (!isTesting) {
                console.warn('Could not find "version" meta tag in the client html');
            }
            return undefined;
        }
        return versionMetaTag.getAttribute('content');
    }
};

// Adds the assets path to the config, so the call doesn't have to go trough the server

export const getConfig = (env: 'development' | AWSEnvOptions): IConfig => {
    if (isServer) console.log('Building config with env', env);
    const version = getVersion();
    switch (env) {
        case 'development':
            console.log('getConfig', 'version', version, 'env', env);
            return local;
        case 'testing':
            console.log('getConfig', 'version', version, 'env', env);
            if (!version) {
                // Fallback to local
                return { ...production, assets: `${local.assets}`, images: `${local.images}` };
            }
            return {
                ...testing,
                assets: `${testing.bucket}/${getVersion()}/${testing.assets}`,
                styles: `${testing.bucket}/${getVersion()}/${testing.styles}`,
                images: `${testing.bucket}/${getVersion()}/${testing.images}`,
            };
        case 'staging':
            console.log('getConfig', 'version', version, 'env', env);
            if (!version) {
                // Fallback to local
                return { ...production, assets: `${local.assets}`, images: `${local.images}` };
            }
            return {
                ...staging,
                assets: `${staging.bucket}/${getVersion()}/${staging.assets}`,
                styles: `${staging.bucket}/${getVersion()}/${staging.styles}`,
                images: `${staging.bucket}/${getVersion()}/${staging.images}`,
            };
        case 'production':
            if (!version) {
                // Fallback to local
                return { ...production, assets: `${local.assets}`, images: `${local.images}` };
            }
            return {
                ...production,
                assets: `${production.bucket}/${getVersion()}/${production.assets}`,
                styles: `${production.bucket}/${getVersion()}/${production.styles}`,
                images: `${production.bucket}/${getVersion()}/${production.images}`,
            };
        default: {
            if (isServer) console.error('No env detected on config.ts');
            return local;
        }
    }
};

export const envValue = () => {
    if (isServer) {
        console.info(
            `Resolving assets env, NODE_ENV: ${process.env.NODE_ENV}, AWS_ENV ${process.env.AWS_ENV}`,
        );
        const env = isLocalEnv ? 'development' : awsEnv;
        console.log('env for assets resolved to', env);
        return env;
    } else {
        // On the client we get the assetsEnv from the html meta tag 'assetsEnv'.
        // Is being injected in server.tsx when the router returns the html render
        const assetsEnvMetaTag = window.document.head.children.namedItem('assetsEnv');
        if (!assetsEnvMetaTag) {
            if (!isTesting) {
                console.log('Could not find "assetsEnv" meta tag in the client html');
            }
            return undefined;
        }
        return assetsEnvMetaTag.getAttribute('content') as AWSEnvOptions;
    }
};

export const config = getConfig(envValue());
