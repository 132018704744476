import * as React from 'react';
import { changeRouteSuccess } from './routerActions';
import { setRequestFormInitialData, updateRequestFormFromCookies } from './requestFormActions';
import { __ } from '../helpers/TranslationService';
import { requestFormPage } from '../consts/pageTypes';
import { defaultSeo } from '../reducers/pageData';
import * as actionTypes from '../consts/actionTypes';
import { CruiseSailsError } from '../errors/CruiseSailsError';
import loadable from '@loadable/component';

export const requestFormPageRouteAction = (context) => {
    const { path, params, baseUrl, action, store } = context;
    const storeState = store.getState();
    const { cruisePage } = storeState;
    const sailNid = Number(params.sailNid);
    const cabinNid = Number(params.cabinNid);

    if (!sailNid || !cabinNid) {
        return;
    }
    // sorting the cabins as per their Nids to fix the server and
    // client html mismatch warning on SSR of request form
    context.store.dispatch({
        type: actionTypes.updateCabins,
        payload: {
            cabins: cruisePage.cabins.slice(0).sort((a, b) => a.cabinType.nid - b.cabinType.nid),
        },
    });
    const pageTitle = `${__('Send Request', 'dreamlines')} ${cruisePage.title} | ${
        cruisePage.ship.title
    } | ${cruisePage.company.title}`;
    return context.store
        .dispatch(setRequestFormInitialData({ sailNid, cabinNid }))
        .then(() => {
            const seo = { ...defaultSeo };
            seo.titleOg = pageTitle;
            seo.robotsNoFollow = true;
            seo.robotsNoIndex = true;

            context.store.dispatch(
                changeRouteSuccess({
                    path,
                    params,
                    baseUrl,
                    action,
                    pageType: requestFormPage,
                    seo: seo,
                }),
            );

            const RequestFormPage = loadable(() => import('../pages/RequestForm/RequestFormPage'));
            return {
                component: <RequestFormPage />,
                context,
            };
        })
        .catch((error) => {
            throw new CruiseSailsError(baseUrl);
        });
};
