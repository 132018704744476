import { useEffect, useState } from 'react';
import { IOption } from '../components/RequestFormPersonalInfo/ControlComponents';
import useValidation, { IFieldValid, ValidatorsEnum } from './useValidation';
export interface IControl extends IFieldValid {
    value: string | boolean | number;
    options?: IOption[];
    isDirty: boolean;
    onChange: (e: any) => void;
    onBlur: (e: any) => void;
    setDirty?: (value: boolean) => void;
}
const str2bool = (value) => {
    if (
        value &&
        typeof value === 'string' &&
        ['true', 'false', 'on'].includes(value.toLowerCase())
    ) {
        return value.toLowerCase() === 'true' || value.toLowerCase() === 'on';
    }
    return value;
};
export const useControl = ({
    initialValue,
    validators,
    options,
    validateNow = false,
}: {
    initialValue: string | number | boolean;
    validators?: ValidatorsEnum[];
    options?: IOption[];
    validateNow?: boolean;
}) => {
    const [value, setValue] = useState<string | number | boolean>(str2bool(initialValue));
    const [isDirty, setDirty] = useState(validateNow);
    const valid: IFieldValid = useValidation(value, validators);

    useEffect(() => {
        setValue(str2bool(initialValue));
    }, [initialValue]);

    const onChange = (e) => {
        let value: string | boolean | number = e.target.value;
        // Dubplicated code to update checkbox value when !isDirty for checkbox
        // Safari doesn't support onFocus for checkbox
        // onBlur never fired and it's never "dirty"
        if (e.target.type === 'checkbox') {
            value = (e as React.ChangeEvent<HTMLInputElement>).target.checked;
            setValue(str2bool(value));
        }

        if (isDirty) {
            if (e.target.type === 'radio') {
                value = (e as React.ChangeEvent<HTMLInputElement>).target.checked;
            }
            setValue(str2bool(value));
        }
    };
    const onBlur = (e) => {
        let value: string | boolean | number = e.target.value;
        if (e.target.type === 'radio') {
            value = (e as React.ChangeEvent<HTMLInputElement>).target.checked;
        }
        setValue(str2bool(value));
        setDirty(true);
    };
    return <IControl>{
        value,
        isDirty,
        onChange,
        onBlur,
        ...valid,
        options,
        setDirty,
    };
};
