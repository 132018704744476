import * as React from 'react';
import { config } from '../../config';
import * as vars from '../../consts/variables';
import { __ } from '../../helpers/TranslationService';
import { ErrorPageCtaSection } from './components/ErrorPageCtaSection';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        textAlign: 'center',
    },
    header: {
        position: 'relative',
    },
    headerText: {
        fontSize: 150,
        letterSpacing: '7.2px',
        textAlign: 'center',
        color: 'rgba(7, 73, 111, 0.1)',
    },
    imgStyle: {
        position: 'absolute',
        width: 72,
        top: '50%',
        left: '49%',
        transform: 'translate(-50%,-50%)',
    },
};

export class NotFoundPage extends React.Component<any, any> {
    render() {
        return (
            <div className="errorPage-wrapper">
                <div className="errorPage-content">
                    <div style={style.wrapper}>
                        <div style={style.header}>
                            <div style={style.headerText}>404</div>
                            <img
                                style={style.imgStyle}
                                src={`${config.images}/common/island.svg`}
                                alt=""
                            />
                        </div>
                    </div>
                    <div className="errorPage-sorryMsg">
                        {__(
                            '...Oops! Seems that this page never returned from vacation.',
                            'dreamlines',
                        )}
                    </div>
                </div>

                <ErrorPageCtaSection />
            </div>
        );
    }
}

export default NotFoundPage;
