import styled from '@emotion/styled';
import * as React from 'react';
import { acqa, white } from '../../consts/variables';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';

const SC_BackToTopButton = styled.button`
    border: 0;
    outline: 0;
    padding: 0;
    z-index: 99;
    right: 30px;
    width: 42px;
    bottom: 25px;
    height: 37px;
    opacity: 0.5;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.button.primary.background};
    transition: opacity 0.15s ease;
    &:hover {
        opacity: 1;
    }
    svg {
        fill: ${({ theme }) => theme.colors.white[100]};
        transform: rotateZ(-180deg);
    }
`;

export const BackToTop = () => {
    return (
        <SC_BackToTopButton
            onClick={(evt) =>
                window.scrollTo({
                    top: 0,
                    behavior: 'smooth',
                })
            }
        >
            <ArrowDownIcon width={18} height={18} />
        </SC_BackToTopButton>
    );
};
