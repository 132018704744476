import * as React from 'react';
import { IIcon } from '../interfaces/IIcon';

interface IProps extends IIcon {
    children: JSX.Element;
}

const iconStyle = {
    position: 'relative',
    zIndex: 1,
};

const darkShadowStyle = {
    position: 'absolute',
    fill: 'rgba(0, 0, 0)',
    filter: 'blur(3px)',
};

const lightShadowStyle = {
    position: 'absolute',
    fill: 'rgba(30, 30, 30)',
    filter: 'blur(2px)',
};

export const Icon = ({
    width = 24,
    height = 24,
    style,
    viewBox = '0 0 512 512',
    children,
    className,
    dropShadow = 'none',
}: IProps) => (
    <>
        {dropShadow === 'dark' && (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewBox}
                width={width}
                height={height}
                style={{ ...style, ...darkShadowStyle }}
                className={className}
            >
                {children}
            </svg>
        )}
        {dropShadow === 'light' && (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox={viewBox}
                width={width}
                height={height}
                style={{ ...style, ...lightShadowStyle }}
                className={className}
            >
                {children}
            </svg>
        )}
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox={viewBox}
            width={width}
            height={height}
            style={dropShadow === 'none' ? style : { ...iconStyle, ...style }}
            className={className}
        >
            {children}
        </svg>
    </>
);
