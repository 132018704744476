import { BreakpointsEnum } from '../breakpoints';
import useWindowSize, { IViewPortSize } from '../../hooks/useViewport';

export const isDesktop = (width) => {
    const onlyInt = (str): number => {
        return str && typeof str !== 'number' ? parseInt(str.replace(/\D/g, '')) : str;
    };
    const minWidth = onlyInt(BreakpointsEnum.md);
    return width >= minWidth;
};

export const isMobile = (width) => {
    const onlyInt = (str): number => {
        return str && typeof str !== 'number' ? parseInt(str.replace(/\D/g, '')) : str;
    };
    const maxWidth = onlyInt(BreakpointsEnum.sm);
    return width <= maxWidth - 1;
};
