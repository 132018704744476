import * as React from 'react';

interface Props {
    title: string;
    theme?: string;
    closeButton: boolean;
    handleClose: () => void;
}

export const ModalHeader = (props: Props) => {
    return (
        <div className={`modal__header ${props.theme} ${props.title ? 'has-title' : ''}`}>
            {props.closeButton && (
                <button
                    className="modal__close-btn"
                    type="button"
                    aria-label="close"
                    onClick={props.handleClose}
                >
                    <span className="icon-cross" aria-hidden="true"></span>
                </button>
            )}
            {props.title && <span className="modal__title">{props.title}</span>}
        </div>
    );
};
