import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { PhoneIcon } from '../Icons/PhoneIcon';
import { __ } from '../../helpers/TranslationService';
import { eventTracker } from '../../helpers/EventTracker/EventTracker';

interface Props {
    phoneNumber: string;
    pageType: string;
}

const CallUsButton = styled.a`
    width: 100%;
    padding: 8px 16px;
    background: linear-gradient(to right, ${({ theme }) => theme.colors.golden[100]}, ${({
    theme,
}) => theme.colors.yellowSea[100]});
    box-shadow: 0 2px 6px 0 rgba(0,0,0,.12);
    border-radius: 6px;
    align-items: center;
    color: ${({ theme }) => theme.text.color.primary};
    display: flex;
    border: 1px solid ${({ theme }) => theme.colors.yellowSea[100]};
    max-width: 400px;
    &:hover, &:active, &:visited {
        color: ${({ theme }) => theme.text.color.primary};\
        text-decoration: none;
    }
`;

const Container = styled.div`
    width: 100%;
    padding: 0 16px;
    position: fixed;
    z-index: ${(props) => props.theme.overlayIndexes.callUsBanner};
    bottom: 16px;
    display: flex;
    justify-content: center;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    align-items: center;
`;

const CallUsTitle = styled.h4`
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    text-align: center;
`;

const CallUsNumber = styled.span`
    font-size: 15px;
`;

const StyledPhoneIcon = styled(PhoneIcon)`
    height: 24px;
    width: 24px;
    flex-shrink: 0;
`;

export const CallUsFooter = ({ phoneNumber, pageType }: Props) => {
    const [url, setUrl] = useState('');

    const handleClick = () => {
        eventTracker.trackG4Event('call_us_banner', {
            page_type: pageType,
            page_url: url,
        });
    };

    useEffect(() => {
        setUrl(window.location.href);
    }, [pageType]);

    return (
        <Container>
            <CallUsButton onClick={handleClick} href={`tel:${phoneNumber}`}>
                <StyledPhoneIcon />

                <TitleWrapper>
                    <CallUsTitle>{__('CallUsTitle', 'dreamlines')}</CallUsTitle>

                    <CallUsNumber>{phoneNumber}</CallUsNumber>
                </TitleWrapper>
            </CallUsButton>
        </Container>
    );
};
