import * as React from 'react';
import { MainNavigationItem } from './MainNavigationItem';
import { INavigationItem } from '../../interfaces/IHeader';
import * as Colors from '../../consts/variables';
import { HomeIcon } from '../Icons/HomeIcon';
import { SearchIcon } from '../Icons/SearchIcon';
import { ShipIcon } from '../Icons/ShipIcon';
import { DiscountIcon } from '../Icons/DiscountIcon';
import { UserIcon } from '../Icons/UserIcon';
import { WorldIcon } from '../Icons/WorldIcon';
import { AnchorIcon } from '../Icons/AnchorIcon';
import { FaqIcon } from '../Icons/FaqIcon';
import { CompassIcon } from '../Icons/CompassIcon';
import { PackageIcon } from '../Icons/PackageIcon';
import { InfoNoFillIcon } from '../Icons/InfoNoFillIcon';
import { FavoritesIcon } from '../Icons/FavoritesIcon';
import { BookingIcon } from '../Icons/BookingIcon';
import { BrochureIcon } from '../Icons/BrochureIcon';
import { TVIcon } from '../Icons/TVIcon';
import { CalendarIcon } from '../Icons/CalendarIcon';
import { __ } from '../../helpers/TranslationService';
import styled from '@emotion/styled';

interface Props {
    menuItems: INavigationItem[];
    isTopNavigation?: boolean;
    seoContent?: string;
    favoriteCount?: number;
}

const homeNavItemStyle: any = {
    border: `1px solid ${Colors.darkGray}`,
    margin: '10px 15px',
};

const lastTopNavItemStyle: any = {
    borderBottom: '0',
};

const MenuWrapper = styled.ul`
    margin: 0px 0 15px;
    padding: 0;
`;

const IconWrapper = styled.span`
    color: ${({ theme }) => theme.icon.brandColor1};
    fill: ${({ theme }) => theme.icon.brandColor1};
    margin-right: 5%;
`;

const BlankIconStyled = styled.div`
    margin-right: 5%;
    width: 24px;
    height: 24px;
`;

const MenuClasses = [
    { label: 'HomeIcon', icon: HomeIcon, isShownAtCollapsibleSection: false },
    { label: 'SearchIcon', icon: SearchIcon, isShownAtCollapsibleSection: false, mask: true },
    { label: 'WorldIcon', icon: WorldIcon, isShownAtCollapsibleSection: true },
    { label: 'AnchorIcon', icon: AnchorIcon, isShownAtCollapsibleSection: true },
    { label: 'ShipIcon', icon: ShipIcon, isShownAtCollapsibleSection: true },
    { label: 'CompassIcon', icon: CompassIcon, isShownAtCollapsibleSection: false },
    { label: 'DiscountIcon', icon: DiscountIcon, isShownAtCollapsibleSection: true },
    { label: 'FaqIcon', icon: FaqIcon, isShownAtCollapsibleSection: false },
    { label: 'UserIcon', icon: UserIcon, isShownAtCollapsibleSection: false },
    { label: 'PackageIcon', icon: PackageIcon, isShownAtCollapsibleSection: false },
    { label: 'FavoriteIcon', icon: FavoritesIcon, isShownAtCollapsibleSection: false },
    { label: 'BookingIcon', icon: BookingIcon, isShownAtCollapsibleSection: false },
    { label: 'TVIcon', icon: TVIcon, isShownAtCollapsibleSection: false },
    { label: 'BrochureIcon', icon: BrochureIcon, isShownAtCollapsibleSection: false },
    { label: 'CalendarIcon', icon: CalendarIcon, isShownAtCollapsibleSection: false },
];

export function MainNavigationMenu({
    seoContent,
    menuItems,
    favoriteCount,
    isTopNavigation = true,
}: Props) {
    const getMainNavigation = () => {
        return menuItems.map((item, index) => {
            const isFavorite = item.icon === 'FavoriteIcon';

            const isLastItem = index === menuItems.length - 1;
            const topNavItemStyle: any = isLastItem ? lastTopNavItemStyle : {};
            const NavMenuItem = MenuClasses.find((iconClass) => {
                return iconClass.label === item.icon;
            });

            const MenuIcon = NavMenuItem?.icon ? (
                <NavMenuItem.icon width={24} height={24} />
            ) : (
                <BlankIconStyled />
            );
            const showItem =
                isTopNavigation || (NavMenuItem && NavMenuItem.isShownAtCollapsibleSection);
            const hasMask = NavMenuItem && NavMenuItem.mask;
            if (showItem) {
                return (
                    <MainNavigationItem
                        key={index}
                        item={item}
                        itemStyle={isTopNavigation ? topNavItemStyle : homeNavItemStyle}
                        isTopNavigation={isTopNavigation}
                        counter={isFavorite ? favoriteCount : undefined}
                        mask={hasMask}
                    >
                        <IconWrapper>{MenuIcon}</IconWrapper>
                    </MainNavigationItem>
                );
            }
            return null;
        });
    };

    return (
        <MenuWrapper>
            {getMainNavigation()}
            {seoContent && (
                <MainNavigationItem
                    item={{ title: __('seoItemTitle', 'dreamlines'), targetUrl: '' }}
                    isTopNavigation={false}
                    itemStyle={homeNavItemStyle}
                    text={seoContent}
                >
                    <IconWrapper>
                        <InfoNoFillIcon />
                    </IconWrapper>
                </MainNavigationItem>
            )}
        </MenuWrapper>
    );
}
