import { loadableReady } from '@loadable/component';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory as createHistory } from 'history';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import Router from 'universal-router';
import '../assets/styles/main.scss'; // From local assets because it gets compiled by WebPack
import { initABTests } from './actions/abTestActions';
import { initHistory, setEnvironment } from './actions/routerActions';
import { Root } from './components/Root';
import { configureStore } from './configureStore';
import { sentryConfigFrontend } from './consts/SentryConfigFrontend';
import { eventTracker } from './helpers/EventTracker/EventTracker';
import { tradedoublerTracker } from './helpers/TradedoublerTracker/TradedoublerTracker';
import { TranslationService } from './helpers/TranslationService';
import { didUrlChange } from './helpers/historyHelpers';
import { get } from './helpers/http';
import { checkNewsletterQuerystringParams } from './helpers/newsletterHelpers';
import { updatePageTitle } from './helpers/rendererHelpers';
import { scrollToId, setScrollRestoration } from './helpers/scroll';
import { shutdownRedirect } from './helpers/shutdownRedirectHelpers';
import { unescapeHTML } from './helpers/unescapeHTML';
import { IState } from './reducers';
import { getRoutes } from './routes';
import NotFoundPage from './pages/Error/NotFoundPage';
import { hideLoadingIndicator } from './consts/actionTypes';
import ErrorPage from './pages/Error/ErrorPage';

const initialState: IState = (window as any).appState;
const store = configureStore(initialState, true);
const routes = getRoutes(store.getState());
const router = new Router(routes);
let waitForAction = false;

// triggers getUserConfirmation of history
const blockFunction = (location, action): string => JSON.stringify({ location, action });

const history = createHistory({
    // calls renderFunc after listener promise is resolved
    getUserConfirmation: (locationActionString: string, renderFunc: Function): Promise<any> => {
        const { location, action } = JSON.parse(locationActionString);
        return listener(location, action).then(() => renderFunc(true));
    },
});

const {
    appConfig: { apiHost, environment, buildVersion, languageCode, countryCode: market },
} = store.getState().pageData;

Sentry.init({
    dsn: sentryConfigFrontend.sentryDSN,
    enabled: environment !== 'development',
    environment,
    release: buildVersion,
    tracesSampleRate: 0.05,
    sampleRate: environment === 'production' ? 0.01 : 1,
    integrations: [new BrowserTracing()],
});

setScrollRestoration('manual');

store.dispatch(initHistory(history));
store.dispatch(setEnvironment({ isBrowser: true }));
store.dispatch(initABTests());

(window as any).dataLayer.push({ event: 'optimize.activate' });

history.listen(() => {
    (window as any).dataLayer.push({ event: 'urlChanged' });
    (window as any).dataLayer.push({ event: 'optimize.activate' });
});

eventTracker.initEcommerce();

function listener(location: Location, action: string): Promise<any> {
    const { baseUrl, path } = store.getState().router;
    const routerUrl = `${baseUrl}${path !== '/' ? path : ''}`;
    const { search } = store.getState().router.location;
    if (didUrlChange(routerUrl, search, location.pathname, location.search)) {
        return renderApp(location, action);
    }
    const { hash } = location;
    if (hash.length > 0) {
        const id = hash.slice(1, hash.length);
        scrollToId(id);
    }
    return new Promise<void>((resolve) => resolve());
}

function renderRoute(route, store) {
    if (route.redirect) {
        window.location = route.redirect;
        return;
    }
    const { hash } = location;
    checkNewsletterQuerystringParams(window.location.href, false);
    tradedoublerTracker.checkTDUIDParam();
    const App = () => (
        <Provider store={store}>
            <Root {...initialState.pageData} store={store} component={route.component} />
        </Provider>
    );
    const storeState = store.getState();

    // Updating app state on every render
    (window as any).appState = storeState;
    const { seo } = storeState.pageData;

    // checks if the market has been shut down
    shutdownRedirect(storeState.pageData, location);

    waitForAction = true;
    if (seo?.titleOg) {
        updatePageTitle(unescapeHTML(seo.titleOg));
    }

    loadableReady(() => {
        const rootElement = document.getElementById('react-app');
        ReactDOM.hydrate(<App />, rootElement);
    });

    if (hash.length > 0) {
        const id = hash.slice(1, hash.length);
        scrollToId(id);
    }
}

function renderApp(location: Location, action: string) {
    return router
        .resolve({
            path: location.pathname,
            queryParams: location.search,
            search: location.search,
            store,
            waitForAction,
            action,
        })
        .then((route) => {
            renderRoute(route, store);
        })
        .catch((err) => {
            Sentry.captureException(err);
            if (environment === 'development') {
                console.error(err);
            }
            store.dispatch({ type: hideLoadingIndicator });

            const isNotFoundError = err?.statusCode === 404;
            renderRoute({ component: isNotFoundError ? <NotFoundPage /> : <ErrorPage /> }, store);
        });
}

export class Application {
    constructor() {
        this.boot();
    }

    async boot() {
        const storeState = store.getState();

        try {
            const translationData = await get(
                `${apiHost}/localization?${storeState.pageData.bootstrapTime}`,
            );

            TranslationService.registerTranslations(market, languageCode, translationData);
            history.block(blockFunction);
            renderApp(window.location, 'PUSH');
        } catch (error) {
            Sentry.captureException(error);
        }
    }
}

new Application();
