import * as actionTypes from '../consts/actionTypes';
import { IZoneTab } from '../interfaces/IZoneTabs';

interface ISeo {
    titleOg: string;
    robotsNoFollow: boolean;
    robotsNoIndex: boolean;
    metadesc: string;
}

interface IHomePageSEO extends ISeo {
    text: string;
    keywords: string;
    canonical: string;
    section?: IHomePageSEOSection | null;
}

export interface IHomePageSEOSection {
    status: boolean;
    content: string;
    text?: string;
}
export interface ITeaser {
    url: string;
    image: string;
    text?: string;
    name?: string;
    price: number;
    label?: string;
    zoneId?: string;
    altText?: string;
    discount: number;
    description: string;
    searchType?: string;
    offerCount?: number;
    companyLogo?: string;
    searchParams?: string;
}

interface ITeasers {
    status: boolean;
    items: ITeaser[];
    buttonLink?: string;
    buttonText?: string;
    headline?: string;
    subline?: string;
}

export interface IResponsiveImage {
    desktop: string;
    mobile: string;
}
export interface ITopOffer {
    status: boolean;
    companyImage: string;
    headline: string;
    subtitle: string;
    subline: string;
    searchType: string;
    searchParams: string;
    buttonText: string;
    buttonLink: string;
    price: number;
    discount: number;
    url: string;
    inclusives?: boolean;
}

interface IPriceData {
    cheapestPrice: number;
    currency: string;
    highestDiscount: number;
}

export interface IOldTeaser {
    bottomText: string;
    image: string;
    customUrl: string;
    mobilefrontPageId: number;
    searchParams: string;
    searchString: string;
    teaserId: number;
    title: string;
    priceData: IPriceData;
}

export interface IDreamDealSource {
    status: boolean;
    companyImage: string;
    backgroundImage: string;
    cruiseId: string;
    description: string;
    subline: string;
    buttonText: string;
    buttonLink: string;
}
export interface IDreamDeal extends IDreamDealSource {
    availableUntil: string | number;
    timestamp: string | number;
    price: number;
    discount: number;
    icons: { icon: string; text: string }[];
}

interface IStage {
    backgroundImage: IResponsiveImage;
}
interface IThemeTopicBanner {
    status: boolean;
    images: {
        desktop: string;
        mobile: string;
    };
    headline: string;
    subline: string;
    buttonText: string;
    buttonLink: string;
}
export interface IHomePageData {
    uri?: string;
    stage: IStage;
    seo: IHomePageSEO;
    topTeasers?: ITeasers;
    teasers?: IOldTeaser[];
    dreamDeal?: IDreamDeal;
    topOffer?: ITopOffer;
    themeTopicBanner?: IThemeTopicBanner;
    cruiseLineGallery: ITeasers;
    destinationGallery: ITeasers;
    themeCruiseTeasers: ITeasers;
    cruiseLineLogos: {
        status: boolean;
        items: {
            text: string;
            image: string;
            url: string;
        }[];
        buttonLink?: string;
        buttonText?: string;
    };
    zoneTabs: null | IZoneTab[];
}

export interface IHomePageReducer {
    homePage: IHomePageData;
}

export const initialState: IHomePageReducer = {
    homePage: null,
};

export function homePage(state: IHomePageReducer = initialState, action): IHomePageReducer {
    switch (action.type) {
        case actionTypes.loadHomePageData:
            const homePage = action.payload;
            return { ...state, homePage };
        default:
            return state;
    }
}
