import * as actionTypes from '../consts/actionTypes';
import { deepClone } from '../helpers/deepClone';
import { IMobileSearchResultCruise } from '../interfaces/ISearchApi';

export interface IFavoritePageReducer {
    content: string;
    title: string;
    favorites: number[];
    favoritesCruiseData: IMobileSearchResultCruise[];
    socialShareLink: string;
    emailShareLink: string;
}

export const initialState: IFavoritePageReducer = {
    content: null,
    title: null,
    favorites: [],
    favoritesCruiseData: [],
    socialShareLink: null,
    emailShareLink: null,
};

export function favoritePage(
    state: IFavoritePageReducer = initialState,
    action,
): IFavoritePageReducer {
    switch (action.type) {
        case actionTypes.loadFavoritePageData:
            const { content, title } = action.payload;
            return { ...state, content, title };
        case actionTypes.loadFavoritesCruiseNids:
            return { ...state, favorites: action.payload };
        case actionTypes.loadFavoritesCruiseData:
            return deepClone({ ...state, favoritesCruiseData: action.payload });
        case actionTypes.addToFavorites:
        case actionTypes.updateFavorites:
            return deepClone({ ...state, favorites: action.payload });
        case actionTypes.removeFromFavorites:
            const { favorites, favoritesCruiseData } = action.payload;
            return deepClone({ ...state, favorites, favoritesCruiseData });
        case actionTypes.setFavoritesShareLink:
            const { socialShareLink, emailShareLink } = action.payload;
            return { ...state, socialShareLink, emailShareLink };
        default:
            return state;
    }
}
