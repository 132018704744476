import * as propTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { clearFilterParams } from '../../actions/searchActions';
import { __ } from '../../helpers/TranslationService';
import { IHeader } from '../../interfaces/IHeader';
import { IState } from '../../reducers';
import { IBrandConfig, IConfig } from '../../reducers/pageData';
import { Logo } from '../Header/Logo';
import { HeaderContactBlock } from './HeaderContactBlock';
import { HeaderMainNavBar } from './HeaderMainNavBar';
import { TrustBadges } from '../TrustBadges/TrustBadges';
import { Favorites } from '../Favorites/Favorites';
import styled from '@emotion/styled';

interface IProps {
    items: IHeader;
    config: IConfig;
    favoriteCount: number;
    favoritesUrl: string;
    brandConfig: IBrandConfig;
    safePayment?: JSX.Element;
}

type Props = IProps & ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps;

const ContactBlockWrapper = styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
`;

const HeaderWrapper = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    border-bottom: 2px solid #f5f5f5;
    height: 90px;
    width: 100%;
    background: #fff;
    z-index: ${(props) => props.theme.overlayIndexes.headerContainer};
`;

export class HeaderDefaultClass extends React.Component<Props> {
    static childContextTypes = {
        countryCode: propTypes.string,
        contentEndpoint: propTypes.string,
        apiHost: propTypes.string,
        brandConfig: propTypes.any,
    };

    getChildContext = () => ({
        countryCode: this.props.countryCode,
        contentEndpoint: null,
        apiHost: this.props.apiHost,
        brandConfig: this.props.brandConfig,
    });

    render() {
        const officeHours = this.props.items.headerContent?.officeHours;
        const { favoriteCount, favoritesUrl } = this.props;

        const firstLine =
            officeHours?.firstLine && officeHours.firstLine.length > 0
                ? officeHours.firstLine
                : __('Mon - Fri: 8am - 6pm Sat: 9am - 3pm', 'dreamlines');
        const secondLine =
            officeHours?.secondLine && officeHours.secondLine.length > 0
                ? officeHours.secondLine
                : '';

        return (
            <header>
                <div className="container-fluid globalPageheader">
                    <div className="row">
                        <HeaderWrapper className="row">
                            <div className="container">
                                <div className="row globalPageheader_brandHeight">
                                    <div className="globalPageheader_brand col-sm-4">
                                        <Logo
                                            clearFilterParams={this.props.clearFilterParams}
                                            portalId={this.props.brandConfig.portalId}
                                            forceReload={true}
                                        />
                                    </div>

                                    <div className="col-sm-4 globalPageheader_trustLogos">
                                        <TrustBadges portalId={this.props.brandConfig.portalId} />
                                    </div>

                                    <ContactBlockWrapper>
                                        <Favorites count={favoriteCount} pageUrl={favoritesUrl} />
                                        <HeaderContactBlock
                                            contactPagePath={this.props.brandConfig.contactPagePath}
                                            phoneNumber={this.props.phoneNumber}
                                            firstLine={firstLine}
                                            secondLine={secondLine}
                                        />
                                    </ContactBlockWrapper>
                                </div>
                            </div>
                        </HeaderWrapper>
                        <HeaderMainNavBar
                            items={this.props.items}
                            countryCode={this.props.countryCode}
                            apiHost={this.props.apiHost}
                            brandConfig={this.props.brandConfig}
                        />
                    </div>
                </div>
            </header>
        );
    }
}

const mapDispatchToProps = {
    clearFilterParams,
};

const mapStateToProps = (state: IState) => ({
    apiHost: state.pageData.appConfig.apiHost,
    countryCode: state.pageData.appConfig.countryCode,
    pageType: state.router.pageType,
    favoriteCount: state.favoritePage.favorites.length,
    favoritesUrl: state.pageData.brandConfig.slugsObj.favorites,
    phoneNumber: state.pageData.brandConfig.phoneNumber,
});

export const HeaderDefault = connect(mapStateToProps, mapDispatchToProps)(HeaderDefaultClass);
