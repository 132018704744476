import { useEffect, useState } from 'react';
import { Modifier } from '../components/RequestFormPersonalInfo/ControlComponents';
import { childAgeErrorMessagesTranslationKeys } from '../components/RequestForm/helpers';
import { __ } from '../helpers/TranslationService';
import { regex } from '../helpers/validation';

export enum ValidatorsEnum {
    isChildAgesValid = 'isChildAgesValid',
    isEmpty = 'isEmpty',
    isLastnameEmpty = 'isLastnameEmpty',
    isFirstnameEmpty = 'isFirstnameEmpty',
    isEmailEmpty = 'isEmailEmpty',
    isEmailValid = 'isEmailValid',
    isPhoneEmpty = 'isPhoneEmpty',
    isPhoneValid = 'isPhoneValid',
    isPhoneCodeValid = 'isPhoneCodeValid',
    isFlightsEmpty = 'isFlightsEmpty',
    isAirportEmpty = 'isAirportEmpty',
    isCityEmpty = 'isCityEmpty',
    isNotDisabled = 'isNotDisabled',
}
export interface IFieldValid {
    isValid: boolean;
    errors: string[];
}
const useValidation = (
    value: string | number | boolean | any,
    validators?: ValidatorsEnum[],
): IFieldValid => {
    const [isFieldValid, setFieldValid] = useState<IFieldValid>({ isValid: false, errors: [] });
    const isEmpty = (value) => {
        return !(
            (typeof value === 'boolean' && (value === false || value === true)) ||
            typeof value === 'number' ||
            value
        );
    };
    useEffect(() => {
        let errors = [];
        validators?.forEach((validator) => {
            switch (validator) {
                case ValidatorsEnum.isEmpty:
                    if (isEmpty(value)) {
                        errors.push('');
                    }
                    break;
                case ValidatorsEnum.isNotDisabled:
                    if (value === Modifier.Disabled) {
                        errors.push('');
                    }
                    break;
                case ValidatorsEnum.isFirstnameEmpty:
                    if (isEmpty(value)) {
                        errors.push(__('errors.firstname', 'dreamlines'));
                    }
                    break;
                case ValidatorsEnum.isLastnameEmpty:
                    if (isEmpty(value)) {
                        errors.push(__('errors.lastname', 'dreamlines'));
                    }
                    break;
                case ValidatorsEnum.isFlightsEmpty:
                    if (isEmpty(value)) {
                        errors.push(__('errors.flight', 'dreamlines'));
                    }
                    break;
                case ValidatorsEnum.isAirportEmpty:
                    if (isEmpty(value)) {
                        errors.push(__('errors.airport', 'dreamlines'));
                    }
                    break;
                case ValidatorsEnum.isCityEmpty:
                    if (isEmpty(value)) {
                        errors.push(__('errors.location', 'dreamlines'));
                    }
                    break;
                case ValidatorsEnum.isEmailEmpty:
                    if (isEmpty(value)) {
                        errors.push(__('errors.email', 'dreamlines'));
                    }
                    break;
                case ValidatorsEnum.isEmailValid:
                    if (!isEmpty(value) && typeof value === 'string') {
                        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        if (!regex.test(value)) {
                            errors.push(__('errors.emailValid', 'dreamlines'));
                        }
                    }
                    break;
                case ValidatorsEnum.isPhoneValid:
                    if (!isEmpty(value) && typeof value === 'string') {
                        if (!regex.phone.test(value)) {
                            errors.push(__('errors.phoneValid', 'dreamlines'));
                        }
                    }
                    break;
                case ValidatorsEnum.isPhoneCodeValid:
                    if (!isEmpty(value) && typeof value === 'string') {
                        if (!regex.onlyNumbers.test(value)) {
                            errors.push(__('errors.phoneValid', 'dreamlines'));
                        }
                    }
                    break;
                case ValidatorsEnum.isPhoneEmpty:
                    if (isEmpty(value)) {
                        errors.push(__('errors.phoneValid', 'dreamlines'));
                    }
                    break;
                case ValidatorsEnum.isChildAgesValid:
                    if (!value?.length) {
                        break;
                    }
                    const isOneOfDatesEmpty = value.filter((item) => item.age === null).length > 0;
                    const hasInvalidDates = value.every((item) => !item.isValid);

                    if (isOneOfDatesEmpty) {
                        errors.push(__(childAgeErrorMessagesTranslationKeys.required, 'dreamlines'));
                    }
                    if (hasInvalidDates) {
                        errors.push(__(childAgeErrorMessagesTranslationKeys.lessThenSixMonth, 'dreamlines'));
                    }
                    break;
                default:
                    break;
            }
        });
        setFieldValid({ ...isFieldValid, errors, isValid: !errors.length });
    }, [value]);
    return isFieldValid;
};
export default useValidation;
