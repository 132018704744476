import * as React from 'react';
import styled from '@emotion/styled';
interface Props {
    content?: string | null;
}

const SeoWrapper = styled.section`
    background: transparent;
    margin: 30px auto;
    max-width: 1140px;
    text-align: left;
`;

const SeoText = styled.div`
    font-size: 16px;
    margin-top: 4px;
    color: ${({ theme }) => theme.text.color.primary};

    & h4,
    & h3 {
        text-align: center;
    }

    & a {
        text-decoration: underline;
        color: ${({ theme }) => theme.link.primary};
    }
`;

export const SEOHome = (props: Props) => {
    const content = props.content;
    const sanitizedContent = content ? content.replace(/\\"/g, '"') : '';
    return (
        Boolean(content) && (
            <SeoWrapper>
                <SeoText dangerouslySetInnerHTML={{ __html: sanitizedContent }}></SeoText>
            </SeoWrapper>
        )
    );
};
