import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function Facebook(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox || '0 0 24 24'}
            style={props.style}
            className={props.className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.9618 1.28552C14.1252 1.31021 16.0797 1.84192 17.8252 2.88065C19.5498 3.89864 20.9845 5.34206 21.9919 7.07289C23.0243 8.82887 23.5528 10.7952 23.5774 12.9718C23.5161 15.9499 22.5768 18.4935 20.7595 20.6026C18.9421 22.7117 16.6145 24.0165 14.2051 24.5167V16.167H16.483L16.9982 12.8857H13.5488V10.7366C13.5297 10.2911 13.6706 9.85339 13.946 9.5027C14.2219 9.15105 14.7077 8.96623 15.4033 8.94823H17.4863V6.07393C17.4564 6.06431 17.1728 6.02629 16.6355 5.95986C16.0262 5.88856 15.4134 5.85048 14.7999 5.84578C13.4114 5.85219 12.3132 6.24386 11.5055 7.02081C10.6977 7.79755 10.2851 8.92131 10.2676 10.3921V12.8857H7.64258V16.167H10.2676V24.5167C7.30906 24.0165 4.98148 22.7117 3.16414 20.6026C1.34679 18.4935 0.407513 15.9499 0.346191 12.9718C0.370726 10.7951 0.89922 8.82877 1.93167 7.07289C2.93915 5.34206 4.37381 3.89864 6.09847 2.88065C7.84389 1.84212 9.79833 1.31041 11.9618 1.28552Z"
            />
        </Icon>
    );
}
