import * as React from 'react';
import { gray, medGray, transparent } from '../consts/variables';

const style: Record<string, React.CSSProperties> = {
    selectButtonWrapper: {
        border: `1px solid ${gray}`,
        overflow: 'hidden',
        position: 'relative',
        textDecoration: 'none !important',
        marginBottom: 10,
    },
    select: {
        background: transparent,
        border: 'none',
        borderRadius: 0,
        width: '100%',
        cursor: 'pointer',
        padding: '10px 20px 10px 8px',
        fontSize: 14,
    },
    arrowDown: {
        borderLeft: `8px solid ${transparent}`,
        borderRight: `8px solid ${transparent}`,
        borderTop: `8px solid ${medGray}`,
        marginTop: -3,
        position: 'absolute',
        right: 4,
        top: '50%',
    },
};
interface IOption {
    id: string | number;
    label: string | number;
}

interface IProps {
    options: IOption[];
    selectedOption?: IOption;
    onChange?: Function;
    ArrowStyle?: any;
    selectStyle?: any;
}

export const DropDown = ({
    options,
    selectedOption,
    onChange,
    ArrowStyle,
    selectStyle,
}: IProps) => {
    const onSelectClick = (event) => {
        let clickedVal = event.target.value;
        onChange(clickedVal);
    };

    const getOptions = () =>
        options.map((val, key) => (
            <option value={val.id} key={key}>
                {val.label}
            </option>
        ));

    const selectedValue = selectedOption
        ? {
              value: selectedOption.id,
          }
        : {};

    return (
        <div style={{ ...style.selectButtonWrapper }}>
            <div style={{ ...style.arrowDown, ...ArrowStyle }}></div>
            <select
                style={{ ...style.select, ...selectStyle }}
                onChange={onSelectClick}
                {...selectedValue}
            >
                {getOptions()}
            </select>
        </div>
    );
};
