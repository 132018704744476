import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import * as actionTypes from '../consts/actionTypes';
import { get } from '../helpers/http';
import { IState } from '../reducers';

const getPathName = (type: string): string => {
    switch (type) {
        case 'harbourReviews':
        case 'destinationReviews':
            return '/ugc/waypointCompositeRatings';
        default:
            return '/ugc/shipCompositeRatings';
    }
};

export const setReviewSorting = (sortString: string, filterParams: string, type: string) => (
    dispatch: Dispatch<AnyAction>,
    getState: () => IState,
) => {
    let pathname = getPathName(type);
    const { apiHost, countryCode } = getState().pageData.appConfig;
    const query = `${filterParams}&filterParams[language]=${countryCode}${
        sortString ? '&sortString=' + sortString : ''
    }`;
    const url = `${apiHost}${pathname}?${query}`;
    dispatch({
        type: actionTypes.getUGCReviews,
        payload: get(url),
    });
    dispatch({ type: actionTypes.setReviewsSortString, payload: sortString });
};

export const loadMoreUGCReviews = (link: string) => (
    dispatch: Dispatch<AnyAction>,
    getState: () => IState,
) => {
    const query = link.replace('/ratings', '');
    const { apiHost } = getState().pageData.appConfig;
    const url = `${apiHost}/ugc/shipRatings${query}`;

    dispatch({ type: actionTypes.toggleUGCreviewsLoading, payload: true });
    get(url).then((resp) => {
        dispatch({ type: actionTypes.getMoreUGCReviews, payload: resp });
        dispatch({ type: actionTypes.toggleUGCreviewsLoading, payload: false });
    });
};
