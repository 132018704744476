import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Link } from '../Link';
import { INavigationItem } from '../../interfaces/IHeader';
import { SubNavigationMenu } from './SubNavigationMenu';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import { TrackComponent } from '../TrackComponent';
import { trackingMapper } from '../../middleware/trackingHelpers';
import { SEOHome } from '../../pages/Home/SEOHome';

interface Props {
    item?: INavigationItem;
    text?: string;
    itemStyle?: React.CSSProperties;
    isTopNavigation?: boolean;
    children?: React.ReactElement<any>;
    counter?: number;
    mask?: boolean;
}

const MenuItem = styled.li<{
    itemStyles: React.CSSProperties;
    isOpen: boolean;
    isTopNavigation: boolean;
    subMenuHeight: number | string;
}>`
    position: relative;
    border-bottom: ${({ isTopNavigation, isOpen, theme }) =>
        isTopNavigation ? (isOpen ? '0' : `1px solid ${theme.colors.sanctuary[100]}`) : '0'};
    fontweight: ${({ isTopNavigation }) => (isTopNavigation ? 'bold' : 'normal')};
    visibility: ${({ subMenuHeight }) => (subMenuHeight === 'auto' ? 'hidden' : 'visible')};

    ${({ itemStyles }) => (itemStyles ? css(itemStyles as any) : '')};
`;

const linkStyles = `
    display: flex;
    align-items: center;
    padding: 15px;
    padding-right: 27px;
    ${({ hasChildren }) => hasChildren && 'padding-right: 5px'};
`;

const MenuLink = styled.div<{ hasChildren: boolean }>`
    ${linkStyles}
`;

const StyledLink = styled(Link)`
    ${linkStyles}
`;

const Separator = styled.div`
    height: 0;
    border-top: 1px solid ${({ theme }) => theme.colors.iron[100]};
    width: 100%;
`;

const Title = styled.span`
    font-size: 17px;
`;

const CounterWrapper = styled.span`
    font-size: 14px;
    font-weight: 700;
    line-height: 16px;
    color: ${({ theme }) => theme.colors.white[100]};
    position: absolute;
    top: 15px;
    right: 8px;
    padding: 4px 12px;
    background-color: ${({ theme }) => theme.colors.watermelon[100]};
    border-radius: ${({ theme }) => theme.container.borderRadius};
`;

const NavWrapper = styled.div<{ text: string; subMenuHeight: number | string; isOpen: boolean }>`
    transition: max-height 0.3s ease-in-out;
    overflow: hidden;
    padding: ${({ text }) => (text ? '0 16px' : '0')};
    max-height: ${({ subMenuHeight, isOpen }) =>
        subMenuHeight === 'auto' ? 'none' : isOpen ? subMenuHeight : '0'};
`;

const StyledArrowDownIcon = styled(ArrowDownIcon)<{ isTopNavigation: boolean; isOpen: boolean }>`
    position: absolute;
    right: 10px;
    top: 19px;
    transition: transform 0.3s ease-in-out;
    transformorigin: center center;
    fill: ${({ isTopNavigation, theme }) =>
        isTopNavigation ? theme.colors.sanctuary[100] : theme.colors.peacockBlue[100]};
    transform: ${({ isOpen }) => (isOpen ? 'rotate(180deg)' : 'rotate(0deg)')};
    width: 16px;
    height: 16px;
`;

export const MainNavigationItem = ({
    item,
    text,
    itemStyle,
    isTopNavigation,
    counter,
    children,
    mask,
}: Props) => {
    const [isOpen, toggleOpen] = React.useState(false);
    const [subMenuHeight, setSubMenuHeight] = React.useState<number | string>('auto');
    const subMenuRef = React.useRef<HTMLDivElement>(null);

    const toggleSubMenu = (ev) => {
        ev.stopPropagation();

        toggleOpen(!isOpen);
    };

    React.useEffect(() => {
        setSubMenuHeight(subMenuRef?.current?.clientHeight || 'none');
    }, []);

    const hasChildren = item.children && item.children.length > 0;
    const TrackableLink = TrackComponent(StyledLink, {
        category: isTopNavigation ? 'mobile-navigation-burger' : 'mobile-navigation-frontpage',
        action: trackingMapper[item.title],
    });

    return (
        <MenuItem
            itemStyles={itemStyle}
            isTopNavigation={isTopNavigation}
            isOpen={isOpen}
            subMenuHeight={subMenuHeight}
        >
            {hasChildren || text ? (
                <MenuLink onClick={toggleSubMenu} hasChildren={hasChildren}>
                    {children}
                    <Title dangerouslySetInnerHTML={{ __html: item.title }} />
                    <StyledArrowDownIcon isOpen={isOpen} isTopNavigation={isTopNavigation} />
                </MenuLink>
            ) : (
                <TrackableLink href={item.targetUrl} mask={mask}>
                    {children}
                    <Title>{item.title}</Title>
                    {Boolean(counter) && <CounterWrapper>{counter}</CounterWrapper>}
                </TrackableLink>
            )}
            <NavWrapper text={text} subMenuHeight={subMenuHeight} isOpen={isOpen} ref={subMenuRef}>
                {hasChildren && (
                    <SubNavigationMenu
                        subNavigation={item.children}
                        isTopNavigation={isTopNavigation}
                        parentTitle={item.title}
                    />
                )}
                {text && (
                    <>
                        <Separator />
                        <SEOHome content={text} />
                    </>
                )}
            </NavWrapper>
        </MenuItem>
    );
};
