import { brasilCountryCode } from '../../consts/variables';
import { readCookie } from '../../helpers/cookie';
import { validateDDD, validateEmail, validatePhone } from '../../helpers/validation';
import {
    IAge,
    IRequestFormReducer,
    IRequestFormUpdatableFields,
    ISelectedPax,
    ISelectedPaxItem,
} from '../../interfaces/IRequestForm';
import { initialState } from './requestForm';

export const REQUEST_FORM_VALIDATORS = {
    isGreaterOrEqualThanZero: (value) => value >= 0 && value !== null,
    nonEmpty: (value) => value && value.length > 0,
    isAirportValid: (value) => {
        return value && value !== '-1';
    },
    isAgeArrayValid: (arr) => {
        const isChildEmpty = arr.length === 0;
        const isDatesValid = arr.every((item) => item.isValid && item.birthday);

        return isChildEmpty || isDatesValid;
    },
};

export function createSelectedPax(state: IRequestFormReducer, data): IRequestFormReducer {
    const { selectedPax, adultsNum, childAges, childrenNum } = state;
    Object.keys(selectedPax).forEach((key) => {
        selectedPax[key].minAge = data[key].min;
        selectedPax[key].maxAge = data[key].max;
        selectedPax[key].isSupported = data[key].isSupported;
    });
    updatePaxInfo({ state, adultsNum, childAges, childrenNum });
    return { ...state, selectedPax };
}

export function getPriceAndDiscountForCabin(
    cabins,
    cabinNid: number,
): {
    perPersonPrice: number;
    discountPercentage: number;
    currency: string;
} {
    const cabin = cabins.find((cabin) => cabin.cabinType.nid === cabinNid);
    if (cabin) {
        const { perPersonPrice, discountPercentage, currency } = cabin;
        return { perPersonPrice, discountPercentage, currency };
    }
    return { perPersonPrice: 0, discountPercentage: 0, currency: null };
}

interface IUpdatePassengersArgs {
    adultsNum: number;
    childrenNum: number;
    adultsMinAge: number;
    childAges: IAge[];
}

export function updatePassengers({
    adultsNum,
    childrenNum,
    adultsMinAge,
    childAges,
}: IUpdatePassengersArgs): IAge[] {
    const adultPassengers = [...Array(adultsNum).keys()].map((index) => ({
        age: adultsMinAge,
    }));
    const childPassengers =
        childAges.length > 0 && childAges.length === childrenNum
            ? childAges.map((item) => ({ age: item.age, birthday: item.birthday }))
            : [...Array(childrenNum).keys()].map((index) => ({
                  age: adultsMinAge - 1,
              }));
    return adultPassengers.concat(childPassengers);
}

interface IUpdateSelectedPaxArgs extends IUpdatePassengersArgs {
    selectedPax: ISelectedPax;
}

const isInRange = (item: ISelectedPaxItem, age: number): boolean =>
    item.isSupported && item.minAge <= age && item.maxAge >= age;

export function updateSelectedPax({
    adultsNum,
    childrenNum,
    adultsMinAge,
    childAges,
    selectedPax,
}: IUpdateSelectedPaxArgs): ISelectedPax {
    const newSelectedPax = { ...selectedPax };
    newSelectedPax.adult.num = adultsNum;
    newSelectedPax.baby.num = 0;
    newSelectedPax.child.num = 0;
    newSelectedPax.junior.num = 0;
    if (childAges.length > 0 && childAges.length === childrenNum) {
        childAges.forEach((item) => {
            if (isInRange(selectedPax.baby, item.age)) {
                return (selectedPax.baby.num += 1);
            }
            if (isInRange(selectedPax.child, item.age)) {
                return (selectedPax.child.num += 1);
            }
            if (isInRange(selectedPax.junior, item.age)) {
                return (selectedPax.junior.num += 1);
            }
        });
        // Executed if rates-api does not support age groups
        if (
            selectedPax.baby.num === 0 &&
            selectedPax.junior.num === 0 &&
            selectedPax.child.num === 0
        ) {
            newSelectedPax.child.num = childrenNum;
        }
        // executed if childAges array is not filled
    } else {
        newSelectedPax.child.num = childrenNum;
    }
    return { ...newSelectedPax };
}

interface IUpdatePaxInfoArgs {
    state: IRequestFormReducer;
    adultsNum: number;
    childrenNum: number;
    childAges: IAge[];
}

export function updatePaxInfo({
    state,
    adultsNum,
    childrenNum,
    childAges,
}: IUpdatePaxInfoArgs): IRequestFormReducer {
    const newState = { ...state };
    const { selectedPax } = newState;
    const adultsMinAge = selectedPax.adult.minAge > 0 ? selectedPax.adult.minAge : 18;
    const filledChildAges = childAges.filter((item) => item.age !== null || item.birthday !== null);
    const passengers = updatePassengers({
        adultsNum,
        childrenNum,
        adultsMinAge,
        childAges: filledChildAges,
    });

    const newSelectedPax = updateSelectedPax({
        adultsNum,
        childrenNum,
        adultsMinAge,
        selectedPax,
        childAges: filledChildAges,
    });
    return {
        ...newState,
        adultsNum,
        childrenNum,
        childAges,
        passengers,
        selectedPax: newSelectedPax,
    };
}

export function updateChildAgesLength(
    childAges: IAge[],
    numChildren: number,
    adultsMinAge: number,
): IAge[] {
    const lengthDiff = numChildren - childAges.length;
    if (lengthDiff !== 0) {
        return lengthDiff > 0
            ? childAges.concat(
                  [...Array(lengthDiff).keys()].map((item) => ({
                      age: adultsMinAge - 1,
                      birthday: null,
                      isValid: false,
                  })),
              )
            : childAges.slice(0, numChildren);
    }
    return childAges;
}

export function setAdultsNum(state: IRequestFormReducer, adultsNum: number): IRequestFormReducer {
    const { childrenNum, childAges } = state;
    return updatePaxInfo({ state, adultsNum, childrenNum, childAges });
}

export function updateRequestFormFields(
    state: IRequestFormReducer,
    obj: IRequestFormUpdatableFields,
): IRequestFormReducer {
    return {
        ...state,
        ...obj,
    };
}

export function setChildrenNum(
    state: IRequestFormReducer,
    childrenNum: number,
): IRequestFormReducer {
    const { adultsNum, selectedPax } = state;
    const adultsMinAge = selectedPax.adult.minAge > 0 ? selectedPax.adult.minAge : 18;
    const childAges = updateChildAgesLength(state.childAges, childrenNum, adultsMinAge);
    return updatePaxInfo({ state, adultsNum, childrenNum, childAges });
}

export function setChildAge(
    state: IRequestFormReducer,
    { age, birthday, index, isValid },
): IRequestFormReducer {
    const { childAges, adultsNum, childrenNum, selectedPax } = state;
    const adultsMinAge = selectedPax.adult.minAge > 0 ? selectedPax.adult.minAge : 18;
    const newChildAges = [...childAges];
    newChildAges[index].age = age;
    newChildAges[index].birthday = birthday;
    newChildAges[index].isValid = isValid;

    const filledChildAges = newChildAges.filter(
        (item) => item.age !== null || item.birthday !== null,
    );
    const passengers = updatePassengers({
        adultsNum,
        childrenNum,
        adultsMinAge,
        childAges: filledChildAges,
    });
    const newSelectedPax = updateSelectedPax({
        adultsNum,
        childrenNum,
        adultsMinAge,
        selectedPax,
        childAges: filledChildAges,
    });
    return {
        ...state,
        passengers,
        childAges: newChildAges,
        selectedPax: newSelectedPax,
    };
}

export function setRequestFormInitialData(
    state: IRequestFormReducer,
    payload,
): IRequestFormReducer {
    return {
        ...state,
        ...payload,
    };
}

export function resetRequestForm(state: IRequestFormReducer): IRequestFormReducer {
    const {
        isFemale,
        firstname,
        lastname,
        countryCode,
        phone,
        email,
        callbackTime,
        showNewsletterCheckbox,
        streetName,
        streetNumber,
        city,
        zip,
        birthday,
    } = state;
    return {
        ...initialState,
        isFemale,
        firstname,
        lastname,
        countryCode,
        phone,
        email,
        callbackTime,
        showNewsletterCheckbox,
        streetName,
        streetNumber,
        city,
        zip,
        birthday,
    };
}

interface IRequestFormValidation {
    isFormValid: boolean;
    firstInvalidFormField: string;
}
export function isRequestFormValid(
    state: IRequestFormReducer,
    hasAdditionalFields: boolean,
): IRequestFormValidation {
    const isValidStreetSection = (value) => {
        return REQUEST_FORM_VALIDATORS.nonEmpty(value) || !hasAdditionalFields;
    };

    const isValidCitySection = (value) => {
        return REQUEST_FORM_VALIDATORS.nonEmpty(value) || !hasAdditionalFields;
    };

    const isValidZipSection = (value) => {
        return (
            state.portalId === 'dreamlines.ru' ||
            REQUEST_FORM_VALIDATORS.nonEmpty(value) ||
            !hasAdditionalFields
        );
    };

    const isValidBirthdaySection = (value) => {
        return REQUEST_FORM_VALIDATORS.nonEmpty(value) || !hasAdditionalFields;
    };

    const isValidCallbackTime = (value) => {
        return state.callbackTimeRequired ? REQUEST_FORM_VALIDATORS.nonEmpty(value) : true;
    };

    const fields = [
        { key: 'cruiseNid', validator: REQUEST_FORM_VALIDATORS.isGreaterOrEqualThanZero },
        { key: 'sailNid', validator: REQUEST_FORM_VALIDATORS.isGreaterOrEqualThanZero },
        { key: 'cabinNid', validator: REQUEST_FORM_VALIDATORS.isGreaterOrEqualThanZero },
        { key: 'childAges', validator: REQUEST_FORM_VALIDATORS.isAgeArrayValid },
        { key: 'isFemale', validator: (value) => value !== null },
        { key: 'firstname', validator: REQUEST_FORM_VALIDATORS.nonEmpty },
        { key: 'lastname', validator: REQUEST_FORM_VALIDATORS.nonEmpty },
        { key: 'phone', validator: validatePhone },
        { key: 'countryCode', validator: REQUEST_FORM_VALIDATORS.nonEmpty },
        { key: 'email', validator: validateEmail },
        { key: 'callbackTime', validator: isValidCallbackTime },
        { key: 'streetName', validator: isValidStreetSection },
        { key: 'streetNumber', validator: isValidStreetSection },
        { key: 'zip', validator: isValidZipSection },
        { key: 'city', validator: isValidCitySection },
        { key: 'birthday', validator: isValidBirthdaySection },
    ];
    let isFormValid = true;
    let firstInvalidFormField = null;
    if (state.includesFlight) {
        fields.splice(4, 0, {
            key: 'selectedAirportCode',
            validator: REQUEST_FORM_VALIDATORS.isAirportValid,
        });
    }
    if (state.countryCode === brasilCountryCode) {
        fields.splice(-1, 0, { key: 'ddd', validator: validateDDD });
    }
    // Validate city only for russia
    if (state.portalId === 'dreamlines.ru') {
        fields.splice(-1, 0, { key: 'city', validator: REQUEST_FORM_VALIDATORS.nonEmpty });
    }
    try {
        fields.forEach((item) => {
            if (!item.validator(state[item.key])) {
                firstInvalidFormField = item.key;
                throw Error;
            }
        });
    } catch (e) {
        isFormValid = false;
    }
    return { isFormValid, firstInvalidFormField };
}

export const isCruiseInquiryFormValid = (state: IRequestFormReducer) => {
    const fields = [
        { key: 'cruiseNid', validator: REQUEST_FORM_VALIDATORS.isGreaterOrEqualThanZero },
        { key: 'sailNid', validator: REQUEST_FORM_VALIDATORS.isGreaterOrEqualThanZero },
        { key: 'cabinNid', validator: REQUEST_FORM_VALIDATORS.isGreaterOrEqualThanZero },
        { key: 'childAges', validator: REQUEST_FORM_VALIDATORS.isAgeArrayValid },
    ];

    let isFormValid = true;
    let firstInvalidFormField = null;

    if (state.includesFlight) {
        fields.splice(4, 0, {
            key: 'selectedAirportCode',
            validator: REQUEST_FORM_VALIDATORS.isAirportValid,
        });
    }

    try {
        fields.forEach((item) => {
            if (!item.validator(state[item.key])) {
                firstInvalidFormField = item.key;
                throw Error;
            }
        });
    } catch (e) {
        isFormValid = false;
    }
    return { isFormValid, firstInvalidFormField };
};

export function submitRequestForm(
    state: IRequestFormReducer,
    hasAdditionalFields: boolean,
): IRequestFormReducer {
    const { isFormValid, firstInvalidFormField } = isRequestFormValid(state, hasAdditionalFields);
    return {
        ...state,
        isSubmitted: true,
        firstInvalidFormField,
        isValid: isFormValid,
    };
}

export function getTimezoneInfo(offsetInMinutes: number): string {
    const sign = offsetInMinutes <= 0 ? '+' : '-';
    const hours = Math.abs(Math.floor(offsetInMinutes / 60));
    const hoursString = hours < 10 ? `0${hours}` : hours.toString();
    const minutes = Math.abs(offsetInMinutes % 60);
    const minutesString = minutes < 10 ? `0${minutes}` : minutes.toString();
    return `UTC${sign}${hoursString}:${minutesString}`;
}

export function getGclidCookie(): string {
    const cookie = readCookie('_gcl_aw');
    const gclid = cookie ? cookie.split('.')[2] : null;
    return gclid;
}

export function getMsclkidCookie(): string {
    const cookie = readCookie('_uetmsclkid');
    const msclkid = cookie ? cookie.replace('_uet', '') : null;
    return msclkid;
}
