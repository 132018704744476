import * as actionTypes from '../../consts/actionTypes';
import { PersonalDataActionEnum } from '../../consts/actionTypes';
import { IRequestFormReducer } from '../../interfaces/IRequestForm';
import {
    createSelectedPax,
    setAdultsNum,
    setChildrenNum,
    setChildAge,
    setRequestFormInitialData,
    resetRequestForm,
    submitRequestForm,
    updateRequestFormFields,
    updatePassengers,
} from './requestFormMappers';

export const initialState: IRequestFormReducer = {
    cruiseNid: null,
    selectedSailIndex: 0,
    sailNid: null,
    cabinNid: null,
    childAges: [],
    adultsNum: 2,
    childrenNum: 0,
    passengers: [{ age: 18 }, { age: 18 }],
    selectedPax: {
        baby: { num: 0, minAge: 0, maxAge: 0, isSupported: false },
        child: { num: 0, minAge: 0, maxAge: 0, isSupported: false },
        junior: { num: 0, minAge: 0, maxAge: 0, isSupported: false },
        adult: { num: 2, minAge: 0, maxAge: 99, isSupported: true },
    },
    perPersonPrice: 0,
    perPersonPriceDefaultCurrency: undefined,
    discountPercentage: 0,
    currency: null,
    isSubmitted: false,
    isValid: false,
    isFemale: null,
    firstname: '',
    lastname: '',
    city: '',
    email: '',
    callbackTime: null,
    phone: '',
    phoneCountryCode: '',
    ddd: '',
    zip: '',
    streetName: '',
    streetNumber: '',
    birthday: undefined,
    newsletterSubscription: false,
    countryCode: '',
    selectedAirportIndex: -1,
    selectedAirportCode: '',
    selectedFlightClass: '',
    includesFlight: false,
    isEstimatedPrice: false,
    isSubmitError: false,
    apiValidationErrors: [],
    firstInvalidFormField: null,
    showNewsletterCheckbox: true,
    portalId: '',
    callbackTimeRequired: false,
    isContactViaEmailOnly: false,
};

export function requestForm(
    state: IRequestFormReducer = initialState,
    action,
): IRequestFormReducer {
    switch (action.type) {
        case actionTypes.setRequestFormInitialData:
            return setRequestFormInitialData(state, action.payload);
        case actionTypes.updateRequestFormFromCookies:
            const {
                isFemale,
                firstname,
                lastname,
                countryCode,
                phone,
                email,
                phoneCountryCode,
                callbackTime,
                showNewsletterCheckbox,
                ddd,
                streetName,
                streetNumber,
                city,
                zip,
                birthday,
                adultsNum,
                childrenNum,
                childAges,
            } = action.payload;
            return {
                ...state,
                isFemale,
                firstname,
                lastname,
                countryCode,
                phoneCountryCode,
                phone,
                email,
                callbackTime,
                showNewsletterCheckbox,
                ddd,
                streetName,
                streetNumber,
                city,
                zip,
                birthday,
                adultsNum,
                childrenNum,
                childAges,
                passengers: updatePassengers({
                    adultsNum,
                    childAges,
                    childrenNum,
                    adultsMinAge: state.selectedPax.adult.minAge || 18,
                }),
            };
        case actionTypes.setAdultsNum:
            return setAdultsNum(state, action.payload);
        case actionTypes.setChildrenNum:
            return setChildrenNum(state, action.payload);
        case actionTypes.loadPaxConfigFulfilled:
            return createSelectedPax(state, action.payload);
        case actionTypes.setChildAge:
            return setChildAge(state, action.payload);
        case actionTypes.setAirport:
            return { ...state, selectedAirportIndex: action.payload };
        case actionTypes.setFlightClass:
            return { ...state, selectedFlightClass: action.payload };
        case actionTypes.updateRequestFormFields:
            return updateRequestFormFields(state, action.payload);
        case actionTypes.updateRatesForSelection:
            const {
                perPersonPrice,
                discountPercentage,
                currency,
                perPersonPriceDefaultCurrency,
            } = action.payload;
            return {
                ...state,
                perPersonPrice,
                discountPercentage,
                currency,
                perPersonPriceDefaultCurrency,
            };
        case actionTypes.resetRequestForm:
            return resetRequestForm(state);
        case actionTypes.submitRequestForm:
            return submitRequestForm(state, action.payload);
        case actionTypes.setIsEstimatedPrice:
            return { ...state, isEstimatedPrice: action.payload };
        case actionTypes.handleInquiryResponse:
            if (action.payload.isSubmitError === false) {
                return { ...state, ...action.payload, apiValidationErrors: [] };
            }
            return { ...state, ...action.payload };
        case actionTypes.updatePersonalDataForm:
            const { key, value } = action.payload;
            return { ...state, [key]: value };
        case PersonalDataActionEnum.SET_PERSONALDATA:
            return { ...state, ...action.payload };
        case PersonalDataActionEnum.SET_AIRPORT_CODE:
            return { ...state, selectedAirportCode: action.payload };
        case actionTypes.callbackTimeRequired:
            return { ...state, callbackTimeRequired: true };
        default:
            return state;
    }
}
