import { saveScrollPosition } from '../actions/routerActions';
/**
 * Scrolls HTML element to selected destination with animation.
 *
 * @param {number} to scroll destination in px
 * @param {number} duration scroll duration in ms
 * @param {HTMLElement} element? element to scroll. By default document.body
 */
export function animatedScrollTo(
    to: number,
    duration: number,
    element: HTMLElement = document.body,
) {
    if (duration >= 0) {
        const difference = to - element.scrollTop;
        const perTick = (difference / duration) * 10;

        setTimeout(function () {
            element.scrollTop = element.scrollTop + perTick;
            if (Math.abs(element.scrollTop - to) > 1) {
                animatedScrollTo(to, duration - 10, element);
            } else {
                return;
            }
        }, 10);
    }
}

/**
 * Scrolls window to the element with specified id with animation
 * @param {string} id id of HTML element
 */
export function scrollToId(id) {
    const element = document.getElementById(id);
    setTimeout(() => {
        if (element) {
            const to =
                element.getBoundingClientRect().top - document.body.getBoundingClientRect().top;
            animatedScrollTo(to, 500);
        }
    }, 100);
}

export function setScrollRestoration(value: string) {
    if (history.scrollRestoration) {
        (history as any).scrollRestoration = value;
    }
}

export function setScrollPosition(dispatch: Function, action: string, scrollArray: number[]) {
    switch (action) {
        case 'POP':
            window.scrollTo(0, scrollArray.length > 0 ? scrollArray[scrollArray.length - 1] : 0);
            dispatch(
                saveScrollPosition({
                    action: action,
                    scrollY: 0,
                }),
            );
            break;
        default:
            dispatch(
                saveScrollPosition({
                    action: action,
                    scrollY: window.scrollY,
                }),
            );
            window.scrollTo(0, 0);
    }
}
export function setDesktop(dispatch: Function, action: string, payload: boolean) {
    // export const setIsDesktop = (payload: boolean = true) => (dispatch) => {
    dispatch({
        type: action,
        payload,
    });
    // };
}

export const scrollElementIntoView = (ele) => ele.scrollIntoView();

/**
 * Checks if the element is in viewport
 * @param {object}  HTML element
 */
export const checkElementInViewport = (el) => {
    const scroll = window.scrollY || window.pageYOffset;
    const boundsTop = el.getBoundingClientRect().top + scroll;

    const viewport = {
        top: scroll,
        bottom: scroll + window.innerHeight,
    };

    const bounds = {
        top: boundsTop,
        bottom: boundsTop + el.clientHeight,
    };

    return (
        (bounds.bottom >= viewport.top && bounds.bottom <= viewport.bottom) ||
        (bounds.top <= viewport.bottom && bounds.top >= viewport.top)
    );
};
