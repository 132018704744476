import { loadNewsletterPageData, updateNewsletterPopUpData } from '../actions/newsletterAction';
import {
    newsletterPage as newsletterLandingPage,
    requestFormPage,
    thankYouPage,
    welcomePage,
} from '../consts/pageTypes';
import { hasConsentUpdated } from './dataLayer';
import {
    isNewsletterModalCookie,
    hasNewsletterHiddenCookie,
    readCookie,
    writeSessionCookie,
} from './cookie';
import { eventTracker } from './EventTracker/EventTracker';
import { getRouteSlugs } from './routing';
import { C1AU_DOMAIN } from '../context/markets';

/**
 * Checks specific queryparams to create newsletter cookie
 */
export const checkNewsletterQuerystringParams = (
    url_string: string,
    ssr: boolean,
    response?: any,
) => {
    const url = new URL(url_string);
    const params = new URLSearchParams(url.search?.toLowerCase());

    const isHideNewsletterParamExists = params.has('hnlp');
    // hide newsletter modal regarding ticket DREAM-17680
    if (isHideNewsletterParamExists) {
        // we should set both cookies SSR and Client because of the CF cache
        if (ssr) {
            response.cookie('__hNLP', `true`);
        } else {
            writeSessionCookie('__hNLP', 'true');
        }
    }

    const utm_source = url.searchParams.get('utm_source');
    const utm_medium = url.searchParams.get('utm_medium');

    if (utm_source && utm_medium) {
        if (utm_source.includes('newsletter_') || utm_medium === 'email') {
            if (!readCookie('isSignedUpForNewsletter')) {
                if (ssr) {
                    response.cookie('isSignedUpForNewsletter', `true`);
                } else {
                    writeSessionCookie('isSignedUpForNewsletter', 'true');
                }
            }
        }
    }
};

export const initNewsletterPopUp = (store) => {
    new NewsletterPopUp(store);
};

// DREAM-17821 Temporarily disable the newsletter pop up on a particular page for Cruise1st.com.au
const excludeNewsletterModalFromC1AUPage = (routePath, market) => {
    const isC1AU = C1AU_DOMAIN === market;
    const excludePageList = ['travelzoo-exclusive-rome-to-rio-iguazu-falls'];
    return excludePageList.includes(routePath?.split('/')[1]) && isC1AU;
};
export class NewsletterPopUp {
    store: any;
    timeoutId: number;
    resetTimerInterval: number = 0;

    constructor(store) {
        this.store = store;
        this.newsletterPopUp(5000);
    }

    newsletterPopUp = (timeout) => {
        window.clearInterval(this.timeoutId);
        const { pageData } = this.store.getState();
        const { pageType, brandConfig } = pageData;
        let shouldNewsletterModalShown = this.shouldNewsletterModalShown();
        const newState = {
            popUp: {
                shouldNewsletterModalShown,
                timeout,
            },
        };
        this.timeoutId = window.setInterval(() => {
            if (!shouldNewsletterModalShown) this.cancelNewsletterModal();
            if (hasConsentUpdated(brandConfig.portalId)) {
                this.cancelNewsletterModal();
                window.setTimeout(() => {
                    eventTracker.track({
                        event: 'event',
                        category: 'newsletter-PI',
                        action: 'click',
                        label: pageType,
                    });

                    updateNewsletterPopUpData(newState)(this.store.dispatch);
                }, 10000);
            }
        }, timeout);
    };

    cancelNewsletterModal = () => {
        window.clearInterval(this.timeoutId);
    };

    shouldNewsletterModalShown = () => {
        const { pageData, router, newsletterPage } = this.store.getState();
        const { brandConfig, appConfig } = pageData;
        const { pageType, path } = router;

        const excludeNewsletterModalList = [
            newsletterLandingPage,
            requestFormPage,
            thankYouPage,
            welcomePage,
        ];
        const newsletterModalFeatureFlag = pageData?.featureFlags?.find(
            (e) => e.feature === 'newsletterModal',
        );
        const isNewsletterModalEnabledByFeatureFlag = newsletterModalFeatureFlag
            ? newsletterModalFeatureFlag?.isEnabled
            : false;

        const disableNewsletterModalOnLP = excludeNewsletterModalFromC1AUPage(
            path,
            brandConfig.portalId,
        );

        const shouldNewsletterModalShown =
            !isNewsletterModalCookie() &&
            !hasNewsletterHiddenCookie() &&
            !disableNewsletterModalOnLP &&
            excludeNewsletterModalList.indexOf(pageType) === -1 &&
            isNewsletterModalEnabledByFeatureFlag;
        const routeSlugs = getRouteSlugs(brandConfig.slugs);
        const newsletterPageSlug = routeSlugs.NewsletterPage;
        if (shouldNewsletterModalShown && !newsletterPage.title) {
            loadNewsletterPageData(this.store.dispatch, appConfig.apiHost, newsletterPageSlug);
        }

        return shouldNewsletterModalShown;
    };
}
