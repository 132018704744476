import * as actionTypes from '../consts/actionTypes';
import { IBreadcrumb } from '../interfaces';

export interface ICompanyOverviewPageReducer {
    headline: string;
    subHeadline: string;
    headerImage: string;
    seoTextBottom: string;
    companies: {
        discount: number;
        title: string;
        price: number;
        teaserImage: string;
        teaserDescription: string;
        description: string;
        uri: string;
        relevance: number;
        currency: string;
        shipCountString: string;
    }[];
    breadcrumbs: IBreadcrumb[];
}

export const initialState: ICompanyOverviewPageReducer = {
    headline: '',
    subHeadline: '',
    headerImage: '',
    seoTextBottom: '',
    companies: [],
    breadcrumbs: [],
};

export function companyOverviewPage(
    state: ICompanyOverviewPageReducer = initialState,
    action,
): ICompanyOverviewPageReducer {
    switch (action.type) {
        case actionTypes.loadCompanyOverviewPageData:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
