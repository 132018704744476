import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Modifier } from '../components/RequestFormPersonalInfo/ControlComponents';
import { __ } from '../helpers/TranslationService';
import { IPersonalData } from '../interfaces/IRequestForm';
import { PersonalDataActionCreators } from '../reducers/requestForm/action-creators';
import { IControl, useControl } from './useControl';
import useCountryOptions from './useCountryOptions';
import useFlights from './useFlights';
import { useTypedSelector } from './useTypedSelector';
import { ValidatorsEnum } from './useValidation';
import { updateRequestFormFields } from '../consts/actionTypes';

export enum FieldsEnumKeys {
    childAges = 'childAges',
    isFemale = 'isFemale',
    firstname = 'firstname',
    lastname = 'lastname',
    countryCode = 'countryCode',
    phone = 'phone',
    phoneCountryCode = 'phoneCountryCode',
    email = 'email',
    selectedAirportIndex = 'selectedAirportCode',
    newsletterSubscription = 'newsletterSubscription',
    message = 'message',
}
export interface IField {
    name: string;
    control: IControl;
    active: boolean;
}
export interface controlsEnum {
    [key: string]: IField;
}

export const isPhoneCodeContainsPlusSign = (code: string) => {
    return /^\+\d+$/.test(code);
};

export const normalizePhoneCode = (code: string): string => {
    if (!code?.length || code === '-1') {
        return '';
    }

    const hasPlusSign = isPhoneCodeContainsPlusSign(code);

    if (hasPlusSign) {
        // remove + sign
        return code.substring(1);
    } else {
        return code;
    }
};

const usePersonalDataValidation = ({
    validateNow = false,
    isAllInclusiveSelected,
}: {
    validateNow?: boolean;
    isAllInclusiveSelected?: boolean;
}): controlsEnum => {
    const { pageData, requestForm, cruisePage } = useTypedSelector((state) => state);

    const airports = useFlights({
        includesFlight: cruisePage.includesFlight,
        cabins: cruisePage.cabins,
        selectedCabin: cruisePage.selectedCabin,
        rates: cruisePage.rates,
        currency: pageData.appConfig.defaultCurrency,
        cabinNid: requestForm.cabinNid,
        cruisePageAirports: cruisePage.airports,
        selectedSail: cruisePage.selectedSailIndex,
        sailNid: requestForm.sailNid,
        passengersNum: requestForm?.passengers?.length,
        isAllInclusive: isAllInclusiveSelected,
    });

    const personalRequestForm: IPersonalData = Object.values(FieldsEnumKeys).reduce(
        (a, v) => ({ ...a, [v]: requestForm[v] !== undefined ? requestForm[v] : null }),
        {},
    ) as IPersonalData;
    const isFligths: boolean = airports.isFligths;
    const showAirportSelect: boolean = !!cruisePage.includesFlight;
    const showNewsletterCheckbox: boolean = !!requestForm.showNewsletterCheckbox;

    const countryOptions = useCountryOptions(personalRequestForm[FieldsEnumKeys.countryCode]);

    const controlsEnum: controlsEnum = {
        [FieldsEnumKeys.childAges]: {
            name: FieldsEnumKeys.childAges,
            control: useControl({
                initialValue: personalRequestForm[FieldsEnumKeys.childAges],
                validators: [ValidatorsEnum.isChildAgesValid],
                validateNow,
            }),
            active: true,
        },
        [FieldsEnumKeys.isFemale]: {
            name: FieldsEnumKeys.isFemale,
            control: useControl({
                initialValue: personalRequestForm[FieldsEnumKeys.isFemale],
                validators: [ValidatorsEnum.isEmpty],
                options: [
                    { value: '', label: '--', modifier: Modifier.Disabled },
                    { value: 'false', label: __('personalData.male', 'dreamlines') },
                    { value: 'true', label: __('personalData.female', 'dreamlines') },
                ],
                validateNow,
            }),
            active: true,
        },
        [FieldsEnumKeys.firstname]: {
            name: FieldsEnumKeys.firstname,
            control: useControl({
                initialValue: personalRequestForm[FieldsEnumKeys.firstname],
                validators: [ValidatorsEnum.isFirstnameEmpty],
                validateNow,
            }),
            active: true,
        },
        [FieldsEnumKeys.lastname]: {
            name: FieldsEnumKeys.lastname,
            control: useControl({
                initialValue: personalRequestForm[FieldsEnumKeys.lastname],
                validators: [ValidatorsEnum.isLastnameEmpty],
                validateNow,
            }),
            active: true,
        },
        [FieldsEnumKeys.countryCode]: {
            name: FieldsEnumKeys.countryCode,
            control: useControl({
                initialValue: countryOptions.selectedCode,
                validators: [ValidatorsEnum.isNotDisabled],
                options: countryOptions.options,
                validateNow,
            }),
            active: true,
        },
        [FieldsEnumKeys.phoneCountryCode]: {
            name: FieldsEnumKeys.phoneCountryCode,
            control: useControl({
                initialValue: normalizePhoneCode(countryOptions.selectedCode),
                validators: [ValidatorsEnum.isPhoneCodeValid],
                validateNow,
            }),
            active: true,
        },
        [FieldsEnumKeys.phone]: {
            name: FieldsEnumKeys.phone,
            control: useControl({
                initialValue: personalRequestForm[FieldsEnumKeys.phone],
                validators: [ValidatorsEnum.isPhoneValid, ValidatorsEnum.isPhoneEmpty],
                validateNow,
            }),
            active: true,
        },
        [FieldsEnumKeys.email]: {
            name: FieldsEnumKeys.email,
            control: useControl({
                initialValue: personalRequestForm[FieldsEnumKeys.email],
                validators: [ValidatorsEnum.isEmailEmpty, ValidatorsEnum.isEmailValid],
                validateNow,
            }),
            active: true,
        },
        [FieldsEnumKeys.message]: {
            name: FieldsEnumKeys.message,
            control: useControl({
                initialValue: personalRequestForm[FieldsEnumKeys.message],
                validateNow,
            }),
            active: true,
        },
        [FieldsEnumKeys.selectedAirportIndex]: {
            name: FieldsEnumKeys.selectedAirportIndex,
            control: useControl({
                initialValue: personalRequestForm[FieldsEnumKeys.selectedAirportIndex],
                validators: isFligths
                    ? [ValidatorsEnum.isFlightsEmpty]
                    : [ValidatorsEnum.isAirportEmpty],
                options: airports.options,
                validateNow,
            }),
            active: showAirportSelect,
        },
        [FieldsEnumKeys.newsletterSubscription]: {
            name: FieldsEnumKeys.newsletterSubscription,
            control: useControl({
                initialValue: personalRequestForm[FieldsEnumKeys.newsletterSubscription],
                validateNow,
            }),
            active: showNewsletterCheckbox,
        },
    };

    const isFormValid: boolean = controlsEnum
        ? Object.values(controlsEnum).every((c) => (c.control.isValid && c.active) || !c.active)
        : false;
    const dispatch = useDispatch();
    const getNewPersonalData = () => {
        const newPersonalData = Object.entries(controlsEnum).reduce(
            (acc, [key, item]) => ({
                ...acc,
                [key]: !item.active ? requestForm[key] : item.control.value,
            }),
            {},
        );
        return newPersonalData;
    };
    const isEqual = (...objects) =>
        objects.every((obj) => JSON.stringify(obj) === JSON.stringify(objects[0]));
    const [newPersonalData, setNewPersonalData] = useState(getNewPersonalData());
    useEffect(() => {
        if (isFormValid) {
            const updatedPersonalData = getNewPersonalData();
            if (!isEqual(updatedPersonalData, newPersonalData)) {
                setNewPersonalData(updatedPersonalData);
            }
        }
    }, [controlsEnum]);

    useEffect(() => {
        if (isFormValid) {
            dispatch(PersonalDataActionCreators.setPersonalData(newPersonalData));
        }
    }, [newPersonalData]);

    useEffect(() => {
        // requestForm.includesFlight is never updated and stays false even if there is a flight included
        // isRequestFormValid() function relies on this field to validate form before sending
        // so here we want it to be in sync
        dispatch({
            type: updateRequestFormFields,
            payload: { includesFlight: showAirportSelect },
        });
    }, [showAirportSelect]);

    return controlsEnum;
};
export default usePersonalDataValidation;
