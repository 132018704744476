import * as actionTypes from '../consts/actionTypes';
import { IThankYouPage } from '../interfaces/IThankYou';

export const initialState: IThankYouPage = {
    shipTitle: '',
    banner: '',
    nights: 0,
    lastName: '',
    gender: 0,
    cruiseNid: '',
    email: '',
    recommendations: [],
    bookingId: '',
    queryParams: null,
    cruiseData: null,
};

export function thankYouPage(state: IThankYouPage = initialState, action) {
    switch (action.type) {
        case actionTypes.setThankYouData:
            return { ...state, ...action.payload };
        case actionTypes.loadRecommendations:
            const recommendationsResult = action.payload;
            return {
                ...state,
                recommendations: recommendationsResult,
            };
        default:
            return state;
    }
}
