import * as React from 'react';
import styled from '@emotion/styled';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { keyframes } from '@emotion/react';
interface IDialogProps {
    open: boolean;
    children: React.ReactNode;
    onClose: () => void;
    className?: string;
}

const ANIMATION_TIME = 200;
const GLITCH_WAIT_TIME = 20;

const DialogAnimation = keyframes`
  0%{
    transform: translateY(100%);
  }

  100%{
    transform: translateY(0);
  }
`;

const DialogCloseAnimation = keyframes`
  0%{
    transform: translateY(0);
  }

  100%{
    transform: translateY(100%);
  }
`;

const DialogOverlayAnimation = keyframes`
  0%{
    opacity: 0;
  }

  100%{
    opacity: 1;
  }
`;

const DialogOverlayCloseAnimation = keyframes`
  0%{
    opacity: 1;
  }

  100%{
    opacity: 0;
  }
`;

const DialogStyled = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0 auto;
    max-height: calc(100% - 40px);
    background-color: ${({ theme }) => theme.colors.white[100]};
    border-top-right-radius: 12px;
    border-top-left-radius: 12px;
    z-index: ${(props) => props.theme.overlayIndexes.dialog};
    animation: ${DialogAnimation} ${ANIMATION_TIME}ms linear;
    &.modalClose {
        animation: ${DialogCloseAnimation} ${ANIMATION_TIME}ms linear;
    }

    @media (min-width: ${BreakpointsEnum.sm}) {
        width: auto;
        margin: 0 24px;
        max-width: 1200px;
        max-height: calc(100% - 100px);
        top: 5%;
        bottom: auto;
        border-radius: 6px;
        animation-name: none;
        &.modalClose {
            display: none;
        }
    }
    @media (min-width: ${BreakpointsEnum.lg}) {
        margin: 0 auto;
    }
`;

const DialogOverlay = styled.div`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${({ theme }) => theme.colors.gunmetal[50]};
    z-index: ${(props) => props.theme.overlayIndexes.dialogOverlay};
    animation: ${DialogOverlayAnimation} ${ANIMATION_TIME}ms linear;
    &.modalClose {
        animation: ${DialogOverlayCloseAnimation} ${ANIMATION_TIME}ms linear;
    }
    @media (min-width: ${BreakpointsEnum.sm}) {
        animation-name: none;
        &.modalClose {
            display: none;
        }
    }
`;

export const DialogContent = styled.div`
    width: 100%;
    padding: 16px;
    overflow-y: auto;
    @media (min-width: ${BreakpointsEnum.lg}) {
        padding: 24px;
    }
`;

export const DialogHeader = styled.div`
    width: 100%;
    padding: 16px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.whiteSmoke[100]};
`;

export const DialogHeaderTitle = styled.h3`
    color: ${({ theme }) => theme.text.color.primary};
    font-size: 20px;
    font-weight: 700;
    line-height: 32px;
    margin: 0 28px 0 0;
`;

export const DialogFooter = styled.div`
    width: 100%;
    padding: 20px 16px 40px;
    border-top: 2px solid ${({ theme }) => theme.colors.whiteSmoke[100]};
`;

export const DialogCloseButton = styled.button`
    position: absolute;
    top: 25px;
    right: 18px;
    width: 20px;
    height: 20px;
    padding: 0;
    border: none;
    background-color: transparent;
    opacity: 0.5;

    &:after,
    &:before {
        content: '';
        position: absolute;
        top: 7px;
        width: 20px;
        height: 2px;
        border-radius: 2px;
        background-color: ${({ theme }) => theme.colors.gunmetal[100]};
    }
    &:after {
        right: 0;
        transform: rotate(-45deg);
    }
    &:before {
        left: 0;
        transform: rotate(45deg);
    }
`;

export const Dialog = (props: IDialogProps) => {
    const [closeClassName, setCloseClassName] = React.useState('');

    // prevent page scroll when dialog opened
    React.useEffect(() => {
        if (props.open) {
            document.body.style.overflow = 'hidden';
        }
        return () => {
            document.body.style.overflow = null;
        };
    }, []);

    const handleClose = () => {
        // add 'modalClose' class for close animation
        setCloseClassName('modalClose');
        setTimeout(() => {
            props.onClose();
        }, ANIMATION_TIME - GLITCH_WAIT_TIME);
        // decreasing ANIMATION_TIME to 20ms to prevent glitches when the component unmounts
    };

    return (
        <>
            <DialogOverlay onClick={handleClose} className={closeClassName} />

            <DialogStyled className={`${closeClassName} ${props.className}`}>
                <DialogCloseButton onClick={handleClose} />
                {props.children}
            </DialogStyled>
        </>
    );
};
