import * as React from 'react';
import * as vars from '../../../consts/variables';
import { Facebook } from '../../Icons/Facebook';
import { SocialIcon } from './SocialIcon';
import { Twitter } from '../../Icons/Twitter';
import { __ } from '../../../helpers/TranslationService';
import { eventTracker } from '../../../helpers/EventTracker/EventTracker';

const styles: Record<string, React.CSSProperties> = {
    icon: {
        fill: vars.white,
        zIndex: 1,
    },
};

export const getMessage = (url: string): string =>
    `${encodeURIComponent(__('fav.share.message', 'dreamlines'))}%20${encodeURIComponent(url)}`;

export const getFacebookLink = (url: string): string =>
    `http://www.facebook.com/share.php?u=${encodeURIComponent(url)}`;

export const getTwitterLink = (url: string): string =>
    `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(
        __('fav.share.message', 'dreamlines'),
    )}`;

interface IProps {
    shareLink: string;
}

interface IState {
    isOpen: boolean;
}

export class SocialShare extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
        };
    }

    handleShareClick = () => this.setState({ isOpen: !this.state.isOpen });

    hide = () => this.setState({ isOpen: false });

    render() {
        const { shareLink } = this.props;
        return (
            <React.Fragment>
                <SocialIcon
                    href={getFacebookLink(shareLink)}
                    trackFuction={() => {
                        eventTracker.track({
                            category: 'share function',
                            action: 'facebook',
                            label: getFacebookLink(shareLink),
                        });
                    }}
                >
                    <Facebook style={styles.icon} />
                </SocialIcon>
                <SocialIcon
                    href={getTwitterLink(shareLink)}
                    trackFuction={() => {
                        eventTracker.track({
                            category: 'share function',
                            action: 'twitter',
                            label: getTwitterLink(shareLink),
                        });
                    }}
                >
                    <Twitter style={styles.icon} />
                </SocialIcon>
            </React.Fragment>
        );
    }
}
