import * as Sentry from '@sentry/react';
import { format } from 'date-fns';
import * as qs from 'qs';
import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import * as actionTypes from '../consts/actionTypes';
import { PersonalDataActionEnum } from '../consts/actionTypes';
import { BOOKING_TYPE_DIRECT, BOOKING_TYPE_INDIRECT, brasilCountryCode } from '../consts/variables';
import { TimeoutError } from '../errors/TimeoutError';
import { emarsysTracker } from '../helpers/EmarsysTracker/EmarsysTracker';
import { eventTracker } from '../helpers/EventTracker/EventTracker';
import { __ } from '../helpers/TranslationService';
import { readCookie } from '../helpers/cookie';
import {
    createDirectBookingUrl,
    createRevelexBookingUrl,
    getDirectBookingThreshold,
    updateDirectBookingButtons,
} from '../helpers/directBookingHelper';
import { isNodeVisible } from '../helpers/domHelpers';
import * as http from '../helpers/http';
import { convertToDualCurrency, roundBy } from '../helpers/priceHelpers';
import {
    IPersonalDataForm,
    getPersonalData,
    savePersonalData,
} from '../helpers/requestFormHelpers';
import { isPhoneCodeContainsPlusSign, normalizePhoneCode } from '../hooks/usePersonalData';
import { IRate, IRateBulk } from '../interfaces';
import {
    IAge,
    IBookingCrmRequestMessage,
    IBookingRequestMessage,
    IExperimentData,
} from '../interfaces/IBookingRequestMessage';
import { ICabinInfo } from '../interfaces/ICabin';
import { IRequestFormUpdatableFields } from '../interfaces/IRequestForm';
import { getThankYouPageViewData } from '../middleware/trackingHelpers';
import { IState } from '../reducers';
import { ICountryCode } from '../reducers/pageData';
import {
    getGclidCookie,
    getMsclkidCookie,
    getPriceAndDiscountForCabin,
    getTimezoneInfo,
    isCruiseInquiryFormValid,
    isRequestFormValid,
} from '../reducers/requestForm/requestFormMappers';
import { getRates, setSelectedSailIndex } from './cruisePageActions';
import { getApiValidationErrors } from '../helpers/httpValidationErrors';

export type BookingRequestType = typeof BOOKING_TYPE_DIRECT | typeof BOOKING_TYPE_INDIRECT;

type CreateRquestPayload = {
    isAllInclusive: boolean;
    ages: IAge[];
    airportName?: string;
    cruiseNid: number;
    sailNid: number;
    cabinNid: number;
    rateCode: string;
    rateDesc: string;
};

export const setRequestFormInitialData = ({ sailNid, cabinNid }) => (dispatch, getState) => {
    const state = getState();
    const { nid: cruiseNid, sails, includesFlight } = state.cruisePage;

    const { precheckNewsletterSubscription, countryCodes } = state.pageData.brandConfig.requestForm;
    const { portalId, countryCode } = state.pageData.appConfig;
    const selectedSailIndex = sails.findIndex((sail) => sail.nid === sailNid);
    let dialCode = state.requestForm.countryCode;
    if (!dialCode) {
        dialCode = getCountryDialCode(countryCode, countryCodes);
    }

    if (selectedSailIndex !== -1) {
        dispatch({
            type: actionTypes.setRequestFormInitialData,
            payload: {
                cruiseNid,
                countryCode: dialCode,
                phoneCountryCode: normalizePhoneCode(dialCode),
                sailNid,
                cabinNid,
                selectedSailIndex,
                includesFlight,
                newsletterSubscription: precheckNewsletterSubscription,
                portalId,
                loadingRates: true,
            },
        });
        return dispatch(updateRatesForSelection());
    } else {
        return new Promise((resolve, reject) => reject('No matching SailId'));
    }
};

export const getPaxConfig = () => (dispatch, getState) => {
    const { apiHost } = getState().pageData.appConfig;
    const { bookingServiceCode } = getState().cruisePage.operator;
    const payload = http.get(
        `${apiHost}/fareservice/ageConfig?cruiseline=${bookingServiceCode}`,
        true,
    );
    dispatch({
        type: actionTypes.loadPaxConfig,
        payload,
    });
};

export const updateRatesForSelection = () => (dispatch, getState: () => IState) => {
    dispatch(setSelectedSailIndex(getState().requestForm.selectedSailIndex));
    return dispatch(getRates()).then((ratesResponse) => {
        const { requestForm, cruisePage, pageData } = getState();
        const { cabinNid } = requestForm;
        const { cabins } = cruisePage;
        const { exchangeRate } = pageData.header;
        const { perPersonPrice, discountPercentage, currency } = getPriceAndDiscountForCabin(
            cabins,
            cabinNid,
        );

        const newRates: IRateBulk[] = ratesResponse.value;
        const selectedRate = newRates.find((rate) => rate.cabintype_id === cabinNid);

        if (selectedRate?.cheapest_price.isEstimatedPrice) {
            eventTracker.trackG4Event('no_availability', {
                type: 'estimated_price',
                cruise_name: cruisePage.title,
                company_name: cruisePage.operator.title,
                pax: `adult: ${requestForm.adultsNum}, child: ${requestForm.childrenNum}`,
                cruise_id: selectedRate.cruise_id,
                sail_id: selectedRate.sail_nid,
            });
        }

        const perPersonPriceDefaultCurrency =
            exchangeRate && exchangeRate.exchangeRate > 0
                ? roundBy(perPersonPrice / exchangeRate.exchangeRate, 0)
                : null;

        if (newRates && Array.isArray(newRates) && newRates.length > 0) {
            dispatch({
                type: actionTypes.setIsEstimatedPrice,
                payload: !!selectedRate?.cheapest_price.isEstimatedPrice,
            });
        }
        dispatch({
            type: actionTypes.updateRatesForSelection,
            payload: {
                perPersonPrice,
                discountPercentage,
                currency,
                perPersonPriceDefaultCurrency,
            },
        });
    });
};

export const setAdultsNum = (num: number) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.setAdultsNum,
        payload: num,
    });
    dispatch(updateRatesForSelection());

    const state = getState();
    const { requestForm } = state;
    savePersonalData({ ...requestForm, adultsNum: num });
};

export const setChildrenNum = (num: number) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.setChildrenNum,
        payload: num,
    });
    dispatch(updateRatesForSelection());

    const state = getState();
    const { requestForm } = state;
    savePersonalData({ ...requestForm, childrenNum: num });
};

export const setAirport = (index: number) => {
    return {
        type: actionTypes.setAirport,
        payload: index,
    };
};

export const setAirportCode = (air: string) => {
    return {
        type: PersonalDataActionEnum.SET_AIRPORT_CODE,
        payload: air,
    };
};

export const setFlightClass = (flightClass: string) => {
    return {
        type: PersonalDataActionEnum.SET_FLIGHT_CLASS,
        payload: flightClass,
    };
};

export const setChildAge = (age: number, birthday: Date, index: number, isValid: boolean) => (
    dispatch,
    getState,
) => {
    dispatch({
        type: actionTypes.setChildAge,
        payload: { age, birthday, index, isValid: isValid },
    });
    const { requestForm } = getState();
    const { childAges, childrenNum } = requestForm;
    savePersonalData({ ...requestForm, childAges, childrenNum });
    const filledChildAges = childAges.filter(
        (item) => item.age !== null,
        (item) => item.birthday !== null,
    );
    if (filledChildAges.length > 0 && filledChildAges.length === childrenNum) {
        dispatch(updateRatesForSelection());
    }
};

export const setChildrenAges = (values) => (dispatch) => {
    dispatch({
        type: actionTypes.updatePersonalDataForm,
        payload: { key: 'childAges', value: values },
    });
};

export const resetRequestForm = () => ({ type: actionTypes.resetRequestForm });

export const setIsEstimatedPrice = (isEstimated) => ({
    type: actionTypes.setIsEstimatedPrice,
    payload: isEstimated,
});

export const submitRequestForm = (isAllInclusive?: boolean, requestType?: BookingRequestType) => (
    dispatch: Dispatch<any>,
    getState: () => IState,
) => {
    const { requestForm, pageData } = getState();
    dispatch({
        type: actionTypes.submitRequestForm,
        payload: pageData.mobileRequestFormAdditionalFields,
    });

    if (isRequestFormValid(requestForm, pageData.mobileRequestFormAdditionalFields).isFormValid) {
        // save personalData to cookie
        savePersonalData(requestForm);
        dispatch({
            type: actionTypes.showLoadingIndicator,
        });
        dispatch(submitInquiry(isAllInclusive, requestType));
    }
};

export const submitContactUsForm = () => (dispatch: Dispatch<any>, getState: () => IState) => {
    const { requestForm, pageData } = getState();
    dispatch({
        type: actionTypes.submitRequestForm,
        payload: pageData.mobileRequestFormAdditionalFields,
    });

    // save personalData to cookie
    savePersonalData(requestForm);
    dispatch({
        type: actionTypes.showLoadingIndicator,
    });
    dispatch(submitInquiry());
};

const getTotalPriceWithFlight = (ratePrice, airportName) => {
    if (!ratePrice?.flights) {
        return 0;
    }

    const flight = ratePrice?.flights.find((flight) => {
        const departureAirportCode = flight.departure_airport_code;
        const returnAirportCode = flight.return_airport_code;
        const airportCode = departureAirportCode || returnAirportCode;
        return airportCode === airportName;
    });

    return flight?.cabin_price || 0;
};

export const submitCruiseInquiryForm = (isAllInclusive?: boolean) => (
    dispatch: Dispatch<any>,
    getState: () => IState,
) => {
    const { requestForm, pageData, cruisePage } = getState();

    dispatch({
        type: actionTypes.submitRequestForm,
        payload: pageData.mobileRequestFormAdditionalFields,
    });

    const { isFormValid } = isCruiseInquiryFormValid(requestForm);

    if (!isFormValid) {
        return false;
    }

    dispatch({
        type: actionTypes.showLoadingIndicator,
    });

    const cabin: ICabinInfo =
        Array.isArray(cruisePage.cabins) &&
        cruisePage.cabins.find(
            (cabin) => cabin.cabinType && cabin.cabinType.nid === requestForm.cabinNid,
        );

    const selectedSailRates = cruisePage?.rates?.filter(
        (cabin) => cabin.sail_nid === cruisePage?.sails[cruisePage.selectedSailIndex]?.nid,
    );

    const rate = selectedSailRates.find((rate) => rate.cabintype_id === cabin.cabinType.nid);
    const ratePrice = isAllInclusive ? rate?.all_in_price : rate?.cheapest_price;

    const totalPriceWithFlight = cruisePage.includesFlight
        ? getTotalPriceWithFlight(ratePrice, requestForm.selectedAirportCode)
        : 0;

    // we allow price on request form submit, so rate might be not available
    const totalPriceWithoutFlight = ratePrice?.cabin_price || 0;
    const currency = requestForm.perPersonPriceDefaultCurrency
        ? pageData.brandConfig.defaultCurrency
        : requestForm.currency || pageData.brandConfig.defaultCurrency;

    const shownPerPersonPrice =
        requestForm.perPersonPriceDefaultCurrency || requestForm.perPersonPrice;
    const exchangeRate = pageData?.header?.exchangeRate;

    const perPersonPriceOut = exchangeRate
        ? convertToDualCurrency(exchangeRate, cabin.perPersonPrice)
        : shownPerPersonPrice || cabin.perPersonPrice;
    const perPersonPriceOutAllIn = rate?.all_in_price?.per_person_price;

    const rateCode = isAllInclusive ? rate?.all_in_price.rate_code : rate?.cheapest_price.rate_code;
    const rateDesc = isAllInclusive ? rate?.all_in_price.rate_desc : rate?.cheapest_price.rate_desc;

    const query = {
        numChild: String(requestForm.childAges.length),
        numAdult: String(requestForm.adultsNum),
        isAllIn: String(!!isAllInclusive),
        isDreamlinesPackage: String(!!cruisePage.operator.isDreamlinesPackage),
        isPriceOnRequest: String(!rate || !ratePrice),
        isEstimatedPrice: String(!!ratePrice?.isEstimatedPrice),
        cruiseCode: cruisePage.operator.bookingServiceCode,
        rateCode,
        rateDesc,
        totalPrice: totalPriceWithFlight || totalPriceWithoutFlight,
        perPersonPrice: String(isAllInclusive ? perPersonPriceOutAllIn : perPersonPriceOut),
        currency,
    };

    requestForm.passengers.forEach((p, idx) => {
        const num = idx + 1;
        const birthKey = `p${num}Birth`;
        query[birthKey] = p.birthday ? format(p.birthday, 'yyyy-MM-dd') : '';
    });

    const properties = [
        'cruiseNid',
        'sailNid',
        'cabinNid',
        'includesFlight',
        'selectedAirportCode',
        'selectedFlightClass',
    ];

    properties.forEach((prop) => {
        if (typeof requestForm[prop] !== 'undefined') {
            query[prop] = requestForm[prop];
        }
    });

    const directBookingPriceThreshold = getDirectBookingThreshold(pageData.appConfig.countryCode);

    const isLowTotalBookingValue = !!isAllInclusive
        ? rate?.all_in_price.cabin_price <= directBookingPriceThreshold
        : rate?.cheapest_price.cabin_price <= directBookingPriceThreshold;

    const isDirectBookingFlow =
        query.isDreamlinesPackage === 'false' &&
        query.isEstimatedPrice === 'false' &&
        query.isPriceOnRequest === 'false' &&
        isLowTotalBookingValue;

    if (!isDirectBookingFlow) {
        const urlParams = new URLSearchParams(query);
        window.location.href = `/booking?${urlParams.toString()}`;
        return;
    }

    const ages = requestForm.childAges.map((item, idx) => {
        return {
            ...item,
            birthday: format((item.birthday as any) as Date, 'yyyy-MM-dd'),
        };
    });

    return createCrmBooking(
        dispatch,
        getState(),
        {
            isAllInclusive: !!isAllInclusive,
            ages,
            airportName: requestForm.selectedAirportCode,
            cruiseNid: requestForm.cruiseNid,
            sailNid: cruisePage?.sails[cruisePage.selectedSailIndex]?.nid,
            cabinNid: requestForm.cabinNid,
            rateCode,
            rateDesc,
        },
        rate,
    );
};

export const getPhone = (countryCode: string, phone: string, ddd: string): string => {
    const hasPlusSign = isPhoneCodeContainsPlusSign(countryCode);
    const normalizedCountryCode = hasPlusSign
        ? countryCode
        : `${countryCode ? `+${countryCode}` : ''}`;

    if (normalizedCountryCode === brasilCountryCode) {
        return `${normalizedCountryCode}${ddd}${phone}`;
    }
    if (normalizedCountryCode === '+1') {
        phone = phone.replace(/[^\d+]+/g, '');
    }
    if (normalizedCountryCode === '+49') {
        phone = phone.replace(/\b0+/g, ''); // Removing leading zeros
    }

    return normalizedCountryCode ? `${normalizedCountryCode}${phone}` : phone;
};

export const getExperimentData = (offsetInMinutes: number = 0): IExperimentData[] => {
    const experimentData = [
        {
            key: 'timezone',
            value: getTimezoneInfo(offsetInMinutes),
        },
    ];
    return experimentData;
};

export const updateRequestFormFromCookies = () => (
    dispatch: Dispatch<AnyAction>,
    getState: () => IState,
) => {
    const { requestForm, pageData } = getState();

    const showNewsletterCheckbox =
        !readCookie('isSignedUpForNewsletter') ||
        ['dreamlines.com'].indexOf(pageData.brandConfig.portalId) !== -1;
    const {
        isFemale,
        firstname,
        lastname,
        phone,
        phoneCountryCode,
        email,
        callbackTime,
        countryCode,
        ddd,
        streetName,
        streetNumber,
        city,
        zip,
        birthday,
        adultsNum,
        childrenNum,
        childAges,
    } = requestForm;
    let personalData: IPersonalDataForm = {
        isFemale,
        firstname,
        lastname,
        phone,
        email,
        callbackTime,
        countryCode,
        phoneCountryCode,
        ddd,
        streetName,
        streetNumber,
        city,
        zip,
        birthday,
        adultsNum,
        childrenNum,
        childAges,
    };
    const isPersonalDataEmpty =
        Object.keys(personalData).filter(
            (key) =>
                ['countryCode', 'phoneCountryCode', 'adultsNum', 'childAges'].indexOf(key) === -1 &&
                Boolean(personalData[key]),
        ).length === 0;
    if (isPersonalDataEmpty) {
        const personalDataFromCookie = getPersonalData();
        personalData = personalDataFromCookie || personalData;
    }
    dispatch({
        type: actionTypes.updateRequestFormFromCookies,
        payload: { ...personalData, showNewsletterCheckbox },
    });
};

export const updatePersonalDataForm = ({ key, value }) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.updatePersonalDataForm,
        payload: { key, value },
    });
    const { requestForm } = getState();
    savePersonalData(requestForm);
};

export const createCrmBooking = async (
    dispatch: Dispatch<AnyAction>,
    state: IState,
    inquiry: CreateRquestPayload,
    rate?: IRate,
) => {
    const { requestForm, cruisePage, pageData, router } = state;
    const { apiHost } = pageData.appConfig;

    const ratePrice = inquiry.isAllInclusive ? rate?.all_in_price : rate?.cheapest_price;

    const getPassengers = () => {
        const legalAgeConfig = {
            minAdultAge: 18,
        };

        const childs = inquiry.ages.map((child) => {
            const ageData = { age: child.age, isAdult: false };
            return child.birthday ? { ...ageData, birthDate: child.birthday } : ageData;
        });
        const adults = Array.from({ length: requestForm.adultsNum }, () => ({
            age: legalAgeConfig.minAdultAge,
            isAdult: true,
        }));

        return [...adults, ...childs];
    };

    const totalPriceWithFlight = state.cruisePage.includesFlight
        ? getTotalPriceWithFlight(ratePrice, inquiry.airportName)
        : 0;
    const totalPriceWithoutFlight = ratePrice.cabin_price;
    const flightCharge = Math.max(0, totalPriceWithFlight - totalPriceWithoutFlight);
    const hotelServiceFee = ratePrice.hotel_service_charges || 0;
    const totalPrice = totalPriceWithFlight || totalPriceWithoutFlight;
    const cruisePrice = totalPrice - hotelServiceFee - flightCharge;

    const crmCreateBookingUrl = `${apiHost}/v2/booking/create`;

    const payload = {
        bookingType: BOOKING_TYPE_DIRECT,
        cruiselineCode: cruisePage.operator.bookingServiceCode,
        cruiseId: inquiry.cruiseNid,
        sailId: inquiry.sailNid,
        cabinTypeId: inquiry.cabinNid,
        passengers: getPassengers(),
        cruisePrice,
        totalPrice,
        hotelServiceFee,
        flightInfo: {
            flights: [],
            price: flightCharge,
            supplierId: null,
        },
        cruiseUrl: cruisePage.canonicalUrl || `${pageData.config.contentEndpoint}${router.baseUrl}`,
        rateCode: inquiry.rateCode,
        rateDesc: inquiry.rateDesc,
        isAllInclusive: inquiry.isAllInclusive,
    };

    if (state.cruisePage.includesFlight) {
        payload.flightInfo.flights = [
            {
                departureAirport: {
                    code: inquiry.airportName,
                    fullName: __(inquiry.airportName, 'dreamlines'),
                },
                arrivalAirport: {
                    code: inquiry.airportName,
                    fullName: __(inquiry.airportName, 'dreamlines'),
                },
            },
        ];
    }

    try {
        const crmCreateResp = await http.post(crmCreateBookingUrl, payload);

        dispatch({
            type: actionTypes.handleInquiryResponse,
            payload: { isSubmitError: false },
        });

        window.location.href = `/booking?bt=${crmCreateResp.publicToken}`;
    } catch (err) {
        const validationErrors = getApiValidationErrors(err);

        dispatch({
            type: actionTypes.handleInquiryResponse,
            payload: {
                isSubmitError: true,
                apiValidationErrors: validationErrors,
            },
        });

        if (state.router.isBrowser) {
            Sentry.captureException(err, { extra: { payload } });
        }
    }
};

export const submitInquiry = (isAllInclusive?: boolean, requestType?: BookingRequestType) => (
    dispatch: Dispatch<AnyAction>,
    getState: () => IState,
) => {
    const { requestForm, cruisePage, pageData, abTests } = getState();
    const { apiHost } = pageData.appConfig;
    const { perPersonPrice, perPersonPriceDefaultCurrency } = requestForm;
    const sail = cruisePage.sails[requestForm.selectedSailIndex];
    const isBookingFunnelActive = !!requestType;
    const portOfDisembarkation =
        Array.isArray(cruisePage.waypoints) &&
        cruisePage.waypoints.find((_) => _.isPortOfDisembarkation);
    const portOfEmbarkation =
        Array.isArray(cruisePage.waypoints) &&
        cruisePage.waypoints.find((_) => _.isPortOfEmbarkation);
    const cabin: ICabinInfo =
        Array.isArray(cruisePage.cabins) &&
        cruisePage.cabins.find(
            (cabin) => cabin.cabinType && cabin.cabinType.nid === requestForm.cabinNid,
        );

    const shownPerPersonPrice = perPersonPriceDefaultCurrency || perPersonPrice;
    const currency = perPersonPriceDefaultCurrency
        ? pageData.brandConfig.defaultCurrency
        : requestForm.currency || pageData.brandConfig.defaultCurrency;

    let callbackTime = requestForm.callbackTime;

    if (isNodeVisible('callbackTime') && !requestForm.callbackTime) {
        callbackTime = 'keine auswahl vorgenommen';
    }

    const selectedSailRates = cruisePage?.rates?.filter(
        (cabin) => cabin.sail_nid === cruisePage?.sails[cruisePage.selectedSailIndex]?.nid,
    );
    const rate = selectedSailRates.find((rate) => rate?.cabintype_id === cabin?.cabinType.nid);

    const exchangeRate = pageData?.header?.exchangeRate;
    // @todo: should we use here the same logic as for perPersonPriceOutAllIn ?
    //  const perPersonPriceOut = rate?.cheapest_price?.per_person_price
    const perPersonPriceOut = exchangeRate
        ? convertToDualCurrency(exchangeRate, cabin?.perPersonPrice)
        : shownPerPersonPrice || cabin?.perPersonPrice;
    const perPersonPriceOutAllIn = rate?.all_in_price?.per_person_price;

    const cabinPrice = rate?.cheapest_price?.cabin_price;
    const cabinPriceAllIn = rate?.all_in_price?.cabin_price;
    const currentRequestFormMessage = requestForm.message || '';

    const message = requestForm.isContactViaEmailOnly
        ? `${currentRequestFormMessage} ${__('contactViaEmailOnly', 'dreamlines')}`
        : requestForm.message;

    const inquiry: IBookingRequestMessage = {
        bookingType: requestType || BOOKING_TYPE_INDIRECT,
        optimizelyCode: '', //fixed
        operatorNid: cruisePage.operator.nid,
        operatorTitle: cruisePage.operator.title,
        shipTitle: cruisePage.ship.title,
        shipNid: cruisePage.ship.nid,
        cruiseNid: requestForm.cruiseNid,
        cruiseTitle: cruisePage.title,
        nights: cruisePage.nights,
        cabinNid: requestForm.cabinNid,
        cabinPrice: isAllInclusive ? cabinPriceAllIn : cabinPrice,
        perPersonPrice: isAllInclusive ? perPersonPriceOutAllIn : perPersonPriceOut,
        cabinTypeTitle: cabin?.cabinType?.title,
        currency,

        rateCode: isAllInclusive ? rate?.all_in_price.rate_code : rate?.cheapest_price.rate_code,
        rateDesc: isAllInclusive ? rate?.all_in_price.rate_desc : rate?.cheapest_price.rate_desc,
        isAllInclusive,
        rateLastUpdate: -1, //fixed

        sailNid: requestForm.sailNid,

        departure: sail?.departure,
        arrival: sail?.arrival,

        departureTime: portOfEmbarkation?.departure,
        arrivalTime: portOfDisembarkation?.arrival,

        departureHarbourTitle: portOfEmbarkation?.title,
        departureHarbourNid: portOfEmbarkation?.nid,

        arrivalHarbourTitle: portOfDisembarkation?.title,
        arrivalHarbourNid: portOfDisembarkation?.nid,

        numAdult: requestForm.selectedPax.adult.num,
        numSenior: 0, //fixed
        numJunior: requestForm.selectedPax.junior.num,
        numChild: requestForm.selectedPax.child.num,
        numBaby: requestForm.selectedPax.baby.num,

        minAgeAdult: 0, // fixed
        minAgeSenior: 0, // fixed
        minAgeJunior: 0, // fixed
        minAgeChild: 0, // fixed
        minAgeBaby: 0, // fixed

        salutation: requestForm.isFemale ? 'female' : 'male',
        firstName: requestForm.firstname,
        lastName: requestForm.lastname,

        phoneCountryCode: requestForm.phoneCountryCode,
        phone: getPhone(requestForm.phoneCountryCode, requestForm.phone, requestForm.ddd),
        email: requestForm.email,
        callbackTime,
        hasActiveDisclaimer: false, //false
        hasNewsletterSubscription: requestForm.newsletterSubscription,
        message,
        airportName: requestForm?.selectedAirportCode,
        transportationType: '', //fixed
        directBookingLink: '', //fixed
        __mpuid: '', //fixed
        zip: requestForm.zip,
        isEstimated: requestForm.isEstimatedPrice,
        childAges: requestForm.childAges.map((item) => item.age),
        experimentData: getExperimentData(new Date().getTimezoneOffset()),
        user_id: readCookie('eruid'),
        _ga: readCookie('_ga'),
        portal: pageData.appConfig.countryCode,
        sessionId: readCookie('ersid'),
        userId: readCookie('eruid'),
        domain: pageData.appConfig.portalId,
        secondRequestFormShown: false,
        secondRequestFormFilledIn: false,
        address: {
            firstName: requestForm.firstname,
            lastName: requestForm.lastname,
            gender: requestForm.isFemale ? 'Female' : 'Male',
            city: requestForm.city,
            postalCode: requestForm.zip,
            street: requestForm.streetName,
            number: requestForm.streetNumber,
        },
        ages: [],
    };

    if (!inquiry.airportName && requestForm.selectedAirportIndex !== -1) {
        const airport = cruisePage.airports[requestForm.selectedAirportIndex];
        inquiry.airportName = airport.airportCode;
    }

    if (requestForm.childAges) {
        inquiry.ages = requestForm.childAges.map((item, idx) => {
            return {
                ...item,
                // 🤮
                birthday: format((item.birthday as any) as Date, 'yyyy-MM-dd'),
            };
        });
    }

    if (getGclidCookie()) {
        inquiry.experimentData.push({ key: 'gclid', value: getGclidCookie() });
    }

    if (getMsclkidCookie()) {
        inquiry.experimentData.push({
            key: 'msclkid',
            value: getMsclkidCookie(),
        });
    }

    inquiry.experimentData.push({
        key: 'isDreamlinesPackage',
        value: cruisePage.operator.isDreamlinesPackage.toString(),
    });

    // Example to send an information to Pegasus that 2nd request form is filled in
    // inquiry.experimentData.push(
    //     {key: 'xxx', value: requestForm.xxx}
    // );
    const URL = `${apiHost}/inquiry`;
    return http
        .post(URL, inquiry)
        .then(async (resp) => {
            if (resp.url) {
                eventTracker.track({
                    category: 'request-form',
                    action: 'request-form',
                    label: cruisePage.title,
                });
                dispatch({
                    type: actionTypes.handleInquiryResponse,
                    payload: {
                        isSubmitError: false,
                    },
                });
                // Emarsys tracking
                try {
                    await emarsysTracker.identifyCustomer(apiHost, requestForm.email);
                } catch (error) {
                    // console.log(error);
                }

                if (inquiry.hasNewsletterSubscription) {
                    (window as any).dataLayer.push({
                        event: 'click',
                        category: 'newsletter',
                        action: 'requestform',
                        label: 'signup',
                    });
                }
                window.location.href = resp.url;
            }
        })
        .catch((err) => {
            const validationErrors = getApiValidationErrors(err);

            dispatch({
                type: actionTypes.handleInquiryResponse,
                payload: {
                    isSubmitError: true,
                    apiValidationErrors: validationErrors,
                },
            });
            if (getState().router.isBrowser) {
                Sentry.captureException(err, { extra: { inquiry } });
            }
        });
};

export const checkForDirectBooking = () => (
    dispatch: Dispatch<AnyAction>,
    getState: () => IState,
) => {
    const { cruisePage, requestForm, pageData, router } = getState();
    const isDirectBookingURLPresent = Boolean(pageData.appConfig.directBookingUrl);
    const {
        doesCompanySupportDirectBooking,
        doesCompanySupportFlightsForDirectBooking,
        directBookingConfig,
        shouldShowDirectBooking,
        shouldShowRequestNow,
        shouldShowDirectBookingOBC,
        minPrice,
    } = cruisePage;
    let updatedConfigs = {
        shouldShowDirectBooking: false,
        shouldShowDirectBookingOBC: false,
        shouldShowRequestNow: true,
    };

    if (isDirectBookingURLPresent) {
        const { adultsNum, childrenNum, perPersonPrice, includesFlight, sailNid } = requestForm;
        const DBConfigs = {
            doesCompanySupportDirectBooking,
            doesCompanySupportFlightsForDirectBooking,
            directBookingConfig,
        };

        const price = router.pageType === 'cruisepage' ? minPrice : perPersonPrice;

        const requestFormFields = {
            adultsNum,
            childrenNum,
            perPersonPrice: price,
            includesFlight,
            sailNid,
        };
        updatedConfigs = updateDirectBookingButtons(requestFormFields, DBConfigs);
    }
    if (
        shouldShowDirectBooking !== updatedConfigs.shouldShowDirectBooking ||
        shouldShowRequestNow === updatedConfigs.shouldShowRequestNow ||
        shouldShowDirectBookingOBC === updatedConfigs.shouldShowDirectBookingOBC
    ) {
        dispatch({
            type: actionTypes.updateDirectBookingConfig,
            payload: { ...updatedConfigs },
        });
    }
};

const getDirectBookingUrl = (getState: () => IState) => {
    const { cruisePage, pageData, requestForm } = getState();
    const { directBookingUrl, languageCode } = pageData.appConfig;
    const { sails, selectedSailIndex, operator, title } = cruisePage;
    const { bookingServiceCode } = sails[selectedSailIndex];
    const additionalInfo = {
        outboundAirportCode: cruisePage.airports[requestForm.selectedAirportIndex],
        operatorNid: operator.nid,
    };

    if (!directBookingUrl) {
        console.error('Direct booking url is not defined');
        return;
    }

    const isRevelex = directBookingUrl && pageData.appConfig.isRevelex;

    return isRevelex
        ? createRevelexBookingUrl(directBookingUrl, bookingServiceCode, 'sail')
        : createDirectBookingUrl(directBookingUrl, languageCode, requestForm, additionalInfo);
};

export const goToDirectBooking = () => (_, getState: () => IState) => {
    const { router, requestForm } = getState();
    const url = getDirectBookingUrl(getState);
    const { cruiseNid, adultsNum, childrenNum, perPersonPrice } = requestForm;
    if (cruiseNid) {
        emarsysTracker.trackAddToCart([
            {
                item: cruiseNid,
                price: perPersonPrice,
                quantity: adultsNum + childrenNum,
            },
        ]);
    }
    if (router.isBrowser) {
        window.open(url, '__blank');
    }
};

export const getCountryDialCode = (countryCode: string, countryCodes: ICountryCode[]): string => {
    const portalCountryCode =
        countryCodes && countryCodes.length > 0
            ? countryCodes
                  .filter(
                      (code) =>
                          code.countryCode.toString().toLowerCase() === countryCode.toLowerCase(),
                  )
                  .shift()
            : null;
    return portalCountryCode ? portalCountryCode.code : '';
};

export const updateRequestFormFields = (obj: IRequestFormUpdatableFields) => (
    dispatch,
    getState,
) => {
    dispatch({
        type: actionTypes.updateRequestFormFields,
        payload: obj,
    });
};
