import fetch from 'isomorphic-fetch';
import { NotFoundError } from '../errors/NotFoundError';
import { TimeoutError } from '../errors/TimeoutError';
import { IsomorphicHelpers, isClientSide } from './IsomorphicHelpers';
import { BadRequestError } from '../errors/BadRequestError';

enum HttpErrorCodes {
    NotFound = 404,
    Timeout = 504,
    BadRequest = 400,
}

const cache = {};
function getUserAgent() {
    try {
        return isClientSide()
            ? window?.navigator?.userAgent
            : IsomorphicHelpers.request.headers['user-agent'];
    } catch (e) {
        console.log(e);
    }
}

function getFromCacheOrRemote(key, url, method, data = null): Promise<any> {
    const methodArgs = [url, data];
    if (cache[key]) {
        return new Promise((resolve) => resolve(cache[key]));
    } else {
        return method.apply(this, methodArgs).then((resp) => {
            cache[key] = resp;
            return resp;
        });
    }
}

async function fetchpost(url: string, payload: {}): Promise<any> {
    try {
        const response = await fetch(url, {
            method: 'POST',
            body: JSON.stringify(payload),
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-User-Agent': getUserAgent(),
                'User-Agent': isClientSide() ? undefined : 'Dreamlines/1.0 (website-mobile)',
            },
        });

        if (response.ok) {
            return response.text().then((text) => parseResponse(text));
        }

        if (response.status === HttpErrorCodes.NotFound) {
            throw new NotFoundError('Not found response from server');
        } else if (response.status === HttpErrorCodes.Timeout) {
            throw new TimeoutError('504 Gateway timeout');
        } else if (response.status === HttpErrorCodes.BadRequest) {
            try {
                const jsonError = await response.json();
                throw jsonError;
            } catch (parsedError) {
                throw new BadRequestError('Bad Request', parsedError);
            }
        } else {
            throw new Error('Bad response from server');
        }
    } catch (error) {
        throw error;
    }
}

const parseResponse = (str: string): Record<string, any> | null => {
    if (!str) {
        return null;
    }
    try {
        return JSON.parse(str);
    } catch (e) {
        return { url: str };
    }
};

export function fetchgetraw(url: string) {
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
            'User-Agent': getUserAgent(),
        },
    })
        .then(async (response) => {
            if (response.ok) {
                const body = await response.text();
                return {
                    body,
                    headers: (response.headers as any)._headers, // This hack is due to the nature of the Header object polyfill
                    statusCode: response.status,
                };
            } else if (response.status === 404) {
                throw new NotFoundError('Not found response from server');
            } else {
                throw new Error('Bad response from server');
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function fetchget(url: string) {
    return fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            'X-User-Agent': getUserAgent(),
            'User-Agent': isClientSide() ? undefined : 'Dreamlines/1.0 (website-mobile)',
        },
    })
        .then((response) => {
            if (response.ok) {
                return response.json();
            } else if (response.status === 404) {
                throw new NotFoundError(`Not found response from server, URL: ${response?.url}`);
            } else if (response.status === 504) {
                throw new TimeoutError('504 Gateway timeout');
            } else {
                throw new Error(`Bad response from server , URL: ${response?.url}`);
            }
        })
        .catch((error) => {
            throw error;
        });
}

export function get(url: string, isCached = false) {
    const key = `get:${url}`;
    if (isCached) {
        return getFromCacheOrRemote(key, url, fetchget);
    }
    return fetchget(url);
}

export function post(url: string, data: any, isCached = false) {
    const key = `post:${url}:${JSON.stringify(data)}`;
    if (isCached) {
        return getFromCacheOrRemote(key, url, fetchpost, data);
    }
    return fetchpost(url, data);
}
