import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function FavoritesIcon(props: IIcon) {
    return (
        <Icon
            dropShadow={props.dropShadow}
            width={props.width}
            height={props.height}
            viewBox={props.viewBox ?? '0 0 20 20'}
            style={props.style}
            className={props.className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M0 6.56847C0 8.08148 0.586773 9.53264 1.63166 10.6063L9.27321 18.8466C9.4616 19.05 9.72513 19.1654 9.99999 19.1654C10.2749 19.1654 10.5384 19.05 10.7268 18.8466L18.3673 10.6063C19.4132 9.53264 20 8.08148 20 6.56847C20 5.04707 19.406 3.58754 18.3498 2.51176C17.2936 1.43598 15.8616 0.832031 14.3669 0.832031C12.8732 0.832031 11.4412 1.43598 10.385 2.51176L9.99999 2.9039L9.61499 2.51176C8.55878 1.43598 7.12579 0.832031 5.63207 0.832031C4.13835 0.832031 2.70536 1.43598 1.64916 2.51176C0.592948 3.58754 0 5.04707 0 6.56847Z"
                fill="currentColor"
            />
        </Icon>
    );
}
