import * as actionTypes from '../consts/actionTypes';

export interface IUIReducer {
    isLoadingGlobal: boolean;
    isLoadingPage: boolean;
    activeTab: string;
}

export const initialState: IUIReducer = {
    isLoadingGlobal: false,
    isLoadingPage: false,
    activeTab: '',
};

export function ui(state: IUIReducer = initialState, action): IUIReducer {
    switch (action.type) {
        case actionTypes.loadCruisePageDataPending:
        case actionTypes.loadHomePageDataPending:
        case actionTypes.showLoadingIndicator:
        case actionTypes.setRequestFormConfig:
        case actionTypes.getUGCReviewsPending:
            return { ...state, isLoadingGlobal: true };
        case actionTypes.loadRatesPending:
        case actionTypes.loadHomePageData:
        case actionTypes.loadCruisePageData:
        case actionTypes.loadCruisePageDataRejected:
        case actionTypes.loadRatesFulfilled:
        case actionTypes.loadRatesRejected:
        case actionTypes.getUGCReviewsFullFilled:
        case actionTypes.getUGCReviewsRejected:
        case actionTypes.hideLoadingIndicator:
        case actionTypes.handleInquiryResponse:
            return { ...state, isLoadingGlobal: false };
        default:
            return state;
    }
}
