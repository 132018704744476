import * as React from 'react';
import * as actionTypes from '../consts/actionTypes';
import { shipOverviewPage } from '../consts/pageTypes';
import { get } from '../helpers/http';
import { changeRouteSuccess } from './routerActions';
import { ISeo } from '../interfaces/ISeo';
import { defaultSeo } from '../reducers/pageData';
import { getFooterByPageType } from './footerActions';

import loadable from '@loadable/component';

export const shipOverviewRouteAction = (context) => {
    const storeState = context.store.getState();
    const { apiHost } = storeState.pageData.appConfig;
    const { path, params, baseUrl, action } = context;

    context.store.dispatch({ type: actionTypes.showLoadingIndicator });

    const defaultActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: shipOverviewPage,
                seo: seo,
            }),
        );

        const ShipOverviewPage = loadable(() => import('../pages/ShipOverview/ShipOverviewPage'));

        return { component: <ShipOverviewPage />, context };
    };

    const result = get(`${apiHost}/footer/ship`);
    result.then((response) => {
        context.store.dispatch(getFooterByPageType(response) as any);
    });

    try {
        return Promise.resolve(get(`${apiHost}/ships/overview/mobile`)).then((resp) => {
            context.store.dispatch({
                type: actionTypes.loadShipOverviewPageData,
                payload: resp,
            });
            return defaultActions(resp.seo);
        });
    } catch {
        return defaultActions(context.store.getState().pageData.seo);
    }
};
