import * as React from 'react';
import * as vars from '../../../consts/variables';

import { EmailShare } from '../EmailShare/EmailShare';
import { SocialShare } from '../SocialShare/SocialShare';

export const styles: Record<string, React.CSSProperties> = {
    wrapper: {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        padding: '0 0 15px 0',
    },
    circle: {
        width: 40,
        height: 40,
        backgroundColor: vars.acqa,
        border: `1px solid ${vars.acqa}`,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        flex: '0 0 auto',
        margin: '0 5px',
    },
    icon: {
        fill: vars.white,
    },
};

interface IProps {
    emailShareLink: string;
    socialShareLink: string;
}

export function ShareArea({ emailShareLink, socialShareLink }: IProps) {
    return (
        <div style={styles.wrapper}>
            <EmailShare shareLink={emailShareLink} />
            <SocialShare shareLink={socialShareLink} />
        </div>
    );
}
