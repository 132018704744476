import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function Instagram(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox || '0 0 24 24'}
            style={props.style}
            className={props.className}
        >
            <path d="M7.055.142c-1.27.06-2.138.264-2.897.56a5.84 5.84 0 0 0-2.112 1.382A5.85 5.85 0 0 0 .673 4.2c-.294.76-.494 1.628-.55 2.9C.068 8.374.055 8.78.061 12.023c.006 3.242.02 3.649.081 4.923.061 1.27.264 2.137.56 2.896.307.785.717 1.45 1.382 2.112a5.856 5.856 0 0 0 2.118 1.373c.76.294 1.628.494 2.899.55 1.273.056 1.68.068 4.921.062 3.243-.006 3.65-.02 4.923-.081 1.271-.06 2.138-.264 2.897-.56a5.854 5.854 0 0 0 2.112-1.382 5.855 5.855 0 0 0 1.373-2.118c.294-.759.494-1.627.55-2.898.055-1.274.068-1.681.062-4.923-.006-3.242-.021-3.649-.081-4.922-.06-1.271-.264-2.138-.56-2.897a5.858 5.858 0 0 0-1.382-2.112A5.844 5.844 0 0 0 19.8.673c-.76-.294-1.628-.494-2.9-.55-1.273-.056-1.68-.068-4.922-.062-3.242.006-3.649.02-4.922.081Zm.14 21.585c-1.165-.05-1.797-.244-2.218-.406a3.72 3.72 0 0 1-1.375-.89 3.711 3.711 0 0 1-.896-1.372c-.164-.42-.36-1.052-.415-2.216-.06-1.259-.073-1.636-.079-4.825-.006-3.187.006-3.565.06-4.824.05-1.164.245-1.796.407-2.217.216-.559.474-.956.89-1.375a3.699 3.699 0 0 1 1.372-.896c.421-.164 1.052-.36 2.216-.415 1.26-.06 1.636-.072 4.824-.079 3.188-.006 3.566.005 4.825.06 1.163.051 1.796.244 2.217.407.558.216.956.473 1.375.89.419.418.678.814.896 1.372.164.42.36 1.052.415 2.216.06 1.259.073 1.636.079 4.824.006 3.188-.006 3.566-.06 4.825-.052 1.164-.245 1.796-.407 2.218a3.71 3.71 0 0 1-.891 1.374 3.713 3.713 0 0 1-1.371.896c-.42.164-1.053.36-2.216.415-1.259.06-1.636.073-4.825.079-3.187.006-3.564-.006-4.824-.06Zm9.733-16.11a1.433 1.433 0 1 0 2.866-.006 1.433 1.433 0 0 0-2.866.006ZM5.87 12.011a6.131 6.131 0 1 0 12.262-.025 6.131 6.131 0 0 0-12.262.025Zm2.15-.004a3.98 3.98 0 1 1 7.961-.014 3.98 3.98 0 0 1-7.96.014Z" />
        </Icon>
    );
}
