import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { ReactNode } from 'react';
import { ButtonProgressCircular } from './ButtonProgressCircular';
import { ArrowRightIcon } from '../Icons/ArrowRightIcon';
import { TestSelector } from '../../helpers/TestSelector';

enum SupportedButtonColors {
    'primary' = 'primary',
    'secondary' = 'secondary',
    'tertiary' = 'tertiary',
    'tertiaryBrand' = 'tertiaryBrand',
    'quaternary' = 'quaternary',
    'flatPrimary' = 'flatPrimary',
    'flatSecondary' = 'flatSecondary',
}

export type ButtonSize = 'small' | 'medium' | 'large';
export type ButtonColor = keyof typeof SupportedButtonColors;

interface Button {
    children?: ReactNode | string;
    size?: ButtonSize;
    color?: ButtonColor;
    className?: string;
    disabled?: boolean;
    loading?: boolean;
    endIcon?: ReactNode;
    startIcon?: ReactNode;
    withArrow?: boolean;
    type?: 'button' | 'reset' | 'submit';
    as?: React.ElementType;
    href?: string;
    testSelectorName?: string;
    fullWidth?: boolean;
    target?: '__self' | '__blank' | '_parent' | '__top';
    rounded?: boolean;
    paddings?: boolean;
    onClick?: (val?: any) => void;
}

const StartIcon = styled.span<{ color: ButtonColor }>`
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 12px;
        fill: ${({ theme, color }) => theme.button[color].icon};
    }
`;
const EndIcon = styled.span<{ color: ButtonColor }>`
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
        width: 12px;
        fill: ${({ theme, color }) => {
            return theme.button[color].icon;
        }};
    }
`;

const handleSize = (size: string, withStartIcon: any, withEndIcon: any, hasText?: boolean) => {
    switch (size) {
        case 'small':
            return css`
                font-size: 14px;
                line-height: 22px;
                padding: ${hasText ? '5px 20px' : '8px'};
                ${!hasText && { width: 32, height: 32 }}
                ${withStartIcon && hasText && { paddingLeft: 16 }}
                ${withEndIcon && { paddingRight: 16 }}
                ${StartIcon}, ${EndIcon} {
                    svg {
                        width: 12px;
                        height: 12px;
                    }
                }
            `;
        case 'medium':
            return css`
                font-size: 14px;
                line-height: 22px;
                padding: ${hasText ? '9px 24px' : '10px'};
                ${withStartIcon && hasText && { paddingLeft: 20 }}
                ${withEndIcon && { paddingRight: 20 }}
                ${StartIcon}, ${EndIcon} {
                    svg {
                        width: ${hasText ? '14px' : '16px'};
                        height: ${hasText ? '14px' : '16px'};
                    }
                }
            `;
        case 'large':
            return css`
                font-size: 16px;
                padding: ${hasText ? '10px 24px' : '12px'};
                ${withStartIcon && hasText && { paddingLeft: 20 }}
                ${withEndIcon && { paddingRight: 20 }}
                ${!hasText && { padding: 12 }}
                ${StartIcon}, ${EndIcon} {
                    svg {
                        width: ${hasText ? '16px' : '20px'};
                        height: ${hasText ? '16px' : '20px'};
                    }
                }
            `;
        default:
            return css``;
    }
};

const ButtonBase = css`
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;

    display: inline-flex;
    padding: 10px 24px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 8px;
    border-style: solid;
    border-width: 2px;
    border-color: transparent;
    cursor: pointer;
    &:hover {
        text-decoration: none;
    }
`;

const getButtonStylesByColor = (color, theme) => {
    return css`
        background-color: ${theme.button[color].background};
        color: ${theme.button[color].text};
        border-color: ${theme.button[color].border};
        &:hover {
            background-color: ${theme.button[color].backgroundHover};
            color: ${theme.button[color].textHover};
            border-color: ${theme.button[color].borderHover};
        }
        &:disabled,
        &.disabled,
        button[disabled] {
            background-color: ${theme.button[color].backgroundDisabled};
            color: ${theme.button[color].textDisabled};
            border-color: ${theme.button[color].borderDisabled};
            ${StartIcon}, ${EndIcon} {
                svg {
                    fill: ${theme.button[color].iconDisabled};
                }
            }
        }
    `;
};

const ButtonStyled = styled.button<{
    size: ButtonSize;
    color: ButtonColor;
    fullWidth?: boolean;
    withArrow?: boolean;
    endIcon?: ReactNode;
    startIcon?: ReactNode;
    rounded: boolean;
    paddings: boolean;
    hasText?: boolean;
}>`
    ${ButtonBase}
    ${({ theme, color }) => {
        if (SupportedButtonColors[color]) {
            return getButtonStylesByColor(color, theme);
        }
        return null;
    }}
    ${({ fullWidth, size, withArrow, startIcon, endIcon, rounded, paddings, hasText }) => {
        const withStartIcon = startIcon;
        const withEndIcon = withArrow || endIcon;

        return css`
            ${fullWidth ? { width: '100%' } : { width: 'auto' }}
            ${handleSize(size, withStartIcon, withEndIcon, hasText)}
            ${rounded && { borderRadius: '50%' }}
            ${!paddings && { padding: 0 }}
        `;
    }}
`;

export const Button = (props: Button) => {
    const {
        children,
        size = 'large',
        color = 'primary',
        type = 'button',
        fullWidth,
        loading,
        startIcon: startIconProp,
        endIcon: endIconProp,
        withArrow,
        disabled,
        testSelectorName,
        rounded = false,
        paddings = true,
        ...restProps
    } = props;
    const currentEndIcon = withArrow ? <ArrowRightIcon /> : endIconProp;
    const startIcon = startIconProp && <StartIcon color={color}>{startIconProp}</StartIcon>;

    const endIcon = (withArrow || endIconProp || loading) && (
        <EndIcon color={color}>
            {loading ? <ButtonProgressCircular color={color} /> : currentEndIcon}
        </EndIcon>
    );
    return (
        <ButtonStyled
            {...restProps}
            paddings={paddings}
            rounded={rounded}
            color={color}
            size={size}
            type={type}
            disabled={loading || disabled}
            fullWidth={fullWidth}
            withArrow={withArrow}
            startIcon={startIconProp}
            endIcon={endIconProp}
            hasText={!!children}
            {...TestSelector.getSelector(testSelectorName)}
        >
            {startIcon}
            {children}
            {endIcon}
        </ButtonStyled>
    );
};
