import * as actionTypes from '../consts/actionTypes';
import { INewsletterPopUp } from '../interfaces/INewsletter';

export interface INewsletterPageReducer {
    uri: string;
    pageId: number;
    headline: string;
    title: string;
    seoRobotsNofollow: boolean;
    seoRobotsNoIndex: boolean;
    seoTitle: string;
    seoMetadesc: string;
    backgroundImage: string;
    newsletterHeadline: string;
    newsletterShowBadge: boolean;
    newsletterBadgeColor: string;
    newsletterBadgeHeadline: string;
    newsletterBadgeSubline: string;
    newsletterBenefits: { benefit: string }[];
    newsletterFooterText: string;
    email: string;
    isLoading: boolean;
    isValid: boolean;
    isSuccessful: boolean;
    submitError: boolean;
    popUp: INewsletterPopUp;
}

export const initialState: INewsletterPageReducer = {
    uri: '',
    pageId: null,
    headline: '',
    title: '',
    seoRobotsNofollow: null,
    seoRobotsNoIndex: null,
    seoTitle: '',
    seoMetadesc: '',
    backgroundImage: '',
    newsletterHeadline: '',
    newsletterShowBadge: null,
    newsletterBadgeColor: '',
    newsletterBadgeHeadline: '',
    newsletterBadgeSubline: '',
    newsletterBenefits: [],
    newsletterFooterText: '',
    email: '',
    isLoading: false,
    isValid: true,
    isSuccessful: false,
    submitError: false,
    popUp: {
        shouldNewsletterModalShown: false,
        timeout: 0,
    },
};

export function newsletterPage(
    state: INewsletterPageReducer = initialState,
    action,
): INewsletterPageReducer {
    switch (action.type) {
        case actionTypes.loadNewsletterPageData:
            return { ...state, ...action.payload };
        case actionTypes.updateNewsletterPopUpData:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
