import * as actionTypes from '../consts/actionTypes';
import * as http from '../helpers/http';
import { Store } from '../configureStore';
import { NotFoundError } from '../errors/NotFoundError';
import { ExpiredCruiseError } from '../errors/ExpiredCruiseError';

export const cruisePageBaseAction = context => {
    const { params, waitForAction } = context;
    const store: Store = context.store;

    store.dispatch({ type: actionTypes.showLoadingIndicator });

    const { cruisePage } = context.store.getState();
    let id: false | number = false;

    if (!isNaN(params.cruise)) {
        id = params.cruise;
    } else {
        const splited = params.cruise.split('-');

        id =
            splited &&
            splited.length > 1 &&
            Number.isInteger(parseInt(splited[splited.length - 1], 10))
                ? parseInt(splited[splited.length - 1], 10)
                : false;
    }

    if (!id) {
        throw new NotFoundError('Cruise id missing');
    }

    if (waitForAction) {
        store.dispatch({ type: actionTypes.loadCruisePageDataPending });
        return http
            .get(`${store.getState().pageData.appConfig.apiHost}/cruisedata/${id}/mobile`)
            .then(
                resp => {
                    store.dispatch({
                        type: actionTypes.loadCruisePageData,
                        payload: resp,
                    });
                    return context.next();
                },
                err => {
                    throw new ExpiredCruiseError('Cruise not found' + err);
                },
            );
    }

    if (cruisePage && !cruisePage.title) {
        throw new ExpiredCruiseError('Cruise not found');
    }

    return context.next();
};
