import * as actionTypes from '../../consts/actionTypes';
import { IContactFormReducer } from '../../interfaces/IContactForm';
import { setContactFormInitialData, deleteContactFormData } from './contactFormMappers';

export const initialState: IContactFormReducer = {
    contactForm: {
        isSubmitted: false,
        isValid: false,
        isSent: false,
        isSubmitError: false,
        errors: [],
        firstInvalidFormField: '',
        firstName: '',
        lastName: '',
        countryCode: '',
        phoneNumber: '',
        customerMail: '',
        timeZone: '',
        messageBody: '',
        bkIdCode: '',
        action: undefined,
    },
    advisorModal: {
        isSubmitted: false,
        isValid: false,
        isSent: false,
        isSubmitError: false,
        errors: [],
        firstInvalidFormField: '',
        firstName: '',
        lastName: '',
        countryCode: '',
        phoneNumber: '',
        customerMail: '',
        timeZone: '',
        messageBody: '',
        bkIdCode: '',
        action: undefined,
    },
    contactFormAgent: {
        isSubmitted: false,
        isValid: false,
        isSent: false,
        isSubmitError: false,
        firstInvalidFormField: '',
        firstName: '',
        lastName: '',
        countryCode: '',
        phoneNumber: '',
        customerMail: '',
        timeZone: '',
        messageBody: '',
        bkIdCode: '',
        action: undefined,
    },
};

export function contactForm(state = initialState, action): IContactFormReducer {
    switch (action.type) {
        case actionTypes.setContactFormInitialData:
            return setContactFormInitialData(state, action.payload);
        case actionTypes.updateContactFormData:
            const { channel, key, value } = action.payload;
            return {
                ...state,
                [channel]: {
                    ...state[channel],
                    [key]: value,
                },
            } as any;
        case actionTypes.deleteContactFormData:
            return deleteContactFormData(state, action.payload);
        default:
            return state;
    }
}
