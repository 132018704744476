import * as CruisePageActionsCreators from '../../actions/cruisePageActions';
import * as RequestFormActionsCreators from '../../actions/requestFormActions';
import * as UgcActionsCreators from '../../actions/ugcAction';
import * as FavoritesPageActionsCreators from '../../actions/favoritesPageActions';
import * as pageDataActions from '../../actions/pageDataActions';

export default {
    ...CruisePageActionsCreators,
    ...RequestFormActionsCreators,
    ...UgcActionsCreators,
    ...FavoritesPageActionsCreators,
    ...pageDataActions,
};
