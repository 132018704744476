import * as React from 'react';

import styled from '@emotion/styled';
import { __ } from '../../helpers/TranslationService';
import { IFooterContactInfo } from '../../interfaces/IFooter';

interface IFooterContactInfoProps extends IFooterContactInfo {
    title: string;
    content?: string;
}

const ContactsItem = styled.li`
    flex-basis: 25%;
    flex-shrink: 0;
`;

const Title = styled.h3`
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
    margin-top: 0;
    text-transform: uppercase;
`;

const StyledLink = styled.a`
    background: ${({ theme }) => theme.button.primary.background};
    color: ${({ theme }) => theme.button.primary.text};
    padding: 4px 12px;
    display: inline-flex;
    align-items: center;
    margin-right: 4px;

    &:hover,
    &:visited {
        color: ${({ theme }) => theme.button.primary.text};
        text-decoration: none;
    }
`;

const ButtonsWrapper = styled.div`
    display: flex;
`;

const TextWrapper = styled.div`
    & p {
        margin-top: 0;
    }
`;

export const FooterContactInfo = ({ title, content, jobButton }: IFooterContactInfoProps) => {
    const showJobButtonLink = !!jobButton?.targetUrl && jobButton?.linkTitle;

    return (
        <ContactsItem>
            <Title>{title}</Title>
            <TextWrapper dangerouslySetInnerHTML={{ __html: content || '' }} />
            <ButtonsWrapper>
                {showJobButtonLink && (
                    <StyledLink href={jobButton.targetUrl} role="button">
                        {jobButton.linkTitle}
                    </StyledLink>
                )}
                <StyledLink
                    href={__('http://blog.dreamlines.it/', 'dreamlines')}
                    title={__('Cruise News', 'dreamlines')}
                >
                    {__('Cruise News', 'dreamlines')}
                </StyledLink>
            </ButtonsWrapper>
        </ContactsItem>
    );
};
