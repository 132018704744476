import { validatePhone, validateEmail } from '../../helpers/validation';
import { IContactFormData, IContactFormReducer } from '../../interfaces/IContactForm';

interface IContactFormValidation {
    isFormValid: boolean;
    firstInvalidFormField: string;
}

export function setContactFormInitialData(
    state: IContactFormReducer,
    payload,
): IContactFormReducer {
    return {
        ...state,
        ...payload,
    };
}

export function deleteContactFormData(state: IContactFormReducer, payload): IContactFormReducer {
    return {
        ...state,
        ...payload,
    };
}

export function isContactFormValid(formData: IContactFormData): IContactFormValidation {
    const nonEmpty = (value) => value && value.length > 0;
    const fields = [
        { key: 'firstName', validator: nonEmpty },
        { key: 'lastName', validator: nonEmpty },
        { key: 'phoneNumber', validator: validatePhone },
        { key: 'countryCode', validator: nonEmpty },
        { key: 'customerMail', validator: validateEmail },
    ];
    let isFormValid = true;
    let firstInvalidFormField = null;

    try {
        fields.forEach((item) => {
            if (!item.validator(formData[item.key])) {
                firstInvalidFormField = item.key;
                throw Error;
            }
        });
    } catch (e) {
        isFormValid = false;
    }
    return { isFormValid, firstInvalidFormField };
}

export function submitContactForm(form: IContactFormData): IContactFormData {
    const { isFormValid, firstInvalidFormField } = isContactFormValid(form);
    return { ...form, firstInvalidFormField, isValid: isFormValid };
}
