import * as React from 'react';
import { IIcon } from '../../interfaces/IIcon';
import { Icon } from '../Icon';

const style: React.CSSProperties = {
    fillRule: 'evenodd',
};

export function ArrowRightIcon(props: IIcon) {
    return (
        <Icon
            dropShadow={props.dropShadow}
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <path
                id="arrow_right"
                data-name="arrow right"
                style={style}
                d="M197.23,21.4a25.33,25.33,0,0,0-35.81,0l-17.9,17.9a25.32,25.32,0,0,0,0,35.81L330,261.59,143.52,448a25.34,25.34,0,0,0,0,35.82l17.9,17.9a25.31,25.31,0,0,0,35.81,0L419.52,279.47a25.28,25.28,0,0,0,0-35.78Z"
            />
        </Icon>
    );
}
