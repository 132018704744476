import * as actionTypes from '../consts/actionTypes';
import { ICruiseRecommendation } from '../interfaces/ISearchApi';
import { IZoneTab } from '../interfaces/IZoneTabs';
import { ICruiseOffers } from '../interfaces/IUGCReviews';
import { IBreadcrumb } from '../interfaces';
import { IPromoBanner } from './landingPage';

export interface IDestinationDetailPageReducer {
    zoneId: number;
    destinationName: string;
    title: string;
    headline: string;
    subHeadline: string;
    description: string;
    headerImage: string;
    factsAndFigures: string;
    url: string;
    images: string[];
    seo: ISeo;
    advisor: IAdvisor;
    cruises: ICruiseRecommendation[];
    harbours: IHarbour[];
    staticHarbours: IHarbourPage[];
    breadcrumbs: IBreadcrumb[];
    offers: ICruiseOffers;
    zoneTabs: IZoneTab[] | null;
    promoBanner?: IPromoBanner | null;
}
export interface ISeo {
    text: string;
    titleOg: string;
    robotsNoFollow: boolean;
    robotsNoIndex: boolean;
    metadesc: string;
}

export interface IAdvisor {
    headline: string;
    text: string;
    name: string;
    image: string;
    phone: string;
}

export interface IHarbourPage {
    nid: number;
    title: string;
    url: string;
}

export interface IHarbour {
    harbourNid: number;
    title: string;
    description: string;
    price: number;
    priceDualCurrency?: number;
    currency: string;
    dualCurrency?: string;
    discount: number;
    image: string;
    harbourPageUri: string;
}

export const initialState: IDestinationDetailPageReducer = {
    zoneId: null,
    destinationName: null,
    title: null,
    headline: null,
    subHeadline: null,
    description: null,
    headerImage: null,
    factsAndFigures: null,
    url: null,
    seo: {
        text: null,
        titleOg: null,
        robotsNoFollow: null,
        robotsNoIndex: null,
        metadesc: null,
    },
    advisor: {
        headline: null,
        text: null,
        name: null,
        image: null,
        phone: null,
    },
    cruises: [],
    images: [],
    harbours: [],
    staticHarbours: [],
    breadcrumbs: [],
    offers: null,
    zoneTabs: null,
};

export function destinationDetailPage(
    state: IDestinationDetailPageReducer = initialState,
    action,
): IDestinationDetailPageReducer {
    switch (action.type) {
        case actionTypes.loadDestinationDetailPageData:
            const destinationPage = action.payload;
            return { ...state, ...destinationPage };
        default:
            return state;
    }
}
