// booking types
export const BOOKING_TYPE_DIRECT = 'DIRECT_BOOKING';
export const BOOKING_TYPE_INDIRECT = 'INDIRECT_BOOKING';

//variables
export const sliderWidth = 300;
export const brasilCountryCode = '+55';
export const SE_TOURS_COMPANY_ID = 140238;
export const AMBASSADOR_COMPANY_ID = 16970327;

//colors
export const black = '#2a2a2a';
export const softBlack = '#1A181E';
export const softBlack50 = '#1a181e80';
export const black70 = '#333333b3';
export const mediumBlack = '#7d7d7d';
export const transparent = 'transparent';
export const darkBlack = '#000000';
export const lighterBlack = '#4a4a4a';
export const lightAqua = 'rgba(0, 35, 59, 0.7)';
export const acqa = '#06aaee';
export const darkAcqa = '#006598';
export const indigo = '#001477';
export const blue = '#07496f';
export const softBlue = '#0099FF';
export const softBlue20 = '#0099ff33';
export const softBlue4 = '#0099ff0a';
export const softBlue8 = '#0099ff14';
export const softGreen = '#84BA4D';
export const softGreen20 = '#84ba4d33';
export const softGreen4 = '#84ba4d0a';

export const pink = '#e01a59';
export const red = '#e27b7b';
export const white = '#ffffff';
export const backgroundColor = '#eeeeee';
export const mapBackground = 'rgba(7, 73, 111, 0.75)';
export const darkGray = '#c3c3c3';
export const medGray = '#d3d3d3';
export const gray = '#e0e0e0';
export const lightGray = '#f0f0f0';
export const lightBlue = 'rgba(0, 101, 152, 0.15)';
export const darkBlue = '#00233b';
export const orange = '#ffa900';
export const softGrey = '#ddd';
export const newSoftGrey = '#E0E1E3';
export const hardGrey = '#aaa';
export const breadcrumbBg = '#f5f5f5';
export const breadcrumbActiveColor = '#777';
export const breadcrumbColor = '#ccc';
export const lightGreen = '#84ba4d';
export const lightRed = '#d95f4f';
export const softRed = '#EE3D58';
export const softRed4 = '#ee3d580a';
export const softRed20 = '#ee3d5833';
export const lightOrange = '#f49b0b';
export const darkRed = '#a61300';
export const dodgerBlue = '#518eff';
export const mineSharf = '#333333';
export const paprika = '#8d022e';
export const dream_deal_ping = '#9e1f62';
export const dream_deal_text = '#ef4358';
export const grey_f2 = '#f2f2f2';
export const grey_f8 = '#f8f8f8';
export const grey_e6 = '#e6e7e9';
export const discount_color = '#ff4056';
export const price_color = 'rgba(0,35,59,.7)';
export const background_company = 'rgba(255,255,255,.75)';
export const cabin_label_shadow = 'rgba(11, 12, 11, 0.56)';

export const DLpackageBg = '#d8eef5';
export const DLpackageText = '#3f4242';
export const DLpackageActionBg = '#0091c2';
export const DLPackageBannerLogo = '#5ea6c4';
export const DLPackageBannerPlus = '#2a89b1';
export const DLPackageBannerInfo = '#d8e9f1';
export const DLPackageBannerIcon = '#2888b0';
export const DLPackageBannerAction = '#11a3e0';
export const DLPackageBannerMobile = '#c4dbe7';

export const labels = {
    main2: '#00355d',
    main4: '#006295',
    shipRatings: '#459bb6',
    onlineBookable: '#07496f',
    quote: '#2e9c57',
    bestseller: '#ff9900',
    routeRatings: '#509f00',
    highDemand: '#de4a3b',
    cabinRatings: '#0d81a6',
    gastroRating: '#098398',
    entertainmentRating: '#45a6b6',
    serviceRating: '#598ea0',
    dreamdealMain: '#9e1f63',
};
