import { validatePhone, validateEmail } from '../../helpers/validation';
import {
    IDynamicFormCreateField,
    IDynamicFormReducer,
    IDynamicFormUserData,
} from '../../interfaces/IDynamicForm';
interface IDynamicFormValidation {
    isFormValid: boolean;
    firstInvalidFormField: string;
}

export function setDynamicFormInitialData(
    state: IDynamicFormReducer,
    payload,
): IDynamicFormReducer {
    return {
        ...state,
        ...payload,
    };
}
export function isDynamicFormValid(
    createFields: IDynamicFormCreateField[],
    userData: IDynamicFormUserData,
): IDynamicFormValidation {
    const nonEmpty = (value) => value && value.length > 0;
    const validationMap = {
        salutation: nonEmpty,
        firstName: nonEmpty,
        lastName: nonEmpty,
        address: nonEmpty,
        addressNumber: nonEmpty,
        postalCode: nonEmpty,
        city: nonEmpty,
        country: nonEmpty,
        countryCode: nonEmpty,
        phoneNumber: validatePhone,
        email: validateEmail,
        tripId: nonEmpty,
        product: nonEmpty,
        message: nonEmpty,
    };
    const fields = createFields
        .filter((field) => !!field?.required?.length)
        .map((field) => ({
            key: field.fieldIdentifier,
            validator: validationMap[field.fieldIdentifier],
        }));
    let isFormValid = true;
    let firstInvalidFormField = null;

    try {
        fields.forEach((item) => {
            if (!item.validator(userData[item.key], userData.countryCode)) {
                firstInvalidFormField = item.key;
                throw Error;
            }
        });
    } catch (e) {
        isFormValid = false;
    }
    return { isFormValid, firstInvalidFormField };
}

export function submitDynamicForm(
    createFields: IDynamicFormCreateField[],
    userData: IDynamicFormUserData,
): IDynamicFormUserData {
    const { isFormValid, firstInvalidFormField } = isDynamicFormValid(createFields, userData);

    return { ...userData, firstInvalidFormField, isValid: isFormValid };
}
