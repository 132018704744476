export const unescapeHTML = (str) =>
    str
        ? str
              .replace(
                  /&amp;|&lt;|&gt;|&#39;|&quot;/g,
                  (tag) =>
                      ({
                          '&amp;': '&',
                          '&lt;': '<',
                          '&gt;': '>',
                          '&#39;': "'",
                          '&quot;': '"',
                          '\\': '',
                      }[tag] || tag),
              )
              .replace(/\\/g, '')
        : null;
