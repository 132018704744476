import * as actionTypes from '../consts/actionTypes';
import { IHeader } from '../interfaces/IHeader';
import { IFooter, IFooterLink } from '../interfaces/IFooter';
import { ISeo } from '../interfaces/ISeo';
import { IBanner } from '../interfaces/IBanner';
import { IFeatureFlag } from '../interfaces/IFeatureFlag';

export interface ISlugsObj {
    [propName: string]: string;
}
export interface ICountryCode {
    code: string;
    label: string;
    countryCode: string;
    countryCodeAlpha2: string;
    country: string;
}

const defaultIFooterLink = {
    targetUrl: '',
    title: '',
    linkTitle: '',
};

const defaultIFooterLinkWithIcon = {
    targetUrl: '',
    title: '',
    linkTitle: '',
    iconClass: '',
};

const defaultIFooterContactInfo = {
    content: '',
    jobButton: null,
};

const defaultISocialMediaIcon = {
    title: '',
    social_icon: '',
    targetUrl: '',
    linkTitle: '',
};

export const defaultSeo: ISeo = {
    text: '',
    titleOg: '',
    robotsNoFollow: false,
    robotsNoIndex: false,
    metadesc: '',
    keywords: '',
};

export interface IAppConfig {
    apiHost: string;
    brand: string;
    countryCode: string;
    environment: string;
    languageCode: string;
    portalId: string;
    defaultCurrency: string;
    dualCurrency: string | null;
    enableServerSideEcommerceTracking: boolean;
    directBookingUrl: string;
    isRevelex?: boolean;
    buildVersion?: string;
}

interface ISlug {
    slugName: string;
    slugValue: string;
}

interface IShipType {
    river: number[];
    sea: number[];
}

interface IRequestForm {
    countryCodes: ICountryCode[];
    airports: { city: string; code: string }[];
    pegasusSalesUnit: number;
    precheckNewsletterSubscription: boolean;
}

export interface IBrandConfig {
    contactPagePath: string;
    defaultCurrency: string;
    emarsysId: string;
    googleOneTapClientId: string;
    googleTagManagerId: string;
    ssrTagManagerId: string;
    phoneNumber: string;
    portalId: string;
    priorityOperatorNid: number;
    priceMin: number;
    requestForm: IRequestForm;
    shipType: IShipType;
    slugs: ISlug[];
    slugsObj: ISlugsObj;
    themePath: string;
}

export interface IConfig {
    contentEndpoint: string;
}

interface IOBCThresholdItem {
    value: number | null;
    min: number | null;
    max: number | null;
}

interface IOBC {
    blacklist: number[];
    thresholds: IOBCThresholdItem[];
}

export interface IPageDataReducer {
    appConfig: IAppConfig;
    brandConfig: IBrandConfig;
    config: IConfig;
    header: IHeader;
    footerMenu: IFooterLink[];
    seo: ISeo;
    footer: IFooter;
    isMobileApp: boolean;
    discountThreshold: number;
    mobileRequestFormAdditionalFields: boolean;
    stockThreshold: any;
    isLoaded: boolean;
    bootstrapTime?: number;
    banners: IBanner;
    featureFlags: IFeatureFlag[];
    deviceType?: 'desktop' | 'tablet' | 'mobile';
    isRequestFormEnabled: boolean;
    obc?: IOBC;
}

export const initialState: IPageDataReducer = {
    bootstrapTime: new Date().getTime(),
    appConfig: {
        apiHost: '',
        brand: '',
        countryCode: '',
        environment: '',
        languageCode: '',
        portalId: '',
        defaultCurrency: '',
        dualCurrency: null,
        enableServerSideEcommerceTracking: false,
        directBookingUrl: '',
    },
    stockThreshold: {},
    brandConfig: {
        slugs: [],
        slugsObj: {},
        priorityOperatorNid: null,
        priceMin: 0,
        defaultCurrency: null,
        googleTagManagerId: null,
        ssrTagManagerId: null,
        googleOneTapClientId: null,
        shipType: {
            river: [],
            sea: [],
        },
        requestForm: {
            countryCodes: [],
            airports: [],
            pegasusSalesUnit: 1,
            precheckNewsletterSubscription: false,
        },
        emarsysId: '',
        contactPagePath: '',
        phoneNumber: '',
        portalId: '',
        themePath: '',
    },
    config: {
        contentEndpoint: null,
    },
    footerMenu: [],
    header: {
        subNavigation: [],
        mainNavigationMobile: [],
        mainNavigationDesktop: [],
        headerContent: {
            bestAwardUrl: '',
            bestPriceUrl: '',
            officeHours: {
                firstLine: '',
                secondLine: '',
            },
        },
        exchangeRate: null,
    },
    seo: defaultSeo,
    footer: {
        pageType: '',
        columnOne: {
            headline: '',
            items: [],
        },
        columnTwo: {
            headline: '',
            items: [],
        },
        columnThree: {
            headline: '',
            items: [],
        },
        columnFour: {
            headline: '',
            items: [],
        },
        columnFive: {
            headline: '',
            items: [],
        },
        bottom: [],
        socialMediaIcons: [defaultISocialMediaIcon],
        logos: [''],
        contactForm: {
            faqPageLink: defaultIFooterLinkWithIcon,
            contactPageLink: defaultIFooterLinkWithIcon,
            emailLink: defaultIFooterLinkWithIcon,
            phoneLink: defaultIFooterLinkWithIcon,
        },
        contactInfo: defaultIFooterContactInfo,
        copyright: '',
    },
    isMobileApp: false,
    discountThreshold: 0,
    mobileRequestFormAdditionalFields: false,
    isLoaded: false,
    banners: {
        infoBanner: null,
        promotionBanner: null,
    },
    featureFlags: [],
    deviceType: 'mobile',
    isRequestFormEnabled: true,
};

export function pageData(state = initialState, action): IPageDataReducer {
    switch (action.type) {
        case actionTypes.changeRouteSuccess:
            const { seo } = action.payload;
            return { ...state, seo };
        case actionTypes.getFooterByPageType:
            return { ...state, footer: action.payload };
        case actionTypes.setIsLoaded:
            return { ...state, isLoaded: action.payload };
        case actionTypes.setIsDesktop:
            const deviceType = action.payload ? 'desktop' : 'mobile';
            return { ...state, deviceType };
        default:
            return state;
    }
}
