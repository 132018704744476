import React from 'react';
import {
    IFooterSocialMediaIcon,
    IFooterPaymentMethods,
    IFooterBestPrice,
    IFooterSaferShopping,
} from '../../interfaces/IFooter';
import { __ } from '../../helpers/TranslationService';
import styled from '@emotion/styled';
import { Twitter } from '../Icons/Twitter';
import { Facebook } from '../Icons/Facebook';
import { Instagram } from '../Icons/Instagram';
import { Youtube } from '../Icons/Youtube';

const socialIcons = {
    facebook: <Facebook />,
    instagram: <Instagram />,
    youtube: <Youtube />,
    twitter: <Twitter />,
};

const StyledSocialLink = styled.a`
    fill: ${({ theme }) => theme.footer.socialIconsColor};
    height: 24px;
    width: 24px;
    margin-right: 24px;

    &:last-child {
        margin-right: 0;
    }
`;

const StyledIconsWrapper = styled.div<{ hideBestPriceAward: boolean }>`
    display: flex;
    margin-top: 25px;
    width: 100%;

       & > div {
            flex-basis: ${({ hideBestPriceAward }) => 100 / (hideBestPriceAward ? 3 : 4)}%
        }
    }
`;

const StyledIconsTitle = styled.strong`
    text-transform: uppercase;
    margin-bottom: 12px;
    display: block;
`;

const StyledImg = styled.img`
    margin-right: 5px;
    &:last-child {
        margin-right: 0;
    }
`;

export const FooterPayment = ({ list }: { list: IFooterPaymentMethods[] }) => (
    <>
        {list.map((item, key) => (
            <StyledImg
                width="52"
                height="35"
                key={key}
                src={item.image}
                alt={item.altText}
                title={item.title}
            />
        ))}
    </>
);

export const FooterBestPrice = ({ list }: { list: IFooterBestPrice[] }) => (
    <>
        {list.map((item, key) => (
            <StyledImg
                width="75"
                height="67"
                key={key}
                src={item.image}
                alt={item.altText}
                title={item.title}
            />
        ))}
    </>
);

export const FooterSaferShopping = ({ list }: { list: IFooterSaferShopping[] }) => (
    <>
        {list.map((item, key) => (
            <img
                width="75"
                height="67"
                key={key}
                src={item.image}
                alt={item.altText}
                title={item.title}
            />
        ))}
    </>
);

export const FooterSocialMediaIcons = ({ list }: { list: IFooterSocialMediaIcon[] }) => (
    <div>
        {list.map((socialMedia, key) => (
            <StyledSocialLink
                href={socialMedia.targetUrl}
                title={socialMedia.linkTitle}
                target="_blank"
                rel="noopener noreferrer"
                key={key}
            >
                {socialIcons[socialMedia.social_icon]}
            </StyledSocialLink>
        ))}
    </div>
);

export const FooterIcons = ({
    socialMediaIcons,
    paymentMethods,
    awards,
    security,
    hideBestPriceAward,
}: {
    portalId: string;
    socialMediaIcons: IFooterSocialMediaIcon[];
    paymentMethods: IFooterPaymentMethods[];
    awards: IFooterBestPrice[];
    security: IFooterSaferShopping[];
    hideBestPriceAward: boolean;
}) => (
    <StyledIconsWrapper hideBestPriceAward={hideBestPriceAward}>
        {socialMediaIcons.length > 0 ? (
            <div>
                <StyledIconsTitle>{__('Social Media', 'dreamlines')}</StyledIconsTitle>
                <FooterSocialMediaIcons list={socialMediaIcons} />
            </div>
        ) : null}

        {paymentMethods && (
            <div>
                <StyledIconsTitle>{__('Payment methods', 'dreamlines')}</StyledIconsTitle>

                <FooterPayment list={paymentMethods} />
            </div>
        )}

        {!hideBestPriceAward && awards && (
            <div>
                <StyledIconsTitle>{__('Best price Guarantee', 'dreamlines')}</StyledIconsTitle>
                <FooterBestPrice list={awards} />
            </div>
        )}

        {awards && (
            <div>
                <StyledIconsTitle>{__('Safe shopping', 'dreamlines')}</StyledIconsTitle>
                <FooterSaferShopping list={security} />
            </div>
        )}
    </StyledIconsWrapper>
);
