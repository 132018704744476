import * as React from 'react';

interface IProps {
    wrapperBg?: string;
    amount: string;
    label: string;
    placement?: string;
}

export const OnBoardCredit = (props: IProps) => {
    const wrapperStyle: React.CSSProperties = {
        background: props.wrapperBg,
    };

    const wrapperClass =
        props.placement && props.placement.length > 0 ? `onboardCredit--${props.placement}` : '';
    return (
        <div className={`onboardCredit__wrapper ${wrapperClass}`} style={wrapperStyle}>
            <div className="onboardCredit__container">
                <div className="onboardCredit__amount"> {props.amount}</div>
                <div className="onboardCredit__label"> {props.label}</div>
            </div>
        </div>
    );
};
