import * as React from 'react';

import { changeRouteSuccess } from './routerActions';
import { resetRequestForm, updateRequestFormFromCookies } from './requestFormActions';
import { cruisePage as cruisePageType } from '../consts/pageTypes';

import loadable from '@loadable/component';
import { updateCruisePageState } from './cruisePageActions';
import { setSeo } from './rootActions';

export const cruisePageRouteAction = (context) => {
    const { params, store, baseUrl, url, action } = context;
    const path = url;
    const { cruisePage, pageData, router } = store.getState();
    context.store.dispatch(resetRequestForm());
    context.store.dispatch(updateRequestFormFromCookies());
    const pageTitle = `${cruisePage.title} | ${cruisePage.ship.title} | ${cruisePage.company.title}`;
    const seo = { ...cruisePage.seo, canonical: cruisePage.canonicalUrl, titleOg: pageTitle };
    const exchangeRate = pageData.header.exchangeRate;
    context.store.dispatch(setSeo(seo));

    if (router.isBrowser && window.location.href.includes('/node/')) {
        const finalUrl = cruisePage.canonicalUrl.replace(pageData.config.contentEndpoint, '');
        window.history.replaceState(undefined, undefined, finalUrl);
    }

    context.store.dispatch(
        changeRouteSuccess({
            path,
            params,
            baseUrl,
            url,
            pageType: cruisePageType,
            pageTitle,
            seo,
            action,
        }),
    );

    context.store.dispatch(
        updateCruisePageState({
            ...cruisePage,
            exchangeRate: exchangeRate,
        }),
    );

    const CruisePage = loadable(() => import('../pages/Cruise/CruisePage'));
    return {
        component: <CruisePage />,
        context,
    };
};
