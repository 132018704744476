import * as actionTypes from '../consts/actionTypes';
import * as http from '../helpers/http';
import * as Sentry from '@sentry/react';

import { Dispatch } from 'react';
import { AnyAction } from 'redux';
import { getQueryParams } from '../helpers/IsomorphicHelpers';
import { ICruiseReducer } from '../interfaces';
import { iadvizeTracking } from '../middleware/trackingHelpers';
import { IState } from '../reducers';

export const getRates = () => (dispatch: Dispatch<AnyAction>, getState: () => IState) => {
    const storeState = getState();
    const { apiHost, defaultCurrency, dualCurrency, countryCode } = storeState.pageData.appConfig;
    const { passengers, selectedPax } = storeState.requestForm;
    const {
        nid,
        operator: { bookingServiceCode },
    } = storeState.cruisePage;
    const { baby, child, junior, adult } = selectedPax;
    const selectedPaxForAPI = {
        num_baby: baby.num,
        num_child: child.num,
        num_junior: junior.num,
        num_adult: adult.num,
    };
    const inquiry: any = {
        market: countryCode,
        bookingServiceCode,
        cruiseId: nid,
        currency: dualCurrency || defaultCurrency,
        passengers,
        selectedPax: selectedPaxForAPI,
    };

    const { promotionCode } = getQueryParams();

    if (promotionCode) {
        inquiry.promotionCode = promotionCode;
    }

    const payload = http
        .post(`${apiHost}/fareservice/ratesByCruiseWithAllIn?includeFlight=true`, inquiry, true)
        .catch((err) => {
            if (getState().router.isBrowser) {
                Sentry.captureException(err, { extra: inquiry });
                throw err;
            }
        });
    return dispatch({ type: actionTypes.loadRates, payload });
};

export const getDecks = () => (dispatch: Dispatch<AnyAction>, getState: () => IState) => {
    const storeState = getState();
    const { apiHost } = storeState.pageData.appConfig;
    const { nid } = storeState.cruisePage.ship;
    http.get(`${apiHost}/v2/ships/${nid}/decks/legacy`).then(
        (resp) => {
            return dispatch({
                type: actionTypes.loadDecks,
                payload: resp,
            });
        },
        (err) => {
            return dispatch({
                type: actionTypes.loadDecks,
                payload: null,
            });
        },
    );
};

export const getRoute = () => (dispatch: Dispatch<AnyAction>, getState: () => IState) => {
    const storeState = getState();
    const { apiHost } = storeState.pageData.appConfig;
    const { nid, isMapImageEnabled } = storeState.cruisePage;
    if (isMapImageEnabled === false) {
        return dispatch({
            type: actionTypes.loadRoute,
            payload: null,
        });
    }

    http.get(`${apiHost}/cruisedata/${nid}/map/coordinates`).then(
        (resp) => {
            return dispatch({
                type: actionTypes.loadRoute,
                payload: resp,
            });
        },
        (err) => {
            return dispatch({
                type: actionTypes.loadRoute,
                payload: null,
            });
        },
    );
};

export const changeSail = (index) => ({ type: actionTypes.changeSail, payload: index });

export const setSelectedSailIndex = (index) => (
    dispatch: Dispatch<AnyAction>,
    getState: () => IState,
) => {
    const storeState = getState();
    dispatch({ type: actionTypes.setSelectedSailIndex, payload: index });
    iadvizeTracking(getState(), storeState.router.pageType);
};

export const updateCruisePageState = (obj: ICruiseReducer) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.updateCruisePageState,
        payload: obj,
    });
};
