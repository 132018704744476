import { ICountryCode } from '../interfaces';

export enum Markets {
    de = 'de',
    nl = 'nl',
    au = 'au',
    uk = 'uk',
}
export enum SortingOptions {
    relevance = 'ab_test_1',
    departure = 'departure',
    price_asc = 'price',
    price_desc = 'price_desc',
    discount_asc = 'discount',
    duration_asc = 'duration_asc',
    duration_desc = 'duration_desc',
    aov = 'aov',
    cvr2 = 'cvr2',
}

const sortingMap: { [key in ICountryCode]?: SortingOptions } = {
    de: SortingOptions.relevance,
    c1au: SortingOptions.relevance,
    c1uk: SortingOptions.relevance,
    nl: SortingOptions.relevance,
    au: SortingOptions.relevance,
};

export const getRelevanceSorting = (
    { market }: { market: ICountryCode } = { market: 'de' },
): string => {
    if (market === 'au') return SortingOptions.relevance;
    return SortingOptions.relevance;
};

const sortingABTestMap: { [key in ICountryCode]?: SortingOptions } = {
    ...sortingMap,
    de: SortingOptions.price_asc,
};

export const getSortingBasedOnMarket = (
    { market, isNewTestGroupSort }: { market: ICountryCode; isNewTestGroupSort?: boolean } = {
        market: 'de',
        isNewTestGroupSort: false,
    },
) => {
    const sorting = isNewTestGroupSort ? sortingABTestMap[market] : sortingMap[market];
    return sorting;
};
