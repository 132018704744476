import * as React from 'react';
import { generateSlug } from '../helpers/string';

interface Props {
    children: any[];
}

const style: Record<string, React.CSSProperties> = {
    container: {
        display: 'flex',
        overflowX: 'auto',
        touchAction: 'manipulation',
    },
};

export const Slider = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
    let startX = 0,
        scrollLeft = 0,
        isDown = false;
    const id = generateSlug();

    const mouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        const slider = document.querySelector(`#${id}`) as HTMLDivElement;
        e.stopPropagation();
        isDown = true;
        startX = e.pageX - slider?.offsetLeft;
        scrollLeft = slider?.scrollLeft;
    };

    const mouseLeaveAndUp = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        isDown = false;
        e.stopPropagation();
        const slider = document.querySelector(`#${id}`) as HTMLDivElement;
        slider?.classList.remove('active');
    };

    const onMouseMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        if (!isDown) return;
        const slider = document.querySelector(`#${id}`) as HTMLDivElement;
        e.preventDefault();
        const x = e.pageX - slider?.offsetLeft;
        const walk = x - startX;
        if (slider !== null) {
            slider.classList.add('active');
            slider.scrollLeft = scrollLeft - walk;
        }
    };

    return (
        <div
            id={id}
            ref={ref}
            className="slider"
            style={style.container}
            onMouseDown={(e) => mouseDown(e, id)}
            onMouseLeave={(e) => mouseLeaveAndUp(e, id)}
            onMouseUp={(e) => mouseLeaveAndUp(e, id)}
            onMouseMove={(e) => onMouseMove(e, id)}
        >
            {props.children}
        </div>
    );
});
