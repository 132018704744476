import * as actionTypes from '../consts/actionTypes';
import { deepClone } from '../helpers/deepClone';
import { updateUGCReviews } from '../helpers/reviews';
import { IUGCReviewsReducer } from '../interfaces/IUGCReviews';

export const initialState: IUGCReviewsReducer = {
    reviews: null,
    sortString: 'date_desc',
    isLoading: false,
};

export function reviews(state: IUGCReviewsReducer = initialState, action): IUGCReviewsReducer {
    var reviews;

    switch (action.type) {
        case actionTypes.loadShipPageData:
            if (action.payload && action.payload.shipPage.reviews) {
                return {
                    ...state,
                    reviews: action.payload.shipPage.reviews,
                };
            }
            return { ...state, reviews: null };
        case actionTypes.loadCompanyDetailPageData:
            if (action.payload && action.payload.reviews) {
                return { ...state, reviews: action.payload.reviews };
            }
            return { ...state, reviews: null };
        case actionTypes.loadCruisePageData:
            if (action.payload && action.payload.reviews) {
                return { ...state, reviews: action.payload.reviews };
            }
            return { ...state, reviews: null };

        case actionTypes.loadLandingPageData:
            if (action.payload && action.payload.reviews) {
                return { ...state, reviews: action.payload.reviews };
            }
            return { ...state, reviews: null };

        case actionTypes.loadDestinationDetailPageData:
            if (action.payload && action.payload.reviews) {
                return { ...state, reviews: action.payload.reviews };
            }
            return { ...state, reviews: null };
        case actionTypes.getUGCReviewsFullFilled:
            reviews = deepClone(action.payload);
            return { ...state, reviews };
        case actionTypes.getMoreUGCReviews:
            return deepClone(updateUGCReviews(state, action));
        case actionTypes.setReviewsSortString:
            return { ...state, sortString: action.payload };
        case actionTypes.toggleUGCreviewsLoading:
            return { ...state, isLoading: action.payload };
        case actionTypes.setReviewsSortString:
            return { ...state, sortString: action.payload };
        case actionTypes.getUGCReviewsFullFilled:
            const companyReviews = deepClone(action.payload);
            return { ...state, reviews: companyReviews };
        default:
            return state;
    }
}
