import * as actionTypes from '../consts/actionTypes';
import { ICMSData } from '../interfaces/ICMSData';

interface ICompanyTermsCmsData extends ICMSData {
    content: string;
}

export interface ICompanyTermsReducer {
    cmsData: ICompanyTermsCmsData;
    companyTerms: string;
    companies: {
        nid: number;
        title: string;
        uri: string;
    }[];
}

export const initialState: ICompanyTermsReducer = {
    cmsData: {
        pageId: null,
        uri: null,
        title: null,
        seoRobotsNofollow: null,
        seoRobotsNoIndex: null,
        seoMetadesc: null,
        seoTitle: null,
        content: null,
    },
    companyTerms: null,
    companies: [],
};

export function companyTerms(
    state: ICompanyTermsReducer = initialState,
    action,
): ICompanyTermsReducer {
    let cmsData = null;
    switch (action.type) {
        case actionTypes.loadCompanyTermsList:
            cmsData = action.payload.cmsData;
            const companies = action.payload.companies;
            return { ...state, cmsData, companies };
        case actionTypes.loadCompanyTerms:
            cmsData = action.payload.cmsData;
            const companyTerms = action.payload.terms;
            return { ...state, cmsData, companyTerms };
        default:
            return state;
    }
}
