import { IDomain } from './../interfaces/IBrands';
import {
    DE_DOMAIN,
    NL_DOMAIN,
    CRUISE_AWAY_DOMAIN,
    C1AU_DOMAIN,
    C1UK_DOMAIN,
} from '../context/markets';

export function hasConsentUpdated(market: IDomain): boolean {
    (window as any).dataLayer = (window as any).dataLayer || [];
    const marketsConsentConfig = {
        [C1AU_DOMAIN]: ['default', 'update'],
        [C1UK_DOMAIN]: ['update'],
        [DE_DOMAIN]: ['update'],
        [CRUISE_AWAY_DOMAIN]: ['update'],
        [NL_DOMAIN]: ['update'],
    };

    const consentValue = ((window as any).dataLayer?.findLast?.(
        (element) => element[0] === 'consent',
    ) || [])[1];

    return marketsConsentConfig[market].includes(consentValue);
}
