import * as React from 'react';
import { getCruiseRecommendations, setThankYouData } from './thankYouActions';
import { parse as parseQueryString } from 'qs';
import { __ } from '../helpers/TranslationService';
import { changeRouteSuccess } from './routerActions';
import { thankYouPage } from '../consts/pageTypes';
import { defaultSeo } from '../reducers/pageData';

import loadable from '@loadable/component';

export const thankYouRouteAction = (context) => {
    const { path, params, baseUrl, search, url, action, queryParams } = context;
    const searchQueryObject = search ? parseQueryString(search.replace('?', '')) : queryParams;
    const cruise = searchQueryObject.cruise ? parseInt(searchQueryObject.cruise, 10) : 0;

    if (!cruise) {
        throw new Error('Invalid cruise');
    }

    const seo = defaultSeo;
    seo.titleOg = __('Thank You Mobile', 'dreamlines');
    const defaultActions = (seo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                url,
                action,
                pageType: thankYouPage,
                seo: seo,
            }),
        );

        const ThankYouPage = loadable(() => import('../pages/ThankYou/ThankYouPage'));

        return { component: <ThankYouPage />, context };
    };

    return context.store
        .dispatch(getCruiseRecommendations(cruise))
        .then((_) =>
            context.store
                .dispatch(setThankYouData(searchQueryObject))
                .then((_) => defaultActions(seo)),
        );
};
