import { IDirectBookingConfig, IAirport } from '../interfaces';
import { IRequestFormReducer } from '../interfaces/IRequestForm';
import { readCookie } from './cookie';
import { regex } from './validation';

export interface IDB {
    shouldShowDirectBooking: boolean;
    shouldShowDirectBookingOBC: boolean;
    shouldShowRequestNow: boolean;
}

interface IRFFieldsForDB {
    adultsNum: number;
    childrenNum: number;
    perPersonPrice: number;
    includesFlight: boolean;
    sailNid: number;
}

interface IDBButtons {
    passengerCount: number;
    perPersonPrice: number;
    passengerCountThreshold: number;
    totalPriceThreshold: number;
    obcBannerThreshold: number;
}

export const getDBButtons = ({
    passengerCount,
    perPersonPrice,
    passengerCountThreshold,
    totalPriceThreshold,
    obcBannerThreshold,
}: IDBButtons): IDB => {
    const result = {
        shouldShowDirectBooking: false,
        shouldShowDirectBookingOBC: false,
        shouldShowRequestNow: true,
    };
    // if cabin price is not on request and passenger count is under threshold
    if (perPersonPrice > 0 && passengerCount <= passengerCountThreshold) {
        const totalPrice = passengerCount * perPersonPrice;
        result.shouldShowDirectBooking = true;
        if (totalPrice >= obcBannerThreshold) {
            result.shouldShowDirectBookingOBC = true;
        }
        if (totalPrice <= totalPriceThreshold) {
            result.shouldShowDirectBooking = false;
        }
    }
    return result;
};

export const updateDirectBookingButtons = (
    requestFormFields: IRFFieldsForDB,
    DBConfigs: IDirectBookingConfig,
): IDB => {
    const {
        doesCompanySupportDirectBooking,
        doesCompanySupportFlightsForDirectBooking,
        directBookingConfig,
    } = DBConfigs;

    const { adultsNum, childrenNum, perPersonPrice, includesFlight, sailNid } = requestFormFields;

    let result = {
        shouldShowDirectBooking: false,
        shouldShowDirectBookingOBC: false,
        shouldShowRequestNow: true,
    };

    // if cruise includes flight check if the operator has support for direct
    // booking with flight or not.
    const isCruiseEligibleForDB = includesFlight
        ? doesCompanySupportDirectBooking && doesCompanySupportFlightsForDirectBooking
        : doesCompanySupportDirectBooking;

    if (isCruiseEligibleForDB) {
        const {
            dbMaxPassengerThreshold,
            directBookingButtonThreshold,
            obcBannerThreshold,
        } = directBookingConfig;

        const buttons = getDBButtons({
            passengerCount: adultsNum + childrenNum,
            perPersonPrice: perPersonPrice,
            passengerCountThreshold: dbMaxPassengerThreshold,
            totalPriceThreshold: directBookingButtonThreshold,
            obcBannerThreshold: obcBannerThreshold,
        });

        result = { ...buttons };
    }

    return result;
};

export const createDirectBookingUrl = (
    staticUrl: string,
    language: string,
    requestForm: IRequestFormReducer,
    additionalInfo: { outboundAirportCode: IAirport; operatorNid: number },
): string => {
    const result: any = {};

    const {
        cruiseNid,
        sailNid,
        cabinNid,
        email,
        childrenNum,
        adultsNum,
        firstname,
        lastname,
        includesFlight,
        isFemale,
        countryCode,
        phone,
    } = requestForm;
    const { outboundAirportCode, operatorNid } = additionalInfo;
    // MANDATORYFIELDS
    result.localization = language;
    result.sail = sailNid;
    result.cabin = cabinNid;
    result.companyNid = operatorNid;
    result.cruise = cruiseNid;

    // OPTIONAL
    result.email = regex.email.test(email) ? email : '';
    result.kidsAmount = childrenNum || 0;
    result.passengerAmount = adultsNum || 2;

    result.lastname = lastname || '';
    result.firstname = firstname || '';

    result.flightAssistance = includesFlight ? true : false;
    result.airport =
        outboundAirportCode && regex.outboundAirportCode.test(outboundAirportCode.airportCode)
            ? outboundAirportCode.airportCode
            : '';
    result.phone =
        regex.countryCode.test(countryCode) && regex.phone.test(phone) ? countryCode + phone : '';
    result.gender = isFemale ? 'female' : 'male'; // 'male' or 'female'
    result.dbf_session_id = readCookie('__mpuid');
    result.cpuid = readCookie('__cpuid');

    // Tracking
    // https://netvacation.atlassian.net/browse/DBF-651
    result.dbf_source = `${result.localization}_website_requestform`;
    result.dbf_timestamp = Date.now();

    const urlParams = Object.keys(result)
        .filter((key) => result[key] !== null && result[key] !== '')
        .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(result[key])}`)
        .join('&');

    return `${staticUrl}?${urlParams}`;
};

type IRevelexType = 'sail' | 'tour';
enum RevelexParams {
    sail = 'sailing_id',
    tour = 'tour_id',
}

export const createRevelexBookingUrl = (
    staticUrl: string,
    code: string,
    type: IRevelexType,
): string => {
    return `${staticUrl}/app/0/cruise/0/select_sailing.html?${RevelexParams[type]}=${code}`;
};

export const getDirectBookingThreshold = (countryCode: string) => {
    switch (countryCode) {
        case 'c1uk':
            return 2500;
        case 'nl':
            return 1;
        default:
            return 2500;
    }
};
