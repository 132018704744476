import { differenceInMonths } from 'date-fns';
import { IAge } from '../../interfaces/IRequestForm';
import { __ } from '../../helpers/TranslationService';

export const childAgeErrorMessagesTranslationKeys = {
    lessThenNthMonth: 'personalData.childrenAge.error.lessThenNthMonthOld',
    lessThenSixMonth: 'personalData.childrenAge.error.lessThenSixMonthOld',
    required: 'personalData.childrenAge.error.required',
};

const CruiseKidsValidators: Record<
    string,
    (dateOfDeparture: number, dateOfBirthday: Date, nights: number) => boolean
> = {
    aid: (dateOfDeparture, dateOfBirthday, nights) => {
        const isMoreThanThreeNights = nights >= 3;
        const departureAgeInMonth = differenceInMonths(
            new Date(dateOfDeparture * 1000),
            dateOfBirthday,
        );
        const isSixMonthOnDeparture = departureAgeInMonth >= 6;
        const isTwelveMonthOnDeparture = departureAgeInMonth >= 12;
        return isMoreThanThreeNights ? isSixMonthOnDeparture : isTwelveMonthOnDeparture;
    },
    msc: () => {
        return true;
    },
    default: (dateOfDeparture, dateOfBirthday) => {
        const departureAgeInMonth = differenceInMonths(
            new Date(dateOfDeparture * 1000),
            dateOfBirthday,
        );
        return departureAgeInMonth >= 6;
    },
};

export const getChildAgeErrorMessage = (
    childAge: IAge,
    cruiseCompany: string,
    cruiseNights: number,
    isSubmitted: boolean,
) => {
    const isDefinedDateNotValid = !childAge.isValid && childAge.birthday;

    if (!childAge.isValid && childAge.birthday === null && isSubmitted) {
        return __(childAgeErrorMessagesTranslationKeys.required, 'dreamlines');
    }

    if (isDefinedDateNotValid && cruiseCompany === 'aid' && cruiseNights >= 3) {
        return __(childAgeErrorMessagesTranslationKeys.lessThenNthMonth, 'dreamlines').replace(
            '%number%',
            12,
        );
    }

    if (isDefinedDateNotValid) {
        return __(childAgeErrorMessagesTranslationKeys.lessThenSixMonth, 'dreamlines');
    }

    return null;
};

export const isChildAgeValid = (
    dateOfDeparture: number,
    dateOfBirthday: Date,
    nights: number,
    cruiseOperatorCode: string,
) => {
    if (!dateOfBirthday) {
        return false;
    }

    const validator = CruiseKidsValidators[cruiseOperatorCode] || CruiseKidsValidators.default;
    return validator(dateOfDeparture, dateOfBirthday, nights);
};
