import * as actionTypes from '../consts/actionTypes';
import { removeTrailingSlash } from '../helpers/routing';
export interface IRouterReducer {
    baseUrl: string;
    path: string;
    params: any;
    route: any;
    url: string;
    history: any;
    scrollArray: any[];
    location: {
        search: string;
        pathname: string;
    };
    isBrowser: boolean;
    pageTitle: string;
    shipOverviewSlug: string;
    shipDetailSlug: string;
    localizationSlug: string;
    pageType: string;
    isServerSideRender: boolean;
}

export const initialState: IRouterReducer = {
    baseUrl: null,
    path: null,
    url: null,
    params: {},
    route: {},
    history: false,
    isBrowser: false,
    pageTitle: '',
    shipOverviewSlug: '',
    shipDetailSlug: '',
    localizationSlug: '',
    scrollArray: [],
    location: {
        pathname: '',
        search: '',
    },
    pageType: null,
    isServerSideRender: true,
};

export function saveScrollPosition(state, { action, scrollY }): IRouterReducer {
    let scrollArray = state.scrollArray;
    switch (action) {
        case 'POP':
            if (scrollArray.length > 0) {
                scrollArray.pop();
            }
            break;
        case 'REPLACE':
            scrollArray[scrollArray.length - 1] = scrollY;
            break;
        default:
            scrollArray.push(scrollY);
    }
    return { ...state, scrollArray };
}

export function router(state = initialState, action): IRouterReducer {
    switch (action.type) {
        case actionTypes.changeRouteSuccess:
            const { baseUrl, path, params, url, pageType, action: historyAction } = action.payload;
            const isServerSideRender = !state.isBrowser;
            if (state.history && state.history.action) {
                state.history.action = historyAction;
            }
            return {
                ...state,
                path,
                params,
                pageType,
                url: removeTrailingSlash(url),
                baseUrl: removeTrailingSlash(baseUrl),
                isServerSideRender,
            };
        case actionTypes.setLocation:
            return { ...state, location: action.payload.location };
        case actionTypes.initHistory:
            return { ...state, history: action.payload };
        case actionTypes.setEnvironment:
            return { ...state, isBrowser: action.payload.isBrowser };
        case actionTypes.setLocalization:
            return { ...state, localizationSlug: action.payload.localizationSlug };
        case actionTypes.saveScrollPosition:
            return saveScrollPosition(state, action.payload);
        default:
            return state;
    }
}
