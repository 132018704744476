import { differenceInDays } from 'date-fns';
import { getCurrencyString } from '../components/PriceString';
import { ICabinInfo, IRate, ISoldOutBooking } from '../interfaces';
import { IExchangeRate } from '../interfaces/IHeader';
import { __ } from './TranslationService';

export const currencyMap = {
    EUR: '€',
    AUD: '$',
    USD: '$',
    GBP: '£',
};

export enum AvailabilityTypes {
    soldOut = 'sold out',
    notAvailable = 'not available',
    onRequest = 'on request',
    available = 'available',
}

export function roundBy(number: number, decimalplaces: number): number {
    if (decimalplaces > 0) {
        const multiply1 = Math.pow(10, decimalplaces + 4);
        const divide1 = Math.pow(10, decimalplaces);
        return Math.round(Math.round(number * multiply1) / 10000) / divide1;
    }
    if (decimalplaces < 0) {
        const divide2 = Math.pow(10, Math.abs(decimalplaces));
        const multiply2 = Math.pow(10, Math.abs(decimalplaces));
        return Math.round(Math.round(number / divide2) * multiply2);
    }
    return Math.round(number);
}

export function daysFromNow(date: number): number {
    return differenceInDays(new Date(date * 1000), new Date());
}

export function hasSoldOut(
    departure: number | undefined,
    soldOutBooking: ISoldOutBooking,
    price: number,
): boolean {
    return Boolean(
        departure &&
            !(price > 0) &&
            soldOutBooking &&
            daysFromNow(departure) < soldOutBooking.duration,
    );
}

export const underOccupancyLimit = (cabin: ICabinInfo, passengerNumber: number) =>
    cabin?.perPersonPrice > 0 ? passengerNumber <= cabin.cabinType.maxPassengers : true;

/**
 *  Cabin and passenger number are duplicated right now with
 * cruisePageMappers/getCabinLabel because both functions can invalidate a
 * cabin price from showing. TODO: Invalidate just from once central part
 */

export interface IPriceAvailability {
    string: string;
    type?: string;
    valid: boolean;
}

export function priceAvailability(
    departure: number | undefined,
    soldOutBooking: ISoldOutBooking,
    price: number,
    currency: string,
    cabin?: ICabinInfo,
    passengerNumber?: number
): IPriceAvailability {
    if (cabin && passengerNumber && !underOccupancyLimit(cabin, passengerNumber)) {
        return {
            string: __(AvailabilityTypes.onRequest, 'dreamlines'),
            type: AvailabilityTypes.onRequest,
            valid: false,
        };
    }

    if (hasSoldOut(departure, soldOutBooking, price)) {
        return {
            string: __(AvailabilityTypes[soldOutBooking.funnelType], 'dreamlines'),
            type: AvailabilityTypes.soldOut,
            valid: false,
        };
    }
    if (cabin && passengerNumber && !underOccupancyLimit(cabin, passengerNumber)) {
        return {
            string: __(AvailabilityTypes.onRequest, 'dreamlines'),
            type: AvailabilityTypes.onRequest,
            valid: false,
        };
    }

    return price > 0
        ? {
              string: getCurrencyString(price, currency) as string,
              type: AvailabilityTypes.available,
              valid: true,
          }
        : {
              string: __(AvailabilityTypes.onRequest, 'dreamlines'),
              type: AvailabilityTypes.onRequest,
              valid: false,
          };
}

const getOnBoardCreditPrice = (price: number) => {
    if (price > 8000) {
        return 200;
    } else if (price > 4000) {
        return 100;
    } else if (price > 2000) {
        return 50;
    } else {
        return null;
    }
};

export const getOnBoardCredit = (
    companyNid: number,
    perPersonPrice: number | undefined,
    rates: IRate[],
    isAllInclusiveSelected?: boolean,
    OBCBlacklist?: number[],
) => {
    if (OBCBlacklist?.includes(companyNid)) {
        return null;
    }
    if (perPersonPrice) {
        return getOnBoardCreditPrice(perPersonPrice * 2);
    }
    if (rates.length > 0) {
        const highestPrice = Math.max(
            ...rates.map((rate) =>
                isAllInclusiveSelected
                    ? rate.all_in_price.cabin_price
                    : rate.cheapest_price.cabin_price,
            ),
        );
        return getOnBoardCreditPrice(highestPrice);
    }
    return null;
};

export const convertToDualCurrency = (exchangeRate: IExchangeRate, price: number): number =>
    exchangeRate && exchangeRate.exchangeRate > 0
        ? roundBy(price / exchangeRate.exchangeRate, 0)
        : null;

export const convertToEur = (exchangeRate: IExchangeRate, price: number): number =>
    exchangeRate && exchangeRate.exchangeRate > 0
        ? roundBy(price * exchangeRate.exchangeRate, 0)
        : null;

export interface IPriceString {
    string: string;
    valid: boolean;
}

export const discountFormat = (discount: number): string => `-${discount}%`;

export const getDiscountAboveThreshold = (discount: number, threshold: number) =>
    discount > threshold ? discount : null;

export const getPerPersonPriceString = (
    price: number | undefined,
    currency: string,
    numberOfPassenger?: number,
    soldout?: ISoldOutBooking,
) => {
    if (!price || typeof price !== 'number') {
        return __(
            soldout?.funnelType ? AvailabilityTypes[soldout.funnelType] : 'request.onRequest',
            'dreamlines',
        );
    }

    const priceWithCurrencySymbol = getCurrencyString(price, currency);
    if (numberOfPassenger && numberOfPassenger > 0) {
        return `${__('from', 'dreamlines')} ${priceWithCurrencySymbol} ${__(
            'p.P.',
            'dreamlines',
        )} ${__('for {{ n }} persons', 'dreamlines').replace('{{ n }}', numberOfPassenger)}`;
    }
    return __('from {{ displayPrice }} p.P.', 'dreamlines').replace(
        '{{ displayPrice }}',
        priceWithCurrencySymbol,
    );
};
