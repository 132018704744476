import * as actionTypes from '../consts/actionTypes';
import { filterParamsSchema } from '../consts/filterParamsSchema';
import { makeFilterParamsList } from '../helpers/queryParams';
import { IRateBulk } from '../interfaces';
import {
    ICruiseFilterParamValue,
    ICruiseSearchApiObject,
    IFacets,
    IFacetsSearchBox,
    IMobileSearchResultCruise,
} from '../interfaces/ISearchApi';
import { updateRatesBulk } from './searchMappers';

export type searchOverlayAction = 'apply' | 'cancel' | null;

export interface ISearchReducer {
    isFacetsLoading: boolean;
    isSearchResultsLoading: boolean;
    isSeachListHidden: boolean;
    searchOverlayAction: searchOverlayAction;
    isSearchFilterOpen: boolean;
    isSortOptionsOpen: boolean;
    searchResults: {
        cruises: IMobileSearchResultCruise[];
        numResults: number;
        priorityCruise: IMobileSearchResultCruise;
        maxPrice?: number;
        maxDiscount?: number;
        maxNights?: number;
    };
    searchRates: IRateBulk[];
    filterParamsList: ICruiseFilterParamValue[];
    filterParamsListActive: ICruiseFilterParamValue[];
    searchParams: ICruiseSearchApiObject;
    trackingFilter: ICruiseSearchApiObject;
    searchFacets: IFacets;
    searchFacetsAll: IFacets;
    searchFacetsSearchBox: IFacetsSearchBox;
    loadMoreResults: IMobileSearchResultCruise[];
    appendAfterLoad: boolean;
    queryString: string;
    topSearchResults: {
        cruises: IMobileSearchResultCruise[];
        numResults: number;
        priorityCruise: IMobileSearchResultCruise;
    };
    searchPageType: 'destination' | 'ship' | 'map' | 'mapWithNoUGC';
}

export const initialState: ISearchReducer = {
    isFacetsLoading: false,
    isSearchResultsLoading: false,
    isSeachListHidden: false,
    searchOverlayAction: null,
    isSearchFilterOpen: false,
    isSortOptionsOpen: false,
    searchResults: {
        cruises: [],
        numResults: 0,
        priorityCruise: null,
        maxPrice: null,
        maxDiscount: 0,
        maxNights: 300,
    },
    searchRates: [],
    filterParamsList: [],
    filterParamsListActive: [],
    searchParams: {
        limit: 10,
        offset: 0,
        filterParams: {},
        sortString: undefined,
    },
    searchFacets: {
        zoneNids: [],
        companyNids: [],
        topCompanies: [],
        topZones: [],
        startHarbours: [],
        endHarbours: [],
        harbours: [],
        startHarbourCountries: [],
        endHarbourCountries: [],
        countries: [],
        startHarbourCountriesCombined: [],
        endHarbourCountriesCombined: [],
        harbourCountriesCombined: [],
        shipNids: [],
        nights: [],
        categories: [],
        categoriesCombined: [],
        shipSizes: [],
        shipTypes: [],
        incliFlight: [],
        optionalFlight: [],
        inclOrOptionalFlight: [],
        hasDiscount: [],
        banderoles: [],
        isDreamDeal: [],
    },
    searchFacetsAll: {
        zoneNids: [],
        companyNids: [],
        topCompanies: [],
        topZones: [],
        startHarbours: [],
        endHarbours: [],
        harbours: [],
        startHarbourCountries: [],
        endHarbourCountries: [],
        countries: [],
        startHarbourCountriesCombined: [],
        endHarbourCountriesCombined: [],
        harbourCountriesCombined: [],
        shipNids: [],
        nights: [],
        categories: [],
        categoriesCombined: [],
        shipSizes: [],
        shipTypes: [],
        incliFlight: [],
        optionalFlight: [],
        inclOrOptionalFlight: [],
        hasDiscount: [],
        banderoles: [],
        isDreamDeal: [],
    },
    searchFacetsSearchBox: {
        zoneNids: [],
        companyNids: [],
        topCompanies: [],
        topZones: [],
    },
    loadMoreResults: null,
    appendAfterLoad: false,
    queryString: null,
    topSearchResults: {
        cruises: [],
        numResults: 0,
        priorityCruise: null,
    },
    trackingFilter: null,
    searchPageType: 'map',
};

const minNightToFacetId = (minNight: number) => {
    return {
        1: 1,
        5: 2,
        11: 3,
        18: 4,
        25: 5,
        32: 0,
    }[minNight];
};

const replaceFacetsNumber = (state, payload: any, facetsReferenceAll: IFacets) => {
    const selectedCompany = state?.searchParams?.filterParams?.company?.[0];
    const selectedZone = state?.searchParams?.filterParams?.zone?.[0];
    const startHarbour = state?.searchParams?.filterParams?.startHarbour?.[0];
    const endHarbour = state?.searchParams?.filterParams?.endHarbour?.[0];
    const harbour = state?.searchParams?.filterParams?.harbour?.[0];
    const ship = state?.searchParams?.filterParams?.ship?.[0];
    const banderoleNew = state?.searchParams?.filterParams?.banderoleNew?.[0];
    const nightsMin = state?.searchParams?.filterParams?.nightsMin;
    let nightId: false | number = false;
    let nights = payload.nights;

    if (nightsMin) {
        nightId = minNightToFacetId(parseInt(nightsMin, 10));
        nights = payload.nights.map((item) => {
            if (parseInt(item.name, 10) === nightId) {
                return { ...item, count: payload.numResults };
            }
            return item;
        });
    }

    const replaceFacetNumber = (item, selected) => {
        if (parseInt(item?.id, 10) === parseInt(selected, 10)) {
            return { ...item, count: payload.numResults };
        }
        return item;
    };

    const injectSelectedFacet = (selected, facets, facetsAll) => {
        const facetStillExists =
            !facetsAll ||
            !selected ||
            !!facets.find((item) => parseInt(item?.id, 10) === parseInt(selected, 10));

        if (facetStillExists) {
            return facets.map((item) => replaceFacetNumber(item, selected));
        }

        const facet = facetsAll.find((item) => parseInt(item?.id, 10) === parseInt(selected, 10));

        return [facet, ...facets].map((item) => replaceFacetNumber(item, selected));
    };

    return {
        ...payload,
        topZones: injectSelectedFacet(selectedZone, payload.topZones, facetsReferenceAll?.topZones),
        zoneNids: injectSelectedFacet(selectedZone, payload.zoneNids, facetsReferenceAll?.zoneNids),
        topCompanies: injectSelectedFacet(
            selectedCompany,
            payload.topCompanies,
            facetsReferenceAll?.topCompanies,
        ),
        companyNids: injectSelectedFacet(
            selectedCompany,
            payload.companyNids,
            facetsReferenceAll?.companyNids,
        ),
        startHarbours: payload?.startHarbours?.map((item) =>
            replaceFacetNumber(item, startHarbour),
        ),
        endHarbours: payload?.endHarbours?.map((item) => replaceFacetNumber(item, endHarbour)),
        harbours: payload?.harbours?.map((item) => replaceFacetNumber(item, harbour)),
        shipNids: payload?.shipNids?.map((item) => replaceFacetNumber(item, ship)),
        banderoles_new: payload?.banderoles_new?.map((item) =>
            replaceFacetNumber(item, banderoleNew),
        ),
        nights,
    };
};

export function search(state: ISearchReducer = initialState, action): ISearchReducer {
    let content: any = action.payload;
    switch (action.type) {
        case actionTypes.newSearch:
            return {
                ...state,
                searchResults: {
                    numResults: action.payload.data.numResults,
                    priorityCruise: action.payload.data.priorityCruise,
                    cruises: action.payload.data.cruises?.slice(0, 10),
                    maxPrice: action.payload.maxPrice,
                    maxDiscount: action.payload.maxDiscount,
                    maxNights: action.payload.data.maxNights,
                },
                loadMoreResults: action.payload.data.cruises?.slice(10, 20),
                filterParamsList: action.payload.filterParamsList,
                filterParamsListActive: action.payload.filterParamsListActive,
                searchFacets: replaceFacetsNumber(
                    { searchParams: action.payload.searchParams },
                    action.payload.searchFacets,
                    action.payload.facetsAll,
                ),
                searchFacetsAll: action.payload.facetsAll,
                searchParams: action.payload.searchParams,
                queryString: action.payload.queryString,
            };
        case actionTypes.newFacetsSearch:
            return {
                ...state,
                searchFacetsAll: action.payload.facetsAll,
            };
        case actionTypes.updateFacets:
            return {
                ...state,
                searchFacets: replaceFacetsNumber(state, action.payload, state.searchFacetsAll),
            };
        case actionTypes.updateFacetsAll:
            return {
                ...state,
                searchFacetsAll: action.payload,
            };
        case actionTypes.updateFacetsSearchBox:
            return {
                ...state,
                searchFacetsSearchBox: action.payload,
            };
        case actionTypes.clearSearchResults:
            return {
                ...state,
                searchResults: { numResults: 0, priorityCruise: null, cruises: [] },
            };
        case actionTypes.loadMore:
            const loadMoreResults = action.payload.data.cruises;
            const searchParams = action.payload.searchParams;
            return {
                ...state,
                searchParams,
                loadMoreResults,
            };
        case actionTypes.appendNextResults:
            if (!state.isSearchResultsLoading) {
                const concatenatedCruises = state.searchResults.cruises.concat(
                    state.loadMoreResults,
                );
                const searchResults = {
                    numResults: state.searchResults.numResults,
                    priorityCruise: state.searchResults.priorityCruise,
                    maxPrice: state.searchResults.maxPrice,
                    maxDiscount: state.searchResults.maxDiscount,
                    cruises: concatenatedCruises,
                };

                return {
                    ...state,
                    searchResults: searchResults,
                    loadMoreResults: [],
                    appendAfterLoad: false,
                };
            } else {
                return {
                    ...state,
                    appendAfterLoad: true,
                };
            }
        case actionTypes.loadTopSearchResults:
            return {
                ...state,
                topSearchResults: action.payload,
            };
        case actionTypes.loadSearchResults:
            return {
                ...state,
                searchResults: {
                    numResults: action.payload.numResults,
                    priorityCruise: action.payload.priorityCruise,
                    cruises: action.payload.cruises,
                    maxPrice: action.payload.searchResult.maxPrice,
                    maxDiscount: action.payload.searchResult.maxDiscount,
                    maxNights: action.payload.searchResult.maxNights,
                },
            };
        case actionTypes.setFilterParam:
            const paramKeys = [action.payload.paramName];
            //remove  filterParam from filterParamsList which is present in the payload...
            const nextFilterParamList = [].concat(state.filterParamsList).filter((param) => {
                return paramKeys.indexOf(param.paramName) === -1;
            });

            // ... and readd if it isn't empty
            if (action.payload.paramValue && action.payload.paramValue !== 'default') {
                if (Array.isArray(action.payload.paramValue)) {
                    action.payload.paramValue.forEach((paramValue) => {
                        nextFilterParamList.push({
                            paramName: action.payload.paramName,
                            paramValue: paramValue,
                        });
                    });
                } else {
                    nextFilterParamList.push(action.payload);
                }
            }

            return {
                ...state,
                filterParamsList: nextFilterParamList,
            };
        case actionTypes.clearFilterParams:
            return {
                ...state,
                filterParamsList: [],
            };
        case actionTypes.setSortOrder:
            const searchParamsNew = state.searchParams;
            searchParamsNew.sortString = action.payload;

            return {
                ...state,
                searchParams: searchParamsNew,
            };
        case actionTypes.toggleSearchLoader:
            return {
                ...state,
                isSearchResultsLoading: action.payload,
            };
        case actionTypes.toggleFacetsLoader:
            return {
                ...state,
                isFacetsLoading: action.payload,
            };
        case actionTypes.toggleSearchOverlay:
            return {
                ...state,
                isSeachListHidden: action.payload.value,
                searchOverlayAction: action.payload.action,
            };
        case actionTypes.openSearchFilter:
            return {
                ...state,
                isSearchFilterOpen: !state.isSearchFilterOpen,
            };
        case actionTypes.loadLandingPageData:
            const filterParamsList = makeFilterParamsList(
                content.searchParameters.filterParams,
                filterParamsSchema,
            );
            return {
                ...state,
                searchResults: {
                    numResults: content.searchResult.numResults,
                    priorityCruise: content.searchResult.priorityCruise,
                    cruises: content.searchResult.cruises?.slice(0, 10),
                    maxPrice: content.searchResult.maxPrice,
                    maxDiscount: content.searchResult.maxDiscount,
                },
                loadMoreResults: content.searchResult.cruises?.slice(10, 20),
                filterParamsList,
                filterParamsListActive: filterParamsList,
                searchParams: content.searchParameters,
                searchFacets: content.facets,
            };
        case actionTypes.loadCompanyDetailPageData:
            return {
                ...state,
                searchResults: {
                    numResults: content.cruises.numResults,
                    cruises: content.cruises.cruises?.slice(0, 10),
                    priorityCruise: content.searchResult?.priorityCruise,
                },
                loadMoreResults:
                    content.cruises.cruises?.length > 0 ? content.cruises.cruises?.slice(10) : [],
                filterParamsList: makeFilterParamsList(
                    { company: [content.companyId] },
                    filterParamsSchema,
                ),
            };
        case actionTypes.loadRatesBulkFulfilled:
            return updateRatesBulk(state, action.payload);
        case actionTypes.deleteFilterParam: {
            const nextFilterParamList = state.filterParamsList.filter(
                (param) => param.paramName !== action.payload,
            );
            return {
                ...state,
                filterParamsList: nextFilterParamList,
            };
        }
        default:
            return state;
    }
}
