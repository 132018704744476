import * as actionTypes from '../consts/actionTypes';

export interface IStandardContentPageReducer {
    content: string;
    additionalContent: string;
    title: string;
    headerImage: string;
    contactForm: string;
}

export const initialState: IStandardContentPageReducer = {
    content: null,
    additionalContent: null,
    title: null,
    headerImage: null,
    contactForm: null,
};

export function standardContentPage(
    state: IStandardContentPageReducer = initialState,
    action,
): IStandardContentPageReducer {
    switch (action.type) {
        case actionTypes.loadStandardContentPageData:
            const { content, additionalContent, title, headerImage, contactForm } = action.payload;
            return {
                ...state,
                content,
                additionalContent,
                title,
                headerImage,
                contactForm,
            };
        default:
            return state;
    }
}
