import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs } from '../../components/Breadcrumbs/Breadcrumbs';
import { FurtherInfo } from '../../components/FurtherInfo/FurtherInfo';
import { PriceWithDiscount } from '../../components/Recommendation/PriceWithDiscount';
import { recommendationDescUnderImg } from '../../components/Recommendation/RecommendationDescUnderImg';
import RecommendationSection from '../../components/Recommendation/RecommendationSection';
import { IState } from '../../reducers';
import { IDestinationOverviewPageReducer } from '../../reducers/destinationOverviewPage';
import { IPageDataReducer } from '../../reducers/pageData';
import { styles } from '../../components/Share/ShareArea/ShareArea';
import { OverviewPageHeader } from '../../components/OverviewPageHeader/OverviewPageHeader';
import { __ } from '../../helpers/TranslationService';
import { DropDown } from '../../components/DropDown';

interface ISortBarConfig {
    key: string;
    areaType?: string;
    sortType?: string;
}
interface ISortBarProps {
    requestFilter: Function;
    requestSort: Function;
    filterConfig: ISortBarConfig;
    sortConfig: ISortBarConfig;
}

interface State {
    isComponentLoaded: boolean;
}

interface StateProps {
    pageData: IPageDataReducer;
    destinationOverview: IDestinationOverviewPageReducer;
}

type Props = StateProps;

export class DestinationOverviewPageClass extends React.Component<Props, State> {
    constructor(props) {
        super(props);
        this.state = {
            isComponentLoaded: false,
        };
    }

    componentDidMount() {
        this.setState({ isComponentLoaded: true });
    }

    render() {
        const {
            breadcrumbs,
            headline,
            subHeadline,
            zones,
            seoTextBottom,
        } = this.props.destinationOverview;

        const { deviceType, discountThreshold } = this.props.pageData;

        const isMobile = deviceType === 'mobile';

        const useSortableData = (items) => {
            const [filterConfig, setFilterConfig] = useState({ key: null, areaType: null });
            const [sortConfig, setSortConfig] = useState({
                key: 'relevance',
                sortType: 'relevance',
            });
            const filterArr = ['both', 'sea', 'river'];

            const sortedItems = useMemo(() => {
                const sortableItems = [...items]
                    .filter(
                        (zone) =>
                            (filterConfig?.key &&
                                (zone.areaType === filterConfig.key || zone.areaType === 'both')) ||
                            (filterConfig?.key === null && filterArr.includes(zone.areaType)),
                    )
                    .sort(
                        (a, b) =>
                            sortConfig &&
                            sortConfig.key === 'alphabetical' &&
                            a.title.localeCompare(b.title),
                    );

                return sortableItems;
            }, [items, filterConfig, sortConfig]);

            const requestFilter = (key) => {
                const areaType = filterConfig && filterConfig.key !== key ? key : null;
                setFilterConfig({ key, areaType });
            };

            const requestSort = (key) => {
                const sortType = sortConfig ? key : 'relevance';
                setSortConfig({ key, sortType });
            };

            return { items: sortedItems, requestFilter, requestSort, filterConfig, sortConfig };
        };

        const SortBar = (props: ISortBarProps) => {
            const getFilterClassNamesActive = (areaType: string) => {
                if (!props.filterConfig) return;

                return props.filterConfig.key === areaType ? 'active' : undefined;
            };

            const filterOptions = [
                { id: null, label: __('all Destinations', 'dreamlines') },
                { id: 'sea', label: __('all sea', 'dreamlines') },
                { id: 'river', label: __('all river', 'dreamlines') },
            ];

            const sortOptions = [
                { id: 'relevance', label: __('Relevance', 'dreamlines') },
                { id: 'alphabetical', label: __('Alphabetical', 'dreamlines') },
            ];

            const dropDownProps = {
                options: sortOptions,
                onChange: props.requestSort,
            };

            return (
                <div className="destination-overview-page--sortbar">
                    <div className="destination-overview-page--sortbar--filters">
                        <ul>
                            {filterOptions.map((item, key) => {
                                return (
                                    <li
                                        key={key}
                                        onClick={() => props.requestFilter(item.id)}
                                        className={getFilterClassNamesActive(item.id)}
                                    >
                                        {item.label}
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                    <div className="destination-overview-page--sortbar--dropdown">
                        <div className="destination-overview-page--sortbar--dropdown--label">
                            <span>{__('Sorted by:', 'dreamlines')}</span>
                        </div>
                        <DropDown {...dropDownProps} />
                    </div>
                </div>
            );
        };

        const DestinationOverview = () => {
            if (!zones?.length) return null;

            const { items, requestFilter, requestSort, filterConfig, sortConfig } = useSortableData(
                zones || [],
            );

            const sortBarProps = {
                requestFilter,
                requestSort,
                filterConfig,
                sortConfig,
            };

            return isMobile ? (
                <>
                    {zones.map((item, key) => {
                        const destinationProps = {
                            data: [item],
                            discountThreshold: discountThreshold,
                        };
                        return (
                            <RecommendationSection
                                key={`Recommendation ${key}`}
                                data={recommendationDescUnderImg(destinationProps)}
                                collapsibleComponent={false}
                                wrapperStyles={styles.recommendationWrapper}
                            />
                        );
                    })}
                </>
            ) : (
                <>
                    <div className="destination-overview-page--sortbar-wrapper">
                        <SortBar {...sortBarProps} />
                    </div>
                    <div className="destination-overview-page--teasers-wrapper">
                        {this.state.isComponentLoaded && items.length > 0
                            ? items.map((item, index) => (
                                  <div className="recommendation-teaser" key={index}>
                                      <a href={item.uri}>
                                          <div className="recommendation-teaser--top">
                                              <img src={item.teaserImage} alt={item.title} />
                                              <div>{item.title}</div>
                                          </div>
                                          <div className="recommendation-teaser--bottom">
                                              <div className="recommendation-teaser--bottom__description">
                                                  <div
                                                      className="responsive-overflow--multi-line no-margin-on-children"
                                                      dangerouslySetInnerHTML={{
                                                          __html: item.teaserDescription,
                                                      }}
                                                  />
                                              </div>
                                              <div className="recommendation-teaser--bottom__price">
                                                  <PriceWithDiscount
                                                      price={item.price}
                                                      discount={item.discount}
                                                      currency={
                                                          this.props.pageData.appConfig
                                                              .defaultCurrency
                                                      }
                                                      discountThreshold={
                                                          this.props.pageData.discountThreshold
                                                      }
                                                      hasPP={true}
                                                  />
                                              </div>
                                          </div>
                                      </a>
                                  </div>
                              ))
                            : null}
                        {/* To make sure the last line is aligned left */}
                        <span className="recommendation-teaser" />
                        <span className="recommendation-teaser" />
                    </div>
                </>
            );
        };

        const MoreInfo = () => {
            if (!seoTextBottom) return null;

            return <FurtherInfo description={seoTextBottom} />;
        };

        return (
            <div className="destination-overview-page">
                <div className="destination-overview-page--container">
                    {!isMobile && (
                        <Breadcrumbs
                            className="onlyTabletUp noLateralPadding"
                            breadcrumbs={breadcrumbs}
                        />
                    )}
                    <OverviewPageHeader title={headline} subTitle={subHeadline} />

                    <DestinationOverview />

                    <MoreInfo />
                    {isMobile && <Breadcrumbs className="onlyMobile" breadcrumbs={breadcrumbs} />}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IState): StateProps => ({
    pageData: state.pageData,
    destinationOverview: state.destinationOverviewPage,
});

export const DestinationOverviewPage = connect(mapStateToProps)(DestinationOverviewPageClass);
export default DestinationOverviewPage;
