import React from 'react';
import styled from '@emotion/styled';
import { BreakpointsEnum } from '../../helpers/breakpoints';

interface IProps {
    title: string;
    subTitle: string;
}

const OverviewPageHeaderStyled = styled.div`
    padding: 36px 16px;
    margin-bottom: 20px;
    margin: 0 -15px;
    @media screen and (min-width: ${BreakpointsEnum.sm}) {
        text-align: center;
        background-color: transparent;
        margin: 0;
        padding: 24px 16px;
    }
`;

const OverviewPageHeaderTitle = styled.h1`
    color: ${({ theme }) => theme.colors.darkCharcoal[100]};
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    margin-top: 0;
    margin-bottom: 16px;
    @media screen and (min-width: ${BreakpointsEnum.sm}) {
        line-height: 48px;
    }
`;

const OverviewPageHeaderSubTitle = styled.div`
    font-size: 14px;
    line-height: 22px;
`;

export const OverviewPageHeader = ({ title, subTitle }: IProps) => (
    <OverviewPageHeaderStyled>
        <OverviewPageHeaderTitle>{title}</OverviewPageHeaderTitle>
        <OverviewPageHeaderSubTitle dangerouslySetInnerHTML={{ __html: subTitle }} />
    </OverviewPageHeaderStyled>
);
