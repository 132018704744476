import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function InfoIconNew({ width, height, viewBox, style, className }: IIcon) {
    const newViewProps = '0 0 20 20' ?? viewBox;

    return (
        <Icon
            width={width}
            height={height}
            viewBox={newViewProps}
            style={style}
            className={className}
        >
            <g clipPath="url(#clip0_3226_24779)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.0001 18.125C14.4875 18.125 18.1251 14.4873 18.1251 10C18.1251 5.51268 14.4875 1.875 10.0001 1.875C5.51281 1.875 1.87512 5.51268 1.87512 10C1.87512 14.4873 5.51281 18.125 10.0001 18.125ZM10.0001 20C15.523 20 20.0001 15.5228 20.0001 10C20.0001 4.47715 15.523 0 10.0001 0C4.47727 0 0.00012207 4.47715 0.00012207 10C0.00012207 15.5228 4.47727 20 10.0001 20ZM9.04187 8.95842C9.04187 8.42917 9.47096 8.00008 10.0002 8.00008C10.5295 8.00008 10.9585 8.42917 10.9585 8.95842V14.2918C10.9585 14.821 10.5295 15.2501 10.0002 15.2501C9.47096 15.2501 9.04187 14.821 9.04187 14.2918V8.95842ZM10.0002 6.08333C10.5295 6.08333 10.9585 5.65427 10.9585 5.125C10.9585 4.59572 10.5295 4.16667 10.0002 4.16667C9.47096 4.16667 9.04187 4.59572 9.04187 5.125C9.04187 5.65427 9.47096 6.08333 10.0002 6.08333Z"
                    fill="currentColor"
                />
                <defs>
                    <clipPath id="clip0_3226_24779">
                        <rect width="20" height="20" fill="white" />
                    </clipPath>
                </defs>
            </g>
        </Icon>
    );
}
