import * as Sentry from '@sentry/react';
import { IAge, IRequestFormReducer } from '../interfaces/IRequestForm';
import { readCookie, writeSessionCookie } from './cookie';

const personalDataCookieId = 'ddUIRf';
/*
 * specialNewsletterTextCruiseNids contains the cruiseNids for which we have to show special
 * newsletter text which is coming from translation string  `cruiseData.newsletterSubSpecial`
 * so far this text is only supported for DE, so be careful adding the cruiseNid for Non-DE markets
 */
export const specialNewsletterTextCruiseNids = [557340];
export interface IPersonalDataForm {
    isFemale: boolean;
    firstname: string;
    lastname: string;
    countryCode: string;
    phone: string;
    phoneCountryCode: string;
    email: string;
    callbackTime: string;
    ddd: string;
    city: string;
    zip: string;
    birthday?: Date;
    streetName: string;
    streetNumber: string;
    adultsNum: number;
    childrenNum: number;
    childAges: IAge[];
}

export function getPersonalDataFromRequestForm(
    requestForm: IRequestFormReducer,
): IPersonalDataForm {
    const {
        isFemale,
        firstname,
        lastname,
        countryCode,
        phoneCountryCode,
        phone,
        email,
        callbackTime,
        ddd,
        streetName,
        streetNumber,
        city,
        zip,
        birthday,
        adultsNum,
        childrenNum,
        childAges,
    } = requestForm;
    return {
        isFemale,
        firstname,
        lastname,
        countryCode,
        phoneCountryCode,
        phone,
        email,
        callbackTime,
        ddd,
        streetName,
        streetNumber,
        city,
        zip,
        birthday,
        adultsNum,
        childrenNum,
        childAges,
    };
}

export function savePersonalData(requestForm: IRequestFormReducer) {
    try {
        const personalData = getPersonalDataFromRequestForm(requestForm);

        const childrenWithBirthday = personalData.childAges.filter((child) => !!child.birthday);
        const newData = {
            ...personalData,
            childAges: childrenWithBirthday,
            childrenNum: childrenWithBirthday.length,
        };

        const encodedData = window.btoa(encodeURIComponent(JSON.stringify(newData)));

        writeSessionCookie(personalDataCookieId, encodedData);
    } catch (err) {
        Sentry.captureException(err, {
            extra: { customErr: `${personalDataCookieId} saving error` },
        });
    }
}

export function getPersonalData(): IPersonalDataForm {
    let data: IPersonalDataForm = {
        birthday: undefined,
        city: '',
        countryCode: '',
        phoneCountryCode: '',
        ddd: '',
        email: '',
        callbackTime: '',
        firstname: '',
        isFemale: false,
        lastname: '',
        phone: '',
        streetName: '',
        streetNumber: '',
        zip: '',
        adultsNum: 2,
        childAges: [],
        childrenNum: 0,
    };
    try {
        const cookieDataString = readCookie(personalDataCookieId);
        const cookieData = JSON.parse(decodeURIComponent(window.atob(cookieDataString)));
        data = { ...data, ...cookieData } as IPersonalDataForm;

        const birthday: Date = new Date(data.birthday);

        const childAges = data.childAges.map((item) => {
            const birthday = new Date(item.birthday);

            return {
                ...item,
                birthday: isNaN(birthday.getTime()) ? undefined : birthday,
            };
        });

        return {
            ...data,
            childAges,
            birthday: isNaN(birthday.getTime()) ? undefined : birthday,
        };
    } catch (e) {
        return null;
    }
}

export function checkColonExistance(label): string {
    return label.slice(-1) === ':' ? label : `${label}:`;
}
