import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
import { LoadingIndicatorInlineInput } from '../LoadingIndicator';
import { SubmitButton } from './SubmitButton';
import { CountryDropdown } from './CountryDropdown';
import { HarbourDropdown } from './HarbourDropdown';
import { PromoterDropdown } from './PromoterDropdown';
import { welcomePage } from '../../consts/pageTypes';

const loadingIndicator: React.CSSProperties = {
    position: 'absolute',
    right: 5,
    top: 9,
};

interface IProps {
    submitHandler: React.EventHandler<any>;
    handleChange: (field: string) => React.EventHandler<any>;
    input: string;
    countryIndex: string;
    harbourIndex: string;
    promoterIndex: string;
    isLoading: boolean;
    isEmailValid: boolean;
    isCountryValid: boolean;
    isHarbourValid: boolean;
    isPromoterValid: boolean;
    pageType?: string;
    isFormSubmittedOnce: boolean;
}

export const Form = (props: IProps) => {
    const isWelcomePage = Boolean(props.pageType === welcomePage);
    const inputClasses = `newsletter-form--input newsletter-form--input--${
        props.isFormSubmittedOnce && !props.isEmailValid ? 'invalid' : 'valid'
    } ${isWelcomePage ? 'newsletter-form--input--align-left' : ''}`;
    return (
        <form onSubmit={props.submitHandler} className="newsletter-form--container">
            <div className="newsletter-form--element">
                <input
                    className={inputClasses}
                    placeholder={__('your mail address', 'dreamlines')}
                    value={props.input}
                    onChange={props.handleChange('input')}
                    data-test-id="newsletterJoinUs-email"
                />
                {props.isFormSubmittedOnce && !props.isEmailValid && (
                    <div className="newsletter-form--error-msg">
                        {__('incorrect email', 'dreamlines')}
                    </div>
                )}
                {props.isLoading && (
                    <div style={loadingIndicator}>
                        <LoadingIndicatorInlineInput />
                    </div>
                )}
            </div>
            {isWelcomePage && (
                <>
                    <div className="newsletter-form--element newsletter-form--element--small-margin">
                        <CountryDropdown
                            value={props.countryIndex}
                            onChange={props.handleChange('country')}
                            isValid={props.isCountryValid}
                        />
                    </div>
                    <div className="newsletter-form--element newsletter-form--element--small-margin">
                        <HarbourDropdown
                            value={props.harbourIndex}
                            onChange={props.handleChange('harbour')}
                            isValid={props.isHarbourValid}
                        />
                    </div>
                    <div className="newsletter-form--element newsletter-form--element--small-margin">
                        <PromoterDropdown
                            value={props.promoterIndex}
                            onChange={props.handleChange('promoter')}
                            isValid={props.isPromoterValid}
                        />
                    </div>
                </>
            )}
            <SubmitButton country={props.countryIndex} pageType={props.pageType} />
        </form>
    );
};
