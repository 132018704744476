import { useEffect, useState } from 'react';
import { getCurrencyString } from '../components/PriceString';
import { IOption, Modifier } from '../components/RequestFormPersonalInfo/ControlComponents';
import { getPerPersonPriceString } from '../helpers/priceHelpers';
import { __ } from '../helpers/TranslationService';
import { IAirport, ICabinInfo, IRate } from '../interfaces';
export interface IUseFlightsProps {
    includesFlight: boolean;
    cabins: ICabinInfo[];
    selectedCabin: number;
    selectedSail?: number;
    rates: IRate[];
    currency: string;
    cabinNid: number;
    sailNid?: number;
    cruisePageAirports: IAirport[];
    removePlaceholder?: boolean;
    isMobile?: boolean;
    isAllInclusive?: boolean;
    passengersNum?: number;
}
const useFlights = (props: IUseFlightsProps): { options: IOption[]; isFligths: boolean } => {
    const [airportOptions, setAirportOptions] = useState<IOption[]>([]);
    const [isFligths, setIsFligths] = useState<boolean>(undefined);
    const {
        includesFlight,
        cabins,
        selectedCabin,
        selectedSail,
        rates,
        currency,
        cabinNid,
        sailNid,
        cruisePageAirports,
        removePlaceholder,
        isMobile,
        passengersNum,
        isAllInclusive,
    } = props;

    useEffect(() => {
        if (includesFlight) {
            const cheapestCabin = () => {
                if (cabins.length <= 0) return null;
                const cabinsWithPrices = cabins.filter((cab) => {
                    return cab.perPersonPrice > 0;
                });
                if (cabinsWithPrices.length > 0) {
                    return cabinsWithPrices.reduce((acc, loc) =>
                        acc.perPersonPrice < loc.perPersonPrice ? acc : loc,
                    ).cabinType.nid;
                }
                return null;
            };

            const rateCabinNid = cabinNid
                ? cabinNid
                : selectedCabin
                ? selectedCabin
                : cheapestCabin();

            const rateSailNid = sailNid ? sailNid : selectedSail;

            const rate = rates.find(
                ({ cabintype_id, sail_nid }) =>
                    cabintype_id === rateCabinNid && (!rateSailNid || sail_nid === rateSailNid),
            );
            const ratePrice = isAllInclusive ? rate?.all_in_price : rate?.cheapest_price;

            let airports: IOption[];

            if (ratePrice?.flights?.length) {
                airports = ratePrice.flights.map((flight, i) => {
                    const {
                        departure_airport_code,
                        departure_flight_code,
                        return_airport_code,
                        return_flight_code,
                        departure_class_description,
                        return_class_description,
                        departure_class_code,
                        return_class_code,
                    } = flight;

                    const departureAirportCode =
                        departure_airport_code || departure_flight_code?.split(' ')[0];

                    const returnAirportCode =
                        return_airport_code || return_flight_code?.split(' ')[0];

                    const airportCode = departureAirportCode || returnAirportCode;

                    let airport = __(airportCode, 'dreamlines') || airportCode;

                    const flightClass = departure_class_description || return_class_description;
                    const classDescription = flightClass
                        ? __(`flight.${flightClass}`, 'dreamlines')
                        : '';
                    const classCode = departure_class_code || return_class_code || '';
                    const additionalFlightPrice = isMobile
                        ? flight.per_person_price - ratePrice.per_person_price
                        : flight.per_person_price;
                    let priceFormatted = '';

                    if (additionalFlightPrice > 0) {
                        const displayPrice = getCurrencyString(additionalFlightPrice, currency);
                        const priceTemplate = priceTmpl(
                            airportCode === 'XXX',
                            displayPrice,
                            isMobile,
                            passengersNum,
                            additionalFlightPrice,
                            currency,
                        );
                        priceFormatted = `(${priceTemplate})`;
                    }

                    return {
                        value: classCode ? `${airportCode}-${classCode}` : airportCode,
                        label: `${airport ? airport + ', ' : ''}${
                            classDescription ? classDescription + ' ' : ''
                        } ${priceFormatted}`,
                    };
                });
                setIsFligths(true);
            } else {
                airports = cruisePageAirports.map((airport, i) => {
                    if (airport.airportName || airport.airportCode) {
                        const lableStringDescription = airport.airportCode
                            ? `(${airport.airportCode})`
                            : '';
                        return {
                            value: airport.airportCode || airport.airportName,
                            label: `${airport.airportName} ${lableStringDescription}`,
                        };
                    }
                });
                setIsFligths(false);
            }
            if (!removePlaceholder && airports.length) {
                airports.unshift({
                    value: '',
                    label: __('general.pleaseChoose', 'dreamlines'),
                    modifier: Modifier.Disabled,
                });
            }
            setAirportOptions(airports);
        }
    }, [
        rates,
        cabinNid,
        selectedCabin,
        includesFlight,
        sailNid,
        selectedSail,
        removePlaceholder,
        cabins,
        isMobile,
        currency,
        passengersNum,
        cruisePageAirports,
        isAllInclusive,
    ]);
    return { options: airportOptions, isFligths };
};
export default useFlights;

const priceTmpl = (
    isAirportUnknown,
    displayPrice,
    isMobile,
    passengersNum,
    additionalFlightPrice,
    currency,
) => {
    if (isMobile) {
        return __(
            isAirportUnknown ? `from +${displayPrice} p.P.` : ` +${displayPrice} p.P.`,
            'dreamlines',
        );
    }
    if (isAirportUnknown) {
        return __(
            isAirportUnknown ? `from +${displayPrice} p.P.` : ` +${displayPrice} p.P.`,
            'dreamlines',
        );
    } else {
        if (passengersNum) {
            return getPerPersonPriceString(additionalFlightPrice, currency, passengersNum);
        } else {
            return `${displayPrice} ${__('p.P.', 'dreamlines')}`;
        }
    }
};
