import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
import { welcomePage } from '../../consts/pageTypes';
import { getPrivacyLink } from '../../helpers/privacyLinkHelper';
import { Button } from '../Button/Button';

interface IProps {
    pageType?: string;
    country: string;
}

export const SubmitButton = (props: IProps) => {
    const isWelcomePage = Boolean(props.pageType === welcomePage);
    const formWrapperClass = isWelcomePage
        ? 'newsletter-form--element newsletter-form--element--small-margin'
        : 'newsletter-form--element';
    const privacy_text = isWelcomePage
        ? getPrivacyLink(
              __('welcome privacy link text', 'dreamlines'),
              __('newsletter.link', 'dreamlines'),
          )
        : __('newsletter privacy link text', 'dreamlines');
    return (
        <>
            <div className={formWrapperClass}>
                <Button
                    type="submit"
                    fullWidth
                    withArrow
                    data-test-id="newsletterJoinUs-submitRequest"
                >
                    {__('Join', 'dreamlines')}
                </Button>
            </div>
            <div
                className="newsletter-form--sub-text"
                dangerouslySetInnerHTML={{ __html: privacy_text }}
            />
        </>
    );
};
