import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function CheckMarkIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <path d="M482.3,83.8c-7.9-7.4-19.9-7.8-28.2-0.9L191.3,321.7L61.1,243.6c-8.6-5.2-19.7-3.6-26.6,3.8c-6.8,7.4-7.6,18.5-1.8,26.8l141.2,199.8c4,5.6,10.4,8.9,17.2,9c0.1,0,0.1,0,0.2,0c6.8,0,13.2-3.2,17.2-8.7l276.5-362.3C491.2,103.2,490.2,91.2,482.3,83.8z" />
        </Icon>
    );
}
