import React from 'react';
import { config } from '../../config';

import styled from '@emotion/styled';

const BadgesContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    width: 100%;
    gap: 48px;

    @media (max-width: 992px) {
        gap: 20px;
    }

    img {
        width: auto;
        max-height: 44px;

        @media (max-width: 992px) {
            max-height: 30px;
        }
    }
`;

export const TrustBadges = ({ portalId, ...props }) => {
    const trustBadges = {
        'dreamlines.nl': [
            `${config.images}/${portalId}/sgr-logo.svg`,
            `${config.images}/${portalId}/anvr-logo.svg`,
            `${config.images}/${portalId}/fonds-logo.svg`,
        ],
        'cruise1st.co.uk': [
            `${config.images}/common/abta-logo.svg`,
            `${config.images}/common/trustpilot-logo.svg`,
        ],
        'cruise1st.com.au': [
            `${config.images}/common/clia_logo.svg`,
            `${config.images}/common/iata_logo.svg`,
            `${config.images}/common/atas_logo.svg`,
            `${config.images}/common/afta_logo.svg`,
        ],
    };

    const marketTrustBadges = trustBadges[portalId] || [];

    if (marketTrustBadges.length === 0) return null;

    return (
        <BadgesContainer {...props}>
            {marketTrustBadges.map((item) => (
                <img src={item} key={item} alt="" />
            ))}
        </BadgesContainer>
    );
};
