import fetch from 'isomorphic-fetch';
import { getCountryDialCode } from '../actions/requestFormActions';
import { getContactFormErrorTranslationKeys } from '../actions/dynamicFormAction';
import * as actionTypes from '../consts/actionTypes';
import { createUniqueId } from '../helpers/crypto';
import { IContactFormData } from '../interfaces/IContactForm';
import { initialState } from '../reducers/contactForm/contactForm';
import { submitContactForm } from '../reducers/contactForm/contactFormMappers';
export const setContactFormInitialData = (payload) => (dispatch, getState) => {
    const { pageData } = getState();
    const { countryCodes } = pageData.brandConfig.requestForm;
    const { countryCode } = pageData.appConfig;
    const countryCodeSelected = getCountryDialCode(countryCode, countryCodes);
    Object.keys(payload).map((channel) => {
        payload[channel].countryCode = countryCodeSelected;
    });

    dispatch({
        type: actionTypes.setContactFormInitialData,
        payload,
    });
};

export const deleteContactFormData = (channel: string) => (dispatch, getState) => {
    const { pageData } = getState();
    const { countryCodes } = pageData.brandConfig.requestForm;
    const { countryCode } = pageData.appConfig;
    const countryCodeSelected = getCountryDialCode(countryCode, countryCodes);
    const contactFormData = initialState[channel];
    contactFormData.countryCode = countryCodeSelected;
    dispatch({
        type: actionTypes.deleteContactFormData,
        payload: {
            [channel]: contactFormData,
        },
    });
};

export const updateContactFormData = ({ channel, key, value }) => ({
    type: actionTypes.updateContactFormData,
    payload: { channel, key, value },
});

export const onContactFormSubmit = (channel: string) => async (dispatch, getState) => {
    const { contactForm, pageData } = getState();
    const contactFormData: IContactFormData = contactForm[channel];

    if (!submitContactForm(contactFormData).isValid) {
        dispatch(updateContactFormData({ channel, key: 'isSubmitted', value: true }));
        return;
    }

    const {
        firstName,
        lastName,
        countryCode,
        phoneNumber,
        customerMail,
        messageBody,
        stafferName,
        stafferCompanyName,
        stafferLanguage,
    } = contactFormData;

    let requestData: any = {
        channel: 'NoOnlineRuleApplies',
        firstName: firstName,
        lastName: lastName,
        phoneNumber: `${countryCode}${phoneNumber}`,
        emailAddress: customerMail,
        domain: pageData.appConfig.portalId,
        userId: null,
        sessionId: null,
        message: messageBody,
        bookingCode: createUniqueId(),
        contactMethod: 'ContactForm',
        bookingUrl: window.location.href,
    };

    if (channel === 'advisorModal') {
        requestData = {
            ...requestData,
            stafferName,
            stafferLanguage,
            stafferCompanyName,
        };
    }

    const advisorEndpoint = `${pageData.appConfig.apiHost}/advisor`;

    dispatch({ type: actionTypes.showLoadingIndicator });

    try {
        const response = await fetch(advisorEndpoint, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const jsonData = await response.json();

        if (response.ok && jsonData.isSuccessful) {
            dispatch({
                type: actionTypes.updateContactFormData,
                payload: { channel, key: 'isSent', value: true },
            });
        }

        throw jsonData;
    } catch (error) {
        dispatch({
            type: actionTypes.updateContactFormData,
            payload: { channel, key: 'isSubmitError', value: true },
        });

        if (typeof error === 'object' && error?.errors) {
            dispatch({
                type: actionTypes.updateContactFormData,
                payload: {
                    channel,
                    key: 'errors',
                    value: getContactFormErrorTranslationKeys(error?.errors),
                },
            });
        }
    }

    dispatch({ type: actionTypes.hideLoadingIndicator });
};
