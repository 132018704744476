import * as React from 'react';
import { pink } from '../../consts/variables';
import { __ } from '../../helpers/TranslationService';
import { Discount } from '../Discount';
import { getCurrencyString } from '../PriceString';

interface IProps {
    pageType?: string;
    price: number;
    discount: number;
    currency: string;
    wrapperStyle?: any;
    discountThreshold?: number;
    exactPrice?: boolean;
    hasPP?: boolean;
}

const style: Record<string, React.CSSProperties> = {
    priceStartFromLabel: {
        fontSize: 14,
        flex: '1 0 auto',
        paddingRight: 2,
        textAlign: 'right',
    },
};

export function PriceWithDiscount({
    price,
    discount,
    currency,
    wrapperStyle = {},
    discountThreshold = 0,
    exactPrice = false,
    hasPP = false,
}: IProps) {
    const hasDiscount = Boolean(discount) && discount > 0 && discount > discountThreshold;
    const priceStartFromStyle = hasDiscount ? style.priceStartFromLabel : {};
    const textColor = hasDiscount ? { color: pink } : {};

    if (!price || price < 1) {
        return (
            <div style={wrapperStyle}>
                <div className="price-on-request">{__('on request', 'dreamlines')}</div>
            </div>
        );
    }

    return (
        <div className="price-with-discount-container" style={{ ...textColor, ...wrapperStyle }}>
            <div className="priceDiscountTag">
                {hasDiscount && (
                    <>
                        {/* <span className="priceDiscountStartFrom" style={priceStartFromStyle}>
                            {__('from', 'dreamlines')}
                        </span> */}
                        <Discount discount={discount} type="ribbon" side="left" />
                    </>
                )}
            </div>
            <div className="price-text">
                {!exactPrice && (
                    <span className="priceStartFrom" style={priceStartFromStyle}>
                        {__('from', 'dreamlines')}
                    </span>
                )}

                {getCurrencyString(price, currency)}
                {hasPP && <span className="price-PP">{__('p.P.', 'dreamlines')}</span>}
            </div>
        </div>
    );
}
