import * as React from 'react';
import { Icon } from '../Icon';
import { IFillableIcon } from '../../interfaces/IIcon';

export function PackageIcon(props: IFillableIcon) {
    const newViewProps = '0 0 714.8 621' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <g>
                <path
                    d="M472.7,79.3c-5.6,0-10,4.5-10,10v72.3H391c-5.6,0-10,4.5-10,10c0,5.6,4.5,10,10,10h71.7v75.8c0,5.6,4.5,10,10,10
		s10-4.5,10-10v-75.8h76.4c5.6,0,10-4.5,10-10c0-5.6-4.5-10-10-10h-76.4V89.4C482.8,83.8,478.3,79.3,472.7,79.3z"
                />
                <path
                    d="M475.1,4.8c-81,0-148.9,57.5-165,133.8h19.6c15.7-65.7,74.9-114.8,145.5-114.8c82.6,0,149.6,67.1,149.6,149.6
		s-67.1,149.7-149.6,149.7c-17.2,0-33.7-2.9-49.1-8.3v20.1c15.5,4.7,32,7.3,49.1,7.3c93,0,168.7-75.8,168.7-168.8
		S568.1,4.8,475.1,4.8z"
                />
                <path d="M404.2,336.3V232.1c0-8.6-6.9-15.6-15.6-15.6l-273.5,0c-8.6,0-15.6,6.9-15.6,15.6v104.2l152.3-73L404.2,336.3z" />
                <path d="M339.7,178.5c0-8.6-6.9-15.6-15.6-15.6h-37.8l-6.8-61.9h-55.3l-6.7,61.9h-37.8c-8.6,0-15.5,7-15.5,15.6v9.5h175.6V178.5z" />
                <path d="M25.6,401.9l67,195.5c31.1-14.5,71.1-28,112.1-28c65.8,0,118.8,31.3,188.3,47.4h11.5l73.7-214.9L251.9,293.5L25.6,401.9z" />
            </g>
        </Icon>
    );
}
