import * as React from 'react';
import * as vars from '../../../consts/variables';
import { __ } from '../../../helpers/TranslationService';
import { Link } from '../../../components/Link';
import { CTA } from '../../../components/CTA';

const style: Record<string, React.CSSProperties> = {
    homeCTA: {
        padding: '20px 24px 20px',
        textAlign: 'center',
    },
    CTAbutton: {
        margin: '15px auto',
        fontSize: 19,
        maxWidth: 257,
    },
    CTAtext: {
        fontSize: 18,
        margin: '0 15px',
        lineHeight: '24px',
    },
    contactText: {
        margin: '25px 5% 20px',
        color: vars.blue,
        textAlign: 'center',
        fontSize: 18,
        lineHeight: '24px',
    },
    contactButtonWrapper: {
        marginBottom: '30px',
    },
};

interface IProps {
    ctaMessage?: string;
}

export class ErrorPageCtaSection extends React.Component<IProps, any> {
    render() {
        return (
            <div>
                <div style={style.homeCTA}>
                    {this.props.ctaMessage && (
                        <div style={style.CTAtext}>{this.props.ctaMessage}</div>
                    )}
                    <Link href="/">
                        <CTA wrapperStyle={style.CTAbutton}>
                            <span>{__('Go to Home Screen', 'dreamlines')}</span>
                        </CTA>
                    </Link>
                </div>
            </div>
        );
    }
}
