import * as React from 'react';
import { __ } from '../helpers/TranslationService';
import { changeRouteSuccess } from './routerActions';
import { defaultSeo } from '../reducers/pageData';

import loadable from '@loadable/component';

export const notFoundPageRouteAction = context => {
    const { path, params, baseUrl, action } = context;
    const seo = defaultSeo;
    seo.titleOg = __('Page not found', 'dreamlines');
    context.store.dispatch(
        changeRouteSuccess({
            path,
            params,
            baseUrl,
            action,
            seo: seo,
        }),
    );

    const NotFoundPage = loadable(() => import('../pages/Error/NotFoundPage'));

    return { component: <NotFoundPage />, context };
};
