import * as React from 'react';
import * as Colors from '../consts/variables';
import { MarketContext } from '../context/markets';
import { TestSelector } from '../helpers/TestSelector';
import { ArrowRightIcon } from './Icons/ArrowRightIcon';

const styles: Record<string, React.CSSProperties> = {
    button: {
        background: Colors.softBlue,
        color: Colors.white,
        minWidth: 140,
        textAlign: 'center',
        padding: '15px 30px 15px 10px',
        borderRadius: 5,
        position: 'relative',
        fontSize: 20,
        cursor: 'pointer',
    },
    arrowRight: {
        position: 'absolute',
        right: 10,
        top: '50%',
        transform: 'translate(0%, -50%)',
        fill: Colors.white,
    },
    buttonWithBorder: {
        background: Colors.transparent,
        border: `2px solid ${Colors.softBlue}`,
        padding: '13px 5px',
        color: Colors.softBlue,
    },
    grayBtn: {
        background: Colors.gray,
    },
    orangeBtn: {
        color: Colors.indigo,
        fontWeight: 700,
        background: Colors.orange,
    },
    modalBtn: {
        minWidth: 175,
        fontSize: 16,
    },
};

interface Props {
    onClick?: any;
    wrapperStyle?: React.CSSProperties;
    arrowStyle?: React.CSSProperties;
    isBackgroundTransparent?: boolean;
    testSelectorName?: string;
    className?: string;
    disabled?: boolean;
    modal?: boolean;
    as?: React.ElementType;
}

export const CTA: React.FC<Props> = ({ as = 'div', ...props }) => {
    const { markets } = React.useContext(MarketContext);
    const { isCruise1st } = markets;
    const buttonStyles = props.isBackgroundTransparent
        ? {
              ...styles.button,
              ...styles.buttonWithBorder,
          }
        : styles.button;

    const disabledButton = props.disabled
        ? {
              ...styles.button,
              ...styles.grayBtn,
          }
        : styles.button;

    const modalButton = props.modal
        ? {
              ...styles.button,
              ...styles.modalBtn,
          }
        : styles.button;

    const orangeButton = isCruise1st ? { ...styles.button, ...styles.orangeBtn } : styles.button;

    const disabledOnClick = props.disabled ? undefined : props.onClick;

    const arrowStyles = props.isBackgroundTransparent
        ? {
              ...styles.arrowRight,
              fill: Colors.acqa,
          }
        : { ...styles.arrowRight, fill: isCruise1st ? Colors.indigo : Colors.white };

    const Elem = as;
    return (
        <Elem
            style={{
                ...buttonStyles,
                ...props.wrapperStyle,
                ...disabledButton,
                ...modalButton,
                ...orangeButton,
            }}
            onClick={disabledOnClick}
            {...TestSelector.getSelector(props.testSelectorName)}
            className={props.className}
        >
            {props.children}
            <ArrowRightIcon
                style={{ ...arrowStyles, ...props.arrowStyle }}
                width={20}
                height={20}
            />
        </Elem>
    );
};
