import { readCookie, writeSessionCookie } from '../cookie';
import * as url from 'url';
import * as qs from 'qs';
import * as Sentry from '@sentry/react';

export class TradedoublerTracker {
    /**
     * Get Tradedoubler TDUID from querystring and create cookie
     */
    checkTDUIDParam = () => {
        try {
            const queryString = url.parse(location.href).query;
            const { tduid } = qs.parse(queryString);
            if (tduid) {
                this.saveCookie(tduid);
            }
        } catch (err) {
            Sentry.captureException(err);
        }
    };

    /**
     * Get Tradedoubler TDUID from Cookie
     */
    getTDUIDCookie = () => {
        return readCookie('TDUID');
    };

    /**
     * Save Tradedoubler TDUID Cookie
     */
    saveCookie(tduid: string) {
        writeSessionCookie('TDUID', tduid);
    }
}

export const tradedoublerTracker = new TradedoublerTracker();
