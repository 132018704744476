import { parse } from 'qs';
import { IBrandConfig } from '../reducers/pageData';

interface Slugs {
    [key: string]: string;
}

/**
 * Returns available slugs for the router
 * Supported slugs:
 *  - NewsletterPage
 *  - booking-thank-you
 *  - cruiseline
 *  - cruisepage
 *  - favorites
 *  - lpCompanyDetail
 *  - optionBookingForm
 *  - privacy
 *  - searchresults
 *  - ship/detail
 *  - ship/overview
 */
export const getRouteSlugs = (slugConfig: IBrandConfig['slugs']): Slugs => {
    const slugs: Slugs = slugConfig.reduce((slugs, item) => {
        slugs[item.slugName] = item.slugValue;
        return slugs;
    }, {});
    return slugs;
};

export function removeTrailingSlash(url: string): string {
    if (url) {
        const lastIndex = url.length - 1;
        if (url[lastIndex] === '/') {
            return url.slice(0, lastIndex);
        }
        return url;
    }
    return url;
}

export function getSailNidFromHash(hash: string) {
    if (hash && hash.length > 0) {
        const hashObj = parse(hash.replace('#', '').replace('/', ''));
        if (hashObj.selectedSail) {
            return Number(hashObj.selectedSail);
        }
    }
    return null;
}
