import * as actionTypes from '../consts/actionTypes';

export const setLocationInState = (payload) => ({ type: actionTypes.setLocation, payload });

export const saveScrollPosition = ({ action, scrollY }) => ({
    type: actionTypes.saveScrollPosition,
    payload: { action, scrollY },
});

export const changeRouteSuccess = (payload) => (dispatch, getState) => {
    dispatch({ type: actionTypes.changeRouteSuccess, payload });
    dispatch({ type: actionTypes.hideLoadingIndicator });
};

export const initHistory = (payload) => ({ type: actionTypes.initHistory, payload });

export const setEnvironment = (payload) => ({ type: actionTypes.setEnvironment, payload });
