import { BreakpointsEnumWithoutUnits } from './../helpers/breakpoints';
import { useEffect, useState } from 'react';
import useDeviceDetect from './useDeviceDetect';
import { IsomorphicHelpers } from '../helpers/IsomorphicHelpers';
export interface IViewPortSize {
    width: number | undefined;
    height: number | undefined;
}
const useWindowSize = (): IViewPortSize => {
    const isSSR = IsomorphicHelpers.isServerSide;
    const { isMobile } = useDeviceDetect();

    const [windowSize, setWindowSize] = useState<IViewPortSize>(
        isSSR
            ? {
                  width: isMobile
                      ? BreakpointsEnumWithoutUnits.sm
                      : BreakpointsEnumWithoutUnits.md + 1,
                  height: isMobile ? 640 : 700,
              }
            : {
                  width: window.innerWidth,
                  height: window.innerHeight,
              },
    );

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, [isMobile]);

    return windowSize;
};
export default useWindowSize;
