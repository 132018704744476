import * as React from 'react';
import { Link } from '../Link';
import { INavigationItem } from '../../interfaces/IHeader';

const styleMenuItem: any = {
    padding: '12px 10px 12px 44px',
    marginLeft: '10%',
    display: 'block',
};

export function SubNavigationItem(props: INavigationItem) {
    const { targetUrl, title } = props;
    return (
        <li>
            <Link href={targetUrl} style={styleMenuItem}>
                <span dangerouslySetInnerHTML={{ __html: title }} />
            </Link>
        </li>
    );
}
