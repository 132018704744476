export const getOverallReviewClasses = (rating) => {
    const reviewClassesRatings = {
        'Very Poor': { sufix: '(1-3)', rating: 0, quantity: 0 },
        Poor: { sufix: '(3-5)', rating: 0, quantity: 0 },
        Ok: { sufix: '(5-7)', rating: 0, quantity: 0 },
        Good: { sufix: '(7-9)', rating: 0, quantity: 0 },
        Excellent: { sufix: '(9+)', rating: 0, quantity: 0 },
    };
    const length = rating.data.length;
    rating.data.forEach((item) => {
        const { reviewClass } = item.attributes;
        const currentReviewClass = reviewClassesRatings[reviewClass];
        if (currentReviewClass) {
            currentReviewClass.quantity += 1;
            currentReviewClass.rating = (currentReviewClass.quantity * 10) / length;
        }
    });
    return reviewClassesRatings;
};

// Responsible for making changes to the titles of the reviews.
export const formatCruiseTitle = (cruiseTitle: string, companyName: string) => {
    //https://netvacation.atlassian.net/browse/DREAM-15942
    if (companyName.toUpperCase().indexOf('AIDA') !== -1) {
        return cruiseTitle.replace(/(auf|mit)\s+der\s+/g, 'mit ');
    }
    return cruiseTitle;
};

export const updateUGCReviews = (state, action) => {
    const { data, links } = action.payload;
    state.reviews.rating.data = state.reviews.rating.data.concat(data);
    state.reviews.rating.links = links;
    return state;
};
