import { ICountryCode } from '../interfaces';
import { IDynamicFormReducer } from '../interfaces/IDynamicForm';
import { initialState } from '../reducers/dynamicForm/dynamicForm';

type IDynamicFormTranslation = {
    [key in ICountryCode]?: {
        firstName: string;
        lastName: string;
        phoneNumber: string;
        email: string;
        message: string;
        successHeadline: string;
        successMessage: string;
        errorMessage: string;
        internalErrorMessage: string;
        mailAddressExistsErrorMessage: string;
    };
};

const dynamicFormTranslations: IDynamicFormTranslation = {
    de: {
        firstName: 'Vorname',
        lastName: 'Nachname',
        phoneNumber: 'Telefonnummer',
        email: 'E-Mail',
        message: 'Mitteilung',
        successHeadline: 'Vielen Dank für Ihre Anfrage!',
        successMessage: 'Einer unserer Kreuzfahrtexperten wird sich bald bei Ihnen melden.',
        errorMessage: 'Bitte vervollständigen Sie Ihre Daten.',
        internalErrorMessage: 'Bitte vervollständigen Sie Ihre Daten.',
        mailAddressExistsErrorMessage: 'Vielen Dank, Sie haben bereits eine Anfrage geschickt!',
    },
    nl: {
        firstName: 'Voornaam',
        lastName: 'Achternaam',
        phoneNumber: 'Telefoonnummer',
        email: 'E-mail',
        message: 'Bericht',
        successHeadline: 'Hartelijk bedankt!',
        successMessage: 'Onze expert neemt zo spoedig mogelijk contact met u op.',
        errorMessage: 'Vul alstublieft alle velden in!',
        internalErrorMessage: 'Vul alstublieft alle velden in!',
        mailAddressExistsErrorMessage: 'Heel erg bedankt, je hebt al een aanvraag gestuurd!',
    },
    au: {
        firstName: 'First Name',
        lastName: 'Surname',
        phoneNumber: 'Telephone Number',
        email: 'Email',
        message: 'Message',
        successHeadline: 'THANK YOU!',
        successMessage: 'Our expert will contact you as soon as possible.',
        errorMessage: 'Please fill in all fields!',
        internalErrorMessage: 'Please fill in all fields!',
        mailAddressExistsErrorMessage: 'Thank you very much, you have already sent an inquiry!',
    },
    c1uk: {
        firstName: 'First Name',
        lastName: 'Surname',
        phoneNumber: 'Telephone Number',
        email: 'Email',
        message: 'Message',
        successHeadline: 'THANK YOU!',
        successMessage: 'Our expert will contact you as soon as possible.',
        errorMessage: 'Please fill in all fields!',
        internalErrorMessage: 'Please fill in all fields!',
        mailAddressExistsErrorMessage: 'Thank you very much, you have already sent an inquiry!',
    },
    c1au: {
        firstName: 'First Name',
        lastName: 'Surname',
        phoneNumber: 'Telephone Number',
        email: 'Email',
        message: 'Message',
        successHeadline: 'THANK YOU!',
        successMessage: 'Our expert will contact you as soon as possible.',
        errorMessage: 'Please fill in all fields!',
        internalErrorMessage: 'Please fill in all fields!',
        mailAddressExistsErrorMessage: 'Thank you very much, you have already sent an inquiry!',
    },
};

const createFormFields = (t: Record<string, string>) => [
    {
        fieldIdentifier: 'firstName',
        fieldType: 'text',
        defaultText: t.firstName,
        required: ['Required'],
        dropDownChoices: '',
    },
    {
        fieldIdentifier: 'lastName',
        fieldType: 'text',
        defaultText: t.lastName,
        required: ['Required'],
        dropDownChoices: '',
    },
    {
        fieldIdentifier: 'phoneNumber',
        fieldType: 'text',
        defaultText: t.phoneNumber,
        required: ['Required'],
        dropDownChoices: '',
    },
    {
        fieldIdentifier: 'email',
        fieldType: 'text',
        defaultText: t.email,
        required: ['Required'],
        dropDownChoices: '',
    },
    {
        fieldIdentifier: 'message',
        fieldType: 'text',
        defaultText: t.message,
        required: [],
        dropDownChoices: '',
    },
];

export const dynamicFormBuilder = (market: ICountryCode): IDynamicFormReducer => {
    const t = dynamicFormTranslations[market];
    return {
        ...initialState,
        identifier: 'dynamicFormBuilder',
        createFields: createFormFields(t),
        successHeadline: t.successHeadline,
        successMessage: t.successMessage,
        errorMessage: t.errorMessage,
        internalErrorMessage: t.internalErrorMessage,
        mailAddressExistsErrorMessage: t.mailAddressExistsErrorMessage,
        useWordPressRoute: false,
    };
};
