export const overlayIndexes = {
    topOffer: 1,
    headerContactBlock: 1,
    mobileCruisePriceLoader: 9,
    callUsBanner: 10,
    drawerNavigationMenu: 30,
    searchMask: 20,
    searchFiltersMenu: 910,
    headerContainer: 900,
    dialogOverlay: 980,
    dialog: 990,
    rootLoader: 1000,
    requestFormLoader: 1000,
};
