import * as React from 'react';
import { IIcon } from '../../interfaces/IIcon';
import { Icon } from '../Icon';

const style: React.CSSProperties = {
    fillRule: 'evenodd',
    fill: 'currentColor',
};

export function ArrowDownIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <path
                id="arrow_down"
                style={style}
                data-name="arrow down"
                d="M502.71,177.29a25.33,25.33,0,0,0,0-35.81l-17.9-17.9a25.32,25.32,0,0,0-35.81,0L262.52,310.05,76.06,123.58a25.34,25.34,0,0,0-35.82,0l-17.9,17.9a25.31,25.31,0,0,0,0,35.81L244.63,399.58a25.28,25.28,0,0,0,35.78,0Z"
            />
        </Icon>
    );
}
