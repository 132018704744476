import * as actionTypes from '../consts/actionTypes';
import * as qs from 'qs';

import { parseFavoriteCookiesToArray, writeFavouriteCruiseNids } from '../helpers/favorites';

import { ICruiseSearchApiObject } from '../interfaces/ISearchApi';
import { defaultSearchParams } from '../consts/defaultSearchParams';
import { iadvizeTracking } from '../middleware/trackingHelpers';
import { post } from '../helpers/http';
import { readCookie } from '../helpers/cookie';
import { searchResultPage } from '../consts/pageTypes';

export const loadFavoriteNids = () => (dispatch) => {
    const favouriteCruiseNids = parseFavoriteCookiesToArray(readCookie('favorites'));
    dispatch({ type: actionTypes.loadFavoritesCruiseNids, payload: favouriteCruiseNids });
};

export const loadFavoritesCruiseData = () => (dispatch, getState) => {
    const favouriteCruiseNids = getState().favoritePage.favorites;
    if (favouriteCruiseNids.length > 0) {
        const filterParams = { cruiseNids: favouriteCruiseNids };
        const url = `${getState().pageData.appConfig.apiHost}/cruises/search`;
        const searchParams: ICruiseSearchApiObject = {
            ...defaultSearchParams,
            filterParams: filterParams,
            limit: favouriteCruiseNids.length,
        };
        return post(url, searchParams).then((result) => {
            dispatch({ type: actionTypes.loadFavoritesCruiseData, payload: result.cruises });
            //remove for unavailable cruises from favorites
            const availableFavorites = result.cruises.map((cruise) => cruise.cruiseNid);
            if (availableFavorites.length !== favouriteCruiseNids.length) {
                dispatch(updateFavorites(availableFavorites));
            }
            dispatch(setFavoritesShareLink(availableFavorites));

            dispatch({ type: actionTypes.hideLoadingIndicator });
        });
    } else {
        dispatch({ type: actionTypes.hideLoadingIndicator });
        return new Promise<void>((resolve) => resolve());
    }
};

export const addToFavorites = (cruiseNid: number) => (dispatch, getState) => {
    const storeState = getState();
    const favorites = storeState.favoritePage.favorites;
    if (favorites.indexOf(cruiseNid) === -1) {
        favorites.push(cruiseNid);
        writeFavouriteCruiseNids(favorites);
        dispatch({ type: actionTypes.addToFavorites, payload: favorites });
        iadvizeTracking(getState(), storeState.router.pageType);
    }
};

export const removeFromFavorites = (cruiseNid: number, updateShareLink: boolean) => (
    dispatch,
    getState,
) => {
    const storeState = getState();
    const currentFavoriteState = storeState.favoritePage;
    const favorites = currentFavoriteState.favorites.filter(
        (favoriteCruiseNid) => favoriteCruiseNid !== cruiseNid,
    );
    if (currentFavoriteState.favorites.length !== favorites.length) {
        writeFavouriteCruiseNids(favorites);
        const favoritesCruiseData = currentFavoriteState.favoritesCruiseData
            ? currentFavoriteState.favoritesCruiseData.filter(
                  (cruise) => cruise.cruiseNid !== cruiseNid,
              )
            : [];
        const payload = {
            favoritesCruiseData: favoritesCruiseData,
            favorites: favorites,
        };
        dispatch({ type: actionTypes.removeFromFavorites, payload: payload });

        if (updateShareLink) {
            dispatch(setFavoritesShareLink(favorites));
        }

        iadvizeTracking(getState(), storeState.router.pageType);
    }
};

export const updateFavorites = (cruiseNids: number[]) => (dispatch, getState) => {
    writeFavouriteCruiseNids(cruiseNids);
    dispatch({ type: actionTypes.updateFavorites, payload: cruiseNids });
};

export const setFavoritesShareLink = (cruiseNids: number[]) => (dispatch, getState) => {
    const storeState = getState();
    const searchSlug = storeState.pageData.brandConfig.slugsObj[searchResultPage];
    const host = window.location.origin;
    const shareUrlBase = `${host}/${searchSlug}`;
    if (Array.isArray(cruiseNids) && cruiseNids.length > 0) {
        const filsterParamsObj = {
            filterParams: {
                cruiseNids,
            },
        };
        const url = `${shareUrlBase}?${qs.stringify(filsterParamsObj)}`;
        const longUrls = {
            socialShareLink: `${url}&utm_source=favorite&utm_medium=socialmedia`,
            emailShareLink: `${url}&utm_source=favorite&utm_medium=deviceswitch`,
        };
        const urlShortenerHost = `${getState().pageData.appConfig.apiHost}/urlShortener`;
        return post(urlShortenerHost, longUrls).then((resp) => {
            dispatch({
                type: actionTypes.setFavoritesShareLink,
                payload: resp,
            });
        });
    }
};
