export function cumulativeOffset(element) {
    let top = 0;
    let left = 0;
    do {
        top += element.offsetTop || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while (element);
    return {
        top: top,
        left: left,
    };
}

export const isNodeVisible = (classSelector: string) => {
    return document.querySelector('.' + classSelector)
        ? getComputedStyle(document.querySelector('.' + classSelector) as HTMLInputElement)
              .display !== 'none'
        : false;
};
