import * as actionTypes from '../consts/actionTypes';

export const setPageSpecificInfo = (payload) => ({
    type: actionTypes.setRequestFormConfig,
    payload,
});

export const setIsLoaded = (payload: boolean = true) => (dispatch) => {
    dispatch({
        type: actionTypes.setIsLoaded,
        payload,
    });
};

export const setIsDesktop = (payload: boolean = true) => (dispatch) => {
    dispatch({
        type: actionTypes.setIsDesktop,
        payload,
    });
};
