import { IFooterLink } from '../../interfaces/IFooter';
import React from 'react';

interface FooterLinkProps extends IFooterLink {
    key?: number;
    rel?: string;
    target?: string;
}

export const FooterLink = ({ targetUrl, linkTitle, title, target }: FooterLinkProps) => (
    <a
        href={targetUrl}
        title={linkTitle}
        target={target}
        rel={title === 'Agentur-Partner' ? 'nofollow' : linkTitle}
    >
        {title}
    </a>
);
