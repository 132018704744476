import { getCurrencyString } from '../../components/PriceString';
import { deepClone } from '../../helpers/deepClone';
import { groupBy } from '../../helpers/groupBy';
import {
    IPriceAvailability,
    priceAvailability,
    underOccupancyLimit,
} from '../../helpers/priceHelpers';
import { __ } from '../../helpers/TranslationService';
import { convertToDualCurrency } from '../../helpers/priceHelpers';
import {
    ICabinInfo,
    ICruiseReducer,
    IFormattedCabin,
    IFormattedCabins,
    IRate,
    ISail,
} from '../../interfaces';
import { IExchangeRate } from '../../interfaces/IHeader';

export interface IBasePrice {
    perPersonPrice: number;
    catalogPerPersonPrice?: number;
    discountPercentage: number;
    cabinType?: { nid: number };
    cabinNid?: number;
    currency: string;
}

export const SHIP_IN_SHIP_CABIN = 'shipinship';

/**
 * if price array has non 0 prices, returns cabinNid of item with min price
 * if price array prices are 0, returns cabinNid of first item in array
 * if price array item does not have cabinType property returns null
 */
export function getCabinNidForBasePriceArr(
    sortedArr: IBasePrice[],
    filteredArr: IBasePrice[],
): number {
    if (filteredArr.length > 0 && filteredArr[0].cabinType) {
        return getMinorPrice(filteredArr);
    }
    if (filteredArr.length > 0 && filteredArr[0].cabinNid) {
        return getMinorPrice(filteredArr);
    }
    if (sortedArr.length > 0 && sortedArr[0].cabinType) {
        return getMinorPrice(sortedArr);
    }
    if (sortedArr.length > 0 && sortedArr[0].cabinNid) {
        return getMinorPrice(sortedArr);
    }

    return null;
}

const getMinorPrice = (array: IBasePrice[]): number => {
    const firstPrice = array[0].perPersonPrice;
    const samePrice = array.filter((cabin) => cabin.perPersonPrice === firstPrice);
    if (samePrice.length === 1) {
        return array[0].cabinType.nid;
    }
    const minorPiceCabin = samePrice.reduce((prev, curr) => {
        return curr.catalogPerPersonPrice < prev.catalogPerPersonPrice ? curr : prev;
    });
    return minorPiceCabin.cabinType.nid;
};

export function getMinPriceCabinAndDiscount<T extends IBasePrice>(
    arr: T[],
): {
    minPrice: number;
    minCatalogPrice: number;
    maxDiscount: number;
    cabinNid: number;
    currency: string;
} {
    const arrByPrice = arr.sort((a, b) => (a.perPersonPrice < b.perPersonPrice ? -1 : 1));
    const arrByPriceFiltered = arrByPrice.filter((item) => item.perPersonPrice > 0);
    const minPrice = arrByPriceFiltered.length > 0 ? arrByPriceFiltered[0].perPersonPrice : 0;
    const minCatalogPrice =
        arrByPriceFiltered.length > 0 ? arrByPriceFiltered[0].catalogPerPersonPrice : 0;
    const currency = arrByPriceFiltered.length > 0 ? arrByPriceFiltered[0].currency : null;
    const arrByDiscount = arr.sort((a, b) =>
        a.discountPercentage > b.discountPercentage ? -1 : 1,
    );
    let maxDiscount = arrByDiscount.length > 0 ? arrByDiscount[0].discountPercentage : 0;
    const cabinNid = getCabinNidForBasePriceArr(arrByPrice, arrByPriceFiltered);
    maxDiscount = minPrice > 0 ? maxDiscount : 0;
    return { minPrice, minCatalogPrice, maxDiscount, cabinNid, currency };
}

export function getSails(sails): ISail[] {
    return sails.map((sail) => {
        return {
            nid: sail.nid,
            arrival: sail.arrival,
            departure: sail.departure,
            label: sail.label,
            cabins: sail.cabins,
            bookingServiceCode: sail.bookingServiceCode,
            minPriceCabin: {
                discountPrice: sail.minPriceCabin?.discountPrice,
                cabinType: {
                    nid: sail.minPriceCabin?.cabinType?.nid,
                },
            },
        };
    });
}

export function formatCruiseData(state: ICruiseReducer, payload): ICruiseReducer {
    const sails = getSails(payload.sails);
    return {
        ...state,
        ...payload,
        videoUrls: payload?.videoUrls
            ? payload.videoUrls.map((video) => {
                  if (video.indexOf('http:') >= 0) {
                      return video.replace('http:', 'https:');
                  }
                  return video;
              })
            : [],
        sails,
        dateRange: payload.dateRange,
    };
}

export function getPricesForCabins(
    sailNid: number,
    rates: IRate[],
    cabins: ICabinInfo[],
): ICabinInfo[] {
    const ratesForSail = rates.filter((rate) => sailNid && rate.sail_nid === sailNid);
    const noRate = {
        cheapest_price: {
            discount_percentage: 0,
            per_person_price: 0,
            currency: null,
            catalog_per_person_price: 0,
        },
    };
    cabins = cabins.map((cabin) => {
        const rate = ratesForSail
            ? ratesForSail.find((rate) => rate.cabintype_id === cabin.cabinType.nid)
            : noRate;
        const cabinType = cabin.cabinType;
        const {
            discount_percentage,
            per_person_price,
            catalog_per_person_price,
            currency,
        } = rate?.cheapest_price ? rate?.cheapest_price : noRate?.cheapest_price;

        return {
            cabinType,
            discountPercentage: discount_percentage,
            perPersonPrice: per_person_price,
            currency,
            catalogPerPersonPrice: catalog_per_person_price,
        };
    });
    return cabins;
}

/**
 * Merge rates response with cruise response
 */
export function updateRates(state: ICruiseReducer, rates: IRate[]): ICruiseReducer {
    let selectedSailIndex = state.selectedSailIndex;
    if (selectedSailIndex === -1 && Array.isArray(rates) && rates.length > 0) {
        const filteredRatesBySails = rates.filter((rate) =>
            state.sails.some((sail) => sail.nid === rate.sail_nid),
        );

        if (filteredRatesBySails.length) {
            const sortedRatesByPrice = [...filteredRatesBySails].sort((a, b) => {
                const priceDiff =
                    a.cheapest_price.per_person_price - b.cheapest_price.per_person_price;
                if (priceDiff !== 0) {
                    return priceDiff;
                }
                return a.departure_date - b?.departure_date;
            });
            const cheapestRate = sortedRatesByPrice[0];
            selectedSailIndex = state.sails.findIndex((item) => item.nid === cheapestRate.sail_nid);
        }
    }

    if (!selectedSailIndex || (selectedSailIndex && !state.sails[selectedSailIndex])) {
        selectedSailIndex = 0;
    }

    const sail =
        state.sails[selectedSailIndex] !== undefined ? state.sails[selectedSailIndex] : null;
    const cabins = sail !== null ? getPricesForCabins(sail.nid, rates, state.cabins) : [];
    const { minPrice, maxDiscount, cabinNid, currency } = getMinPriceCabinAndDiscount(cabins);

    const processedCabins = processCabins(
        cabins.length > 0 ? cabins : state.cabins,
        sail,
        state.soldOutBooking,
        rates?.length ? rates[0]?.cheapest_price?.passenger_count : 2,
        state.exchangeRate,
    );

    const groupedRates = groupBy(rates, 'sail_nid');

    const ratesBySail = Object.entries(groupedRates).reduce(
        (acc, [key, sails]) => ({
            ...acc,
            [key]: sails.reduce(
                (acc, sail) => {
                    if (
                        !acc.maxDiscount ||
                        sail.cheapest_price?.discount_percentage > acc.maxDiscount
                    ) {
                        acc.maxDiscount = sail.cheapest_price?.discount_percentage;
                    }
                    if (!acc.minPrice || sail.cheapest_price?.discount_percentage < acc.minPrice) {
                        const cabins =
                            sail !== null
                                ? getPricesForCabins(sail.sail_nid, rates, state.cabins)
                                : [];
                        const processedCabins = cabinsPricesByType(
                            cabins.length > 0 ? cabins : state.cabins,
                        );
                        acc.minPrice = getMin(processedCabins);
                    }
                    return acc;
                },
                { minPrice: undefined, maxDiscount: undefined },
            ),
        }),
        {},
    );

    return {
        ...state,
        cabins,
        minPrice,
        maxDiscount,
        currency,
        processedCabins,
        cheapestCabinNidForSail: cabinNid,
        rates,
        ratesBySail,
        selectedSailIndex,
        loadingRates: false,
    };
}

const getMin = (processedCabins: IFormattedCabins) => {
    const arr = [
        processedCabins.inside.perPersonPrice,
        processedCabins.outside.perPersonPrice,
        processedCabins.balcony.perPersonPrice,
        processedCabins.suite.perPersonPrice,
    ].filter((pos) => pos && pos > 0);
    return arr.length > 0 ? Math.min(...arr) : 0;
};

const getPlaceholderCabins = (inputCabins) => {
    const cabin: IFormattedCabin = {
        perPersonPrice: 0,
        catalogPerPersonPrice: 0,
        discountPercentage: 0,
        currency: null,
        cabinNid: null,
        image: { original: '' as any, thumb: '' as any },
        cabins: [],
    };
    const cabins = {
        inside: deepClone(cabin),
        outside: deepClone(cabin),
        balcony: deepClone(cabin),
        suite: deepClone(cabin),
        [SHIP_IN_SHIP_CABIN]: deepClone(cabin),
    };
    inputCabins.forEach((cabin) => {
        cabins[cabin.cabinType.kind].cabins.push(cabin);
    });
    return cabins;
};

const processCabins = (inputCabins, sail, soldOutBooking, nPassengers, exchangeRate) => {
    const cabin: IFormattedCabin = {
        perPersonPrice: 0,
        catalogPerPersonPrice: 0,
        discountPercentage: 0,
        currency: null,
        cabinNid: null,
        image: { original: '' as any, thumb: '' as any },
        cabins: [],
    };
    const cabins = {
        inside: deepClone(cabin),
        outside: deepClone(cabin),
        balcony: deepClone(cabin),
        suite: deepClone(cabin),
        [SHIP_IN_SHIP_CABIN]: deepClone(cabin),
    };

    inputCabins.forEach((cabin) => {
        cabins[cabin.cabinType.kind].cabins.push(cabin);
    });

    Object.keys(cabins).forEach((key) => {
        const {
            minPrice,
            minCatalogPrice,
            maxDiscount,
            cabinNid,
            currency,
        } = getMinPriceCabinAndDiscount<ICabinInfo>(cabins[key].cabins);
        cabins[key].perPersonPrice = minPrice;
        cabins[key].catalogPerPersonPrice = minCatalogPrice;
        cabins[key].discountPercentage = maxDiscount;
        cabins[key].currency = currency;
        cabins[key].cabinNid = cabinNid;
        let cabinWithPrice = cabins[key]?.cabins?.find((el) => el.perPersonPrice > 0);
        if (!cabinWithPrice?.cabinType.image.original) {
            cabinWithPrice = cabins[key]?.cabins?.find((el) => el.cabinType.image.original);
        }
        cabins[key].image =
            cabins[key].cabins.length > 0
                ? cabinWithPrice
                    ? cabinWithPrice.cabinType.image
                    : cabins[key].cabins[0].cabinType.image
                : { original: '', thumb: '' };
        cabins[key].cabins = cabins[key].cabins.sort(sortCabins);
        cabins[key].cabins.forEach((cabin) => {
            const price = priceAvailability(
                sail !== null ? sail.departure : new Date().getTime(),
                soldOutBooking,
                cabin.perPersonPrice,
                cabin.currency,
            );
            cabin.cabinType.price = price;
            cabin.cabinType.labels = getCabinLabels(price, cabin, nPassengers, exchangeRate);
        });
    });
    return cabins;
};
const cabinsPricesByType = (inputCabins) => {
    const cabin: IFormattedCabin = {
        perPersonPrice: 0,
        catalogPerPersonPrice: 0,
        discountPercentage: 0,
        currency: null,
        cabinNid: null,
        image: { original: '' as any, thumb: '' as any },
        cabins: [],
    };
    const cabins = {
        inside: deepClone(cabin),
        outside: deepClone(cabin),
        balcony: deepClone(cabin),
        suite: deepClone(cabin),
        [SHIP_IN_SHIP_CABIN]: deepClone(cabin),
    };

    inputCabins.forEach((cabin) => {
        cabins[cabin.cabinType.kind].cabins.push(cabin);
    });

    Object.keys(cabins).forEach((key) => {
        const { minPrice } = getMinPriceCabinAndDiscount<ICabinInfo>(cabins[key].cabins);
        cabins[key].perPersonPrice = minPrice;
    });
    return cabins;
};
export const getCabinLabels = (
    price: IPriceAvailability,
    cabin: ICabinInfo,
    nPassengers: number,
    exchangeRate?: IExchangeRate,
) => {
    const labels = {
        labelForRequestForm: '',
        cabinCard: {
            labelMainCurrency: '',
            labelDualCurrency: '',
        },
        priceTable: {
            labelMainCurrency: '',
            labelDualCurrency: '',
            labelOldMainCurrency: '',
            labelOldDualCurrency: '',
        },
        withoutPrice: '',
    };
    const cabinTitle = cabin.cabinType.title;
    labels.withoutPrice = cabinTitle.replace(': ', '');
    const isDualCurrency = exchangeRate !== null && exchangeRate !== undefined;
    if (nPassengers && !underOccupancyLimit(cabin, nPassengers)) {
        labels.labelForRequestForm = `${cabinTitle} (${__('on request', 'dreamlines')})`;
        labels.cabinCard.labelMainCurrency = `${__('on request', 'dreamlines')}`;
        labels.cabinCard.labelDualCurrency = isDualCurrency
            ? `${__('on request', 'dreamlines')}`
            : '';
        labels.priceTable.labelMainCurrency = `${__('on request', 'dreamlines')}`;
        labels.priceTable.labelDualCurrency = isDualCurrency
            ? `${__('on request', 'dreamlines')}`
            : '';
        labels.priceTable.labelOldMainCurrency = ``;
        labels.priceTable.labelOldDualCurrency = ``;
    }
    if (cabin.perPersonPrice > 0) {
        labels.labelForRequestForm = `${cabinTitle} (${
            isDualCurrency
                ? `${getCurrencyString(
                      convertToDualCurrency(exchangeRate, cabin.perPersonPrice),
                      exchangeRate?.sourceCurrency,
                  )}
                    ${__('p.P.', 'dreamlines')} /
                    ${getCurrencyString(cabin.perPersonPrice, cabin.currency)}
                    ${__('p.P.', 'dreamlines')}`
                : `${getCurrencyString(cabin.perPersonPrice, cabin.currency)} ${__(
                      'p.P.',
                      'dreamlines',
                  )}`
        }) ${
            nPassengers === 1
                ? ` ${__('for {{ n }} person', 'dreamlines').replace('{{ n }}', nPassengers)}`
                : ` ${__('for {{ n }} persons', 'dreamlines').replace('{{ n }}', nPassengers)}`
        }`;
        labels.cabinCard.labelMainCurrency = `${__('from', 'dreamlines')}  ${getCurrencyString(
            cabin.perPersonPrice,
            cabin.currency,
        )} ${__('p.P.', 'dreamlines')} ${
            nPassengers === 1
                ? ` ${__('for {{ n }} person', 'dreamlines').replace('{{ n }}', nPassengers)}`
                : ` ${__('for {{ n }} persons', 'dreamlines').replace('{{ n }}', nPassengers)}`
        }`;
        labels.cabinCard.labelDualCurrency = isDualCurrency
            ? `${__('from', 'dreamlines')} ${getCurrencyString(
                  convertToDualCurrency(exchangeRate, cabin.perPersonPrice),
                  exchangeRate?.sourceCurrency,
              )} ${__('p.P.', 'dreamlines')} ${
                  nPassengers === 1
                      ? ` ${__('for {{ n }} person', 'dreamlines').replace('{{ n }}', nPassengers)}`
                      : ` ${__('for {{ n }} persons', 'dreamlines').replace(
                            '{{ n }}',
                            nPassengers,
                        )}`
              }`
            : '';
        labels.priceTable.labelMainCurrency = `${__('from', 'dreamlines')}  ${getCurrencyString(
            cabin.perPersonPrice,
            cabin.currency,
        )} ${__('p.P.', 'dreamlines')}`;
        labels.priceTable.labelDualCurrency = isDualCurrency
            ? `${__('from', 'dreamlines')} ${getCurrencyString(
                  convertToDualCurrency(exchangeRate, cabin.perPersonPrice),
                  exchangeRate?.sourceCurrency,
              )} ${__('p.P.', 'dreamlines')}`
            : '';
        labels.priceTable.labelOldMainCurrency =
            cabin.discountPercentage > 0
                ? `${__('from', 'dreamlines')}  ${getCurrencyString(
                      cabin.catalogPerPersonPrice,
                      cabin.currency,
                  )} ${__('p.P.', 'dreamlines')}`
                : '';
        labels.priceTable.labelOldDualCurrency =
            isDualCurrency && cabin.discountPercentage > 0
                ? `${__('from', 'dreamlines')} ${getCurrencyString(
                      convertToDualCurrency(exchangeRate, cabin.catalogPerPersonPrice),
                      exchangeRate?.sourceCurrency,
                  )} ${__('p.P.', 'dreamlines')}`
                : '';
    } else {
        labels.labelForRequestForm = `${cabinTitle} (${__(
            price.string || 'on request',
            'dreamlines',
        )})`;
        labels.cabinCard.labelMainCurrency = __(price.string || 'on request', 'dreamlines');
        labels.cabinCard.labelDualCurrency = isDualCurrency
            ? __(price.string || 'on request', 'dreamlines')
            : '';
        labels.priceTable.labelMainCurrency = __(price.string || 'on request', 'dreamlines');
        labels.priceTable.labelDualCurrency = isDualCurrency
            ? __(price.string || 'on request', 'dreamlines')
            : '';
        labels.priceTable.labelOldMainCurrency = '';
        labels.priceTable.labelOldDualCurrency = '';
    }
    return labels;
};

/**
 * Sort cabins in asc order by perPersonPrice, but put 0 prices (on request) in the end
 */
export function sortCabins(a, b): number {
    if (a.perPersonPrice === 0 && b.perPersonPrice > 0) {
        return 1;
    }
    if (a.perPersonPrice > 0 && b.perPersonPrice === 0) {
        return -1;
    }
    if (a.perPersonPrice > b.perPersonPrice && a.perPersonPrice !== 0 && b.perPersonPrice !== 0) {
        return 1;
    }
    return -1;
}

export function changeSail(state: ICruiseReducer, index: number): ICruiseReducer {
    const cabins = getPricesForCabins(state?.sails[index]?.nid, state.rates, (state as any).cabins);
    const { minPrice, maxDiscount, cabinNid, currency } = getMinPriceCabinAndDiscount<ICabinInfo>(
        cabins,
    );
    const processedCabins =
        index !== -1
            ? processCabins(
                  cabins,
                  state.sails[index],
                  state.soldOutBooking,
                  state.rates.length ? state.rates[0].cheapest_price?.passenger_count : 2,
                  state.exchangeRate,
              )
            : getPlaceholderCabins(cabins);
    return {
        ...state,
        selectedSailIndex: index,
        minPrice,
        maxDiscount,
        currency,
        cabins,
        processedCabins,
        cheapestCabinNidForSail: cabinNid,
    };
}

/**
 * Set setSelectedSailIndex to selectedSail from route hash,
 * if hash is empty, set setSelectedSailIndex to sail wit h min price according to product api data
 */
export function setSelectedSailIndex(state: ICruiseReducer, index: number): ICruiseReducer {
    const selectedSailIndex = index ?? -1;
    return changeSail(state, selectedSailIndex);
}
