import * as actionTypes from '../consts/actionTypes';
import { IShipLivePositionPageReducer } from '../interfaces/IShipLivePosition';

export const initialState: IShipLivePositionPageReducer = {
    title: null,
    shipTitle: null,
    shipId: null,
    searchUrl: null,
    pageHost: null,
    banner: null,
    shipOverview: null,
    cruiseRecommendations: [],
    seo: null,
    shipPosition: null,
    webcams: [],
    factsAndFigures: null,
    shipPositionSlug: null,
    showLivePosition: null,
    url: null,
    cruiseLineName: null,
    cruiseLineUrl: null,
    breadcrumbs: [],
    webcamFreetextField: null,
};

export function shipLivePositionPage(
    state: IShipLivePositionPageReducer = initialState,
    action,
): IShipLivePositionPageReducer {
    switch (action.type) {
        case actionTypes.loadShipLivePositionPageData:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
