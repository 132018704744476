import * as React from 'react';
import { config } from '../../config';
import { __ } from '../../helpers/TranslationService';
import { ErrorPageCtaSection } from './components/ErrorPageCtaSection';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        textAlign: 'center',
    },
    header: {
        position: 'relative',
    },
    headerText: {
        fontSize: 150,
        textAlign: 'center',
        color: 'rgba(7, 73, 111, 0.1)',
    },
    imgStyle: {
        position: 'absolute',
        width: 72,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
    },
};

export default class ErrorPage extends React.Component<any, any> {
    render() {
        return (
            <div className="errorPage-wrapper">
                <div className="errorPage-content">
                    <div style={style.wrapper}>
                        <div style={style.header}>
                            <div style={style.headerText}>500</div>
                            <img
                                style={style.imgStyle}
                                src={`${config.images}/common/repair.svg`}
                                alt="error"
                            />
                        </div>
                    </div>
                    <div className="errorPage-sorryMsg" data-test-id="no-result-error-message">
                        {__('We are sorry.... but something went wrong on our end.', 'dreamlines')}
                    </div>
                </div>
                <ErrorPageCtaSection
                    ctaMessage={__(
                        'The site you are looking for is not available at the moment.',
                        'dreamlines',
                    )}
                />
            </div>
        );
    }
}
