import React, { useState, useContext } from 'react';
import classnames from 'classnames';
import { __ } from '../../helpers/TranslationService';
import { MarketContext } from '../../context/markets';
import { HeaderMainNavigation } from './HeaderMainNavigation';
import { IHeader } from '../../interfaces/IHeader';
import { useInView } from 'react-intersection-observer';
import { BackToTop } from '../BackToTop/BackToTop';
import { IBrandConfig } from '../../reducers/pageData';

export function BurgerButton({ onClick }: { onClick?: React.EventHandler<any> }) {
    return (
        <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#globalPage_navigation"
            onClick={onClick}
        >
            <span className="sr-only">{__('Toggle navigation', 'dreamlines')}</span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
            <span className="icon-bar"></span>
        </button>
    );
}
interface IHeaderMainNavBar {
    items: IHeader;
    countryCode: string;
    apiHost: string;
    brandConfig: IBrandConfig;
}

export const HeaderMainNavBar = (props: IHeaderMainNavBar) => {
    const { items } = props;
    const { markets } = useContext(MarketContext);
    const { isCruise1st } = markets;

    const [isMobileMenuCollapsed, setIsMobileMenuCollapsed] = useState(true);
    const [ref, inView] = useInView({
        threshold: 0,
        // if intersection observer is not supported, we want to show the back to top button
        fallbackInView: true,
    });
    const classes = classnames('navbar', { 'navbar-white': isCruise1st });
    return (
        <>
            <nav ref={ref} className={classes}>
                <div className="container">
                    <div className="row">
                        {/** Brand and toggle get grouped for better mobile display */}
                        <div className="navbar-header">
                            <BurgerButton
                                onClick={(evt) => setIsMobileMenuCollapsed(!isMobileMenuCollapsed)}
                            />
                        </div>
                        <HeaderMainNavigation
                            items={items}
                            isCollapsed={isMobileMenuCollapsed}
                            countryCode={props.countryCode}
                            apiHost={props.apiHost}
                            brandConfig={props.brandConfig}
                        />
                    </div>
                </div>
            </nav>
            {!inView && <BackToTop />}
        </>
    );
};
