import * as React from 'react';

interface Props {
    submitTitle: string;
    disableSubmit: boolean;
    handleSubmit: () => void;
}

export const ModalFooter = (props: Props) => (
    <div className={`modal__footer ${props.submitTitle ? 'has-submit-button' : ''}`}>
        {props.submitTitle && (
            <button
                disabled={props.disableSubmit}
                className="modal__submit-btn btn btn-more pull-right"
                type="button"
                onClick={props.handleSubmit}
            >
                {props.submitTitle}
            </button>
        )}
    </div>
);
