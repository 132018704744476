import { ICruiseReducer } from '../../interfaces';
import { IThankYouPage } from '../../interfaces/IThankYou';
import { base64Decoder } from '../base64Decoder';
import * as Sentry from '@sentry/react';

export interface ITrackingData {
    category?: string;
    action?: string;
    label?: string;
    event?: string;
    bkId?: string;
}

interface IEventNames {
    [propName: string]: any;
}

interface IWindow extends Window {
    gtag: any;
    dataLayer: any[];
    ga: any;
    appState: any;
}

declare let window: IWindow;

export class EventTracker {
    trackerName: string = null;
    dryRun: boolean = false; // when set to true all sent events will be displayed in the console and not set to GA
    isEcommerceInitialized: boolean = false;
    isLoading: boolean = false;
    eventNames: IEventNames = {
        pageView: 'VPV-All Pages',
        cruisePageView: 'VPV-Cruisepage',
        searchPage: 'VPV-Listing Page',
        thankYouPageView: 'VPV-ThankYoupage',
        adwordsPageView: 'VPV-AdWordsConversion',
        bookOnline: 'BookOnline-Start',
    };

    setDryRunMode = () => {
        console.log('GA', 'Setting Google Analytics to debug mode');
        window.ga = (...params) => {
            console.log('GA', params);
        };
        this.trackerName = 'GAdebug';
        this.dryRun = true;
    };

    getTrackerName = () => {
        const analyticsAllProps = window?.ga?.getAll?.() || [];
        return analyticsAllProps[0]?.get('name') || false;
    };

    initEcommerce = (debug = false) => {
        try {
            const trackerName = this.getTrackerName();

            if (trackerName) {
                this.trackerName = trackerName;
                window.ga(trackerName + '.require', 'ecommerce');
                this.isEcommerceInitialized = true;
            }

            if (debug) {
                this.setDryRunMode();
            }
        } catch (err) {
            Sentry.captureException(err, {
                extra: { windowGaGetAll: window?.ga?.getAll?.() },
            });
        }
    };

    getRequestTransaction = (thankYouPage: IThankYouPage) => {
        return {
            id: thankYouPage.bookingId,
            affiliation: 'Mobile-Website-Request',
            shipping: '',
            tax: '',
        };
    };

    getRequestItem = (cruisePage: ICruiseReducer, thankYouPage: IThankYouPage) => {
        const [, , , , score] = base64Decoder(thankYouPage.queryParams.ls).split('|');
        return {
            id: thankYouPage.bookingId,
            name: cruisePage.title,
            sku: cruisePage.operator.title,
            category: cruisePage.ship.title,
            price: score,
            quantity: thankYouPage.queryParams.numberOfAdults,
        };
    };

    sendTransaction = (cruisePage: ICruiseReducer, thankYouPage: IThankYouPage) => {
        window.ga(
            this.trackerName + '.ecommerce:addTransaction',
            this.getRequestTransaction(thankYouPage),
        );
        window.ga(
            this.trackerName + '.ecommerce:addItem',
            this.getRequestItem(cruisePage, thankYouPage),
        );
        window.ga(this.trackerName + '.ecommerce:send');
    };

    trackInquirySubmission = (cruisePage: ICruiseReducer, thankYouPage: IThankYouPage) => {
        if (this.isEcommerceInitialized) {
            this.sendTransaction(cruisePage, thankYouPage);
        } else {
            this.initEcommerce();
            this.sendTransaction(cruisePage, thankYouPage);
        }
    };

    trackViaDataLayer = (trackingData: ITrackingData) => {
        try {
            trackingData.event = trackingData.event || 'click';
            if (Array.isArray(window.dataLayer)) {
                window.dataLayer.push(trackingData);
            }
        } catch (err) {
            Sentry.captureException(err);
        }
    };

    pushDataToDataLayer = (data: any[]) => {
        try {
            if (Array.isArray(data) && Array.isArray(window.dataLayer)) {
                data.forEach((item) => window.dataLayer.push(item));
            }
        } catch (err) {
            Sentry.captureException(err);
        }
    };

    track = (trackingData: ITrackingData) => {
        if (this.dryRun && window.ga !== console.log) {
            this.setDryRunMode();
        }

        try {
            if (this.trackerName) {
                trackingData.event = trackingData.event || 'click';
                window.ga(
                    `${this.trackerName}.send`,
                    'event',
                    trackingData.category,
                    trackingData.action,
                    trackingData.label,
                );
            }
        } catch (err) {
            Sentry.captureException(err);
        }
    };

    trackG4Event = (eventName: string, eventParams?: Record<string, string | number>) => {
        window.dataLayer.push({ event: eventName, ...eventParams });
    };

    sendPageView = (url: string) => {
        try {
            if (this.trackerName) {
                window.ga(`${this.trackerName}.set`, 'page', url);
                window.ga(`${this.trackerName}.send`, 'pageview');
            } else {
                this.trackerName = this.getTrackerName();
            }
        } catch (err) {
            Sentry.captureException(err);
        }
    };

    trackShipFilter = (label, type) => {
        this.track({
            category: 'ship-overview',
            action: `search mask ${type}`,
            label: label,
        });
    };
}

export const eventTracker = new EventTracker();
