import { IDynamicFormCreateField, IDynamicFormUserData } from '../interfaces/IDynamicForm';

export const generateRequestPayload = (
    customized: boolean,
    createFields: IDynamicFormCreateField[],
    userData: IDynamicFormUserData,
) => {
    if (customized) {
        const obj = createFields.reduce((acc, it, index) => {
            const { fieldIdentifier } = it;
            acc[it.fieldIdentifier] = userData[fieldIdentifier];
            return acc;
        }, {});
        return {
            customized,
            ...obj,
        };
    }

    return createFields.reduce((acc, it, index) => {
        const { fieldIdentifier, fieldType, required } = it;
        const isRequired = required?.length > 0 ?? false;

        acc[`${fieldType}_${index}${isRequired ? '_required' : ''}___${fieldIdentifier}`] =
            userData[fieldIdentifier];

        return acc;
    }, {});
};
