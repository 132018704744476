import * as React from 'react';
import styled from '@emotion/styled';
import { __ } from '../../helpers/TranslationService';
import { ArrowLeftIcon } from '../Icons/ArrowLeftIcon';

const BackLinkStyled = styled.button`
    color: ${({ theme }) => theme.link.primary};
    text-decoration: none;
    position: relative;
    display: flex;
    border: none;
    background: #fff;
    padding: 0;
    align-items: center;
    padding: 16px;
    background-color: #fff;
    align-items: center;
`;

const BackLinkIconStyled = styled(ArrowLeftIcon)`
    fill: ${({ theme }) => theme.link.primary};
`;

const BackLinkTextStyled = styled.div`
    color: ${({ theme }) => theme.link.primary};
    text-decoration: underline;
    position: relative;
    margin-left: 3px;
    margin-top: 0;
    font-size: 13px;
    font-weight: bold;
`;

export const BackLink = (props) => {
    const onBackButtonClicked = () => {
        window.history.back();
        props.backButtonHandler?.();
    };
    return (
        <BackLinkStyled onClick={onBackButtonClicked} title={__('Go Back', 'dreamlines')}>
            <BackLinkIconStyled width={10} height={10} />
            <BackLinkTextStyled>{__('Go Back', 'dreamlines')}</BackLinkTextStyled>
        </BackLinkStyled>
    );
};
