import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function BrochureIcon(props: IIcon) {
    return (
        <Icon
            dropShadow={props.dropShadow}
            width={props.width}
            height={props.height}
            viewBox={props.viewBox ?? '0 0 24 24'}
            style={props.style}
            className={props.className}
        >
            <g>
                <path
                    d="M23.9535 5.0475L19.738 16.5333V2.775L23.5321 4.14422C23.8986 4.27641 24.0875 4.68188 23.9535 5.0475ZM17.6288 0H1.37878C0.99066 0 0.675659 0.315 0.675659 0.703125V23.2969C0.675659 23.685 0.99066 24 1.37878 24H17.6288C18.0169 24 18.3319 23.685 18.3319 23.2969V0.703125C18.3319 0.315 18.0169 0 17.6288 0ZM13.41 19.7812H4.19128C3.80316 19.7812 3.48816 19.4663 3.48816 19.0781C3.48816 18.69 3.80316 18.375 4.19128 18.375H13.41C13.7982 18.375 14.1132 18.69 14.1132 19.0781C14.1132 19.4663 13.7982 19.7812 13.41 19.7812ZM14.8163 16.9688H4.19128C3.80316 16.9688 3.48816 16.6538 3.48816 16.2656C3.48816 15.8775 3.80316 15.5625 4.19128 15.5625H14.8163C15.2044 15.5625 15.5194 15.8775 15.5194 16.2656C15.5194 16.6538 15.2044 16.9688 14.8163 16.9688ZM14.8163 14.1562H5.59753C5.20941 14.1562 4.89441 13.8413 4.89441 13.4531C4.89441 13.065 5.20941 12.75 5.59753 12.75H14.8163C15.2044 12.75 15.5194 13.065 15.5194 13.4531C15.5194 13.8413 15.2044 14.1562 14.8163 14.1562ZM15.5194 9.23438C15.5194 9.6225 15.2044 9.9375 14.8163 9.9375H4.19128C3.80316 9.9375 3.48816 9.6225 3.48816 9.23438V5.01562C3.48816 4.6275 3.80316 4.3125 4.19128 4.3125H14.8163C15.2044 4.3125 15.5194 4.6275 15.5194 5.01562V9.23438Z"
                    fill="currentColor"
                />
                <path d="M4.89441 5.71875H14.1132V8.53125H4.89441V5.71875Z" fill="currentColor" />
            </g>
        </Icon>
    );
}
