import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function DesktopMail(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <path d="M214.38,450.75V411.18H53.48A35.47,35.47,0,0,1,18,375.72V126.55A35.47,35.47,0,0,1,53.48,91.09H182.37v32.05H50.07v255.5H425.55V285.51H457.6v90A35.46,35.46,0,0,1,422.14,411h-161v39.57h48.54a20.86,20.86,0,0,1,20.86,20.86h0a20.86,20.86,0,0,1-20.86,20.86H165.84A20.86,20.86,0,0,1,145,471.41h0a20.86,20.86,0,0,1,20.86-20.86H198Zm39.1-159.07V246.34h-.59A35,35,0,0,1,218,211.47V68a35,35,0,0,1,34.87-34.87H463A35,35,0,0,1,497.85,68V211.57A35,35,0,0,1,463,246.43H309l-48.28,48.29a4.18,4.18,0,0,1-2.94,1.27A4.34,4.34,0,0,1,253.48,291.68Zm5.78-75.9a8.52,8.52,0,0,0,5.88-2.35l68.16-64.26,19.1,16.17a8.58,8.58,0,0,0,5.59,2,8.75,8.75,0,0,0,5.58-2l19.1-16.17,68.27,64.26a8.48,8.48,0,0,0,5.87,2.35,8.36,8.36,0,0,0,6.27-2.75,8.63,8.63,0,0,0-.39-12.14L395.89,138,462.2,81.59a8.54,8.54,0,1,0-11.07-13l-93.14,79L264.74,68.47a8.55,8.55,0,0,0-11.06,13l66.5,56.41-66.8,62.88a8.64,8.64,0,0,0-.39,12.15A8,8,0,0,0,259.26,215.78Z" />
        </Icon>
    );
}
