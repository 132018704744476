import * as React from 'react';
import * as Colors from '../../consts/variables';
import { SubNavigationItem } from './SubNavigationItem';
import { INavigationItem } from '../../interfaces/IHeader';
import { TrackComponent } from '../TrackComponent';
import { trackingMapper } from '../../middleware/trackingHelpers';

interface Props {
    subNavigation: INavigationItem[];
    isTopNavigation?: boolean;
    parentTitle: string;
}

export function SubNavigationMenu(props: Props) {
    const styleSubNav: any = {
        padding: props.isTopNavigation ? '20px 0px 20px 0' : '12px 0px 12px 0',
        borderTop: props.isTopNavigation ? '0' : `1px solid ${Colors.gray}`,
        background: props.isTopNavigation ? Colors.lightGray : Colors.transparent,
        fontSize: props.isTopNavigation ? '14px' : '16px',
        boxShadow: props.isTopNavigation
            ? `0px 15px 20px -15px ${Colors.darkGray} inset, 0px -15px 20px -15px ${Colors.darkGray} inset`
            : 'none',
        margin: props.isTopNavigation ? '0' : '0 10px',
    };

    let getSubNavigation = () => {
        return props.subNavigation.map((item, index) => {
            const TrackableSubNavigationItem = TrackComponent(SubNavigationItem, {
                category: props.isTopNavigation
                    ? 'mobile-navigation-burger'
                    : 'mobile-navigation-frontpage',
                action: trackingMapper[props.parentTitle],
                label: item.title,
            });
            return (
                <TrackableSubNavigationItem
                    key={index}
                    title={item.title}
                    targetUrl={item.targetUrl}
                />
            );
        });
    };
    return <ul style={styleSubNav}>{getSubNavigation()}</ul>;
}
