/**
 * Checks for application specific changes in the URL (path and search string)
 * and returns true if something changed.
 *
 * @param pathname
 * @param searchString
 */
export const didUrlChange = (
    oldPath: string,
    oldSearch: string,
    newPath: string,
    newSearch: string,
) => {
    // There will be more complicated logic here in the future, enjoy while the simplicity lasts... Cheers!
    return oldPath !== newPath || oldSearch !== newSearch;
};
