import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function CrossIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <path
                id="cross"
                d="M462.38,92.71,445.92,76.25a8.05,8.05,0,0,0-11.39,0L263.21,247.57,91.88,76.25a8.05,8.05,0,0,0-11.39,0L64,92.71a8.05,8.05,0,0,0,0,11.39L235.36,275.42,64,446.74a8.05,8.05,0,0,0,0,11.39L80.5,474.59a8.05,8.05,0,0,0,11.39,0L263.21,303.27,434.53,474.59a8.05,8.05,0,0,0,11.39,0l16.46-16.46a8.05,8.05,0,0,0,0-11.39L291.06,275.42,462.38,104.09A8.05,8.05,0,0,0,462.38,92.71Z"
            />
        </Icon>
    );
}
