import { ICountryCode } from '../interfaces';
import { Theme } from './types';
import { colors } from './colors';
import { container } from './container';
import { c1 } from './c1';
import { overlayIndexes } from './zIndex';

import {
    defaultThemedFooter,
    defaultThemedIcon,
    defaultThemedLink,
    defaultThemedBackground,
    defaultThemedActionBar,
    defaultThemedHeaderContacts,
    defaultThemedButton,
    defaultThemedAlert,
    defaultThemedToggleButton,
    defaultThemedText,
    defaultThemedPagination,
    defaultThemedProductPage,
    defaultThemedBorder,
} from './default';

export const getTheme = (code: ICountryCode): Theme => {
    const theme = {
        colors,
        container,
        border: defaultThemedBorder,
        footer: defaultThemedFooter,
        icon: defaultThemedIcon,
        link: defaultThemedLink,
        pagination: defaultThemedPagination,
        background: defaultThemedBackground,
        actionBar: defaultThemedActionBar,
        headerContacts: defaultThemedHeaderContacts,
        button: defaultThemedButton,
        alert: defaultThemedAlert,
        toggleButton: defaultThemedToggleButton,
        text: defaultThemedText,
        productPage: defaultThemedProductPage,
        overlayIndexes,
    };

    switch (code) {
        case 'de':
            return { ...theme };
        case 'c1uk':
        case 'c1au':
            return { ...theme, ...c1 };
        default:
            return theme;
    }
};
