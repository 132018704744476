import * as React from 'react';
import { config } from '../config';

export function LoadingIndicator() {
    return <div className="loading-indicator" />;
}

export function LoadingIndicatorInline() {
    return <div className="loading-indicator-inline" />;
}

export function LoadingIndicatorInlineInput() {
    return (
        <img
            className="loading-indicator-inline"
            alt=""
            src={`${config.images}/common/loading.gif`}
        />
    );
}
