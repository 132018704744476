import * as React from 'react';
import { IField } from '../../hooks/usePersonalData';
export enum Modifier {
    Disabled = 'disabled',
    Invalid = 'invalid',
    Empty = 'isEmpty',
}
export interface IOption {
    value: string | number;
    label: string;
    modifier?: Modifier;
}
interface ISelectComponent {
    options: IOption[];
    title: string;
    field: IField;
    className?: string;
    onChange?: Function;
}
const controlClass = 'form-control';
const SelectComponent = (props: ISelectComponent) => {
    const { options, title, field, className, onChange } = props;
    if (!options?.length) {
        return null;
    }
    let selectedValue;
    if (
        typeof field.control.value !== 'boolean' &&
        options.some((e) => e.value === field.control.value)
    ) {
        selectedValue = field.control.value;
    } else {
        selectedValue = '';
    }

    const handleChange = (e) => {
        if (typeof onChange === 'function') {
            onChange(e);
        }
        field.control.onBlur(e);
    };

    const emptyClass = !field.control.value ? Modifier.Empty : '';

    return (
        <>
            <select
                onBlur={field.control.onBlur}
                onChange={handleChange}
                defaultValue={selectedValue}
                className={`${controlClass}__select${
                    field.control.isDirty && !field.control.isValid ? `--${Modifier.Invalid}` : ''
                } ${emptyClass} ${className}`}
                id={field.name}
                name={field.name}
                title={title}
            >
                {options?.map((opt, i) => {
                    return (
                        <option
                            value={opt.value}
                            key={`${field.name}-${i}`}
                            className={`${controlClass}__option`}
                            disabled={opt.modifier === Modifier.Disabled}
                        >
                            {opt.label}
                        </option>
                    );
                })}
            </select>
            {field.control.isDirty && !field.control.isValid && (
                <ErrorsComponent errors={field.control.errors} />
            )}
        </>
    );
};

interface IInputComponent {
    type: string;
    title?: string;
    className?: string;
    field: IField;
}
const InputComponent = (props: IInputComponent) => {
    const { title, type, className, field } = props;
    return (
        <>
            {['checkbox', 'radio'].includes(type) || typeof field.control.value === 'boolean' ? (
                <input
                    id={field.name}
                    defaultChecked={!!field.control.value}
                    name={field.name}
                    title={title}
                    className={`${controlClass}__checbox${
                        field.control.isDirty && !field.control.isValid
                            ? `--${Modifier.Invalid}`
                            : ''
                    } ${className}`}
                    type={type}
                    onBlur={field.control.onBlur}
                    onChange={field.control.onChange}
                />
            ) : (
                <input
                    id={field.name}
                    defaultValue={field.control.value || ''}
                    name={field.name}
                    title={title}
                    className={`${controlClass}__input${
                        field.control.isDirty && !field.control.isValid
                            ? `--${Modifier.Invalid}`
                            : ''
                    } ${className}`}
                    type={type}
                    onBlur={field.control.onBlur}
                    onChange={field.control.onChange}
                />
            )}
            {field.control.isDirty && !field.control.isValid && (
                <ErrorsComponent errors={field.control.errors} />
            )}
        </>
    );
};
interface ITextareaComponent {
    title?: string;
    className?: string;
    rows?: number;
    cols?: number;
    field: IField;
}
const TextareaComponent = (props: ITextareaComponent) => {
    const { title, className, field, rows, cols } = props;
    return (
        <>
            {typeof field.control.value !== 'boolean' && (
                <textarea
                    className={`${controlClass}__textarea${
                        field.control.isDirty && !field.control.isValid
                            ? `--${Modifier.Invalid}`
                            : ''
                    } ${className}`}
                    title={title}
                    name={field.name}
                    id={field.name}
                    rows={rows}
                    cols={cols}
                    defaultValue={field.control.value}
                    onBlur={field.control.onBlur}
                    onChange={field.control.onChange}
                />
            )}
            {field.control.isDirty && !field.control.isValid && (
                <ErrorsComponent errors={field.control.errors} />
            )}
        </>
    );
};
const ErrorsComponent = ({ errors }: { errors: string[] }) => {
    return (
        <div className={`${controlClass}__errors-holder`}>
            {errors.map(
                (error, i) =>
                    !!error && (
                        <span
                            key={`error-${i}`}
                            className={`${controlClass}__errors-holder__error`}
                        >
                            {error}
                        </span>
                    ),
            )}
        </div>
    );
};
export { SelectComponent, InputComponent, TextareaComponent, ErrorsComponent };
