import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function HeartFilledIcon(props: IIcon) {
    const newViewProps = '0 0 24 22' ?? props.viewBox;
    return (
        <Icon
            dropShadow={props.dropShadow}
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <path
                d="M2.69123 12.0492L2.68304 12.0403L2.67464 12.0317C1.60429 10.9319 1 9.44145 1 7.88373C1 6.31712 1.61075 4.81811 2.69256 3.71626C3.77374 2.61503 5.23695 2 6.75849 2C8.28002 2 9.74322 2.61503 10.8244 3.71625C10.8244 3.71625 10.8244 3.71626 10.8244 3.71626L11.2864 4.18683L12 4.91362L12.7136 4.18684L13.1756 3.71627L13.1756 3.71626C14.2569 2.61489 15.7189 2 17.2403 2C18.7629 2 20.2249 2.61496 21.3062 3.71626C22.3883 4.81844 23 6.31759 23 7.88373C23 9.4414 22.3958 10.9316 21.3245 12.0314L21.3159 12.0402L21.3075 12.0492L12.1389 21.9376L12.1385 21.938C12.1004 21.9791 12.0495 22 12 22C11.9505 22 11.8996 21.9791 11.8615 21.938L11.8611 21.9375L2.69123 12.0492Z"
                fill="currentColor"
                stroke="white"
                strokeWidth="2"
            />
        </Icon>
    );
}
