import fetch from 'isomorphic-fetch';
import { getCountryDialCode } from '../actions/requestFormActions';
import * as actionTypes from '../consts/actionTypes';
import { createUniqueId } from '../helpers/crypto';
import { toQueryString } from '../helpers/querystring';
import { get } from '../helpers/http';
import { generateRequestPayload } from '../helpers/dynamicFormsHelpers';
import { IDynamicFormReducer } from '../interfaces/IDynamicForm';
import { IRouterReducer } from '../reducers/router';
import { submitDynamicForm } from '../reducers/dynamicForm/dynamicFormMappers';

type IContactFormError = {
    contexts: {
        [key: string]: {
            translationKey: string;
        };
    };
    property: string;
};

export const getDynamicFormByIdentifier = (payload) => (dispatch, getState) => {
    dispatch({
        type: actionTypes.getDynamicFormByIdentifier,
        payload,
    });
};

export const getContactFormErrorTranslationKeys = (errors: IContactFormError[]) =>
    (errors || []).map((error) => Object.values(error?.contexts || [])?.[0].translationKey);

export const loadDynamicForm = (identifier: string) => (dispatch, getState) => {
    const url = `${getState().pageData.appConfig.apiHost}/dynamic-forms/${identifier}`;
    return get(url).then((response) => {
        dispatch({ type: actionTypes.getDynamicFormByIdentifier, payload: response });
    });
};

export const setDynamicFormInitialData = (payload) => (dispatch, getState) => {
    const { pageData } = getState();
    const { countryCodes } = pageData.brandConfig.requestForm;
    const { countryCode } = pageData.appConfig;
    const countryCodeSelected = getCountryDialCode(countryCode, countryCodes);
    dispatch({
        type: actionTypes.setDynamicFormInitialData,
        payload: {
            ...payload,
            userData: {
                ...payload.userData,
                countryCode: countryCodeSelected,
            },
        },
    });
};

export const updateDynamicFormUserData = ({ key, value }) => ({
    type: actionTypes.updateDynamicFormUserData,
    payload: { key, value },
});

export const onDynamicFormSubmit = () => async (dispatch, getState) => {
    const { dynamicForm, router } = getState();
    const { userData, createFields }: IDynamicFormReducer = dynamicForm;
    let { useWordPressRoute, sendToBlackBumblebee }: IDynamicFormReducer = dynamicForm;

    if (!submitDynamicForm(createFields, userData).isValid) {
        dispatch(updateDynamicFormUserData({ key: 'isSubmitted', value: true }));
        return;
    }

    if (router.path === '/katalog') {
        useWordPressRoute = true;
        sendToBlackBumblebee = false;
    }

    if (useWordPressRoute) {
        await submitToWordPress(dispatch, getState);
    }
    if (sendToBlackBumblebee) {
        await submitToPegasus(dispatch, getState);
    }
};

export const submitToPegasus = async (dispatch, getState) => {
    const { dynamicForm, pageData } = getState();
    const { userData }: IDynamicFormReducer = dynamicForm;
    const requestData = {
        channel: 'NoOnlineRuleApplies',
        firstName: userData.firstName,
        lastName: userData.lastName,
        phoneNumber: `${userData['countryCode']}${userData['phoneNumber']}`,
        emailAddress: userData.email,
        domain: pageData.appConfig.portalId,
        userId: null,
        sessionId: null,
        message: userData.message,
        bookingCode: createUniqueId(),
        contactMethod: 'ContactForm',
        bookingUrl: window.location.href,
    };

    const advisorEndpoint = `${pageData.appConfig.apiHost}/advisor`;

    dispatch({
        type: actionTypes.showLoadingIndicator,
    });

    try {
        const response = await fetch(advisorEndpoint, {
            method: 'POST',
            body: JSON.stringify(requestData),
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const jsonData = await response.json();

        if (response.ok && jsonData.isSuccessful) {
            dispatch({
                type: actionTypes.updateDynamicFormUserData,
                payload: { key: 'isSent', value: true },
            });
        }

        throw jsonData;
    } catch (error) {
        dispatch({
            type: actionTypes.updateDynamicFormUserData,
            payload: { key: 'isSubmitError', value: true },
        });

        if (typeof error === 'object' && error?.errors) {
            dispatch({
                type: actionTypes.updateDynamicFormUserData,
                payload: {
                    key: 'errors',
                    value: getContactFormErrorTranslationKeys(error?.errors),
                },
            });
        }
    }

    dispatch({
        type: actionTypes.hideLoadingIndicator,
    });
};

export const submitToWordPress = async (dispatch, getState) => {
    const {
        dynamicForm,
        router,
        destinationDetailPage,
        shipData,
        standardContentPage,
        pageData,
    } = getState();
    const {
        userData,
        customized,
        identifier,
        hasDoubleOptIn,
        formCustomerConfirmationMail,
        formCustomerDoubleOptInMail,
        doubleOptInTargetUrl,
        doubleOptInTargetUrlCaption,
        targetUrl,
        formCustomerMail,
        successHeadline,
        successMessage,
        errorMessage,
        internalErrorMessage,
        mailAddressExistsErrorMessage,
        createFields,
    }: IDynamicFormReducer = dynamicForm;
    const { pageType, path }: IRouterReducer = router;
    let pageRef = '';
    switch (pageType) {
        case 'lpDestinationDetail':
            pageRef = destinationDetailPage.destinationName;
            break;
        case 'shippage':
            pageRef = shipData.shipPage.shipTitle;
            break;
        case 'standardContentPage':
            pageRef = standardContentPage.title;
            break;
        default:
    }
    const pageUrl = `${pageData?.config?.contentEndpoint}${path}`;
    const requestData: any = {
        action: 'dynamic_contact_form',
        request: {
            action: 'submit',
            ...generateRequestPayload(customized, createFields, userData),
            identifier,
            hasDoubleOptIn: hasDoubleOptIn ? 1 : 0,
            sendToBlackBumblebee: 0,
            formCustomerConfirmationMail,
            formCustomerDoubleOptInMail,
            doubleOptInTargetUrl,
            doubleOptInTargetUrlCaption,
            targetUrl,
            formCustomerMail,
            successHeadline,
            successMessage,
            errorMessage,
            internalErrorMessage,
            mailAddressExistsErrorMessage,
            bkIdCode: createUniqueId(),
            pageType: pageType ? pageType : '',
            pageRef,
            pageUrl,
        },
    };

    const ajaxHandlerEndpoint = `${pageData.config.contentEndpoint}/wp-content/themes/dreamlines/ajax/ajaxhandler.php`;
    dispatch({
        type: actionTypes.showLoadingIndicator,
    });
    try {
        await fetch(ajaxHandlerEndpoint, {
            method: 'POST',
            credentials: 'include',
            body: toQueryString(requestData, null),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
            },
        });
        dispatch({
            type: actionTypes.updateDynamicFormUserData,
            payload: { key: 'isSent', value: true },
        });
    } catch (error) {
        dispatch({
            type: actionTypes.updateDynamicFormUserData,
            payload: { key: 'isSubmitError', value: true },
        });
    }
    dispatch({
        type: actionTypes.hideLoadingIndicator,
    });
};
