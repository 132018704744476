import React, { useCallback, useEffect, useState } from 'react';
import * as actionTypes from '../consts/actionTypes';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { Store } from 'redux';
import { loadFavoriteNids } from '../actions/favoritesPageActions';
import { setIsLoaded } from '../actions/pageDataActions';
import * as Colors from '../consts/variables';
import { emarsysTracker } from '../helpers/EmarsysTracker/EmarsysTracker';
import { initNewsletterPopUp } from '../helpers/newsletterHelpers';
import { setDesktop, setScrollPosition } from '../helpers/scroll';
import useWindowSize, { IViewPortSize } from '../hooks/useViewport';
import { MarketsProvider } from '../context/markets';
import { IState } from '../reducers';
import { IPageDataReducer } from '../reducers/pageData';
import { Header } from './Header/Header';
import { HeaderDefault } from './HeaderDesktop/indexDefault';
import { InfoBanner } from './InfoBanner/InfoBanner';
import { LoadingIndicator } from './LoadingIndicator/LoadingIndicator';
import { NewsletterModal } from './Newsletter/NewsletterModal';
import { PromotionBanner } from './PromotionBanner/PromotionBanner';
import { FeatureToggles } from '@paralleldrive/react-feature-toggles';
import styled from '@emotion/styled';
import { setFeaturesInStore } from '../actions/rootActions';
import { findFeatures } from '../reducers/root';
import { isDesktop } from '../helpers/responsiveHelpers/responsiveHelpers';
import { lightGray } from '../consts/variables';
import { ThemeProvider } from '@emotion/react';
import { getTheme } from '../theme';
import { Footer } from './Footer/Footer';
import { CallUsFooter } from './CallUsFooter/CallUsFooter';
import { overlayIndexes } from '../theme/zIndex';
import { cruisePage as cruisePageType } from '../consts/pageTypes';

interface Props extends IPageDataReducer {
    component: React.Component<any, any>;
    store: Store<IState>;
}

const styles: Record<string, React.CSSProperties> = {
    wrapper: {
        width: '100%',
        position: 'relative',
        overflow: 'hidden',
        minHeight: '100vh',
        color: Colors.black,
        fontFamily: 'Arial, Helvetica, sans-serif',
        display: 'flex',
        flexDirection: 'column',
        background: 'white',
    },
    loadingWrapper: {
        position: 'fixed',
        height: '100%',
        width: '100%',
        top: 0,
        background: 'rgba(255,255,255,0.75)',
        zIndex: overlayIndexes.rootLoader,
    },
    loading: {
        position: 'absolute',
        top: '50%',
        marginTop: '-0.5em',
    },
    compWrapper: {
        flex: '1 1 auto',
        display: 'flex',
        flexDirection: 'column',
    },
};

const SC_AppRootStyles = styled.div<{ isOverflowVisible: boolean }>`
    width: 100%;
    position: relative;
    overflow: ${({ isOverflowVisible }) => (isOverflowVisible ? 'visible' : 'hidden')};
    min-height: 100vh;
    color: ${Colors.black};
    font-family: Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
    background: ${lightGray};
`;

export const Root = (props: Props) => {
    const { config, brandConfig, store, header } = props;
    const configProps = { config, brandConfig };
    const pageData = useSelector((state: RootStateOrAny) => state.pageData);
    const router = useSelector((state: RootStateOrAny) => state.router);
    const dispatch = useDispatch();
    const { width }: IViewPortSize = useWindowSize();
    const [features, setFeatures] = useState<readonly string[]>([]);

    const isBrowserDesktop = ((): boolean => {
        if (!width) return null;
        return isDesktop(width);
    })();

    useEffect(() => {
        if (isBrowserDesktop !== null) {
            const { dispatch } = props.store;
            setDesktop(dispatch, actionTypes.setIsDesktop, isBrowserDesktop);
        } else {
            const width = Math.max(document?.documentElement?.clientWidth, window.innerWidth || 0);
            const { dispatch } = props.store;
            setDesktop(dispatch, actionTypes.setIsDesktop, isDesktop(width));
        }
    }, [isBrowserDesktop]);

    const initTrackers = async () => {
        initNewsletterPopUp(props.store);
        if (pageData.appConfig.apiHost) {
            await emarsysTracker.identifyCustomer(pageData.appConfig.apiHost);
        }
        (window as any).utilityData.pageType = router.pageType;
        (window as any).dataLayer.push({ pageType: router.pageType });
    };

    const handleBrowserPageCache = useCallback((event) => {
        // https://web.dev/articles/bfcache
        // browser will cache pages in memory
        // and this is to prevent hanging global loader in case user press back browser button
        if (event?.persisted) {
            dispatch({
                type: actionTypes.hideLoadingIndicator,
            });
        }
    }, []);

    useEffect(() => {
        const { router, pageData, favoritePage } = store.getState();

        initTrackers();

        if (favoritePage?.favorites.length === 0) {
            loadFavoriteNids()(dispatch);
        }

        if (pageData.isLoaded) {
            (window as any).dataLayer.push({ event: 'pageRendered' });
        } else {
            setIsLoaded(true)(dispatch);
        }

        window.addEventListener('pageshow', handleBrowserPageCache);

        return () => {
            window.removeEventListener('pageshow', handleBrowserPageCache);
            setScrollPosition(dispatch, router.history.action, router.scrollArray);
        };
    }, []);

    useEffect(() => {
        const urlParams =
            router.isBrowser && window.location.href.indexOf('?') >= 0
                ? `?${window.location.href.split('?')[1]}`
                : '';
        const resultingFeatures = findFeatures(urlParams, pageData.brandConfig.portalId);
        localStorage.setItem('feature-toggles', resultingFeatures.join(','));
        dispatch(setFeaturesInStore(resultingFeatures));
        setFeatures(resultingFeatures);
    }, [router?.location?.search, router?.history?.location?.hash, router.isBrowser]);
    // we need to set overflow: visible to make the sticky sidebar workable DREAM-17700
    const cruisePageOverflowVisible = isBrowserDesktop && router.pageType === cruisePageType;
    return (
        <ThemeProvider theme={getTheme(pageData.appConfig.countryCode)}>
            <FeatureToggles features={features}>
                <MarketsProvider market={pageData.brandConfig.portalId}>
                    <SC_AppRootStyles isOverflowVisible={cruisePageOverflowVisible}>
                        <React.Fragment>
                            <InfoBanner
                                device={pageData?.deviceType}
                                banner={pageData?.banners?.infoBanner}
                            />
                        </React.Fragment>

                        {pageData.deviceType === 'desktop' ? (
                            <HeaderDefault items={header} {...configProps} />
                        ) : (
                            <>
                                <Header />

                                <CallUsFooter
                                    phoneNumber={pageData.brandConfig.phoneNumber}
                                    pageType={router?.pageType}
                                />
                            </>
                        )}
                        <>
                            <React.Fragment>
                                <PromotionBanner
                                    countryCode={pageData.appConfig.countryCode}
                                    device={pageData?.deviceType}
                                    banner={pageData?.banners?.promotionBanner}
                                />
                            </React.Fragment>
                        </>

                        <LoadingIndicator style={styles.loading} wrapper={styles.loadingWrapper} />
                        <div style={styles.compWrapper}>{props.component}</div>
                        <Footer />
                        <NewsletterModal />
                    </SC_AppRootStyles>
                </MarketsProvider>
            </FeatureToggles>
        </ThemeProvider>
    );
};
