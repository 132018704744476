import { eventTracker } from '../helpers/EventTracker/EventTracker';
import { tradedoublerTracker } from '../helpers/TradedoublerTracker/TradedoublerTracker';
import { iadvizeTracker } from '../helpers/IadvizeTracker/IadvizeTracker';
import { ICruiseReducer } from '../interfaces';
import { IRequestFormReducer } from '../interfaces/IRequestForm';
import { ISearchReducer } from '../reducers/search';
import { format } from 'date-fns';
import * as pageTypes from '../consts/pageTypes';
import { IThankYouPage } from '../interfaces/IThankYou';

export function getShipPageViewData(storeState, url: string, isEvent = true): any[] {
    let productIdsObj = {};

    const productIdsArray = storeState.shipData.shipPage.cruiseRecommendations
        .filter((_, index) => index < 3)
        .map((item) => item.cruiseNid);
    productIdsArray.forEach((item, index) => (productIdsObj[`product_id_${index + 1}`] = item));

    return [
        isEvent ? { ...productIdsObj, event: eventTracker.eventNames.pageView } : productIdsObj,
    ];
}

export function getCompanyPageViewData(storeState, url: string, isEvent = true): any[] {
    let productIdsObj = {};

    const productIdsArray = storeState.companyDetailPage.cruises
        .filter((_, index) => index < 3)
        .map((item) => item.cruiseNid);
    productIdsArray.forEach((item, index) => (productIdsObj[`product_id_${index + 1}`] = item));

    return [
        isEvent ? { ...productIdsObj, event: eventTracker.eventNames.pageView } : productIdsObj,
    ];
}

export function getDestinationPageViewData(storeState, url: string, isEvent = true): any[] {
    let productIdsObj = {};

    const productIdsArray = storeState.destinationDetailPage.cruises
        .filter((_, index) => index < 3)
        .map((item) => item.cruiseNid);
    productIdsArray.forEach((item, index) => (productIdsObj[`product_id_${index + 1}`] = item));

    return [
        isEvent ? { ...productIdsObj, event: eventTracker.eventNames.pageView } : productIdsObj,
    ];
}

export function getLandingPageViewData(storeState, url: string, isEvent = true): any[] {
    let productIdsObj = {};

    const productIdsArray = storeState.landingPage.searchResult.cruises
        .filter((_, index) => index < 3)
        .map((item) => item.cruiseNid);
    productIdsArray.forEach((item, index) => (productIdsObj[`product_id_${index + 1}`] = item));

    return [
        isEvent ? { ...productIdsObj, event: eventTracker.eventNames.pageView } : productIdsObj,
    ];
}

export function getCruisePageViewData(storeState, url: string, isEvent = true): any[] {
    const cruisePage: ICruiseReducer = storeState.cruisePage;
    const sail = cruisePage.sails[cruisePage.selectedSailIndex] ||
        cruisePage.sails[0] || {
            minPriceCabin: null,
            arrival: null,
            departure: null,
        };
    const minPrice = sail.minPriceCabin ? sail.minPriceCabin.discountPrice : null;

    const criteo_start = new Date(sail.departure * 1000);
    const criteo_end = new Date(sail.arrival * 1000);

    const data = {
        cruise_name: cruisePage.title,
        destination_name: cruisePage.zone,
        ship_name: cruisePage.ship.title,
        company_name: cruisePage.company.title,
        product_id_1: cruisePage.nid,
        product_url: url,
        image_url: cruisePage.images.length > 0 ? cruisePage.images[0] : '',
        price_from: minPrice,
        criteo_start: isNaN(criteo_start.getTime()) ? '' : format(criteo_start, 'yyyy-MM-dd'),
        criteo_end: isNaN(criteo_end.getTime()) ? '' : format(criteo_end, 'yyyy-MM-dd'),
        unix_start: sail.departure,
    };

    const defaultData = isEvent ? { ...data, event: eventTracker.eventNames.cruisePageView } : data;
    let trackingData: any[] = [defaultData];

    const ecommerceData = {
        ecommerce: {
            detail: {
                products: [
                    {
                        id: cruisePage.nid.toString(),
                        name: cruisePage.title,
                        price: minPrice,
                        brand: cruisePage.company.title,
                        category: cruisePage.company.title + '/' + cruisePage.ship.title,
                        variant: '',
                    },
                ],
            },
        },
    };

    if (storeState.pageData.appConfig.countryCode === 'ru') {
        trackingData.push(ecommerceData);
    }

    return trackingData;
}

export function getSearchPageViewData(storeState, fullUrl: string, isEvent = true): any[] {
    const productIdsObj = {};
    const listingObj = {};
    const search: ISearchReducer = storeState.search;
    const cruises = search.searchResults?.cruises;
    const productIdsArray = cruises.filter((_, index) => index < 3).map((item) => item.cruiseNid);
    const listingArray = cruises.map(
        (item) => `${item.cruiseNid} ${item.cheapestSail ? item.cheapestSail.price : null}`,
    );
    productIdsArray.forEach((item, index) => (productIdsObj[`product_id_${index + 1}`] = item));
    listingArray.forEach((item, index) => (listingObj[`rpi_listing_${index + 1}`] = item));
    return [
        isEvent
            ? {
                  event: eventTracker.eventNames.searchPage,
                  ...productIdsObj,
                  ...listingObj,
                  category_url: fullUrl,
              }
            : {
                  ...productIdsObj,
                  ...listingObj,
                  category_url: fullUrl,
              },
    ];
}

export const getTrackingAbTestsValues = (testData) => {
    if (!testData) {
        return 'not_set';
    }
    if (!testData.variationGroup) {
        return 'not_set';
    }
    return `${testData.name}_${testData.variationGroup === testData.newVariationId ? 'on' : 'off'}`;
};

export function getThankYouPageViewData(storeState, thankYouQueryParams?: string): any[] {
    const thankYouPage: IThankYouPage = storeState.thankYouPage;
    const cruise: ICruiseReducer = storeState.thankYouPage.cruiseData || storeState.cruisePage;
    const queryParams: any = thankYouQueryParams || thankYouPage.queryParams;

    if (!cruise || !queryParams) {
        return;
    }

    const startHarbour = cruise.waypoints.find((item) => item.isPortOfEmbarkation);
    const endHarbour = cruise.waypoints.find((item) => item.isPortOfDisembarkation);

    const cheapestSail = cruise.sails.reduce(function (prev, curr) {
        const prevCabinDiscountPrice = prev.minPriceCabin ? prev.minPriceCabin.discountPrice : 0;
        const currCabinDiscountPrice = curr.minPriceCabin ? curr.minPriceCabin.discountPrice : 0;
        return prevCabinDiscountPrice < currCabinDiscountPrice ? prev : curr;
    }).minPriceCabin;
    const cheapestSailPrice = cheapestSail ? cheapestSail.discountPrice : 0;

    const date_start = new Date(queryParams.departure * 1000);
    const date_end = new Date(queryParams.arrival * 1000);

    const price = queryParams.perPersonPrice;

    const abTestsData = Object.values(storeState.abTests).map((testData) =>
        getTrackingAbTestsValues(testData),
    );

    const data = {
        event: eventTracker.eventNames.adwordsPageView,
        type: 'inquiry',
        step: 'cruise-page',
        cabin_type: queryParams.cabinTypeTitle,
        company_name: cruise.operator.title,
        cruise_name: cruise.title,
        currency: storeState.pageData.brandConfig.defaultCurrency,
        date_start: isNaN(date_start.getTime()) ? '' : format(date_start, 'dd.M.yyyy'),
        date_end: isNaN(date_end.getTime()) ? '' : format(date_end, 'dd.M.yyyy'),
        destination_name: cruise.zone,
        flight_option: cruise.includesFlight ? 'incl' : queryParams.flight,
        harbor_start: startHarbour?.title,
        harbor_end: endHarbour?.title,
        nights_number: cruise.nights.toString(),
        page_type: pageTypes.thankYouPage, // Or 'conversion',
        price,
        product_id_1: cruise.nid.toString(),
        quantity: queryParams.numberOfAdults,
        rpi_lead: `${cruise.nid} ${cheapestSailPrice} ${queryParams.price}`, // Or `${cruise.nid} ${sail.nid} ${queryParams.perPersonPrice}`
        ship_name: cruise.ship.title,
        transactionAffiliation: 'Mobile-Website-Request',
        transactionId: queryParams.bookingId,
        transactionProducts: [
            {
                category: cruise.ship.title,
                name: cruise.title,
                price: price.toString(),
                quantity: queryParams.numberOfAdults,
                sku: cruise.operator.title,
            },
        ],
        //DREAM-15426
        transactionTotal: price * queryParams.numberOfAdults,
        abtest: abTestsData.join(','),
        isEstimatedPrice: queryParams.isEstimated === '1',
    };

    const ecommerceData = {
        ecommerce: {
            purchase: {
                actionField: {
                    id: data.transactionId,
                },
                products: [
                    {
                        id: cruise.nid.toString(),
                        name: data.transactionProducts[0].name,
                        price: parseFloat(data.transactionProducts[0].price),
                        brand: data.company_name,
                        category: data.company_name + '/' + data.ship_name,
                        variant: '',
                        quantity: parseInt(data.transactionProducts[0].quantity, 10),
                    },
                ],
            },
        },
    };

    let trackingData: any[] = [data];

    if (storeState.pageData.appConfig.countryCode === 'ru') {
        trackingData.push(ecommerceData);
    }

    return trackingData;
}

export function getThankPageEventRecData(store): any[] {
    const storeState = store.getState();
    const thankYouPage: IThankYouPage = storeState.thankYouPage;
    const cruisePage: ICruiseReducer = storeState.cruisePage;
    const sail = cruisePage.sails.find(
        (sail) => sail.nid === parseInt(thankYouPage.queryParams.sailNid, 10),
    );

    return [
        {
            event: 'request-form-submit',
            category: 'form-submit',
            action: 'form-submit',
            label: 'Request Form Submit',
            bkId: thankYouPage.bookingId,
            sailNid: sail ? sail.nid : -1,
        },
    ];
}

export function getTradedoublerEventRecData(): any[] | boolean {
    const tduid = tradedoublerTracker.getTDUIDCookie();
    return tduid
        ? [
              {
                  event: 'click',
                  category: 'tradedoubler-tracking',
                  action: 'visit',
                  label: tduid,
              },
          ]
        : false;
}

export function pageViewTracking(store, pageType: string, url: string) {
    const storeState = store.getState();
    const { enableServerSideEcommerceTracking } = storeState.pageData.appConfig;
    if (storeState.router.isBrowser) {
        switch (pageType) {
            case pageTypes.thankYouPage:
                // send transaction only if enableServerSideEcommerceTracking is disabled
                if (!enableServerSideEcommerceTracking) {
                    eventTracker.trackInquirySubmission(
                        storeState.cruisePage,
                        storeState.thankYouPage,
                    );
                }
                // for Adwords tracking we should push event to dataLayer
                eventTracker.pushDataToDataLayer(getThankYouPageViewData(storeState));
                break;
            case pageTypes.landingPage:
                eventTracker.pushDataToDataLayer(getLandingPageViewData(storeState, url, true));
                break;
            case pageTypes.shipDetailPage:
                eventTracker.pushDataToDataLayer(getShipPageViewData(storeState, url, true));
                break;
            case pageTypes.companyPage:
                eventTracker.pushDataToDataLayer(getCompanyPageViewData(storeState, url, true));
                break;
            case pageTypes.destinationPage:
                eventTracker.pushDataToDataLayer(getDestinationPageViewData(storeState, url, true));
                break;
            case pageTypes.searchResultPage:
                eventTracker.pushDataToDataLayer(getSearchPageViewData(storeState, url, true));
                break;
            case pageTypes.cruisePage:
                eventTracker.pushDataToDataLayer(getCruisePageViewData(storeState, url, true));
                break;
        }
        eventTracker.sendPageView(url);
    }
}

export function submitRequestFormTracking(store, action) {
    const requestForm: IRequestFormReducer = store.getState().requestForm;
    const cruisePage: ICruiseReducer = store.getState().cruisePage;
    const category = 'mobile-request form';
    const sailLabel = cruisePage.sails[requestForm.selectedSailIndex].label;
    // New interfaces don't align anymore (item as any)
    const selectedCabin = cruisePage.cabins.find(
        (item) => (item as any).cabinType.nid === requestForm.cabinNid,
    );
    eventTracker.track({ category, action: 'send request', label: 'button click' });
    eventTracker.track({
        category,
        action: 'date select',
        label: sailLabel,
    });
    if (selectedCabin) {
        eventTracker.track({
            category,
            action: 'cabin select',
            // New interfaces don't align anymore (selected cabin as any)
            label: (selectedCabin as any).cabinType.title,
        });
    }
}

const pageTypeToCategory = {
    [pageTypes.frontPage]: 'mobile-search-frontpage',
    [pageTypes.shipDetailPage]: 'mobile-search-shippage',
    [pageTypes.destinationPage]: 'mobile-search-destinationpage',
    [pageTypes.searchResultPage]: 'mobile-searchresult-filter',
};

export function searchMaskEventTracking(store) {
    const { pageType } = store.getState().router;
    eventTracker.track({ category: 'mobile-searchresult', label: '1', action: 'pagination' });
    const category = pageTypeToCategory[pageType];
    if (category) {
        searchFilterTrack(category, store);
    }
}

function searchFilterTrack(category: string, store) {
    const { pageType } = store.getState().router;
    const { filterParamsList } = store.getState().search;
    const { filterParams } = store.getState().search.searchParams;
    const paramKeys = filterParams ? Object.keys(filterParams) : [];
    const { searchFacets } = store.getState().search;
    const { river, sea } = store.getState().pageData.brandConfig.shipType;
    const riverShipType = river && river.length === 1 ? river[0] : -1;
    const shipTypes =
        filterParams?.shipType && filterParams.shipType.length > 0
            ? filterParams.shipType.map((i) => parseInt(i, 10))
            : [];

    if (pageType === pageTypes.searchResultPage) {
        const hasInclFlight = paramKeys.filter((name) => name === 'inclFlight');
        if (hasInclFlight.length > 0) {
            eventTracker.track({ category: category, action: 'flights', label: 'yes' });
        }
        const hasDiscount = paramKeys.filter((name) => name === 'discount');
        if (hasDiscount.length > 0) {
            eventTracker.track({ category: category, action: 'only discount', label: 'yes' });
        }
        if (shipTypes.length > 0 && shipTypes.indexOf(riverShipType) !== -1) {
            eventTracker.track({ category: category, action: 'river', label: 'yes' });
        }
        if (
            shipTypes.length > 0 &&
            sea.length === 2 &&
            (shipTypes.indexOf(sea[0]) !== -1 || shipTypes.indexOf(sea[1]) !== -1)
        ) {
            eventTracker.track({ category: category, action: 'sea', label: 'yes' });
        }
    }
    if (
        [pageTypes.frontPage, pageTypes.shipDetailPage, pageTypes.destinationPage].indexOf(
            pageType,
        ) !== -1
    ) {
        eventTracker.track({
            category,
            action: 'search cruise',
            label: paramKeys.length.toString(),
        });
    }
    filterParamsList.forEach((val, key) => {
        let label, action;
        switch (val.paramName) {
            case 'shipType':
                return;
            case 'company':
            case 'zone':
                action = trackingMapper[val.paramName];
                label = findPropertyName(searchFacets[`${val.paramName}Nids`], val.paramValue);
                break;
            case 'dateMax':
            case 'dateMin':
                action = trackingMapper[val.paramName];
                const date = new Date(val.paramValue * 1000);
                label = format(date, 'yyyy.MM.dd');
                break;
            case 'priceMin':
            case 'priceMax':
                action = 'price';
                label = val.paramValue;
                break;
            case 'startHarbour':
                action = 'start harbour';
                label = findPropertyName(searchFacets.startHarbours, val.paramValue);
                break;
            case 'endHarbour':
                action = 'end harbour';
                label = findPropertyName(searchFacets.endHarbours, val.paramValue);
                break;
            case 'harbour':
                action = 'all harbour';
                label = findPropertyName(searchFacets.harbours, val.paramValue);
                break;
            case 'shipSizeMin':
                action = 'ship category';
                if (val.paramValue === 1) {
                    label = 'small';
                } else if (val.paramValue === 501) {
                    label = 'medium';
                } else {
                    label = 'large';
                }
                break;
            case 'ship':
                if (pageType === pageTypes.shipDetailPage) {
                    action = trackingMapper[val.paramName];
                } else {
                    action = 'all ships';
                }
                label = findPropertyName(searchFacets[`${val.paramName}Nids`], val.paramValue);
                break;
            case 'shipCategory':
                action = 'stars';
                label = val.paramValue;
                break;
            default:
                return;
        }
        const trackData = { category, action, label };
        eventTracker.track(trackData);
    });
}

function findPropertyName(facets, value) {
    const labelName = facets.filter((_) => _.id === parseInt(value, 10)).shift();
    return labelName ? labelName.title : value;
}

export const trackingMapper = {
    dateMin: 'start date',
    dateMax: 'end date',
    zone: 'destination',
    company: 'company',
    ship: 'company',
    Schiffe: 'top ships',
    'Top-Angebote': 'top offer',
    Startseite: 'home',
    Kreuzfahrtsuche: 'find your cruise',
    Kontakt: 'contact',
};

export function iadvizeTracking(store, pageType: string) {
    if (store?.router?.isBrowser) {
        switch (pageType) {
            case pageTypes.requestFormPage:
                iadvizeTracker.customData(store);
                break;
            default:
                iadvizeTracker.customData(store);
        }
    }
}
