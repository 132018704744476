import * as React from 'react';

import { EnvelopeIcon } from '../Icons/EnvelopeIcon';
import { Link } from '../Link';
import { PhoneIcon } from '../Icons/PhoneIcon';
import { TrackComponent } from '../TrackComponent';
import { __ } from '../../helpers/TranslationService';
import { getEmailAddress } from '../../helpers/contactInfoHelpers';
import styled from '@emotion/styled';
interface IProps {
    phoneNumber: string;
    className?: string;
}

const sharedIconStyles = ({ theme }) => `
    padding: 9px;
    height: 20px;
    width: 20px;
    box-sizing: content-box;
    border: 1px solid ${theme.footer.contactUsIconsColor};
    fill: ${theme.footer.contactUsIconsColor};
    border-radius: 50%;
    margin-right: 9px;
`;

const StyledPhoneIcon = styled(PhoneIcon)`
    ${sharedIconStyles}
`;

const StyledEnvelopeIcon = styled(EnvelopeIcon)`
    ${sharedIconStyles}
`;

const StyledLink = styled(Link)`
    display: flex;
    flex-basis: 50%;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    background: ${({ theme }) => theme.footer.contactUsBgColor};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
`;

const Title = styled.p`
    font-size: 20px;
    font-weight: 700;
`;

const LinksWrapper = styled.div`
    display: flex;
    width: 100%;
`;

export function ContactUs(props: IProps) {
    const TrackableLinkCallUs = TrackComponent(StyledLink, {
        category: 'mobile-contact us',
        action: 'call us',
    });
    const TrackableLinkWriteUs = TrackComponent(StyledLink, {
        category: 'mobile-contact us',
        action: 'write us',
    });
    return (
        <Container className={props.className}>
            <Title>{__('Get in Contact with us!', 'dreamlines')}</Title>

            <LinksWrapper>
                {props.phoneNumber && (
                    <TrackableLinkCallUs href={`tel:${props.phoneNumber}`}>
                        <StyledPhoneIcon />
                        <span>{__('Call us', 'dreamlines')}</span>
                    </TrackableLinkCallUs>
                )}

                <TrackableLinkWriteUs href={`mailto:${getEmailAddress()}`}>
                    <StyledEnvelopeIcon />
                    <span>{__('Write us', 'dreamlines')}</span>
                </TrackableLinkWriteUs>
            </LinksWrapper>
        </Container>
    );
}
