import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function NewsletterIcon(props: IIcon) {
    const newViewProps = '0 0 512 512' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <g xmlns="http://www.w3.org/2000/svg">
                <path d="M85.9,211V181c0-17,13.8-30.7,30.7-30.7h279.5c17,0,30.7,13.8,30.7,30.7v29.9l39.4-39.4c1.7-1.7,2.5-3.9,2.4-6.3   c-0.1-2.3-1.3-4.5-3.1-5.9l-190.3-148c-11.1-8.6-26.6-8.6-37.7,0l-190.3,148c-1.9,1.4-3,3.6-3.1,5.9c-0.1,2.3,0.7,4.6,2.4,6.3   L85.9,211z" />
                <path d="M504,195.6c-3-1.3-6.6-0.6-8.9,1.8L286.4,406c-8,8-18.8,12.4-30,12.4c-11.3,0-22.1-4.5-30-12.4L17.7,197.4   c-2.3-2.3-5.8-3-8.9-1.8c-3,1.3-5,4.2-5,7.5v265c0,18.6,15.1,33.7,33.7,33.7h437.8c18.6,0,33.7-15.1,33.7-33.7v-265   C509,199.8,507,196.9,504,195.6z" />
                <path d="M349.7,197.3H163.1c-9.3,0-16.8,7.5-16.8,16.8c0,9.3,7.5,16.8,16.8,16.8h186.6c9.3,0,16.8-7.5,16.8-16.8   C366.6,204.8,359,197.3,349.7,197.3z" />
                <path d="M349.7,264.7H163.1c-9.3,0-16.8,7.5-16.8,16.8c0,9.3,7.5,16.8,16.8,16.8h186.6c9.3,0,16.8-7.5,16.8-16.8   C366.6,272.2,359,264.7,349.7,264.7z" />
            </g>
        </Icon>
    );
}
