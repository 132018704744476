import { ApiValidationError } from '../helpers/httpValidationErrors';

export class BadRequestError {
    details: ApiValidationError[] | unknown;
    name: string;
    message: string;

    constructor(message: string, payload?: unknown) {
        this.message = message;
        this.name = 'BadRequestError';
        this.details = payload ?? null;
    }
}
