import {
    ICruiseFilterParamType,
    ICruiseFilterParamValue,
    ICruiseSearchFilterParams,
} from '../interfaces/ISearchApi';

// TODO: Implement later
/**
 * Takes ICruiseSearchApiObject... and validates it, but does some in-function validation for the search param, but takes
 * filterParams schema
 *
 * @param searchObject
 * @param {ICruiseFilterParamType[]} filterParamsSchema
 * @returns {boolean}
 */
export const isValidSearchObject = (
    searchObject: any,
    filterParamsSchema: ICruiseFilterParamType[],
): boolean => true;

const appendArrayValue = (
    accumulator: ICruiseFilterParamValue[],
    filterParamName: string,
    filterParamValues: any[],
): ICruiseFilterParamValue[] => {
    const mappedFilterParamsList = filterParamValues.map((value) => {
        return {
            paramName: filterParamName,
            paramValue: value,
        };
    });
    return accumulator.concat(mappedFilterParamsList);
};

const appendSingleValue = (
    accumulator: ICruiseFilterParamValue[],
    filterParamName: string,
    filterParamValue: any,
): ICruiseFilterParamValue[] => {
    return accumulator.concat([
        {
            paramName: filterParamName,
            paramValue: filterParamValue,
        },
    ]);
};

const setArrayValue = (
    accumulator: ICruiseSearchFilterParams,
    filterParam: ICruiseFilterParamValue,
): ICruiseSearchFilterParams => {
    const currentArray = accumulator[filterParam.paramName];
    const newArray = currentArray
        ? currentArray.concat([filterParam.paramValue])
        : [filterParam.paramValue];
    let params = {};
    params[filterParam.paramName] = newArray;
    return { ...accumulator, ...params };
};

const setSingleValue = (
    accumulator: ICruiseSearchFilterParams,
    filterParam: ICruiseFilterParamValue,
): ICruiseSearchFilterParams => {
    let params = {};
    params[filterParam.paramName] = filterParam.paramValue;
    return { ...accumulator, ...params };
};

const appendValue = (
    accumulator: ICruiseFilterParamValue[],
    filterParamName,
    filterParamValue,
    filterSchema: ICruiseFilterParamType,
): ICruiseFilterParamValue[] => {
    return filterSchema.isArray
        ? appendArrayValue(accumulator, filterParamName, filterParamValue)
        : appendSingleValue(accumulator, filterParamName, filterParamValue);
};

const setValue = (
    accumulator: ICruiseSearchFilterParams,
    filterParam: ICruiseFilterParamValue,
    filterSchema: ICruiseFilterParamType,
): ICruiseSearchFilterParams => {
    return filterSchema.isArray
        ? setArrayValue(accumulator, filterParam)
        : setSingleValue(accumulator, filterParam);
};

/**
 * Takes a filterParam object subtree, and makes it a list of ICruiseFilterParamValue.
 *
 *
 * @param {ICruiseSearchFilterParams} filterParamsObject
 * @param {ICruiseFilterParamType[]} filterParamsSchemaList
 * @returns {ICruiseFilterParamValue[]}
 */
export const makeFilterParamsList = (
    filterParamsObject: ICruiseSearchFilterParams,
    filterParamsSchemaList: ICruiseFilterParamType[],
): ICruiseFilterParamValue[] => {
    const keys = filterParamsObject ? Object.keys(filterParamsObject) : [];
    return keys.reduce((accumulator, filterParamName: any) => {
        const filterSchema = filterParamsSchemaList
            .filter((item) => item.paramName === filterParamName)
            .shift() as ICruiseFilterParamType;

        return filterSchema
            ? appendValue(
                  accumulator,
                  filterParamName,
                  filterParamsObject[filterParamName],
                  filterSchema,
              )
            : accumulator;
    }, []);
};

/**
 * Takes a list of ICruiseFilterParamValue and converts it to a API usable search filterParam object subtree.
 *
 * @param {ICruiseFilterParamValue[]} filterParams
 * @param {ICruiseFilterParamType[]} filterParamsSchemaList
 * @returns {ICruiseSearchFilterParams}
 */
export const makeFilterParamsObject = (
    filterParams: ICruiseFilterParamValue[],
    filterParamsSchemaList: ICruiseFilterParamType[],
): ICruiseSearchFilterParams => {
    return filterParams.reduce((accumulator, filterItem: ICruiseFilterParamValue) => {
        const paramName = filterItem.paramName;
        const filterSchema = filterParamsSchemaList
            .filter((item) => item.paramName === paramName)
            .shift() as ICruiseFilterParamType;

        return filterSchema ? setValue(accumulator, filterItem, filterSchema) : accumulator;
    }, {} as ICruiseSearchFilterParams) as ICruiseSearchFilterParams;
};
