import React from 'react';
import styled from '@emotion/styled';
import { FavoritesIcon } from '../Icons/FavoritesIcon';
import { Link } from '../Link';
import { TrackComponent } from '../TrackComponent';
interface Props {
    count: number;
    pageUrl: string;
}

const TrackableLink = TrackComponent(Link, {
    category: 'mobile-favorites',
    action: 'favorites icon',
});

const TrackableLinkStyled = styled(TrackableLink)`
    text-decoration: none;
    position: relative;
    display: flex;
    align-items: flex-start;
`;

const FavoritesIconStyled = styled(FavoritesIcon)`
    color: ${({ theme }) => theme.icon.brandColor1};
`;

const FavoritesCounter = styled.div`
    position: absolute;
    top: 5px;
    right: -4px;
    font-size: 9px;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.white[100]};
    line-height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 16px;
    height: 16px;
    border: 1.5px solid ${({ theme }) => theme.colors.white[100]};
    background-color: ${({ theme }) => theme.colors.watermelon[100]};
    border-radius: 50%;
`;

export const Favorites = (props: Props) => {
    return (
        <TrackableLinkStyled href={`/${props.pageUrl}`}>
            <FavoritesIconStyled width={20} height={20} />
            {props.count > 0 && <FavoritesCounter>{props.count}</FavoritesCounter>}
        </TrackableLinkStyled>
    );
};
