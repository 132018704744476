import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function HomeIcon(props: IIcon) {
    const newViewProps = '0 0 512 512' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
<g>
<path id="home" d="M508.8,208.5c-0.6-4-2.8-7.6-6.1-10L264.5,24.1c-5.4-4-12.7-4-18.1,0L8.3,198.5c-6.8,5-8.3,14.6-3.3,21.4
	c5,6.8,14.6,8.3,21.4,3.3l31.1-22.8v257.9c0,8.4,6.9,15.3,15.3,15.3h130.6c7.9,0,14.6-6.1,15.2-14c0-0.4,0.1-0.8,0.1-1.2V331.7
	c0.4-20.4,17.2-36.7,37.6-36.3c19.9,0.3,36,16.4,36.3,36.3v126.5c0,0.4,0,0.8,0.1,1.2c0.6,8,7.3,14.1,15.2,14.1h130.6
	c8.4,0,15.3-6.8,15.3-15.3V200.4l31.1,22.8c2.6,1.9,5.8,3,9,3c4.9,0,9.5-2.3,12.3-6.3C508.4,216.6,509.4,212.5,508.8,208.5z"/>
<rect x="-633" y="-14.1" width="55" height="57"/>

<path  d="M-594,15.5c0-0.2-0.1-0.3-0.2-0.4l-9.7-7.1
	c-0.2-0.2-0.5-0.2-0.7,0l-9.7,7.1c-0.3,0.2-0.3,0.6-0.1,0.9c0.2,0.3,0.6,0.3,0.9,0.1l1.3-0.9v10.5c0,0.3,0.3,0.6,0.6,0.6h5.3
	c0.3,0,0.6-0.2,0.6-0.6c0,0,0,0,0-0.1v-5.1c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5v5.1c0,0,0,0,0,0c0,0.3,0.3,0.6,0.6,0.6h5.3
	c0.3,0,0.6-0.3,0.6-0.6V15.2l1.3,0.9c0.1,0.1,0.2,0.1,0.4,0.1c0.2,0,0.4-0.1,0.5-0.3C-594,15.9-594,15.7-594,15.5z"/>
</g>
        </Icon>
    );
}
