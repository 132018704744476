import loadable from '@loadable/component';
import * as React from 'react';
import * as actionTypes from '../consts/actionTypes';
import { shipPositionPage } from '../consts/pageTypes';
import { NotFoundError } from '../errors/NotFoundError';
import { get } from '../helpers/http';
import { getRouteSlugs } from '../helpers/routing';
import { ISeo } from '../interfaces/ISeo';
import { defaultSeo } from '../reducers/pageData';
import { getFooterByPageType } from './footerActions';
import { changeRouteSuccess } from './routerActions';

export const shipLivePositionPageRouteAction = (context) => {
    const storeState = context.store.getState();
    const { apiHost } = storeState.pageData.appConfig;
    const slugs = getRouteSlugs(storeState.pageData.brandConfig.slugs);
    const { path, params, baseUrl, action } = context;

    if (params.positionSlug !== slugs['ship/position']) {
        throw new NotFoundError('Page not found');
    }

    context.store.dispatch({ type: actionTypes.showLoadingIndicator });

    const defaultActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: shipPositionPage,
                seo: seo,
            }),
        );

        const ShipLivePositionPage = loadable(() =>
            import('../pages/ShipLivePosition/ShipLivePositionPage'),
        );

        return { component: <ShipLivePositionPage />, context };
    };

    try {
        const { shipName } = context.params;

        const result = get(`${apiHost}/footer/ship_position`);
        result.then((response) => {
            context.store.dispatch(getFooterByPageType(response) as any);
        });

        return get(`${apiHost}/ships/${shipName}/live-information/mobile`)
            .then((resp) => {
                context.store.dispatch({
                    type: actionTypes.loadShipLivePositionPageData,
                    payload: resp,
                });
                return defaultActions(resp.seo);
            })
            .catch((error) => {
                throw error;
            });
    } catch {
        const currentState = context.store.getState();
        if (currentState.pageData.pageType === shipPositionPage) {
            return defaultActions(currentState.pageData.seo);
        } else {
            throw new Error();
        }
    }
};
