import React from 'react';
import { IFooterLink } from '../../interfaces/IFooter';
import { FooterLink } from './FooterLink';
import styled from '@emotion/styled';

interface IProps {
    list: IFooterLink[];
    domainLinks: IFooterLink[];
}

const StyledNav = styled.div`
    max-width: ${({ theme }) => theme.container.maxWidth};
    padding: 0 ${({ theme }) => theme.container.gutter};
    width: 100%;
    margin: 0 auto;
    display: flex;
`;
const StyledNavDomains = styled(StyledNav)`
    justify-content: flex-end;
`;
const LinkWrapper = styled.span`
    line-height: 40px;
    color: ${({ theme }) => theme.footer.bottomTextColor};
    font-size: 12px;
    padding: 0 8px;
`;

const Container = styled.div`
    background: ${({ theme }) => theme.footer.bottomBackground};
    display: flex;
`;

export const FooterBottom = ({ list, domainLinks }: IProps) => (
    <Container>
        <StyledNav>
            {list.map((e, key) => (
                <LinkWrapper key={key}>
                    <FooterLink {...e} />
                </LinkWrapper>
            ))}
        </StyledNav>

        <StyledNavDomains>
            {domainLinks.map((e, key) => (
                <LinkWrapper key={key}>
                    <FooterLink {...e} target="_blank" />
                </LinkWrapper>
            ))}
        </StyledNavDomains>
    </Container>
);
