import * as actionTypes from '../../consts/actionTypes';
import { IDynamicFormReducer } from '../../interfaces/IDynamicForm';
import { setDynamicFormInitialData } from './dynamicFormMappers';

export const initialState = {
    customized: false,
    identifier: '',
    headline: '',
    hasDoubleOptIn: false,
    sendToBlackBumblebee: true,
    formCustomerDoubleOptInMail: '',
    formCustomerConfirmationMail: '',
    doubleOptInTargetUrl: '',
    doubleOptInTargetUrlCaption: '',
    doubleOptInConfirmed: 0,
    createFields: [],
    formAction: '',
    targetUrl: '',
    formCustomerMail: '',
    successHeadline: '',
    successMessage: '',
    errorMessage: '',
    internalErrorMessage: '',
    mailAddressExistsErrorMessage: '',
    userData: {
        isSubmitted: false,
        isValid: false,
        isSent: false,
        isSubmitError: false,
        firstInvalidFormField: '',
        salutation: '',
        firstName: '',
        lastName: '',
        address: '',
        addressNumber: '',
        postalCode: '',
        city: '',
        country: '',
        countryCode: '',
        phoneNumber: '',
        email: '',
        tripId: '',
        product: '',
        message: '',
    },
    useWordPressRoute: true,
};

export function dynamicForm(
    state: IDynamicFormReducer = initialState,
    action,
): IDynamicFormReducer {
    switch (action.type) {
        case actionTypes.setDynamicFormInitialData:
            return setDynamicFormInitialData(state, action.payload);
        case actionTypes.getDynamicFormByIdentifier:
            return { ...state, ...action.payload };
        case actionTypes.updateDynamicFormUserData:
            const { key, value } = action.payload;
            return {
                ...state,
                userData: {
                    ...state.userData,
                    [key]: value,
                } as any,
            };
        default:
            return state;
    }
}
