export const upperFirstLetter = (str: string): string =>
    str.length > 0 ? str[0].toUpperCase().concat(str.substring(1)) : '';

export const unescapeHTML = (str) =>
    str.replace(
        /&amp;|&lt;|&gt;|&#39;|&quot;/g,
        (tag) =>
            ({
                '&amp;': '&',
                '&lt;': '<',
                '&gt;': '>',
                '&#39;': "'",
                '&quot;': '"',
            }[tag] || tag),
    );

export const isBlank = (string: string) => !string || /^\s*$/.test(string);

export const generateSlug = (): string => 'id' + new Date().getTime();

export const stripHtmlTags = (str: string) => str.replace(/(<([^>]+)>)/gi, '');
