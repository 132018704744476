import * as React from 'react';
import * as vars from '../../consts/variables';
import { roundBy } from '../../helpers/priceHelpers';
import { ExchangeRate } from '../Icons/ExchangeRate';

const styles: Record<string, React.CSSProperties> = {
    wrapper: {
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: vars.lightGray,
        padding: '11px 10px',
        color: vars.black,
        fontSize: 17,
        fontWeight: 'bold',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '5px auto 10px 20px',
        minWidth: 230,
    },
    icon: {
        margin: '0 10px',
    },
};

interface IProps {
    sourceCurrency: string;
    targetCurrency: string;
    exchangeRate: number;
}

const Currency = (props: { amount: number; currency: string }) => (
    <div>{`${props.amount} ${props.currency}`}</div>
);

export function ExchangeRateSection({ sourceCurrency, targetCurrency, exchangeRate }: IProps) {
    return (
        <div style={styles.wrapper}>
            <Currency amount={1} currency={sourceCurrency} />
            <ExchangeRate style={styles.icon} />
            <Currency amount={roundBy(exchangeRate, 2)} currency={targetCurrency} />
        </div>
    );
}
