import expressHttpContext from 'express-http-context';
import { IPortal } from "../interfaces";

/**
 * Replaces placeholders in translation string (i.e. "i need to insert %s and %s")
 * Call example: replacePlaceholders("i need to insert %s and %s", ['this', 'that'])
 *
 * @param {string} str translation string (could contain placeholders like %s)
 * @param {Array} variables array of variables to replace placeholders
 */
export const replacePlaceholders = (str, variables) => {
    let index = 0;
    return str.replace(/%s/g, (a) => {
        const r = Array.isArray(variables) && variables.length > 0 ? variables[index] : undefined;
        index += 1;
        return typeof r === 'string' || typeof r === 'number' ? r : a;
    });
};

/**
 * Decorates simpleTranslate function
 * If placeholder variables are available, try to replace placeholders
 * If variables are not available, return simpleTranslate function
 *
 * @param {Function} simpleTranslate
 * @returns {(text:string, textDomain:string, ...rest:any[])=>(any|any)}
 */
const translateFunc = (simpleTranslate: Function) => {
    return (text: string, textDomain: string, ...rest) => {
        if (Array.isArray(rest) && rest.length > 0) {
            const translationString = simpleTranslate(text, textDomain);
            return replacePlaceholders(translationString, rest);
        }
        return simpleTranslate(text, textDomain);
    };
};

const getNumberFormatter = (locale: string) => {
    const formatter = new Intl.NumberFormat(locale, {
        style: 'decimal',
        minimumFractionDigits: 0,
    });
    return formatter.format;
};

export class TranslationService {
    // Client side fallback when express http context is not available
    private static _market: string;

    private static _repository: Record<IPortal, any> = {
        de: {},
        nl: {},
        au: {},
        c1au: {},
        c1uk: {},
    };

    private static _numberFormatter: Record<IPortal, { format: (value: number) => string }> = {
        de: {
            format: (value: number) => value.toString(),
        },
        nl: {
            format: (value: number) => value.toString(),
        },
        au: {
            format: (value: number) => value.toString(),
        },
        c1au: {
            format: (value: number) => value.toString(),
        },
        c1uk: {
            format: (value: number) => value.toString(),
        }
    };

    private static _simpleTranslate = (text: string) => {
        const market = expressHttpContext.get('market') || TranslationService._market || 'de';
        if (TranslationService._repository[market]?.hasOwnProperty(text)) {
            return TranslationService._repository[market][text] || text;
        } else {
            return text;
        }
    };

    static get numberFormatter() {
        const market = expressHttpContext.get('market') || TranslationService._market || 'de';
        return TranslationService._numberFormatter[market];
    }

    static translate = translateFunc(TranslationService._simpleTranslate);

    static registerTranslations(market: string, locale: string, data: any) {
        TranslationService._market = market;
        TranslationService._repository[market] = { ...TranslationService._repository[market], ...data };
        TranslationService._numberFormatter[market].format = getNumberFormatter(locale);
    }
}

export const __ = TranslationService.translate;
export const numberFormatter = () => TranslationService.numberFormatter;
