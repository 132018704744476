import * as React from 'react';
import { connect } from 'react-redux';

interface StateProps {
    isLoading: boolean;
}

interface ExtProps {
    style?: any;
    wrapper?: any;
}

type IProps = StateProps & ExtProps;

const handleClick = e => e.stopPropagation();

export function LoadingIndicatorControlled(props: IProps) {
    return props.isLoading ? (
        <div style={props.wrapper} onClick={handleClick}>
            <div className="loading-indicator" style={props.style} />
        </div>
    ) : null;
}

// @TODO Check state types
const mapStateToProps = (state: StateProps) => ({
    isLoading: (state as any).ui.isLoadingGlobal,
});

export const LoadingIndicator = connect<StateProps, {}, any>(
    mapStateToProps,
    {},
)(LoadingIndicatorControlled);
