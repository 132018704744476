import loadable from '@loadable/component';
import * as React from 'react';
import * as actionTypes from '../consts/actionTypes';
import { shipDetailPage } from '../consts/pageTypes';
import { get } from '../helpers/http';
import { ISeo } from '../interfaces/ISeo';
import { initialState as dynamicFormInitialState } from '../reducers/dynamicForm/dynamicForm';
import { defaultSeo } from '../reducers/pageData';
import { getDynamicFormByIdentifier, setDynamicFormInitialData } from './dynamicFormAction';
import { getFooterByPageType } from './footerActions';
import { changeRouteSuccess } from './routerActions';
import { dynamicFormBuilder } from '../helpers/dynamicFormBuilder';

export const shipPageRouteAction = (context) => {
    const storeState = context.store.getState();
    const { apiHost, countryCode } = storeState.pageData.appConfig;
    const { isBrowser } = storeState.router;
    const { path, params, baseUrl, waitForAction, action } = context;

    context.store.dispatch({ type: actionTypes.showLoadingIndicator });

    const defaultActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: shipDetailPage,
                seo,
            }),
        );

        const ShipPage = loadable(() => import('../pages/Ship/ShipPage'));

        return { component: <ShipPage />, context };
    };

    const result = get(`${apiHost}/footer/ship`);
    result.then((response) => {
        context.store.dispatch(getFooterByPageType(response) as any);
    });

    const response = dynamicFormBuilder(countryCode);

    context.store.dispatch(setDynamicFormInitialData(dynamicFormInitialState) as any);
    context.store.dispatch(getDynamicFormByIdentifier(response) as any);

    if (waitForAction) {
        if (isBrowser) {
            // This is forcing full page load when the user get in the ship page by
            // client side navigation
            // @TODO Remove after the LP test
            window.location.href = context.path;
            return new Promise<void>((resolve) =>
                setTimeout(() => {
                    console.log('Timeout');
                    resolve();
                }, 10000),
            );
        }
        const { shipName } = context.params;

        return get(`${apiHost}/ships/${shipName}/details/mobile`)
            .then((shipData) => {
                context.store.dispatch({
                    type: actionTypes.loadShipPageData,
                    payload: { shipPage: shipData },
                });
                return defaultActions(shipData.seo);
            })
            .catch((error) => {
                throw error;
            });
    } else {
        const currentState = context.store.getState();
        if (currentState.shipData.shipPage) {
            return defaultActions(context.store.getState().pageData.seo);
        } else {
            throw new Error();
        }
    }
};
