import * as actionTypes from '../consts/actionTypes';
import * as http from '../helpers/http';
import { readCookie, writeSessionCookie, setNewsletterModalCookie } from '../helpers/cookie';
import { emarsysTracker } from '../helpers/EmarsysTracker/EmarsysTracker';

export const loadNewsletterPageData = (dispatch, apiHost, newsletterPage) => {
    return http
        .get(`${apiHost}/page?path=${newsletterPage}`)
        .then((resp: any) => {
            return dispatch({
                type: actionTypes.loadNewsletterPageData,
                payload: resp.content,
            });
        })
        .catch(console.error);
};

export const updateNewsletterPopUpData = (payload) => (dispatch) => {
    return dispatch({
        type: actionTypes.updateNewsletterPopUpData,
        payload,
    });
};

export const signUp = (email, poc) => (dispatch, getState) => {
    //
    // poc for newsletter subscription when submitting
    // request/inquiry/Contact us Form is set on BE
    //
    const { apiHost, portalId } = getState().pageData.appConfig;
    const postData = poc
        ? {
              Email: email,
              Portal: portalId,
              PointOfContact: poc,
          }
        : {
              Email: email,
              Portal: portalId,
          };
    const payload = http.post(`${apiHost}/newsletter`, postData, false);
    return dispatch({
        type: actionTypes.newsletterSignUp,
        payload,
    })
        .then(async function () {
            writeSessionCookie('isSignedUpForNewsletter', 'true');
            setNewsletterModalCookie();
            await emarsysTracker.identifyCustomer(apiHost, email);
        })
        .catch((error) => {
            throw error;
        });
};

export const welcomePageSignUp = (emailAddress, country, port) => (dispatch, getState) => {
    const { apiHost } = getState().pageData.appConfig;
    return http
        .post(`${apiHost}/inquiry/gph`, {
            port,
            emailAddress,
            domain: country,
            pointOfCollection: 'welcomepage',
            userId: readCookie('eruid'),
            sessionId: readCookie('ersid'),
        })
        .then(() => writeSessionCookie('isSignedUpForNewsletter', 'true'));
};
