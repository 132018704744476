import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function AnchorIcon(props: IIcon) {
    const newViewProps = '0 0 50 50' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <path d="M43.965625,27.69 L37.88125,33.509375 L39.725625,34.04625 C37.081875,38.3925 32.301875,40.305625 26.858125,40.680625 L26.858125,18.64125 L31.906875,18.64125 C32.3275,19.065 32.91,19.32375 33.5575,19.32375 C34.839375,19.32375 35.88125,18.28625 35.88125,17 C35.88125,15.715 34.839375,14.673125 33.5575,14.673125 C32.916875,14.673125 32.334375,14.935 31.906875,15.359375 L26.858125,15.359375 L26.858125,12.7025 C29.31125,11.744375 31.065,9.369375 31.065,6.5775 C31.065625,2.95625 28.114375,1.77635684e-14 24.481875,1.77635684e-14 C20.85,1.77635684e-14 17.900625,2.95625 17.900625,6.5775 C17.900625,9.559375 19.90875,12.063125 22.63875,12.864375 L22.63875,15.35625 L17.055625,15.35625 C16.633125,14.935625 16.05125,14.673125 15.408125,14.673125 C14.125,14.673125 13.08125,15.715 13.08125,17 C13.08125,18.28625 14.125,19.32375 15.408125,19.32375 C16.050625,19.32375 16.633125,19.065 17.055625,18.64125 L22.63875,18.64125 L22.63875,40.6675 C16.9025,40.24 11.895625,38.006875 9.3825,33.298125 L11.219375,32.89375 L5.5325,26.693125 L3,34.719375 L5.144375,34.24 C8.27875,41.1075 15.424375,46.63875 23.49375,47.1725 L24.74625,49.71 L25.995625,47.17875 C33.670625,46.664375 40.5025,41.61875 43.88125,35.263125 L45.961875,35.871875 L43.965625,27.69 Z M21.1075,6.5775 C21.1075,4.715 22.621875,3.20375 24.484375,3.20375 C26.34625,3.20375 27.86125,4.715 27.86125,6.5775 C27.86125,8.44 26.343125,9.95437519 24.48125,9.95437519 C22.61875,9.955 21.1075,8.440625 21.1075,6.5775 Z"></path>
        </Icon>
    );
}
