import * as React from 'react';
import * as propTypes from 'prop-types';

import { ITrackingData, eventTracker } from '../helpers/EventTracker/EventTracker';

import { IState } from '../reducers';
import { connect } from 'react-redux';

interface ITrackingProps {
    trackingData: ITrackingData;
    component: any;
}
interface IStateProps {
    pageType: string;
}

class TrackReactComponent extends React.Component<ITrackingProps> {
    static childContextTypes = {
        track: propTypes.func.isRequired,
    };

    getChildContext() {
        return {
            track: this.trackHandler,
        };
    }

    trackHandler = (params) => {
        const { action, category, label } = this.props.trackingData;
        const trackingData = { action, category, label };
        const componentParams = params || {};
        const { actionValue, ...restParams } = componentParams;
        if (actionValue) {
            trackingData.action = trackingData.action
                ? `${trackingData.action}-${params.actionValue}`
                : params.actionValue;
        }
        const trackingInfo: ITrackingData = { ...trackingData, ...restParams };
        eventTracker.track(trackingInfo);
    };

    render() {
        const { component: Component } = this.props;
        return <Component {...this.props} />;
    }
}

const mapStateToProps = (state: IState): IStateProps => ({
    pageType: state.router.pageType,
});

const TrackComponentConnected = connect(mapStateToProps)(TrackReactComponent);

export const TrackComponent = <T extends {} = any>(
    Component: T,
    trackingData: ITrackingData = {},
): T =>
    ((props) => (
        <TrackComponentConnected {...props} component={Component} trackingData={trackingData} />
    )) as any; // We force returning the component to make the tracking invisible in usage
