import * as React from 'react';
import { connect } from 'react-redux';
import * as vars from '../consts/variables';
import { getEmailAddress } from '../helpers/contactInfoHelpers';
import { __ } from '../helpers/TranslationService';
import { MarketContext } from '../context/markets';
import { EnvelopeIcon } from './Icons/EnvelopeIcon';
import { PhoneIcon } from './Icons/PhoneIcon';
import { Link } from './Link';
import { TrackComponent } from './TrackComponent';

const style: Record<string, React.CSSProperties> = {
    contactButton: {
        padding: '10px 0px 6px',
        width: '48%',
        maxWidth: 140,
        textAlign: 'center',
        background: vars.acqa,
        color: vars.white,
        borderRadius: '5px',
        flex: '0 0 auto',
    },
    contactButtonOrange: {
        background: vars.orange,
        color: vars.indigo,
    },
    contactButtonIcon: {
        fill: vars.white,
    },
    contactButtonIconBlue: {
        fill: vars.indigo,
    },
    contactButtonText: {
        fontSize: '15px',
        display: 'block',
    },
    contactButtonsWrapper: {
        display: 'flex',
        width: '100%',
        padding: '10px 5% 25px',
        justifyContent: 'center',
    },
};
interface IStateProps {
    phoneNumber: string;
    countryCode: string;
}

type Props = IStateProps;
export class ContactButtonClass extends React.Component<Props, {}> {
    static contextType = MarketContext;
    render() {
        const { isCruise1st } = this.context.markets;
        const TrackableLinkCallUs = TrackComponent(Link, {
            category: 'mobile-navigation-burger',
            action: 'call us',
        });
        const TrackableLinkWriteUs = TrackComponent(Link, {
            category: 'mobile-navigation-burger',
            action: 'write us',
        });

        const contactButtonStyles = isCruise1st
            ? { ...style.contactButton, ...style.contactButtonOrange }
            : style.contactButton;

        const contactButtonIcon = isCruise1st
            ? style.contactButtonIconBlue
            : style.contactButtonIcon;

        return (
            <div style={style.contactButtonsWrapper}>
                {this.props.phoneNumber && (
                    <TrackableLinkCallUs
                        style={{ ...contactButtonStyles, ...{ marginRight: 10 } }}
                        href={`tel:${this.props.phoneNumber}`}
                        className="hideDesktop"
                    >
                        <PhoneIcon style={contactButtonIcon} width={28} height={28} />
                        <span style={style.contactButtonText}>{__('Call us', 'dreamlines')}</span>
                    </TrackableLinkCallUs>
                )}
                <TrackableLinkWriteUs
                    style={contactButtonStyles}
                    href={`mailto:${getEmailAddress()}`}
                >
                    <EnvelopeIcon style={contactButtonIcon} width={28} height={28} />
                    {<span style={style.contactButtonText}>{__('Write us', 'dreamlines')}</span>}
                </TrackableLinkWriteUs>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    phoneNumber: state.pageData.brandConfig.phoneNumber,
    countryCode: state.pageData.appConfig.countryCode,
});

export const ContactButtons = connect<IStateProps, any, any>(mapStateToProps)(ContactButtonClass);
