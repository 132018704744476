import * as React from 'react';
import { connect } from 'react-redux';
import { signUp, updateNewsletterPopUpData } from '../../actions/newsletterAction';
import { INewsletterPageData } from '../../interfaces/INewsletter';
import { FormWrapper } from './FormWrapper';
import { CheckMarkList } from './CheckMarkList';
import { Modal } from '../Modal/index';
import { IPageDataReducer } from '../../reducers/pageData';
import { setNewsletterModalCookie, isNewsletterModalCookie } from '../../helpers/cookie';

interface StateProps {
    pageData: IPageDataReducer;
    newsletterPage: INewsletterPageData;
    pageType: string;
}

interface DispatchedProps {
    signUp: Function;
    updateNewsletterPopUpData: Function;
}

type IProps = StateProps & DispatchedProps;

export class NewsletterModalClass extends React.Component<IProps, {}> {
    render() {
        const {
            newsletterHeadline,
            backgroundImage,
            newsletterBenefits,
            newsletterShowBadge,
            newsletterBadgeColor,
            newsletterBadgeHeadline,
            newsletterBadgeSubline,
            popUp,
        } = this.props.newsletterPage;
        const { shouldNewsletterModalShown } = popUp;
        const formProps = {
            newsletterHeadline,
            newsletterShowBadge,
            newsletterBadgeColor,
            newsletterBadgeHeadline,
            newsletterBadgeSubline,
            signUp: this.props.signUp,
            isModal: true,
            pageType: this.props.pageType,
            portalId: this.props.pageData.brandConfig.portalId,
        };
        const showBenefits = Boolean(
            newsletterBenefits &&
                newsletterBenefits.length > 0 &&
                newsletterBenefits[0].benefit &&
                newsletterBenefits[0].benefit.length > 0,
        );
        const notAllowedMarkets: any = ['dreamlines.it', 'dreamlines.fr'];

        const onModalCloseHandler = () => {
            setNewsletterModalCookie();
            const newState = {
                popUp: {
                    shouldNewsletterModalShown: false,
                    timeout: 0,
                },
            };
            this.props.updateNewsletterPopUpData(newState);
        };

        if (isNewsletterModalCookie()) {
            return null;
        }

        return (
            shouldNewsletterModalShown &&
            !notAllowedMarkets.includes(this.props.pageData.brandConfig.portalId) && (
                <Modal
                    backgroundImage={backgroundImage}
                    split={true}
                    contentLayer={true}
                    size="large"
                    onModalClose={onModalCloseHandler}
                >
                    <FormWrapper {...formProps} />
                    {showBenefits && (
                        <CheckMarkList
                            benefits={newsletterBenefits}
                            showHeadline={true}
                            baseStyleClass="modalCheckMarkList"
                        />
                    )}
                </Modal>
            )
        );
    }
}

export const NewsletterModal = connect<StateProps, DispatchedProps, any>(
    (state) => {
        return {
            pageData: (state as any).pageData,
            newsletterPage: (state as any).newsletterPage,
            pageType: (state as any).pageData.pageType,
        };
    },
    { signUp, updateNewsletterPopUpData },
)(NewsletterModalClass);
