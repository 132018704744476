import * as React from 'react';
import { INewsletterBenefitItem } from '../../interfaces/INewsletter';
import { __ } from '../../helpers/TranslationService';

interface IProps {
    benefits: INewsletterBenefitItem[];
    showHeadline?: Boolean;
    baseStyleClass: string;
}
export const CheckMarkList = (props: IProps) => {
    const getBenefitList = () =>
        props.benefits.map((item, index) =>
            item.benefit && item.benefit.length > 0 ? (
                <div className={`${props.baseStyleClass}__item`} key={index}>
                    <span className={`${props.baseStyleClass}__icon icon-checkMark`}></span>
                    <span className={`${props.baseStyleClass}__label`}>{item.benefit}</span>
                </div>
            ) : null,
        );
    return (
        <div className={`${props.baseStyleClass}__wrapper`}>
            {props.showHeadline && (
                <div className={`${props.baseStyleClass}__heading`}>
                    {' '}
                    {__('Benefits', 'dreamlines')}
                </div>
            )}
            <div className={props.baseStyleClass}>{getBenefitList()}</div>
        </div>
    );
};
