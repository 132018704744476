import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function UserIcon(props: IIcon) {
    const newViewProps = '0 0 317.2 330' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <g>
                <path
                    d="M262.9,201.7c-36.2-14.5-71-21.8-104.3-21.8c-33.4,0-68.2,7.3-104.3,21.8C18.1,216.2,0,235.3,0,258.7V330h317.2v-71.2
		C317.1,235.3,299,216.3,262.9,201.7z"
                />
                <path
                    d="M158.5,158.6c21.6,0,40.2-7.7,55.7-23.2s23.2-34,23.2-55.7s-7.7-40.4-23.2-56.1C198.7,7.8,180.2,0,158.5,0
		c-21.7,0-40.2,7.9-55.7,23.6C87.3,39.4,79.6,58.1,79.6,79.7c0,21.6,7.7,40.2,23.2,55.7S136.9,158.6,158.5,158.6z"
                />
            </g>
        </Icon>
    );
}
