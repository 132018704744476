import * as actionTypes from '../consts/actionTypes';
import { IThankYouPage } from '../interfaces/IThankYou';
import { get } from '../helpers/http';
import * as Sentry from '@sentry/react';

export const setThankYouData = (queryParams) => (dispatch, getState) => {
    const { apiHost } = getState().pageData.appConfig;
    const { requestForm } = getState();

    const bookingId = queryParams.bookingId || requestForm.bookingId;
    const cruiseNid = queryParams.cruise || requestForm.cruiseNid;
    const email = queryParams.email || requestForm.email;
    const payload: IThankYouPage = {
        cruiseNid,
        email,
        lastName: '',
        gender: null,
        bookingId,
        cruiseData: null,
        queryParams,
    };

    return Promise.all([
        get(`${apiHost}/inquiry/bookingid/${bookingId}`),
        get(`${apiHost}/cruisedata/${cruiseNid}/mobile`),
    ])
        .then(([respBooking, respCruiseData]) => {
            return dispatch({
                type: actionTypes.setThankYouData,
                payload: {
                    ...payload,
                    lastName: respBooking.lastName,
                    gender: Number(!respBooking.isFemale),
                    cruiseData: respCruiseData,
                },
            });
        })
        .catch((err) => {
            if (getState().router.isBrowser) {
                Sentry.captureException(err);
            }
            return dispatch({
                type: actionTypes.setThankYouData,
                payload,
            });
        });
};

export const getCruiseRecommendations = (cruiseNid: number) => (dispatch, getState) => {
    const recommmendationsUrl = `${
        getState().pageData.appConfig.apiHost
    }/cruises/recommendations/${cruiseNid}`;
    dispatch({ type: actionTypes.showLoadingIndicator });
    return get(recommmendationsUrl)
        .then((recommendations) => {
            dispatch({ type: actionTypes.loadRecommendations, payload: recommendations });
        })
        .catch((_) => {
            dispatch({ type: actionTypes.loadRecommendations, payload: [] });
        });
};
