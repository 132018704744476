export enum BreakpointsEnum {
    xss = '360px',
    xs = '480px',
    sm = '768px',
    md = '992px',
    lg = '1200px',
}

export enum WidthsEnum {
    md = '970px',
    lg = '1140px',
}

export enum BreakpointsEnumWithoutUnits {
    xss = 360,
    xs = 480,
    sm = 768,
    md = 992,
    lg = 1200,
    xl = 1536,
}
