import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function BookingIcon(props: IIcon) {
    return (
        <Icon
            dropShadow={props.dropShadow}
            width={props.width}
            height={props.height}
            viewBox={props.viewBox ?? '0 0 24 24'}
            style={props.style}
            className={props.className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 22.0001H18V5.99993H15.9999V4.00013C15.9999 2.90033 15.0996 2.00003 14.0001 2.00003H9.9999C8.9004 2.00003 8.0001 2.90033 8.0001 4.00013V5.99993H6V22.0001ZM9.9999 3.99983H14.0001V5.99993H9.9999V3.99983ZM21.9999 5.99993H20.0001V22.0001H21.9999C23.0997 22.0001 24 21.0995 24 19.9997V7.99973C24 6.90023 23.0997 5.99993 21.9999 5.99993ZM2.0001 5.99993H3.9999V22.0004H2.0001C0.9003 22.0004 0 21.0998 0 20V8.00003C0 6.90023 0.9003 5.99993 2.0001 5.99993Z"
                fill="currentColor"
            />
        </Icon>
    );
}
