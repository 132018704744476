import React, { useContext } from 'react';
import styled from '@emotion/styled';
import { Newsletter } from '../Newsletter/Newsletter';
import { FooterBottom } from './FooterBottom';
import { FooterNav } from './FooterNav';
import { FooterUSP } from './FooterUSP';
import { ContactUs } from './ContactUs';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { MarketContext } from '../../context/markets';
import { IFooterLink } from '../../interfaces';
import { requestFormPage } from '../../consts/pageTypes';

const StyledFooter = styled.footer`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`;
const StyledContactUs = styled(ContactUs)<{ extraPadding: boolean }>`
    padding-bottom: ${({ extraPadding }) => (extraPadding ? ' 85px' : '')};
`;

export const Footer = () => {
    const storeState = useTypedSelector((state) => state);
    const {
        pageData: { brandConfig, footer, deviceType },
        router: { pageType },
    } = storeState;
    const isMobile = deviceType === 'mobile';

    const { domainMap, url } = useContext(MarketContext);
    const domainEntries = Object.entries(domainMap);
    const domainLinks: IFooterLink[] = domainEntries
        .filter(([key, domain]) => domain !== url)
        .map(([key, domain]) => ({
            targetUrl: `https://www.${domain}`,
            title: domain,
        }));
    const isRequestFormPage = pageType === requestFormPage;

    return (
        <StyledFooter>
            <Newsletter />

            {isMobile ? (
                <StyledContactUs
                    extraPadding={isRequestFormPage}
                    phoneNumber={brandConfig.phoneNumber}
                ></StyledContactUs>
            ) : (
                <FooterUSP />
            )}

            {!isRequestFormPage && (
                <FooterNav
                    isMobile={isMobile}
                    brandConfig={brandConfig}
                    footer={footer}
                    pageType={pageType}
                    domainLinks={domainLinks}
                />
            )}

            {!isMobile && <FooterBottom list={footer.bottom} domainLinks={domainLinks} />}
        </StyledFooter>
    );
};
