import * as React from 'react';
import { defaultPromoters } from '../../consts/welcomePage';
import loadable from '@loadable/component';

const CustomSelect = loadable(() =>
    import('../../components/Newsletter/CustomSelect/CustomSelect'),
);

interface IProps {
    value: string;
    onChange: React.EventHandler<any>;
    isValid: boolean;
}

export const PromoterDropdown = (props: IProps) => {
    const onChange = (event) => {
        const selectedPromoter = defaultPromoters
            .filter((val) => val.value === event.target.value)
            .shift();
        props.onChange(selectedPromoter);
    };
    const disable = (index) => index === 0;
    const wrapperClasses = `customSelect--whitebg customSelect--bigger ${
        props.isValid ? '' : 'customSelect--invalid'
    }`;

    return (
        <CustomSelect
            options={defaultPromoters}
            wrapperClass={wrapperClasses}
            value={props.value}
            onChange={onChange}
            disable={disable}
        />
    );
};
