import * as React from 'react';
import { ModalHeader } from './Header';
import { ModalBody } from './Body';
import { ModalFooter } from './Footer';
import classnames from 'classnames';
interface Props {
    onSubmit?: Function;
    onModalClose?: () => void;
    disableSubmit?: boolean;
    closeButton?: boolean;
    submitTitle?: string;
    title?: string;
    theme?: 'primary1' | 'primary2' | 'default';
    backgroundImage?: string;
    split?: boolean;
    contentLayer?: boolean;
    size?: 'normal' | 'large';
}

interface State {
    isOpen: boolean;
}

export class Modal extends React.Component<Props, State> {
    public static defaultProps: Props = {
        closeButton: true,
        theme: 'default',
        contentLayer: false,
        size: 'normal',
    };

    state: State = {
        isOpen: true,
    };

    handleClose = (): void => {
        this.setState({ isOpen: false });
        this.props.onModalClose?.();
    };

    handleSubmit = () => {
        this.props.onSubmit();
        this.handleClose();
    };

    render() {
        const { backgroundImage } = this.props;
        const style = backgroundImage
            ? { backgroundImage: `url(${this.props.backgroundImage})` }
            : {};

        const classes = classnames(`modal modal--size-${this.props.size}`, {
            'modal--shown': this.state.isOpen,
            'modal--hidden': !this.state.isOpen,
        });

        return (
            <div className={classes}>
                <div className="modal__overlay" onClick={this.handleClose}></div>
                <div className="modal__dialog" style={style}>
                    <ModalHeader
                        title={this.props.title}
                        closeButton={this.props.closeButton}
                        handleClose={this.handleClose}
                        theme={this.props.theme}
                    />
                    <ModalBody split={this.props.split} contentLayer={this.props.contentLayer}>
                        {this.props.children}
                    </ModalBody>
                    <ModalFooter
                        handleSubmit={this.handleSubmit}
                        submitTitle={this.props.submitTitle}
                        disableSubmit={this.props.disableSubmit}
                    />
                </div>
            </div>
        );
    }
}
