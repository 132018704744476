import React from 'react';
import { IDomain } from './../interfaces/IBrands';

export const DE_DOMAIN = 'dreamlines.de' as const;
export const NL_DOMAIN = 'dreamlines.nl' as const;
export const CRUISE_AWAY_DOMAIN = 'cruiseaway.com.au' as const;
export const C1AU_DOMAIN = 'cruise1st.com.au' as const;
export const C1UK_DOMAIN = 'cruise1st.co.uk' as const;

const contextDefaults = {
    markets: {
        isDreamlinesDE: false,
        isDreamlinesNL: false,
        isCruiseAway: false,
        isCruise1stAU: false,
        isCruise1stUK: false,
        isCruise1st: false,
    },
    domainMap: {
        DE_DOMAIN,
        NL_DOMAIN,
        CRUISE_AWAY_DOMAIN,
        C1AU_DOMAIN,
        C1UK_DOMAIN,
    },
    url: '' as IDomain,
};

export interface IMarketContextValue {
    markets: {
        [key in keyof typeof contextDefaults.markets]: boolean;
    };
    domainMap: typeof contextDefaults.domainMap;
    url: IDomain;
}

export const MarketContext = React.createContext(contextDefaults);

interface IMarketProvider {
    children: React.ReactNode;
    market?: IDomain;
}

export const MarketsProvider: React.FC<IMarketProvider> = ({
    children,
    market = 'dreamlines.de',
}) => {
    const markets = {
        isDreamlinesDE: market === DE_DOMAIN,
        isDreamlinesNL: market === NL_DOMAIN,
        isCruiseAway: market === CRUISE_AWAY_DOMAIN,
        isCruise1stAU: market === C1AU_DOMAIN,
        isCruise1stUK: market === C1UK_DOMAIN,
        isCruise1st: market === C1UK_DOMAIN || market === C1AU_DOMAIN,
    };

    return (
        <MarketContext.Provider
            value={{
                ...contextDefaults,
                url: market,
                markets,
            }}
        >
            {children}
        </MarketContext.Provider>
    );
};
