import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function FaqIcon(props: IIcon) {
    const newViewProps = '0 0 32 32' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <g>
                <path d="M26.669 10.788c0-5.958-5.971-10.788-13.334-10.788s-13.334 4.83-13.334 10.788c0 3.385 1.927 6.407 4.942 8.386 0.040 0.244 0.059 0.488 0.059 0.746 0 1.814-1.438 3.398-3.22 4.269v0.699c3.306-0.073 6.611-0.587 9.475-3.438 0.68 0.086 1.372 0.132 2.078 0.132 7.363 0 13.334-4.83 13.334-10.794zM11.362 16.145h-2.098v-2.098h2.098v2.098zM13.816 9.554c-0.264 0.416-0.818 0.976-1.676 1.689-0.442 0.37-0.719 0.666-0.825 0.891s-0.158 0.627-0.145 1.201h-1.9c-0.007-0.271-0.007-0.442-0.007-0.501 0-0.614 0.099-1.122 0.303-1.524 0.205-0.396 0.614-0.845 1.221-1.346 0.614-0.495 0.976-0.825 1.095-0.976 0.185-0.244 0.277-0.515 0.277-0.805 0-0.409-0.165-0.759-0.488-1.049s-0.765-0.435-1.32-0.435c-0.534 0-0.976 0.152-1.333 0.455s-0.607 0.765-0.739 1.386l-1.927-0.238c0.053-0.891 0.435-1.643 1.135-2.27 0.706-0.62 1.63-0.93 2.771-0.93 1.201 0 2.164 0.317 2.87 0.944 0.713 0.627 1.069 1.359 1.069 2.197 0.007 0.462-0.125 0.897-0.383 1.313zM18.461 16.145h-2.098v-2.098h2.098v2.098zM18.54 7.772l-0.534 5.549h-1.188l-0.548-5.549v-2.567h2.27v2.567z"></path>
                <path d="M27.665 24.188c0-0.218 0.020-0.429 0.046-0.64 2.613-1.696 4.282-4.289 4.282-7.185 0-2.501-1.24-4.77-3.259-6.433 0.020 0.284 0.033 0.574 0.033 0.864 0 1.801-0.435 3.543-1.287 5.179-0.805 1.544-1.953 2.916-3.398 4.091-2.877 2.329-6.671 3.616-10.689 3.629 1.953 1.201 4.394 1.92 7.047 1.92 0.614 0 1.214-0.040 1.801-0.112 2.481 2.448 5.113 2.883 7.977 2.949v-0.6c-1.544-0.752-2.553-2.111-2.553-3.662z"></path>
            </g>
        </Icon>
    );
}
