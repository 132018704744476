import React from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';

const checkIsMobile = (stateDeviceType) => {
    let userAgent;
    try {
        userAgent = window.navigator.userAgent;
    } catch (e) {
        userAgent = undefined;
    }

    const mobile =
        userAgent &&
        Boolean(
            userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i),
        );

    return mobile || stateDeviceType === 'mobile';
};

export default function useDeviceDetect() {
    const { deviceType: stateDeviceType } = useSelector((state: RootStateOrAny) => state.pageData);
    const [isMobile, setMobile] = React.useState(checkIsMobile(stateDeviceType));

    React.useEffect(() => {
        setMobile(checkIsMobile(stateDeviceType));
    }, [isMobile]);

    return { isMobile };
}
