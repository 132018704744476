import * as propTypes from 'prop-types';
import * as React from 'react';
import { connect } from 'react-redux';
import { IState } from '../reducers';

const defaultAnchorStyles: any = {
    textDecoration: 'none',
};
interface PropsSelf {
    rel?: string;
    href: string;
    children?: React.ReactNode;
    onClick?: (props: any) => void;
    className?: string;
    history?: any;
    followLink?: boolean;
    id?: string;
    style?: any;
    target?: string;
    shouldTrack?: boolean;
    trackFunction?: () => void;
    'data-test-id'?: string;
    mask?: boolean;

    forceReload?: boolean;
}

interface StateProps {
    history: any;
    localizationSlug?: string;
    deviceType?: string;
}

type ILink = PropsSelf & StateProps;

export class LinkClass extends React.Component<ILink, any> {
    static contextTypes = {
        track: propTypes.func,
    };

    static defaultProps = {
        shouldTrack: true,
    };

    /**
     * Returns href if href is provided as location (i.e. /ship/ship-name)
     * Returns location if href is provided as absolute path and host in href equals
     * host in browser
     * @return {string}  location (relative path)
     */
    static getRelativeHref(href: string, location): string {
        if (!href || href[0] === '/' || href[0] === '#') {
            return href;
        }

        const origin = location.origin || location.protocol + '//' + location.host;
        if (href.indexOf(origin) !== 0) {
            return null;
        }

        return href.substring(origin.length) || '/';
    }

    render() {
        const { props } = this;
        let target = props.href;
        const context = this.context as any;
        if (props.localizationSlug) {
            target = `/${props.localizationSlug}${props.href}`;
        }
        const click = (e) => {
            if (props.shouldTrack && (context.track || props.trackFunction)) {
                if (context.track) {
                    context.track();
                }

                if (props.trackFunction) {
                    props.trackFunction();
                }
            }
            if (props.followLink) {
                return;
            }

            // @TODO Revert this when rollout mobile project for desktop
            if (props.mask && props.deviceType !== 'mobile') {
                window.location.href = props.href;
                return;
            }

            if (props.history) {
                const relativeHref = LinkClass.getRelativeHref(target, window.location);
                //if relativeHref is null, do hard refresh
                if (!relativeHref || props.forceReload || props.deviceType !== 'mobile') {
                    return;
                }
                e.preventDefault();
                props.history.push(relativeHref);
                if (props.onClick) {
                    props.onClick(props);
                }
            }
        };

        const targetProp = props.target
            ? {
                  target: props.target,
              }
            : {};
        if (props.mask) {
            return (
                <div
                    style={{ ...defaultAnchorStyles, ...props.style }}
                    className={props.className}
                    id={props.id}
                    onClick={click}
                    {...targetProp}
                    data-test-id={props['data-test-id']}
                >
                    {props.children}
                </div>
            );
        } else {
            return (
                <a
                    href={target}
                    style={{ ...defaultAnchorStyles, ...props.style }}
                    className={props.className}
                    id={props.id}
                    onClick={click}
                    {...targetProp}
                    data-test-id={props['data-test-id']}
                    rel={props.rel}
                >
                    {props.children}
                </a>
            );
        }
    }
}

const mapStateToProps = (state: IState): StateProps => ({
    history: state.router.history,
    localizationSlug: state.router.localizationSlug,
    deviceType: state.pageData.deviceType,
});

export const Link = connect(mapStateToProps, {})(LinkClass);
export default Link;
