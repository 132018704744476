import React from 'react';
import { getSortingBasedOnMarket } from '../../helpers/getSortingBasedOnMarket';
import { IBrandConfig } from '../../reducers/pageData';
import { post } from '../../helpers/http';
import { ICountryCode } from '../../interfaces';
import * as Sentry from '@sentry/react';
import { ICruiseSearchApiObject } from '../../interfaces/ISearchApi';

export function useSearchCruise(props: {
    countryCode: string;
    apiHost: string;
    brandConfig: IBrandConfig;
}) {
    const [searchId, setSearchId] = React.useState('');

    const replaceRelevanceSortString = () => {
        return getSortingBasedOnMarket({ market: props.countryCode as ICountryCode });
    };

    const handleInputChange = (value: string) => {
        const alphanumericRegex = /^[a-zA-Z0-9]+$/;
        if (value === '' || alphanumericRegex.test(value)) {
            setSearchId(value);
        }
    };

    const searchCruiseUsingFilterParams = (params: ICruiseSearchApiObject) => {
        const url = `${props.apiHost}/cruises/search`;

        return post(url, params)
            .then((res) => {
                const cruises = res.cruises;
                if (!cruises?.length) {
                    throw new Error('Cruise id not found during search.');
                }

                return cruises[0];
            })
            .catch((error) => {
                Sentry.captureException(error, { extra: { params } });
                throw error;
            });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const { brandConfig } = props;
        const isTvOfferCode = /[a-zA-Z]/.test(searchId);

        const searchParams = {
            filterParams: {},
            limit: 1,
            offset: 0,
            page: 1,
            sortString: '',
        };

        if (!isTvOfferCode) {
            const relevanceSortString: string = replaceRelevanceSortString();

            searchParams.filterParams = {
                cruiseNids: searchId,
            };
            searchParams.sortString = relevanceSortString;
        } else {
            searchParams.filterParams = {
                tvOfferCodes: [searchId],
            };
            delete searchParams.sortString;
        }

        if (searchId.length) {
            searchCruiseUsingFilterParams(searchParams)
                .then((cruise) => {
                    window.location.href = `${cruise.cruisePageUrl}`;
                })
                .catch(() => {
                    const urlNotFound = `/${brandConfig.slugsObj.searchresults}?cruiseNids=${searchId}`;
                    const tvOffersPage = `/${brandConfig.slugsObj.mediaOffer}`;
                    window.location.href = isTvOfferCode ? tvOffersPage : urlNotFound;
                });
        }
    };

    return {
        searchId,
        handleSubmit,
        handleInputChange,
        searchCruiseUsingFilterParams,
    };
}
