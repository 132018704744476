import * as React from 'react';
import { defaultCountries } from '../../consts/welcomePage';
import loadable from '@loadable/component';

const CustomSelect = loadable(() => import('../CustomSelect/CustomSelect'));

interface IProps {
    value: string;
    onChange: React.EventHandler<any>;
    isValid: boolean;
}

export const CountryDropdown = (props: IProps) => {
    const onChange = (event) => {
        const selectedCountry = defaultCountries
            .filter((val) => val.value === event.target.value)
            .shift();
        props.onChange(selectedCountry);
    };
    const disable = (index) => index === 0;
    const wrapperClasses = `customSelect--whitebg customSelect--bigger ${
        props.isValid ? '' : 'customSelect--invalid'
    }`;

    return (
        <CustomSelect
            options={defaultCountries}
            value={props.value}
            onChange={onChange}
            disable={disable}
        />
    );
};
