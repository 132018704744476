import * as React from 'react';
import * as Colors from '../consts/variables';
import { useActions } from '../hooks/useActions';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { HeartFilledIcon } from './Icons/HeartFilledIcon';
import { addToFavorites, removeFromFavorites } from '../actions/favoritesPageActions';

const style: Record<string, React.CSSProperties> = {
    wrapper: {
        cursor: 'pointer',
        padding: 5,
    },
    heartIcon: {
        color: Colors.softBlack50,
        display: 'block',
    },
    heartFilledIcon: {
        color: Colors.softRed,
        display: 'block',
    },
};

interface IProps {
    className?: string;
    cruiseNid: number;
    iconStyle?: any;
    filledIconStyle?: any;
    wrapperStyle?: any;
    isFavorite?: boolean;
    addToFavorites?: typeof addToFavorites;
    removeFromFavorites?: typeof removeFromFavorites;
    dropShadow?: 'dark' | 'light' | 'none';
}

export const MarkAsFavorite = (props: IProps) => {
    const { cruiseNid, iconStyle, filledIconStyle, wrapperStyle, dropShadow = 'light' } = props;

    const { favoritePage } = useTypedSelector((state) => state);
    const isFavorite = favoritePage?.favorites.indexOf(cruiseNid) > -1;

    const compIconStyles = isFavorite ? style.heartFilledIcon : style.heartIcon;
    const parentIconStyles: any = isFavorite && filledIconStyle ? filledIconStyle : iconStyle;

    const { addToFavorites, removeFromFavorites } = useActions();

    const clickHandler = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        if (isFavorite) {
            removeFromFavorites(cruiseNid, false);
        } else {
            addToFavorites(cruiseNid);
        }
    };

    return (
        <div
            style={{ ...style.wrapper, ...wrapperStyle }}
            onClick={clickHandler}
            className={props.className}
        >
            <HeartFilledIcon
                dropShadow={dropShadow}
                style={{ ...compIconStyles, ...parentIconStyles }}
            />
        </div>
    );
};
