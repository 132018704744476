import React from 'react';
import styled from '@emotion/styled';
import { PhoneIcon } from '../Icons/PhoneIcon';
import { __ } from '../../helpers/TranslationService';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { isDesktop as isBrowserDesktop } from '../../helpers/responsiveHelpers/responsiveHelpers';
import useWindowSize from '../../hooks/useViewport';
import { MarketContext } from '../../context/markets';

interface Props {
    phoneNumber: string;
    contactPagePath: string;
    firstLine: string;
    secondLine?: string;
}

const HeaderContactWrapper = styled.div`
    position: relative;
    display: flex;
    height: 46px;
    justify-content: flex-end;
    align-items: center;
    margin-right: 0;
    @media (min-width: ${BreakpointsEnum.md}) {
        height: 90px;
        margin-right: 15px;
    }
`;

const HeaderContactPhoneWrapper = styled.div`
    flex-direction: column;
    padding: 5px 0;
    cursor: pointer;
    display: flex;
    &:hover {
        address {
            display: none;
            @media (min-width: ${BreakpointsEnum.md}) {
                display: block;
            }
        }
    }
`;

const HeaderContactPhoneTitle = styled.div`
    color: ${({ theme }) => theme.headerContacts.titleColor};
    font-size: 12px;
    font-weight: 700;
    white-space: nowrap;
    display: none;
    @media (min-width: ${BreakpointsEnum.sm}) {
        display: block;
    }
`;

const HeaderContactPhoneTitleArrow = styled.div`
    position: relative;
    display: inline-block;
    width: 14px;
    height: 12px;
    margin-left: 5px;
    &:after,
    &:before {
        content: '';
        position: absolute;
        top: 8px;
        width: 9px;
        height: 2px;
        border-radius: 2px;
        background-color: ${({ theme }) => theme.headerContacts.titleColor};
    }
    &:after {
        right: 0;
        transform: rotate(-45deg);
    }
    &:before {
        left: 0;
        transform: rotate(45deg);
    }
`;

const HeaderContactPhone = styled.div`
    color: ${({ theme }) => theme.headerContacts.phoneColor};
    font-size: 24px;
    font-weight: 700;
`;

const HeaderContactInfo = styled.address`
    display: none;
    position: absolute;
    top: 75px;
    right: 0;
    padding: 16px;
    background-color: ${({ theme }) => theme.colors.white[100]};
    border-radius: ${({ theme }) => theme.container.borderRadius};
    box-shadow: 0px 2px 6px 0px rgba(0, 0, 0, 0.12);
    z-index: ${({ theme }) => theme.overlayIndexes.headerContactBlock};
    cursor: default;
    > p {
        margin-top: 0;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
    }
`;

const HeaderContactInfoTitle = styled.div`
    color: ${({ theme }) => theme.colors.gunmetal[100]};
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 6px;
    white-space: nowrap;
    > span {
        font-weight: 400;
    }
`;

const HeaderContactInfoText = styled.p`
    color: ${({ theme }) => theme.colors.gunmetal[100]};
    font-size: 14px;
    white-space: nowrap;
`;

const HeaderContactInfoLink = styled.a`
    color: ${({ theme }) => theme.link.primary};
    font-size: 14px;
    font-weight: 700;
    &:hover {
        color: ${({ theme }) => theme.link.primaryHover};
        text-decoration: none;
    }
`;

const PhoneIconButtonStyled = styled.button`
    appearance: none;
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    cursor: default;
`;

const PhoneIconStyled = styled(PhoneIcon)`
    color: ${({ theme }) => theme.icon.brandColor1};
    margin-right: 0;
    @media (min-width: ${BreakpointsEnum.md}) {
        margin-right: 16px;
    }
`;

const HeaderContactSeparator = styled.div`
    width: 0;
    height: 28px;
    margin: 0 12px;
    background-color: ${({ theme }) => theme.colors.seashell[100]};
    @media (min-width: ${BreakpointsEnum.md}) {
        width: 2px;
        margin: 0 16px;
    }
`;

export const HeaderContactBlock = ({
    phoneNumber,
    contactPagePath,
    firstLine,
    secondLine,
}: Props) => {
    const { markets } = React.useContext(MarketContext);
    const { isCruise1st, isCruise1stUK, isDreamlinesNL } = markets;
    const { width } = useWindowSize();
    const isDesktop = isBrowserDesktop(width);

    const afterSalePhoneUK = '0161 385 7425';

    return (
        <>
            <HeaderContactWrapper>
                <HeaderContactSeparator />
                <PhoneIconButtonStyled>
                    <PhoneIconStyled width={20} height={20} />
                </PhoneIconButtonStyled>
                {isDesktop && (
                    <HeaderContactPhoneWrapper>
                        <HeaderContactPhoneTitle>
                            {__('Open daily for expert advice', 'dreamlines')}
                            <HeaderContactPhoneTitleArrow />
                        </HeaderContactPhoneTitle>
                        <HeaderContactPhone
                            dangerouslySetInnerHTML={{ __html: phoneNumber }}
                            data-test-id="header-phone"
                        />
                        <HeaderContactInfo>
                            <HeaderContactInfoTitle>
                                {__('Sales', 'dreamlines')}:
                            </HeaderContactInfoTitle>
                            <HeaderContactInfoText
                                className="globalPageheader_officeHours"
                                dangerouslySetInnerHTML={{ __html: firstLine }}
                            />
                            {secondLine && (
                                <>
                                    {!isCruise1stUK && !isDreamlinesNL && (
                                        <HeaderContactInfoTitle>
                                            {__('Aftersales', 'dreamlines')}:
                                        </HeaderContactInfoTitle>
                                    )}
                                    {isCruise1stUK && (
                                        <HeaderContactInfoTitle>
                                            {__('Already booked', 'dreamlines')}
                                            <br />
                                            <span>
                                                {__('Call Aftersales for free on', 'dreamlines')}
                                            </span>{' '}
                                            {afterSalePhoneUK}
                                        </HeaderContactInfoTitle>
                                    )}
                                    <HeaderContactInfoText
                                        className="globalPageheader_officeHours"
                                        dangerouslySetInnerHTML={{ __html: secondLine }}
                                    />
                                </>
                            )}
                            {!isCruise1st && (
                                <HeaderContactInfoLink href={contactPagePath}>
                                    {__('Choose Cruise Expert', 'dreamlines')}
                                </HeaderContactInfoLink>
                            )}
                        </HeaderContactInfo>
                    </HeaderContactPhoneWrapper>
                )}
            </HeaderContactWrapper>
        </>
    );
};
