export const base64Encoder = (Value) => {
    if (typeof window !== 'undefined' && window?.btoa) {
        return window.btoa(Value);
    }

    if (Buffer?.from) {
        return Buffer.from(Value).toString('base64');
    }

    return '';
};

export const base64Decoder = (encodedValue): string => {
    if (typeof window !== 'undefined' && window?.atob) {
        return window.atob(encodedValue);
    }

    if (Buffer?.from) {
        return Buffer.from(encodedValue, 'base64').toString('binary');
    }

    return '';
};
