import * as React from 'react';
import { IPromotionBanner } from '../../interfaces/IBanner';

interface IProps {
    countryCode: string;
    device: string;
    banner: IPromotionBanner;
}
export class PromotionBanner extends React.Component<IProps, {}> {
    render() {
        if (!this.props.banner) return null;

        const { devices, headline, subHeadline, linkDescription, linkUrl } = this.props.banner;

        if (!devices?.includes(this.props.device)) return null;

        const customClass = this.props.countryCode === 'au' ? 'cruiseaway' : undefined;

        return headline && subHeadline ? (
            <div id="promotion-banner" className={customClass}>
                <div className="container">
                    <div className="headline" dangerouslySetInnerHTML={{ __html: headline }} />
                    <div
                        className="subHeadline"
                        dangerouslySetInnerHTML={{ __html: subHeadline }}
                    />
                    {linkUrl && linkDescription && (
                        <div className="link">
                            <a href={linkUrl}>{linkDescription}</a>
                        </div>
                    )}
                </div>
            </div>
        ) : null;
    }
}
