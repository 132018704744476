import * as actionTypes from '../consts/actionTypes';
import { IBreadcrumb } from '../interfaces';

export interface IThemeOverviewPageReducer {
    pageData: IPageData;
    themeData: IThemeData[];
    breadcrumbs: IBreadcrumb[];
    seo: ISeoData;
}

export interface ISeoData {
    text: string;
}
export interface IPageData {
    pageId: number;
    uri: string;
    title: string;
    seoRobotsNofollow: number;
    seoRobotsNoIndex: number;
    seoMetadesc: string;
    seoTitle: string;
    headerImage: string;
    headerTitle: string;
    headerDescription: string;
    showDiscount: number;
    fontColor: string;
}

export interface IThemeData {
    teaserId: number;
    themeOverviewPageId: number;
    landingPageId: number;
    description: string;
    image: string;
    price: number;
    discount: number;
    uri: string;
    title: string;
    searchParameters: {
        filterParams: {
            discount: number;
            departureMax: number;
            page: number;
        };
        limit: number;
        offset: number;
        sortString: string;
    };
}

export const initialState: IThemeOverviewPageReducer = {
    pageData: null,
    themeData: [],
    breadcrumbs: [],
    seo: { text: '' },
};

export function themeOverviewPage(
    state: IThemeOverviewPageReducer = initialState,
    action,
): IThemeOverviewPageReducer {
    switch (action.type) {
        case actionTypes.loadThemeOverviewPageData:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
