import * as React from 'react';
import { IIcon } from '../../interfaces/IIcon';
import { Icon } from '../Icon';

export function ShipPlainIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <path d="M145 68h-51c-9 0 -17 7 -17 16v109l160 -76l159 76v-109c0 -9 -8 -16 -17 -16h-51h-183zM328 52h-183v-10c0 -9 7 -16 16 -16h40l7 -65h57l7 65h40c9 0 16 7 16 16v10zM237 135l236 113l-77 225h-12c-73 -17 -128 -50 -197 -50c-43 0 -85 15 -117 30l-70 -205zM237 135z" />
        </Icon>
    );
}
