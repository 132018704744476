import * as actionTypes from '../consts/actionTypes';
import { IFAQPageReducer } from '../interfaces/IFAQPage';
import { defaultSeo } from './pageData';
import { string } from 'prop-types';

export const initialState: IFAQPageReducer = {
    cmsData: {
        pageId: null,
        uri: null,
        title: null,
        seoRobotsNofollow: null,
        seoRobotsNoIndex: null,
        seoMetadesc: null,
        seoTitle: null,
        headline: null,
    },
    faq: [],
    seo: defaultSeo,
};

export function faqPage(state: IFAQPageReducer = initialState, action): IFAQPageReducer {
    switch (action.type) {
        case actionTypes.loadFAQPageData:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
