import * as React from 'react';

import { RootStateOrAny, useSelector } from 'react-redux';

import { DesktopMail } from '../../Icons/DesktopMail';
import { __ } from '../../../helpers/TranslationService';
import { eventTracker } from '../../../helpers/EventTracker/EventTracker';
import { styles as parentStyles } from '../ShareArea/ShareArea';
import Link from '../../Link';

interface IProps {
    shareLink: string;
}

export const getLink = (url: string): string =>
    `mailto:?subject=${__('fav.share.emailSubject', 'dreamlines')}&body=${encodeURIComponent(
        __('fav.share.emailBody', 'dreamlines', url),
    )}`;

export function EmailShare({ shareLink }: IProps) {
    const { canonicalUrl } = useSelector((state: RootStateOrAny) => state.cruisePage);

    return (
        <Link
            href={getLink(shareLink)}
            style={parentStyles.circle}
            shouldTrack
            trackFunction={() =>
                eventTracker.track({
                    category: 'share function',
                    action: 'email',
                    label: canonicalUrl,
                })
            }
        >
            <DesktopMail style={parentStyles.icon} />
        </Link>
    );
}
