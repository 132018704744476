import * as React from 'react';
import styled from '@emotion/styled';
import { welcomePage } from '../../consts/pageTypes';
import { countriesPortalMapping } from '../../consts/welcomePage';
import { eventTracker } from '../../helpers/EventTracker/EventTracker';
import { validateEmail } from '../../helpers/validation';
import { Form } from './Form';
import { OnBoardCredit } from './OnBoardCredit';
import { SubmitError } from './SubmitError';
import { ThankYouMsg, WelcomePageThankYouMsg } from './ThankYouMsg';

interface IProps {
    newsletterHeadline: string;
    newsletterShowBadge: boolean;
    newsletterBadgeColor?: string;
    newsletterBadgeHeadline: string;
    newsletterBadgeSubline: string;
    signUp: Function;
    wrapperClassName?: string;
    isModal?: boolean;
    pageType?: string;
    contentEndpoint?: string;
    portalId?: string;
}

interface IState {
    input: string;
    country: string;
    harbour: string;
    promoter: string;
    countryIndex: string;
    harbourIndex: string;
    promoterIndex: string;
    isEmailValid: boolean;
    isCountryValid: boolean;
    isHarbourValid: boolean;
    isPromoterValid: boolean;
    isSuccessful: boolean;
    submitError: boolean;
    isLoading: boolean;
    isFormSubmittedOnce: boolean;
}

const ThankYouMessageWrapper = styled.div`
    margin: 0 15px;
`;
export class FormWrapper extends React.Component<IProps, IState> {
    constructor(props) {
        super(props);

        this.state = {
            input: '',
            country: '',
            harbour: '',
            promoter: '',
            countryIndex: '',
            harbourIndex: '',
            promoterIndex: '',
            isEmailValid: true,
            isCountryValid: true,
            isHarbourValid: true,
            isPromoterValid: true,
            isSuccessful: false,
            submitError: false,
            isLoading: false,
            isFormSubmittedOnce: false,
        };
    }

    handleChange = (field) => (ev) => {
        if (field === 'country') {
            this.setState({
                countryIndex: ev.value,
                country: ev.label,
                isCountryValid: this.validateField('country', ev.value),
            });
            return;
        }
        if (field === 'harbour') {
            this.setState({
                harbourIndex: ev.value,
                harbour: ev.label,
                isHarbourValid: this.validateField('harbour', ev.value),
            });
            return;
        }
        if (field === 'promoter') {
            this.setState({
                promoterIndex: ev.value,
                promoter: ev.label,
                isPromoterValid: this.validateField('promoter', ev.value),
            });
            return;
        }
        this.setState({
            input: ev.target.value,
            isEmailValid: this.validateField('email', ev.target.value),
        });
    };

    validateField = (field, value) => (field === 'email' ? validateEmail(value) : value !== '');

    formSubmitHandler = (ev) => {
        ev.preventDefault();
        const isEmailValid = this.validateField('email', this.state.input);
        if (isEmailValid) {
            this.setState({
                isLoading: true,
            });
            this.props.signUp(this.state.input, `${this.props.portalId} popup`).then(
                (response) => {
                    this.setState({
                        input: '',
                        isSuccessful: true,
                        submitError: false,
                        isLoading: false,
                    });
                    if (this.props.isModal) {
                        eventTracker.track({
                            category: 'newsletter',
                            action: 'layer',
                            label: this.props.pageType,
                        });
                    } else {
                        eventTracker.track({
                            category: 'newsletter',
                            action: 'signup-page',
                            label: 'click',
                        });
                    }
                },
                (error) => {
                    this.setState({
                        submitError: true,
                        isLoading: false,
                    });
                },
            );
        }
        this.setState({
            isEmailValid: isEmailValid,
            isFormSubmittedOnce: true,
        });
    };

    welcomePageFormSubmitHandler = (ev) => {
        ev.preventDefault();
        const isEmailValid = this.validateField('email', this.state.input);
        const isCountrySelected = this.validateField('country', this.state.countryIndex);
        const isHarbourSelected = this.validateField('harbour', this.state.harbourIndex);
        const isPromoterSelected = this.validateField('promoter', this.state.promoterIndex);

        if (isEmailValid && isCountrySelected && isHarbourSelected && isPromoterSelected) {
            this.setState({
                isLoading: true,
            });
            this.props
                .signUp(
                    this.state.input,
                    countriesPortalMapping[this.state.countryIndex],
                    this.state.harbour,
                )
                .then(
                    (response) => {
                        this.setState({
                            input: '',
                            isSuccessful: true,
                            submitError: false,
                            isLoading: false,
                        });
                        eventTracker.track({
                            category: 'Port Sign Up',
                            action: 'sign up',
                            label: `${this.state.country}|${this.state.harbour}|${this.state.promoter}`,
                        });
                    },
                    (error) => {
                        this.setState({
                            submitError: true,
                            isLoading: false,
                        });
                    },
                );
        }
        this.setState({
            isEmailValid: isEmailValid,
            isCountryValid: isCountrySelected,
            isHarbourValid: isHarbourSelected,
            isPromoterValid: isPromoterSelected,
            isFormSubmittedOnce: true,
        });
    };

    retryClickHandler = () => {
        this.setState({
            isEmailValid: true,
            isSuccessful: false,
            submitError: false,
            isLoading: false,
        });
    };

    render() {
        const headingClass = this.props.isModal ? 'modal__heading' : 'newsletter-form--heading';
        const isWelcomePage = Boolean(this.props.pageType === welcomePage);
        const submitHandler = isWelcomePage
            ? this.welcomePageFormSubmitHandler
            : this.formSubmitHandler;
        const portal = `${this.props.contentEndpoint}${
            countriesPortalMapping[this.state.countryIndex]
        }?utm_source=gph_signup&utm_medium=cta`;

        return (
            <div className={this.props.wrapperClassName}>
                {this.state.isSuccessful ? (
                    <ThankYouMessageWrapper>
                        {isWelcomePage ? (
                            <WelcomePageThankYouMsg {...{ portal }} />
                        ) : (
                            <ThankYouMsg />
                        )}
                    </ThankYouMessageWrapper>
                ) : this.state.submitError ? (
                    <SubmitError retryClickHandler={this.retryClickHandler} />
                ) : (
                    <>
                        <h3 className={headingClass}>{this.props.newsletterHeadline}</h3>
                        <Form
                            submitHandler={submitHandler}
                            handleChange={this.handleChange}
                            input={this.state.input}
                            countryIndex={this.state.countryIndex}
                            harbourIndex={this.state.harbourIndex}
                            promoterIndex={this.state.promoterIndex}
                            isLoading={this.state.isLoading}
                            isEmailValid={this.state.isEmailValid}
                            isCountryValid={this.state.isCountryValid}
                            isHarbourValid={this.state.isHarbourValid}
                            isPromoterValid={this.state.isPromoterValid}
                            pageType={this.props.pageType}
                            isFormSubmittedOnce={this.state.isFormSubmittedOnce}
                        />
                        {this.props.newsletterShowBadge && (
                            <OnBoardCredit
                                wrapperBg={this.props.newsletterBadgeColor}
                                amount={this.props.newsletterBadgeHeadline}
                                label={this.props.newsletterBadgeSubline}
                                placement={this.props.isModal ? 'topLeft' : ''}
                            />
                        )}
                    </>
                )}
            </div>
        );
    }
}
