import * as React from 'react';

interface Props {
    children: React.ReactNode;
    split: boolean;
    contentLayer: boolean;
}

export const ModalBody = ({ children, split, contentLayer }: Props) => {
    return (
        <div
            className={`modal__body${split ? ' split' : ''}${contentLayer ? ' content-layer' : ''}`}
        >
            {children}
        </div>
    );
};
