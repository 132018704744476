import * as Sentry from '@sentry/react';
import { readCookie, writeCookie } from '../cookie';
import { sha256 } from '../crypto';
import { get } from '../http';

interface ICartItem {
    item: number;
    quantity: number;
    price: number;
}

export class EmarsysTracker {
    private readonly HERM_ID: string = '_herm_uid';
    private readonly EMARSYS_CID: string = '_emarsys_cid';
    private readonly COOKIE_MAX_AGE: number = 365;
    private eventsQueue: any[] = [];
    private queue: any[];

    constructor() {
        this.queue = typeof window !== 'undefined' ? (window as any).ScarabQueue : [];
    }

    trackView(itemId: number) {
        this.eventsQueue.push(['view', `${itemId}`]);
    }

    trackAddToCart(cartItemList: ICartItem[]) {
        this.eventsQueue.push(['cart', cartItemList]);
        this.pushEmarsysEvents();
    }

    trackTags(tagName: string, tagObject: Record<string, any>) {
        this.eventsQueue.push(['tag', tagName, tagObject]);
    }

    trackNonSearchPage(url: string, pathName?: string): void {
        if (pathName) {
            this.eventsQueue.push(['category', pathName]);
        } else {
            if (url.charAt(0) === '/') {
                url = url.substring(1);
            }
            url = url.replace(/-/g, '_');
            const category: string = url.replace(/\//g, '>');
            this.eventsQueue.push(['category', category]);
        }
    }

    // User identification
    async identifyCustomer(apiHost: string, email?: string) {
        const hermId = this.getCustomerHermId();
        const customerId = this.getCustomerEmarsysId();

        if (email) {
            const hashedEmail = await this.getHashedEmail(email);
            this.setCustomerId(hashedEmail);
            this.setEmarsysCustomerIdCookie(hashedEmail);
            this.pushEmarsysEvents();
        } else {
            if (hermId && !customerId) {
                const url: string = `${apiHost}/person/getEmailHashByHermesId/${hermId}`;
                get(url)
                    .then((response) => {
                        const hashedEmail = response.payload.emailHash;
                        this.setCustomerId(hashedEmail);
                        this.setEmarsysCustomerIdCookie(hashedEmail);
                        this.pushEmarsysEvents();
                    })
                    .catch((error) => {
                        Sentry.captureException(error, {
                            extra: { customErr: 'Error by getting hashedEmail' },
                        });
                    });
            } else {
                this.pushEmarsysEvents();
            }
        }
    }

    trackSearchPage(store: any) {
        const trackingString: string = this.buildTrackingCategoryString(store);
        if (trackingString && this.queue) {
            this.queue.push(['category', trackingString.toLowerCase()]);
            this.queue.push(['go']);
        }
    }

    /**
     * Sends the tracking events to Emarsys
     */
    pushEmarsysEvents(): void {
        if (this.eventsQueue.length > 0 && this.queue) {
            this.eventsQueue.forEach((event) => this.queue.push(event));
            this.eventsQueue = [];
            this.queue.push(['go']);
        }
    }

    formatSearchFilter(filterString: string): string {
        filterString = filterString.replace(/\((.*?)\)/gm, '');
        return filterString.trim().replace(/ /g, '_').toLowerCase();
    }

    private buildTrackingCategoryString(store) {
        try {
            const search = store.search;
            const companies = search.searchFacets.companyNids;
            const zones = search.searchFacets.zoneNids;
            const ships = search.searchFacets.shipNids;

            const company = Number(this.getCompany(search));
            const zone = Number(this.getZone(search));
            const ship = Number(this.getShip(search));

            const cruiseLine = company
                ? companies.filter((x) => x.id === company).reduce((x) => x).title
                : '';
            const destination = zone
                ? zones.filter((x) => x.id === zone).reduce((x) => x).title
                : '';
            const shipName = ship ? ships.filter((x) => x.id === ship).reduce((x) => x).title : '';
            const trackingString = [destination, cruiseLine, shipName]
                .filter((x) => x !== '')
                .map((x) => this.formatSearchFilter(x))
                .join('>');

            return trackingString;
        } catch (error) {
            Sentry.captureException(error, {
                extra: { customErr: 'Error tracking Emarsys category' },
            });
        }
    }

    private getElement(array) {
        if (Array.isArray(array)) {
            return array.shift();
        } else {
            return;
        }
    }

    private getShip(search) {
        const ship = search.searchParams.filterParams?.ship;
        return this.getElement(ship);
    }

    private getCompany(search) {
        const company = search.searchParams.filterParams?.company;
        return this.getElement(company);
    }

    private getZone(search) {
        const zone = search.searchParams.filterParams?.zone;
        return this.getElement(zone);
    }

    private setEmarsysCustomerIdCookie(hashedEmail: string) {
        writeCookie(this.EMARSYS_CID, hashedEmail, this.COOKIE_MAX_AGE);
    }

    setCustomerId(hashedEmail: string) {
        this.eventsQueue.push(['setCustomerId', hashedEmail]);
    }

    private async getHashedEmail(email: string) {
        const shaObj = await sha256(email);
        return shaObj;
    }

    private getCustomerHermId() {
        return readCookie(this.HERM_ID);
    }

    private getCustomerEmarsysId() {
        return readCookie(this.EMARSYS_CID);
    }
}

export const emarsysTracker = new EmarsysTracker();
