import * as React from 'react';
import { config } from '../../config';
import styled from '@emotion/styled';
import { Link } from '../Link';
import { clearFilterParams } from '../../actions/searchActions';
import { BreakpointsEnum } from '../../helpers/breakpoints';
import { MarketContext } from '../../context/markets';

const StyledWrapper = styled.div`
    padding-right: 10px;
`;

const StyledLink = styled(Link)`
    display: inline-block;
    max-width: 195px;
    height: 42px;
`;

const StyledImg = styled.img<{ isC1st: boolean }>`
    height: 42px;
    display: block;
    max-width: 100%;

    @media (min-width: ${BreakpointsEnum.sm}) {
        max-height: ${({ isC1st }) => (isC1st ? '60px' : '50px')};
        height: unset !important;
    }
`;

interface IProps {
    clearFilterParams: typeof clearFilterParams;
    portalId: string;
    forceReload?: boolean;
    isMobile?: boolean;
}

export const Logo = (props: IProps) => {
    const { markets } = React.useContext(MarketContext);
    const { isCruise1st } = markets;

    const clearFilterParams = () => {
        props.clearFilterParams(false);
    };
    const logoImage = props.isMobile ? 'logo_mobile.svg' : 'logo.svg';
    return (
        <StyledWrapper>
            <StyledLink href="/" onClick={clearFilterParams} forceReload={props.forceReload}>
                <StyledImg
                    isC1st={isCruise1st}
                    src={`${config.images}/${props.portalId}/${logoImage}`}
                    alt="Dreamlines logo"
                    title="Dreamlines"
                />
            </StyledLink>
        </StyledWrapper>
    );
};
