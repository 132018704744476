import * as React from 'react';
import { changeRouteSuccess, setLocationInState } from './routerActions';
import { newSearch } from './searchActions';
import { __ } from '../helpers/TranslationService';
import * as actionTypes from '../consts/actionTypes';
import { searchResultPage } from '../consts/pageTypes';
import { ISeo } from '../interfaces/ISeo';
import { defaultSeo } from '../reducers/pageData';

import loadable from '@loadable/component';

export const searchRouteAction = (context) => {
    const { path, params, baseUrl, waitForAction, search, action } = context;
    const location = { search, pathname: path };

    context.store.dispatch({ type: actionTypes.toggleSearchLoader, payload: true });

    const defaultActions = (seo: ISeo = defaultSeo) => {
        context.store.dispatch(
            changeRouteSuccess({
                path,
                params,
                baseUrl,
                action,
                pageType: searchResultPage,
                seo: seo,
            }),
        );
        // This is only being set in search action, but that seems to be harmless at the moment...
        context.store.dispatch(setLocationInState({ location }));

        const SearchPage = loadable(() => import('../pages/Search/SearchPage'));
        context.store.dispatch({ type: actionTypes.toggleSearchLoader, payload: false });

        return { component: <SearchPage />, context };
    };

    if (waitForAction) {
        const state = context.store.getState();

        const { brandConfig, config } = state.pageData;
        const { contentEndpoint } = config;
        const { slugsObj } = brandConfig;

        const searchSlug = slugsObj.searchresults || '';

        const seo = {
            ...defaultSeo,
            canonical: `${contentEndpoint}/${searchSlug}`,
            robotsNoIndex: true,
            robotsNoFollow: false,
        };

        seo.titleOg = __('Dreamlines search', 'dreamlines');
        // only trigger new search if there are no search results in app state so that we don't lose additional search results from endless scrolling when user hits 'back' button on cruise page
        if (
            state.search &&
            state.search.searchResults &&
            state.search.searchResults.cruises &&
            state.search.searchResults.cruises.length > 0 &&
            search === state.search.queryString
        ) {
            return defaultActions(seo);
        } else {
            return context.store.dispatch(newSearch(search)).then((_) => defaultActions(seo));
        }
    } else {
        return defaultActions(context.store.getState().pageData.seo);
    }
};
