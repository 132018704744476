import React from 'react';
import { changeRouteSuccess } from './routerActions';
import { frontPage } from '../consts/pageTypes';
import { defaultSeo } from '../reducers/pageData';
import { IsomorphicHelpers } from '../helpers/IsomorphicHelpers';
import { setSeo } from './rootActions';
import { C1AU_DOMAIN, C1UK_DOMAIN } from '../context/markets';
import loadable from '@loadable/component';

const getCanonicalFromServerSide = (req) => {
    return `https://${req.get('host')}`;
};

export const homePageRouteAction = (context) => {
    const { store } = context;
    const {
        homePage: { homePage },
        pageData,
    } = store.getState();

    const { path, params, baseUrl, action } = context;
    const { appConfig } = pageData;
    const cruise1stMarketPortalIds = [C1AU_DOMAIN, C1UK_DOMAIN];
    const isCruise1stMarket = cruise1stMarketPortalIds.includes(appConfig.portalId);

    const seo = defaultSeo;
    seo.titleOg = homePage.seo?.titleOg;
    seo.canonical = `${
        IsomorphicHelpers.isServerSide
            ? getCanonicalFromServerSide(context.response.req)
            : window.location.origin
    }`;
    seo.metadesc = homePage.seo?.metadesc;
    context.store.dispatch(setSeo(seo));
    context.store.dispatch(
        changeRouteSuccess({
            path,
            params,
            baseUrl,
            action,
            pageType: frontPage,
            seo,
        }),
    );
    const HomePage = loadable(() => import('../pages/Home/HomePage'));
    const HomePageCruise1st = loadable(() => import('../pages/Home/HomePageCruise1st'));

    return {
        component: isCruise1stMarket ? <HomePageCruise1st /> : <HomePage />,
        context,
    };
};
