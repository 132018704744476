//https://stackoverflow.com/questions/26084733/convert-multidimensional-object-to-query-string
/**
 * Transforms objects recursively in a query string.
 * Each subObject is represented as appending key
 *
 * @example
 * {
 *  filterParams: {
 *      approved: true
 *  },
 *  page: {
 *      limit: 5
 * }
 * }
 * gets translated into:
 * filterParams[approved]=true&page[limit]=5
 */

export const toQueryString = (obj: any, prefix: string): string => {
    let str = [],
        k,
        v;
    for (let p in obj) {
        if (!Object.prototype.hasOwnProperty.call(obj, p)) {
            continue;
        }
        if (~p.indexOf('[')) {
            k = prefix
                ? prefix + '[' + p.substring(0, p.indexOf('[')) + ']' + p.substring(p.indexOf('['))
                : p;
        } else {
            k = prefix ? prefix + '[' + p + ']' : p;
        }
        v = obj[p];
        str.push(
            typeof v === 'object'
                ? toQueryString(v, k)
                : encodeURIComponent(k) + '=' + encodeURIComponent(v),
        );
    }
    return str.join('&');
};
