import { IRateBulk } from '../interfaces';
import { IMobileSearchResultCruise, IMobileSearchResultSail } from '../interfaces/ISearchApi';
import { ISearchReducer } from './search';

/**
 * Concat rates and update prices (cruises, priorityCruise).
 * Displayed pp price on product page includes discount and service charges.
 * @param state
 * @param rates
 * @returns
 */
export function updateRatesBulk(state: ISearchReducer, rates: IRateBulk[]): ISearchReducer {
    if (!rates?.length) {
        return state;
    }

    const sailHash = (cruiseId: number, sailId: number) => `${cruiseId}${sailId}`;
    const sailRateHash = (r: IRateBulk) => `${sailHash(r.cruise_id, r.sail_nid)}${r.cabintype_id}`;

    const stateRatesHashMap = state.searchRates.reduce((map: any, rate: IRateBulk) => {
        map[sailRateHash(rate)] = rate;
        return map;
    }, {});

    const searchRates = state.searchRates.concat(
        rates.filter((rate: IRateBulk) => rate && !stateRatesHashMap[sailRateHash(rate)]),
    );

    const minRatesHashMap = searchRates.reduce((map: any, rate: IRateBulk) => {
        const key = sailHash(rate.cruise_id, rate.sail_nid);
        const prev = map[key];

        if (!(prev?.perPersonPrice < rate.perPersonPrice) && rate.perPersonPrice > 0) {
            map[key] = rate;
        }
        return map;
    }, {});

    // fareservice api will return lowest price for each cabin id
    // if the new price we got is different from initial one we will update it
    const updateSailRate = (cruise: IMobileSearchResultCruise, sail: IMobileSearchResultSail) => {
        const cruiseId = cruise?.cruiseNid;
        const sailId = sail?.sailNid;
        const rate: IRateBulk = minRatesHashMap[sailHash(cruiseId, sailId)];
        if (rate) {
            const { perPersonPrice, discountPercentage } = rate;
            if (perPersonPrice > 0 && sail.price !== perPersonPrice) {
                sail.price = perPersonPrice;
            }
            if (discountPercentage > 0 && sail.discount !== discountPercentage) {
                sail.discount = discountPercentage;
            }
        }
        return sail;
    };

    const mapCruiseRate = (cruise: IMobileSearchResultCruise) => {
        return (
            cruise && {
                ...cruise,
                cheapestSail: updateSailRate(cruise, cruise.cheapestSail),
                discountSail: updateSailRate(cruise, cruise.discountSail),
            }
        );
    };

    const cruises = (state.searchResults?.cruises ?? []).map((cruise) => mapCruiseRate(cruise));
    const priorityCruise = mapCruiseRate(state.searchResults?.priorityCruise);

    return {
        ...state,
        searchResults: {
            ...state.searchResults,
            cruises,
            priorityCruise,
        },
        searchRates,
    };
}
