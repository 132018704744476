import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function CompassIcon(props: IIcon) {
    const newViewProps = '0 0 512 512' ?? props.viewBox;

    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
    <g>
	<path d="M254.7,4.4C115.6,4.4,2.5,117.5,2.5,256.6c0,0.1,0,0.2,0,0.3c0,0.1,0,0.2,0,0.3c0,0.2,0,0.4,0,0.6
		C3.2,396.3,116,508.7,254.7,508.7c139,0,252.2-113.1,252.2-252.2C506.8,117.5,393.7,4.4,254.7,4.4z M273,475v-27.2
		c0-9.8-7.9-17.7-17.7-17.7s-17.7,7.9-17.7,17.7v27.3C130.8,466.8,45.2,381.6,36.3,275h28.5c9.8,0,17.7-7.9,17.7-17.7
		c0-9.8-7.9-17.7-17.7-17.7H36.2c8.3-107.3,94.1-193.1,201.4-201.4v28.8c0,9.8,7.9,17.7,17.7,17.7S273,76.6,273,66.8V38.2
		c106.7,8.9,191.9,94.5,200.1,201.3h-28.6c-9.8,0-17.7,7.9-17.7,17.7c0,9.8,7.9,17.7,17.7,17.7H473C464.2,381.2,379.2,466.1,273,475
		z"/>
	<path d="M383.1,123.1c-2.7-1.3-5.8-1.3-8.6,0l-173.9,82c-2.2,1-4,2.9-5,5.1l-74.6,166.5c-2.3,5.2,0,11.3,5.2,13.6
		c2.6,1.2,5.7,1.2,8.3,0l166.5-74.6c2.2-1,4-2.8,5.1-5l82-173.9C390.5,131.5,388.3,125.4,383.1,123.1z M257.8,279.6
		c-14,0-25.4-11.4-25.4-25.4c0-14,11.4-25.4,25.4-25.4c14,0,25.4,11.4,25.4,25.4C283.2,268.3,271.8,279.6,257.8,279.6z"/>
    </g>
        </Icon>
    );
}
