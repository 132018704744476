import * as React from 'react';
import * as Color from '../consts/variables';

type IType = 'ribbon' | 'arrow';
type ISide = 'left' | 'right';

interface IProps {
    discount: number;
    wrapperStyle?: React.CSSProperties;
    innerStyle?: React.CSSProperties;
    type?: IType;
    side?: ISide;
}
interface IShapeStyle {
    styleUp: React.CSSProperties;
    styleDown: React.CSSProperties;
}

const styles: Record<string, React.CSSProperties> = {
    wrapper: {
        background: Color.pink,
        position: 'relative',
        height: '100%',
    },
    discount: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        color: Color.white,
        fontSize: 12,
        padding: '5px',
        zIndex: 2,
        fontWeight: 'bold',
    },
    discountUp: {
        position: 'absolute',
        background: Color.pink,
        width: '100%',
        height: '50%',
        top: '0',
    },
    discountDown: {
        position: 'absolute',
        background: Color.pink,
        width: '100%',
        height: '50%',
        bottom: '0',
    },
};

export function Discount(props: IProps) {
    const discount = props.discount > 75 ? 75 : props.discount;
    const type = props.type ?? 'arrow';
    const side = props.side ?? 'right';
    const discountStyle = { ...styles.discount, ...props.innerStyle };
    const wrapperStyle = {
        ...styles.wrapper,
        ...props.wrapperStyle,
        ...(side === 'right' ? { marginRight: '15px' } : { marginLeft: '15px' }),
    };

    const { styleUp, styleDown } = getShapeStyle(type, side);

    return (
        <div style={wrapperStyle}>
            <span style={styleUp} />
            <span style={styleDown} />

            <span style={discountStyle}>
                <span>{`-${discount}%`}</span>
            </span>
        </div>
    );
}

const getShapeStyle = (type: IType, side: ISide): IShapeStyle => {
    const { styleUp, styleDown } = type === 'ribbon' ? getRibbonStyle(side) : getArrowStyle(side);
    return {
        styleUp: { ...styles.discountUp, ...styleUp },
        styleDown: { ...styles.discountDown, ...styleDown },
    };
};

const getRibbonStyle = (side: ISide): IShapeStyle =>
    side === 'left'
        ? {
              styleUp: { bottom: '1px', right: '7px', transform: 'skewX(30deg)' },
              styleDown: { bottom: '1px', right: '7px', transform: 'skewX(-30deg)' },
          }
        : {
              styleUp: { left: '7px', transform: 'skewX(-30deg)' },
              styleDown: { left: '7px', transform: 'skewX(30deg)' },
          };

const getArrowStyle = (side: ISide): IShapeStyle =>
    side === 'left'
        ? {
              styleUp: { right: '7px', transform: 'skewX(-31deg)' },
              styleDown: { right: '7px', transform: 'skewX(31deg)' },
          }
        : {
              styleUp: { left: '7px', transform: 'skewX(30deg)' },
              styleDown: { left: '7px', transform: 'skewX(-30deg)' },
          };
