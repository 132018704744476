import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function DiscountIcon(props: IIcon) {
    const newViewProps = '0 0 640 665.589' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <g>
                <path
                    d="M276.192,258.416c25.675-27.845,39.827-65.213,39.827-105.189C316.019,77.088,261.623,0,157.662,0
		C109.284,0,68.474,16.61,39.624,48.068C14.078,75.923,0,113.249,0,153.226c0,76.148,54.15,153.226,157.662,153.226
		C206.232,306.452,247.225,289.841,276.192,258.416L276.192,258.416z M157.662,71.252c56.972,0,82.914,42.521,82.914,82.027
		c0,21.485-7.236,41.377-20.373,56.021c-14.676,16.333-35.979,24.97-61.622,24.97c-58.404,0-84.998-42.521-84.998-82.017
		C73.583,111.935,99.578,71.252,157.662,71.252L157.662,71.252z M157.662,71.252"
                />
                <path
                    d="M535.783,59.11c-9.427,0.16-22.297,6.039-28.604,13.051L39.496,592.135
		c-6.307,7.033-1.839,16.867,7.578,16.867h61.216c9.417,0,22.286-5.751,28.571-12.784L601.669,76.33
		c6.264-7.022,5.879-18.438-9.705-18.438L535.783,59.11z M535.783,59.11"
                />
                <path
                    d="M484.647,364.953c-47.438,0-87.499,16.311-115.782,47.159C343.789,439.412,330,476.053,330,515.26
		c0,74.716,53.146,150.329,154.647,150.329c47.651,0,87.863-16.312,116.296-47.139C626.126,591.152,640,554.489,640,515.26
		C640,440.566,586.641,364.953,484.647,364.953L484.647,364.953z M485.587,596.603c-56.844,0-82.711-42.67-82.711-82.305
		c0-21.592,7.205-41.537,20.331-56.224c14.644-16.397,35.915-25.056,61.439-25.056c56.844,0,82.711,42.692,82.711,82.326
		c0,21.55-7.204,41.538-20.352,56.225C532.363,587.923,511.113,596.603,485.587,596.603L485.587,596.603z M485.587,596.603"
                />
            </g>
        </Icon>
    );
}
