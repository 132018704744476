import * as actionTypes from '../consts/actionTypes';
import { IZoneTab } from '../interfaces/IZoneTabs';
import { IBreadcrumb } from '../interfaces';
import { IPromoBanner } from './landingPage';

export interface IMediaOfferPageReducer {
    url: string;
    title: string;
    headline: string;
    seo: ISeo;
    seoTextBottom: string;
    headerImage: string;
    headerTitle: string;
    headerDescription: string;
    headerYoutubeMediaUrl: string;
    zoneTabs: IZoneTab[] | null;
    contactForm: string;
    breadcrumbs: IBreadcrumb[];
    promoBanner?: IPromoBanner | null;
}

export const initialState: IMediaOfferPageReducer = {
    url: null,
    title: null,
    headline: null,
    seo: {
        text: null,
        titleOg: null,
        robotsNoFollow: null,
        robotsNoIndex: null,
        metadesc: null,
    },
    seoTextBottom: null,
    headerImage: null,
    headerTitle: null,
    headerDescription: null,
    headerYoutubeMediaUrl: null,
    zoneTabs: null,
    contactForm: null,
    breadcrumbs: [],
    promoBanner: null,
};

export interface ISeo {
    text: string;
    titleOg: string;
    robotsNoFollow: boolean;
    robotsNoIndex: boolean;
    metadesc: string;
}

export function mediaOfferPage(
    state: IMediaOfferPageReducer = initialState,
    action,
): IMediaOfferPageReducer {
    switch (action.type) {
        case actionTypes.loadMediaOfferPageData:
            const mediaOfferPage = action.payload;
            return { ...state, ...mediaOfferPage };
        default:
            return state;
    }
}
