import * as actionTypes from '../consts/actionTypes';
import { ICMSData } from '../interfaces/ICMSData';

export interface IContactPageReducer {
    cmsData: ICMSData;
    advisors: IAdvisor[];
}

export interface IAdvisor {
    id: number;
    name: string;
    email: string;
    phone: string;
    image: string;
    whitebgImage: string;
    companies: {
        count: number;
        id: number;
        title: string;
        short_title: string;
    }[];
    languages: string[];
}

export const initialState: IContactPageReducer = {
    cmsData: {
        pageId: null,
        uri: null,
        title: null,
        seoRobotsNofollow: null,
        seoRobotsNoIndex: null,
        seoMetadesc: null,
        seoTitle: null,
    },
    advisors: [],
};

export function contactPage(
    state: IContactPageReducer = initialState,
    action,
): IContactPageReducer {
    switch (action.type) {
        case actionTypes.loadContactPageData:
            const { cmsData, advisors } = action.payload;
            return { ...state, cmsData, advisors };
        default:
            return state;
    }
}
