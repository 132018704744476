import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function InfoNoFillIcon({ width, height, viewBox, style, className, rectFill }: IIcon) {
    const newViewProps = '0 0 24 25' ?? viewBox;
    return (
        <Icon
            width={width}
            height={height}
            viewBox={newViewProps}
            style={style}
            className={className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M11.0837 5.45714C12.4526 5.45714 13.5622 4.34745 13.5622 2.97857C13.5622 1.60969 12.4526 0.5 11.0837 0.5C9.7148 0.5 8.60511 1.60969 8.60511 2.97857C8.60511 4.34745 9.7148 5.45714 11.0837 5.45714ZM6.72 23.2663C6.72 22.4468 7.36135 21.8054 8.18086 21.8054H11.5301V10.4036H8.18086C7.36135 10.4036 6.72 9.76226 6.72 8.94275C6.72 8.12325 7.36135 7.48189 8.18086 7.48189H12.9554C13.7749 7.48189 14.4162 8.12325 14.4162 8.94275V21.8054H17.7655C18.585 21.8054 19.2264 22.4468 19.2264 23.2663C19.2264 24.0858 18.585 24.7272 17.7655 24.7272H8.18086C7.36135 24.7272 6.72 24.0858 6.72 23.2663Z"
            />
        </Icon>
    );
}
