declare var window;

export const updatePageTitle = (title: string) => {
    document.getElementsByTagName('title')[0].innerHTML = title;
};

export const getUtilityDataScript = ({ pageType }) => {
    const utilityData = {} as any;
    utilityData.pageType = pageType;
    return `var utilityData = ${JSON.stringify(utilityData)}`;
};

export const updatePageSpecificData = data => {
    const { pageTitle, pageType } = data;
    document.title = pageTitle;
    window.utilityData = { pageType };
};

export const getPageTypeFromWindow = () => {
    if (typeof window !== 'undefined' && window.utilityData && window.utilityData.pageType) {
        return window.utilityData.pageType;
    }
    return '';
};
