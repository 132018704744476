import * as React from 'react';
import { Icon } from '../Icon';
import { IFillableIcon } from '../../interfaces/IIcon';

export function EnvelopeIcon(props: IFillableIcon) {
    const newViewProps = '0 0 32 32' ?? props.viewBox;
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={newViewProps}
            style={props.style}
            className={props.className}
        >
            <g>
                {props.hasFill && <rect rx="40" ry="40" x="50" y="120" width="430" height="310" />}
                <path d="M16 18.718l-14.555-12.78h29.117l-14.562 12.78z"></path>
                <path d="M10.2 15.954l-10.2 8.953v-17.913l10.2 8.96z"></path>
                <path d="M11.527 17.115l4.473 3.932 4.473-3.932 10.088 8.861h-29.13l10.095-8.861z"></path>
                <path d="M21.8 15.954l10.2-8.96v17.92l-10.2-8.96z"></path>
            </g>
        </Icon>
    );
}
