import * as actionTypes from '../consts/actionTypes';
import { IBreadcrumb } from '../interfaces';
import { IMobileSearchResultCruise } from '../interfaces/ISearchApi';
import { ICruiseOffers } from '../interfaces/IUGCReviews';

export interface ISeo {
    text: string;
    titleOg: string;
    robotsNoFollow: boolean;
    robotsNoIndex: boolean;
    metadesc: string;
}

export interface IPromoBanner {
    title: string;
    description: string;
    subTitle?: string;
    imageUrl?: string;
    button?: {
        text?: string;
        url?: string;
    };
}

export interface ILandingPageReducer {
    seo: ISeo;
    url: string;
    title: string;
    discount: number;
    showDiscount?: boolean;
    searchParameters: {};
    searchParametersString: string;
    headerImage: string;
    headerTitle: string;
    headerDescription: string;
    breadcrumbs: IBreadcrumb[];
    reviews: any;
    areReviewsLoading: boolean;
    offers: ICruiseOffers;
    searchResult: {
        cruises?: IMobileSearchResultCruise[];
        numResults?: number;
        priorityCruise?: IMobileSearchResultCruise;
        maxPrice?: number;
        maxDiscount?: number;
        maxNights?: number;
    };
    promoBanner?: IPromoBanner | null;
}

export const initialState: ILandingPageReducer = {
    seo: {
        text: null,
        titleOg: null,
        robotsNoFollow: null,
        robotsNoIndex: null,
        metadesc: null,
    },
    url: '',
    title: '',
    discount: 0,
    showDiscount: false,
    searchParameters: {},
    searchParametersString: '',
    headerImage: '',
    headerTitle: '',
    headerDescription: '',
    breadcrumbs: [],
    reviews: null,
    areReviewsLoading: false,
    offers: null,
    searchResult: {},
    promoBanner: null,
};

export function landingPage(
    state: ILandingPageReducer = initialState,
    action,
): ILandingPageReducer {
    switch (action.type) {
        case actionTypes.loadLandingPageData:
            const content = action.payload;
            return { ...state, ...content };
        default:
            return state;
    }
}
