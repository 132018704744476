import * as React from 'react';
import { currencyMap } from '../helpers/priceHelpers';
import { __, numberFormatter } from '../helpers/TranslationService';
import { IRate } from '../interfaces';
import { CabinTypeIdsMapper } from './SearchFilters/CabinFilter';

export const getCurrencyString = (price: number, currency: string) => {
    const formattedPrice = numberFormatter().format(parseInt((price as any) as string, 10));

    switch (currency) {
        case 'EUR':
            return `${formattedPrice} ${currencyMap.EUR}`;
        case 'AUD':
            return `${currencyMap.AUD}${formattedPrice}`;
        case 'USD':
            return `${currencyMap.USD}${formattedPrice}`;
        case 'GBP':
            return `${currencyMap.GBP}${formattedPrice}`;
        default:
            return `${formattedPrice} ${currencyMap.EUR}`;
    }
};

interface IPriceString {
    price: number;
    countryCode?: string;
    currency?: string;
    prefix?: string;
    discount?: number;
    discountThreshold?: number;
}

export function PriceString({ price, currency, prefix = null }: IPriceString) {
    return (
        <span>
            {prefix ? `${prefix} ` : null}
            {getCurrencyString(Math.floor(price), currency)}
        </span>
    );
}

export const getAllInPriceFormatted = (allInPrice: number, currency: string) => {
    if (!allInPrice || !currency) return '';
    const formattedPrice = `${__('from', 'dreamlines')} ${getCurrencyString(
        allInPrice,
        currency,
    )} ${__('p.P.', 'dreamlines')}`;
    return `${formattedPrice} ${__('All-inclusive', 'dreamlines')}`;
};

export const getCabinAllInPriceByRates = (rates: IRate[], cabinKind: string) => {
    const allInPrices = rates
        ?.reduce((allInPrices, item) => {
            if (
                item?.all_in_price?.per_person_price &&
                item.cabinkind_code === CabinTypeIdsMapper[cabinKind]
            ) {
                allInPrices.push(item.all_in_price.per_person_price);
            }
            return allInPrices;
        }, [])
        .sort((a, b) => a - b);
    return allInPrices[0] || 0;
};
