import { getCurrentActiveFeatureNames } from '@paralleldrive/feature-toggles';
import * as actionTypes from '../consts/actionTypes';
import initialFeatures from '../feature-toggles';
import { ISeo } from '../interfaces/ISeo';

export interface IRootReducer {
    features: readonly string[] | null;
    seo: ISeo | null;
}

export const initialState: IRootReducer = {
    features: null,
    seo: null,
};

export function root(state = initialState, action): IRootReducer {
    switch (action.type) {
        case actionTypes.setFeatures:
            return { ...state, features: action.payload };
        case actionTypes.setSeo:
            return { ...state, seo: action.payload };
        default:
            return state;
    }
}

export const findFeatures = (url: string, brand: string, stateFeatures?: string) => {
    const urlToggles = new URLSearchParams(url).get('ft') || '';
    const storageToggles =
        typeof localStorage !== 'undefined' ? localStorage.getItem('feature-toggles') || '' : '';
    let featuresArray = [...urlToggles.split(','), ...storageToggles.split(',')];
    if (stateFeatures) {
        featuresArray = [...featuresArray, ...stateFeatures.split(',')];
    }
    const ft = [...new Set(featuresArray)].join(',');

    return getCurrentActiveFeatureNames({
        initialFeatures: initialFeatures[brand] || initialFeatures['dreamlines.de'],
        req: {
            query: { ft },
        },
    });
};
