import * as React from 'react';
import { Icon } from '../Icon';
import { IFillableIcon } from '../../interfaces/IIcon';

export function AttentionIcon(props: IFillableIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox || '0 0 16 17'}
            style={props.style}
            className={props.className}
        >
            <path d="M15.6676 12.4734L10.0155 2.08354C9.10744 0.554754 6.89378 0.552723 5.98447 2.08354L0.332715 12.4734C-0.595597 14.0356 0.528309 16.0138 2.34778 16.0138H13.652C15.47 16.0138 16.5959 14.0372 15.6676 12.4734ZM8 14.1388C7.48318 14.1388 7.0625 13.7181 7.0625 13.2013C7.0625 12.6844 7.48318 12.2638 8 12.2638C8.51681 12.2638 8.9375 12.6844 8.9375 13.2013C8.9375 13.7181 8.51681 14.1388 8 14.1388ZM8.9375 10.3888C8.9375 10.9056 8.51681 11.3263 8 11.3263C7.48318 11.3263 7.0625 10.9056 7.0625 10.3888V5.70125C7.0625 5.18444 7.48318 4.76375 8 4.76375C8.51681 4.76375 8.9375 5.18444 8.9375 5.70125V10.3888Z" />
        </Icon>
    );
}
