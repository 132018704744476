import { Request, Response } from 'express';
import * as url from 'url';
import * as qs from 'qs';

export class IsomorphicHelpers {
    static request: Request;
    static response: Response;
    static isServerSide: boolean;
    static bootstrapTime: number;

    static setServerSide(request: Request, response: Response) {
        this.request = request;
        this.response = response;
        this.isServerSide = true;
    }
}

export const isClientSide = () =>
    !!(typeof window !== 'undefined' && window.document && window.document.createElement);

export const getQueryParams = () => {
    const currentUrl = IsomorphicHelpers.isServerSide
        ? IsomorphicHelpers.request.url
        : location.href;
    const queryString = url.parse(currentUrl).query;
    return qs.parse(queryString);
};

export const getReferrer = () => {
    const referral = IsomorphicHelpers.isServerSide
        ? IsomorphicHelpers.request.get('Referrer')
        : document.referrer;
    return referral;
};
