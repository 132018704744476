import * as React from 'react';
import { style } from './RecommendationSection';
import { PriceWithDiscount } from './PriceWithDiscount';
import { AnchorIcon } from '../../components/Icons/AnchorIcon';
import { ShipIcon } from '../../components/Icons/ShipIcon';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

const compStyles: any = {
    boldHeading: {
        fontWeight: 'bold',
        fontSize: 15,
    },
};

interface IProps {
    data: any[];
    discountThreshold: number;
}

const getIconStyles = (theme) => {
    return css`
        float: left;
        margin-right: 5px;
        fill: ${theme.icon.brandColor1};
        flex: 0 0 auto;
    `;
};

const ShipIconStyled = styled(ShipIcon)`
    ${({ theme }) => getIconStyles(theme)};
`;

const AnchorIconStyled = styled(AnchorIcon)`
    ${({ theme }) => getIconStyles(theme)};
`;

export function recommendationDescUnderImg(props: IProps) {
    return props.data.map((val, key) => {
        const child = (
            <div key={key}>
                <Card {...val} discountThreshold={props.discountThreshold} />
                <div style={style.shipListDescWrapper}>
                    <div dangerouslySetInnerHTML={{ __html: val.teaserDescription }} />
                </div>
            </div>
        );
        const teaserTitle = <h2 style={style.teaserTitle}>{val.title}</h2>;
        return {
            child: child,
            url: `${val.uri}`,
            teaserTitle: teaserTitle,
            imageSrc: val.teaserImage,
            imageAlt: val.title,
        };
    });
}

function Card(props) {
    const { harbours, shipCountString } = props;
    const cardInfo = harbours
        ? CardInfo(harbours, AnchorIconStyled, true)
        : CardInfo(shipCountString, ShipIconStyled, false);
    return (
        <div style={style.card}>
            {cardInfo}
            <PriceInfo {...props} />
        </div>
    );
}

function CardInfo(info: string, IconComp: React.SFC<any>, textbolded: boolean = false) {
    const textStyle: any = textbolded
        ? { ...style.teaserText, ...compStyles.boldHeading }
        : { ...style.teaserText, fontSize: 15 };
    return (
        <div style={style.cardInfo}>
            {info && (
                <div style={style.spacingBottom}>
                    <IconComp width={18} height={18} />
                    <div style={textStyle} dangerouslySetInnerHTML={{ __html: info }} />
                </div>
            )}
        </div>
    );
}

function PriceInfo(props) {
    const priceProps = {
        price: props.priceDualCurrency || props.price,
        currency: props.dualCurrency || props.currency,
        discount: props.discount,
        discountThreshold: props.discountThreshold,
    };
    return <PriceWithDiscount {...priceProps} />;
}
