import * as pageTypes from '../../consts/pageTypes';
import { ICustomData, ITrans } from '../../interfaces/IIadvize';
import { pageTypeMapper } from '../pageTypes';

interface IWindow extends Window {
    iAdvize: any;
    idzCustomData: ICustomData;
    idzTrans: ITrans;
}

declare var window: IWindow;

export class IadvizeTracker {
    init = (store: any) => {
        this.customData(store);
        this.transaction(store);
    };

    /**
     * Transaction Tag
     */
    transaction = (store: any) => {
        const { pageData, router, thankYouPage } = store;
        try {
            if (router.pageType === pageTypes.thankYouPage) {
                window.idzTrans = {
                    cartAmount: thankYouPage.queryParams.price,
                    tID: thankYouPage.bookingId,
                };
                if (window.iAdvize) {
                    if (!window.iAdvize.recordTransaction) {
                        Object.defineProperty(window.iAdvize, 'recordTransaction', {
                            set: function (x) {
                                if (x) {
                                    this._recordTransaction = x;
                                    window.iAdvize.recordTransaction();
                                }
                            },
                            get: function () {
                                return this._recordTransaction;
                            },
                        });
                    } else {
                        window.iAdvize.recordTransaction();
                    }
                }
            }
        } catch (err) {
            if (pageData.appConfig.environment === 'development') {
                console.log(err);
            }
        }
    };
    /**
     * CustomData Tag
     */
    customData = (store: any) => {
        const {
            pageData,
            router,
            cruisePage,
            shipData,
            favoritePage,
            requestForm,
            destinationDetailPage,
            companyDetailPage,
        } = store;
        var trackingData: ICustomData = {
            page_type: pageTypeMapper[router.pageType] || router.pageType,
            favorites: favoritePage.favorites.length,
            ship: '',
            cabin_type: '',
            cruise_line: '',
            destination: '',
            product_price: '',
        };
        try {
            if (router.pageType === pageTypes.destinationPage) {
                trackingData = {
                    product_price: '',
                    page_type: pageTypeMapper[router.pageType] || router.pageType,
                    favorites: favoritePage.favorites.length,
                    destination: destinationDetailPage.destinationName,
                    ship: '',
                    cabin_type: '',
                    cruise_line: '',
                };
            }
            if (router.pageType === pageTypes.shipDetailPage) {
                trackingData = {
                    page_type: pageTypeMapper[router.pageType] || router.pageType,
                    favorites: favoritePage.favorites.length,
                    ship: shipData.shipPage.shipTitle,
                    cabin_type: '',
                    cruise_line: '',
                    destination: '',
                    product_price: '',
                };
            }
            if (router.pageType === pageTypes.companyPage) {
                trackingData = {
                    page_type: pageTypeMapper[router.pageType] || router.pageType,
                    favorites: favoritePage.favorites.length,
                    cruise_line: companyDetailPage.companyName,
                    ship: '',
                    cabin_type: '',
                    destination: '',
                    product_price: '',
                };
            }
            if (router.pageType === pageTypes.cruisePage) {
                const cabin = cruisePage.cabins.find(
                    (cabin) => cabin.cabinType.nid === cruisePage.cheapestCabinNidForSail,
                );
                trackingData = {
                    page_type: pageTypeMapper[router.pageType] || router.pageType,
                    favorites: favoritePage.favorites.length,
                    ship: cruisePage.ship.title,
                    destination: cruisePage.zone,
                    cruise_line: cruisePage.company.title,
                    product_price: cruisePage.minPrice,
                    cabin_type: cabin ? cabin.cabinType.kindName : '',
                };
            }
            if (router.pageType === pageTypes.requestFormPage) {
                const cabinNid = requestForm.cabinNid
                    ? requestForm.cabinNid
                    : cruisePage.cheapestCabinNidForSail;
                const cabin = cruisePage.cabins.find(
                    (cabin) => cabin.cabinType && cabin.cabinType.nid === cabinNid,
                );
                trackingData = {
                    page_type: pageTypeMapper[router.pageType] || router.pageType,
                    favorites: favoritePage.favorites.length,
                    ship: cruisePage.ship.title,
                    destination: cruisePage.zone,
                    cruise_line: cruisePage.company.title,
                    product_price: cabin ? cabin.perPersonPrice : cruisePage.minPrice,
                    cabin_type: cabin ? cabin.cabinType.kindName : null,
                };
            }

            window.idzCustomData = trackingData;

            if (window.iAdvize) {
                if (!window.iAdvize.navigate) {
                    Object.defineProperty(window.iAdvize, 'navigate', {
                        set: function (x) {
                            if (x) {
                                this._navigate = x;
                                window.iAdvize.navigate(window.location.href);
                            }
                        },
                        get: function () {
                            return this._navigate;
                        },
                    });
                } else {
                    window.iAdvize.navigate(window.location.href);
                }
            }
        } catch (err) {
            if (pageData.appConfig.environment === 'development') {
                console.log(err);
            }
        }
    };
}

export const iadvizeTracker = new IadvizeTracker();
