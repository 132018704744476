import * as React from 'react';
import { __ } from '../helpers/TranslationService';
import { changeRouteSuccess } from './routerActions';
import { defaultSeo } from '../reducers/pageData';

import loadable from '@loadable/component';

export const startPageRouteAction = context => {
    const { path, params, baseUrl, action } = context;
    const seo = defaultSeo;
    seo.titleOg = __('Start Page', 'dreamlines');
    context.store.dispatch(
        changeRouteSuccess({
            path,
            params,
            baseUrl,
            action,
            seo: seo,
        }),
    );

    const StartPage = loadable(() => import('../pages/Start/StartPage'));
    return {
        component: <StartPage />,
        context,
    };
};
