import * as actionTypes from '../consts/actionTypes';
import { ICMSData } from '../interfaces/ICMSData';
import { IBreadcrumb } from '../interfaces';

export interface IShipOverview {
    isLoaded?: boolean;
    loading: boolean;
    content: IShipOverviewPageContent;
    companies: IShipOverviewPageMobileCompany[];
    breadcrumbs: IBreadcrumb[];
    ships: any[];
    facets: any;
    queryParams: any;
    numResults: number;
    url: string;
}

interface IShipOverviewPageContent extends ICMSData {
    bannerHeadline: string;
    bannerSubline: string;
    bannerImage: string;
    seoText: string;
}

export interface IShipOverviewPageMobileCompany {
    nid: number;
    title: string;
    logo: string;
    ships: IShipOverviewPageMobileShip[];
}

export interface IShipOverviewPageMobileShip {
    shipNid: number;
    title: string;
    maxPercentDiscount: number;
    minPrice: number;
    manufactureYear: number;
    passengers: number;
    numDecks: number;
    teaserImage: string;
    teaserDescription: string;
    url: string;
    length: number;
    category: number;
    currency: string;
}

const initialState = {
    isLoaded: true,
    loading: false,
    content: {
        pageId: 0,
        uri: '',
        title: '',
        seoRobotsNofollow: false,
        seoRobotsNoIndex: false,
        seoMetadesc: '',
        seoTitle: '',
        bannerHeadline: '',
        bannerSubline: '',
        bannerImage: '',
        seoText: '',
    },
    companies: [],
    breadcrumbs: [],
    ships: [],
    facets: {},
    queryParams: {},
    numResults: 0,
    url: '',
};

export function shipOverview(state: IShipOverview = initialState, action): IShipOverview {
    let newState, newQueryParams, newPageData;

    switch (action.type) {
        case actionTypes.loadShipOverviewPageData:
            return {
                ...state,
                content: action.payload.content,
                companies: action.payload.companies,
                breadcrumbs: action.payload.breadcrumbs,
                ships: action.payload.ships,
                facets: action.payload.facets,
                queryParams: action.payload.queryParams,
                numResults: action.payload.numResults,
                url: action.payload.url,
            };
        case actionTypes.updateShipOverviewPrices: {
            const companies = state.companies;
            const index = companies.findIndex(
                (company) => company.nid === action.payload.companyNid,
            );
            const ships = companies[index].ships.map((ship) => {
                action.payload.prices.forEach((price) => {
                    if (price.shipNid === ship.shipNid) {
                        ship.minPrice = price.minPrice;
                        ship.maxPercentDiscount = price.maxPercentDiscount;
                    }
                });
                return ship;
            });
            companies[index].ships = ships;
            return {
                ...state,
                companies: companies,
            };
        }
        case actionTypes.loadMorePending:
        case actionTypes.newSearchPending:
        case actionTypes.newSortRequestPending:
            return { ...state, isLoaded: false };

        case actionTypes.newSearchRejected:
        case actionTypes.loadMoreRejected:
            return { ...state, isLoaded: true };

        case actionTypes.loadMoreFulfilled:
            return {
                ...state,
                ...action.payload,
                ships: state.ships.concat(action.payload.ships),
                isLoaded: true,
            };
        case actionTypes.newSearchFulfilled:
        case actionTypes.newSortRequestFulfilled:
            newQueryParams = Object.assign({}, state.queryParams, { filterParams: action.meta });
            newPageData = Object.assign({}, state, {
                isLoaded: true,
                ships: action.payload.ships,
                facets: action.payload.facets,
                numResults: action.payload.numResults,
                queryParams: newQueryParams,
            });
            newState = Object.assign({}, state, newPageData);
            return newState;
        default:
            return state;
    }
}
