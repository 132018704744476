import * as React from 'react';
import { IIcon } from '../../interfaces/IIcon';
import { Icon } from '../Icon';

const style: React.CSSProperties = {
    fillRule: 'evenodd',
};

export function ArrowLeftIcon(props: IIcon) {
    return (
        <Icon
            dropShadow={props.dropShadow}
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <path
                id="arrow_left"
                style={style}
                data-name="arrow left"
                d="M346.81,501.77a25.33,25.33,0,0,0,35.81,0l17.9-17.9a25.32,25.32,0,0,0,0-35.81L214.05,261.58,400.53,75.13a25.34,25.34,0,0,0,0-35.82l-17.9-17.9a25.31,25.31,0,0,0-35.81,0L124.52,243.7a25.28,25.28,0,0,0,0,35.78Z"
            />
        </Icon>
    );
}
