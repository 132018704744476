import { writeCookie } from './cookie';

export function parseFavoriteCookiesToArray(favCookieString) {
    if (favCookieString) {
        return decodeURIComponent(favCookieString)
            .split(',')
            .filter(nid => nid.length > 0)
            .map(nid => parseInt(nid, 10));
    } else {
        return [];
    }
}

export function writeFavouriteCruiseNids(cruiseNids: number[]) {
    writeCookie('favorites', encodeURIComponent(cruiseNids.join(',')), 365);
}
