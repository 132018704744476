import * as React from 'react';
import { Link } from '../../Link';
import { styles as parentStyles } from '../ShareArea/ShareArea';

interface IProps {
    className?: string;
    children: JSX.Element;
    href: string;
    trackFuction?: () => any;
}

export function SocialIcon(props: IProps) {
    return (
        <Link
            className={props.className}
            href={props.href}
            target="_blank"
            style={parentStyles.circle}
            trackFunction={props.trackFuction}
        >
            {props.children}
        </Link>
    );
}
