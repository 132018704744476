import { ActiveTest, ActiveTestNames } from '../actions/abTestActions';
import { applyTestVariation } from '../consts/actionTypes';

export type IabTests = {
    [key in ActiveTestNames]: ActiveTest;
};

export const initialState = {} as IabTests;

export function abTests(state: IabTests = initialState, action): IabTests {
    switch (action.type) {
        case applyTestVariation:
            if (!action.payload.length) return state;

            const newState = action.payload.reduce((accumulator, currentValue) => {
                return { ...accumulator, [currentValue.name]: currentValue };
            }, {});

            return { ...state, ...newState };
        default:
            return state;
    }
}
