import { IState, reducers } from './reducers';
import { applyMiddleware, compose, createStore } from 'redux';

import promise from 'redux-promise-middleware';
import thunk from 'redux-thunk';
import { tracking } from './middleware/tracking';

/**
 * Configure store, applies middlewares and combine reducers for the application
 *
 * @param {Object} initialState - initial state for the store
 * @param {boolean} isBrowser - indicates running environment is browser (default false)
 * @returns {Object} store - created store
 */
const showReduxLogs = false; // only activate in local, revert before merging
export function configureStore(initialState: IState, isBrowser = false) {
    const isReduxLoggerEnabled =
        showReduxLogs && isBrowser && process.env.NODE_ENV === 'development';
    const middlewares = [promise, thunk];
    const middlewaresWithTracking = isBrowser ? [...middlewares, tracking] : middlewares;
    const middlewaresWithLogger = isReduxLoggerEnabled
        ? [...middlewaresWithTracking, require('redux-logger').default]
        : middlewaresWithTracking;
    const composedStoreWMiddleware = applyMiddleware(...middlewaresWithLogger)(createStore);
    // @TODO Fix initial State inconsistencies
    return compose(composedStoreWMiddleware)(reducers, initialState as any);
}
export type Store = ReturnType<typeof configureStore>;
