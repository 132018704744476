import { ISentryConfig, ISentryTags } from '../interfaces/ISentryConfig';

declare const window: any;

const configTags: ISentryTags = {
    component: 'MOBILE_FRONTEND',
    environment: window.appState.pageData.appConfig.environment,
    brand: window.appState.pageData.appConfig.portalId,
};

export const sentryConfigFrontend: ISentryConfig = {
    sentryDSN: window.appState.pageData.appConfig.sentryDSN,
    release: window.appState.pageData.appConfig.buildVersion,
    tags: configTags,
};
