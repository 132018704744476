import * as React from 'react';
import { Button } from '../Button/Button';
import { __ } from '../../helpers/TranslationService';
import { eventTracker } from '../../helpers/EventTracker/EventTracker';
import { MarketContext } from '../../context/markets';

export const ThankYouMsg = () => {
    const { markets } = React.useContext(MarketContext);
    const { isDreamlinesDE, isCruise1stUK } = markets;
    return (
        <>
            <h4
                className="newsletter-form--thankyou-msg"
                data-test-id="newsletterJoinUs-thankYouMsg"
            >
                {__('Thank you! Your registration was successful!', 'dreamlines')}
            </h4>
            <div className="newsletter-form--thankyou-sub-text">
                {__(
                    'you are just one step away from getting all top offers: Please go to your E-Mail provider and verify your E-Mail address.',
                    'dreamlines',
                )}
            </div>
            {(isDreamlinesDE || isCruise1stUK) && (
                <>
                    <div className="newsletter-form--thankyou-sub-text__bold">
                        {__('wantToGetCatalog', 'dreamlines')}
                    </div>
                    <Button as="a" href={__('brochureLink', 'dreamlines')} withArrow fullWidth>
                        {__('SignUpForFree', 'dreamlines')}
                    </Button>
                </>
            )}
        </>
    );
};

export const WelcomePageThankYouMsg = (props: { portal: string }) => {
    const onClick = () => {
        eventTracker.track({
            category: 'Port Sign Up',
            action: 'thank you page',
            label: props.portal,
        });
        window.location.href = props.portal;
    };

    return (
        <>
            <h4 className="newsletter-form--thankyou-msg">
                {__('Thank you! Your registration was successful!', 'dreamlines')}
            </h4>
            <div className="newsletter-form--thankyou-sub-text">
                {__(
                    'You will receive the 200€ cashback code in your next mail from us',
                    'dreamlines',
                )}
            </div>
            <div className="newsletter-form--element newsletter-form--element--small-margin">
                <Button onClick={onClick} withArrow fullWidth>
                    {__('Search for cruise offers now', 'dreamlines')}
                </Button>
            </div>
        </>
    );
};
