import * as React from 'react';
import { memo } from 'react';
import * as Colors from '../../consts/variables';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { MarketContext } from '../../context/markets';
import { IBreadcrumb } from '../../interfaces';
import { Link } from './../Link';
import parse from 'html-react-parser';
const styles: Record<string, React.CSSProperties> = {
    wrapper: {
        margin: '0 auto',
        color: Colors.softBlue,
    },
    withLink: {
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 0,
    },
    slash: {
        margin: '0 5px',
    },
    withoutLink: {
        display: 'flex',
        flexDirection: 'row',
        color: Colors.breadcrumbActiveColor,
        marginBottom: 0,
    },
};

interface PropsSelf {
    breadcrumbs: IBreadcrumb[];
    isSeparatorShown?: boolean;
    className?: string;
}

type IProps = PropsSelf;

const getFullUrl = (url: string, portalId: string): string =>
    url && url.indexOf('http') === -1 ? `https://www.${portalId}${url}` : url;

const Breadcrumbs = memo(({ breadcrumbs, isSeparatorShown = true, className }: IProps) => {
    const { pageData } = useTypedSelector((state) => state);
    const { markets } = React.useContext(MarketContext);
    const { isCruise1st } = markets;
    const { portalId } = pageData?.brandConfig;

    const last = breadcrumbs.length - 1;
    const links = breadcrumbs
        .filter((item) => Boolean(item))
        .map(({ url, text }, index) =>
            url && url.length > 0 && index !== last ? (
                <li style={styles.withLink} key={`breadcrumbs:${index}`}>
                    {index > 0 && <div style={styles.slash}>/</div>}
                    <Link href={url}>{parse(text)}</Link>
                </li>
            ) : (
                <li style={styles.withoutLink} key={`breadcrumbs:${index}`}>
                    {index > 0 && <div style={styles.slash}>/</div>}
                    <div style={styles.slash}>{parse(text)}</div>
                </li>
            ),
        );
    const breadcrumbsData = {
        '@context': 'http://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement: breadcrumbs
            .filter((item) => Boolean(item))
            .map((item, index) => ({
                '@type': 'ListItem',
                position: index + 1,
                item: {
                    '@id': getFullUrl(item.url, portalId),
                    name: item.text,
                },
            })),
    };
    const cruise1stClassName = isCruise1st ? 'darkBlue' : '';

    const wrapperStyle = isSeparatorShown
        ? { ...styles.wrapper, ...styles.separator }
        : styles.wrapper;
    return (
        <>
            <ol className={`breadcrumbs ${cruise1stClassName} ${className}`} style={wrapperStyle}>
                {links}
            </ol>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(breadcrumbsData) }}
            />
        </>
    );
});
export { Breadcrumbs };
