import * as React from 'react';
import { IIcon } from '../../interfaces/IIcon';
import { Icon } from '../Icon';

export function AwardFirstIcon(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox}
            style={props.style}
            className={props.className}
        >
            <path
                d="M148 307l31 -18h2l-15 47l-37 129l-38 -37l-51 12l45 -152l34 -4zM216 299l22 -29l6 -1l49 171l-52 -12l-38 37l-31 -108l19 -65zM161 25c58 0 104 47 104 105s-46 104 -104 104s-105 -46 -105 -104s47 -105 105 -105zM177 189v0v-124h-19c-3 8 -8 14 -15 20
    s-14 9 -20 11v21c12 -4 22 -9 31 -17v89h23zM322 142v0l-21 27l4 34l-30 17l-9 32l-34 4l-21 27l-33 -9l-30 17l-26 -21l-34 4l-17 -30l-33 -9l-4 -34l-27 -21l10 -33l-17 -29l21 -27l-4 -34l30 -17l9 -32l34 -4l21 -27l32 9l30 -17l27 21l34 -4l16 30l33 9l4 34l27 21
    l-9 33zM161 246c64 0 116 -52 116 -116s-52 -116 -116 -116s-116 52 -116 116s52 116 116 116z"
            />
        </Icon>
    );
}
