import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function TVIcon(props: IIcon) {
    return (
        <Icon
            dropShadow={props.dropShadow}
            width={props.width}
            height={props.height}
            viewBox={props.viewBox ?? '0 0 24 24'}
            style={props.style}
            className={props.className}
        >
            <g>
                <path
                    d="M9.20859 4.19403H11.3093L7.9424 0.337919C7.65463 0.0213726 7.16542 -0.0361814 6.8201 0.251588C6.50355 0.539358 6.446 1.02857 6.73377 1.37389L9.20859 4.19403Z"
                    fill="currentColor"
                />
                <path
                    d="M16.2878 3.01419C16.6043 2.69765 16.6043 2.20844 16.2878 1.89189C15.9712 1.57534 15.482 1.57534 15.1655 1.89189L12.8921 4.16527H15.1079L16.2878 3.01419Z"
                    fill="currentColor"
                />
                <path
                    d="M22.2158 5.05734H12H1.78417C0.805755 5.05734 0 5.8631 0 6.84152V19.6473C0 20.6257 0.805755 21.4314 1.78417 21.4314H4.54676H19.4532H22.2158C23.1942 21.4314 24 20.6257 24 19.6473V6.84152C24 5.8631 23.1942 5.05734 22.2158 5.05734ZM16.9496 17.6904C16.9496 18.3811 16.4029 18.9278 15.7122 18.9278H3.88489C3.19424 18.9278 2.64748 18.3811 2.64748 17.6904V8.82713C2.64748 8.13648 3.19424 7.58972 3.88489 7.58972H15.7122C16.4029 7.58972 16.9496 8.13648 16.9496 8.82713V17.6904ZM22.0432 14.9278C22.0432 15.0717 21.9281 15.1868 21.7842 15.1868H19.1942C19.0504 15.1868 18.9353 15.0717 18.9353 14.9278V12.971C18.9353 12.8271 19.0504 12.712 19.1942 12.712H21.7842C21.9281 12.712 22.0432 12.8271 22.0432 12.971V14.9278ZM22.0432 10.784C22.0432 10.9278 21.9281 11.043 21.7842 11.043H19.1942C19.0504 11.043 18.9353 10.9278 18.9353 10.784V8.82713C18.9353 8.68324 19.0504 8.56813 19.1942 8.56813H21.7842C21.9281 8.56813 22.0432 8.68324 22.0432 8.82713V10.784Z"
                    fill="currentColor"
                />
                <path
                    d="M1.78418 23.0142C1.78418 23.5321 2.18706 23.935 2.70504 23.935H3.62591C4.14389 23.935 4.54677 23.5321 4.54677 23.0142V22.2947H1.78418V23.0142Z"
                    fill="currentColor"
                />
                <path
                    d="M19.4531 23.0142C19.4531 23.5321 19.856 23.935 20.374 23.935H21.2949C21.8128 23.935 22.2157 23.5321 22.2157 23.0142V22.2947H19.4531V23.0142Z"
                    fill="currentColor"
                />
            </g>
        </Icon>
    );
}
