import styled from '@emotion/styled';
import * as React from 'react';

import { __ } from '../../helpers/TranslationService';
import { CheckMarkIcon } from '../Icons/CheckMarkIcon';
import { AwardFirstIcon } from '../Icons/AwardFirstIcon';
import { ShipPlainIcon } from '../Icons/ShipPlainIcon';
import { PersonExpertIcon } from '../Icons/PersonExpertIcon';
import { MarketContext } from '../../context/markets';

const sharedUspIcon = ({ theme }) => `
    height: 45px;
    width: 45px;

    padding: 15px;
    border: 1px solid #fff;
    border-radius: 50%;
    fill: ${theme.footer.newsletterIconColor};
    box-sizing: content-box;
    overflow: visible !important;
`;
const StyledCheckMarkIcon = styled(CheckMarkIcon)`
    fill: ${({ theme }) => theme.footer.newsletterIconColor};
    height: 12px;
    width: 12px;
    margin-right: 6px;
`;

const StyledList = styled.ul`
    display: flex;
    justify-content: center;
    max-width: 1170px;
    width: 100%;
    margin-bottom: 0;
`;

const StyledLi = styled.li`
    display: flex;
    align-items: center;
    flex-basis: 33%;
    justify-content: center;
    color: ${({ theme }) => theme.footer.highlightsTextColor};
    flex-direction: column;
`;

const StyledLiRow = styled(StyledLi)`
    flex-direction: row;
`;

const Container = styled.div`
    background: ${({ theme }) => theme.footer.highlightsBackground};
    padding: 25px 0;
    display: flex;
    justify-content: center;
    width: 100%;
`;

const StyledPersonExpertIcon = styled(PersonExpertIcon)`
    ${sharedUspIcon}
`;
const StyledShipPlainIcon = styled(ShipPlainIcon)`
    ${sharedUspIcon}
`;
const StyledAwardFirstIcon = styled(AwardFirstIcon)`
    ${sharedUspIcon}
`;

const Paragraph = styled.p`
    font-size: 14px;
    max-width: 180px;
    text-align: center;
`;

const ParagraphFullWidth = styled(Paragraph)`
    max-width: none;
`;

const Title = styled.p`
    font-size: 20px;
    font-weight: 700;
`;

const DefaultUSP = () => (
    <StyledList>
        <StyledLi>
            <StyledPersonExpertIcon viewBox="-10 0 527 512" />
            <Title>{__('Expert', 'dreamlines')}</Title>
            <Paragraph>{__('Expert description', 'dreamlines')}</Paragraph>
        </StyledLi>

        <StyledLi>
            <StyledShipPlainIcon viewBox="-10 0 493 512" />
            <Title>{__('Board Credits Header', 'dreamlines')}</Title>
            <Paragraph>
                {`${__('Now up to', 'dreamlines')}`}&nbsp;
                {`${__('200 €', 'dreamlines')}`}&nbsp;
                {`${__('Board Credits', 'dreamlines')}`}
            </Paragraph>
        </StyledLi>

        <StyledLi>
            <StyledAwardFirstIcon viewBox="-10 0 342 512" />
            <Title>{__('Best price Guarantee', 'dreamlines')}</Title>
            <Paragraph>{__('Best price Guarantee description', 'dreamlines')}</Paragraph>
        </StyledLi>
    </StyledList>
);

const Cruise1stUSP = () => (
    <StyledList>
        {[
            'Member of ABTA and ATOL',
            'Exclusive special offers',
            'Experienced cruise specialists',
        ].map((i, key) => (
            <StyledLiRow key={key}>
                <StyledCheckMarkIcon />
                <ParagraphFullWidth>{i}</ParagraphFullWidth>
            </StyledLiRow>
        ))}
    </StyledList>
);

export const FooterUSP = () => {
    const { markets } = React.useContext(MarketContext);
    const { isCruise1st } = markets;

    const component = isCruise1st ? <Cruise1stUSP /> : <DefaultUSP />;
    return <Container>{component}</Container>;
};
