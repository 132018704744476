import React from 'react';
import * as Colors from '../../consts/variables';
import { IHeader, INavigationItem } from '../../interfaces/IHeader';
import { MainNavigationMenu } from './MainNavigationMenu';
import { CrossIcon } from '../Icons/CrossIcon';
import { ContactButtons } from '../ContactButtons';
import { ExchangeRateSection } from './ExchangeRateSection';
import { SearchCruise } from './SearchCruise';
import { IAppConfig, IBrandConfig, IConfig } from '../../reducers/pageData';
import { overlayIndexes } from '../../theme/zIndex';
interface Props {
    isOpen: boolean;
    exchangeRate: IHeader['exchangeRate'];
    mainNavigation: INavigationItem[];
    closeMenuClick: React.EventHandler<any>;
    appConfig: IAppConfig;
    brandConfig: IBrandConfig;
    config: IConfig;
    favoriteCount?: number;
}
const styleMenuFlex: React.CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
};
const styleCloseButton: React.CSSProperties = {
    alignSelf: 'flex-end',
    padding: '10px',
    marginTop: '-10px',
    cursor: 'pointer',
};

export class NavigationMenuContainer extends React.Component<Props, {}> {
    render() {
        const styleMenu: React.CSSProperties = {
            width: '100%',
            position: 'fixed',
            top: '0px',
            bottom: '0px',
            left: this.props.isOpen ? '0' : '-100%',
            background: this.props.isOpen ? 'rgba(0,0,0,0.5)' : 'rgba(0,0,0,0)',
            transition: 'background 0.15s ease-in-out',
            zIndex: overlayIndexes.drawerNavigationMenu,
        };

        const styleMenuWrapper: React.CSSProperties = {
            width: '80%',
            maxWidth: '400px',
            background: Colors.white,
            margin: '0px',
            padding: '15px 0px',
            position: 'fixed',
            left: this.props.isOpen ? '0' : '-100%',
            transition: 'left 0.25s ease-in-out',
            top: '0px',
            bottom: '0px',
            overflow: 'auto',
        };

        const crossIconContainer: React.CSSProperties = {
            marginLeft: 'auto',
        };

        const { exchangeRate, mainNavigation } = this.props;
        return (
            <div style={styleMenu} onClick={this.props.closeMenuClick}>
                <div style={styleMenuWrapper} onClick={(e) => e.stopPropagation()}>
                    <div style={styleMenuFlex}>
                        <span style={crossIconContainer} onClick={this.props.closeMenuClick}>
                            <CrossIcon style={styleCloseButton} width={44} height={44} />
                        </span>

                        <MainNavigationMenu
                            menuItems={mainNavigation}
                            favoriteCount={this.props.favoriteCount}
                        />
                        {exchangeRate ? <ExchangeRateSection {...exchangeRate} /> : null}
                        <SearchCruise
                            countryCode={this.props.appConfig.countryCode}
                            apiHost={this.props.appConfig.apiHost}
                            brandConfig={this.props.brandConfig}
                            contentEndpoint={this.props.config.contentEndpoint}
                        />
                        <ContactButtons />
                    </div>
                </div>
            </div>
        );
    }
}
