import * as React from 'react';
import { __ } from '../../helpers/TranslationService';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { ArrowDownIcon } from '../Icons/ArrowDownIcon';
import styled from '@emotion/styled';
import parse from 'html-react-parser';
import { IShipDescription } from '../../interfaces';
import { Button } from '../Button/Button';

export interface IFurtherInfo {
    description: string | IShipDescription;
}

const VISIBLE_HEIGHT = 415;

const DescriptionWrapper = styled.div<{ isOpen: boolean }>`
    overflow: hidden;
    transition: max-height 0.5s ease-in;
    max-height: ${({ isOpen }) => (isOpen ? '4000px' : VISIBLE_HEIGHT)};

    a {
        color: ${({ theme }) => theme.link.primary} !important; // in case some css's inlined in WP

        &:hover {
            color: ${({ theme }) => theme.link.primaryHover} !important;
        }
    }

    span {
        text-decoration: none !important; // override content menagers inline styles
    }

    h2,
    h3,
    h4,
    h5 {
        font-weight: bold;
    }
`;

export const FurtherInfo = (props: IFurtherInfo) => {
    const { cruisePage } = useTypedSelector((state) => state);
    const { ship } = cruisePage;
    const description =
        typeof props.description === 'string'
            ? props.description
            : ((ship.description as any) as string);
    const facts = (ship.factsAndFigures as any) as string;
    const infoElement = React.useRef<HTMLDivElement | null>(null);

    const [isOpen, toggleOpen] = React.useState(false);
    const [showMoreInfo, setShowMoreInfo] = React.useState(true);

    React.useEffect(() => {
        const currentInfoElementHeight = infoElement?.current?.offsetHeight;
        const contentFits = currentInfoElementHeight < VISIBLE_HEIGHT;

        setShowMoreInfo(!contentFits);
    }, [infoElement?.current]);

    const toggleText = !isOpen ? __('load more', 'dreamlines') : __('show less', 'dreamlines');

    return (
        <div className="furtherInfo" ref={infoElement}>
            {facts && <div>{parse(facts)}</div>}

            <DescriptionWrapper isOpen={isOpen}>{parse(description + '')}</DescriptionWrapper>

            {showMoreInfo && (
                <Button color="flatPrimary" size="small" onClick={() => toggleOpen(!isOpen)}>
                    {toggleText}
                    <ArrowDownIcon
                        style={{ transform: isOpen ? 'rotate(180deg)' : 'none' }}
                        width={14}
                    />
                </Button>
            )}
        </div>
    );
};
