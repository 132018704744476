import { BadRequestError } from '../errors/BadRequestError';

export interface ApiValidationError {
    children?: ApiValidationError[];
    property: string;
    value: unknown;
    target: unknown;
    constraints?: Record<string, string>;
    contexts?: Record<
        string,
        {
            translationKeyV2?: string;
            translationKey?: string;
        }
    >;
}

export interface ApiBadRequestError {
    errors: ApiValidationError[];
}

export const getApiValidationErrors = (error: unknown) => {
    const isValidBadRequestError = error instanceof BadRequestError && error.details;

    if (!isValidBadRequestError) {
        return [];
    }

    const hasErrorDetails = typeof error.details === 'object' && 'errors' in error.details;

    if (!error.details || !hasErrorDetails) {
        return [];
    }

    const errorsList = (error.details as ApiBadRequestError).errors;

    if (!errorsList || !Array.isArray(errorsList)) {
        return [];
    }

    const flatErrorsList = errorsList.reduce((acc, val) => {
        const { children, ...rest } = val;

        if (children && children.length) {
            acc = [...acc, ...children];
        }

        if (val.constraints) {
            acc.push(rest);
        }

        return acc;
    }, [] as ApiValidationError[]);

    return flatErrorsList.map((error) => {
        const constraintsList = error.constraints ? Object.keys(error.constraints) : [];
        const firstErrorKey = constraintsList[0];
        const context = error.contexts ? error.contexts[firstErrorKey] : null;

        return {
            field: error.property,
            errorKey: context
                ? context.translationKey || context.translationKeyV2
                : error.constraints?.[firstErrorKey] || '',
        };
    });
};
