import * as React from 'react';
import * as Colors from '../../consts/variables';
import * as Cookie from '../../helpers/cookie';
import { config } from '../../config';
import { __ } from '../../helpers/TranslationService';
import { ArrowRightIcon } from '../Icons/ArrowRightIcon';
import { CrossIcon } from '../Icons/CrossIcon';
import { IInfoBanner } from '../../interfaces/IBanner';

const styles: Record<string, React.CSSProperties> = {
    closeButton: {
        fill: Colors.darkBlack,
    },
    arrowRightIcon: {
        fill: '#00233b',
        marginLeft: 5,
    },
};

interface IState {
    isOpen: boolean;
}

interface IProps {
    device: string;
    banner: IInfoBanner;
}

export const InfoBanner = (props: IProps) => {
    const [isOpen, setIsOpen] = React.useState(Cookie.readCookie('info-banner') !== '1');
    const { devices, headline, subHeadline, linkDescription, linkUrl } = props.banner;

    const onClose = () => {
        Cookie.writeCookie('info-banner', '1', 7);
        setIsOpen(false);
    };

    return devices?.includes(props.device) && isOpen ? (
        <div id="info-banner" className="bannerWrapper">
            {headline && subHeadline ? (
                <div className="contentWrapper">
                    <div className="nameWrapper">
                        <p>{headline}</p>
                    </div>

                    <img
                        width="137"
                        height="80"
                        src={`${config.images}/common/infoBanner.jpg`}
                        alt={headline}
                        title={headline}
                    />

                    <div className="copyWrapper">
                        <div className="copyLinkWrapper">
                            <p className="hidden-xs">{subHeadline}</p>
                            <p className="visible-xs">{headline}</p>
                            {linkDescription && linkUrl && (
                                <a className="link" href={linkUrl}>
                                    {linkDescription}
                                    <ArrowRightIcon
                                        width={9}
                                        height={9}
                                        style={styles.arrowRightIcon}
                                    />
                                </a>
                            )}
                        </div>
                    </div>
                </div>
            ) : null}
            <div className="closeIconWrapper" onClick={onClose}>
                <CrossIcon width={27} height={27} style={styles.closeButton} />
            </div>
        </div>
    ) : null;
};
