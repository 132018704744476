import React from 'react';
import classnames from 'classnames';
import { Link } from '../Link';
import { IHeader, INavigationItem } from '../../interfaces/IHeader';
import { SearchCruise } from '../SearchCruise/SearchCruise';
import { IBrandConfig } from '../../reducers/pageData';

interface IProps {
    className: string;
    item: INavigationItem;
}

export class MenuItemLink extends React.Component<IProps, {}> {
    render() {
        return (
            <Link className={this.props.className} href={this.props.item.targetUrl} followLink>
                <span>{this.props.item.title}</span>
            </Link>
        );
    }
}

export class MenuItem extends React.Component<INavigationItem, {}> {
    getClassName(identifier: string) {
        if (identifier) {
            return identifier;
        } else {
            return 'nav-main-link';
        }
    }

    render() {
        let dropdownChildren;
        // prevent empty list rendering
        if (this.props.children && this.props.children.length) {
            dropdownChildren = (
                <MenuItemList className="dropdown-menu" items={this.props.children} />
            );
        }

        return (
            <li className={this.props.isActive ? 'active' : ''}>
                <MenuItemLink
                    className={this.getClassName(this.props.identifier)}
                    item={this.props}
                />
                {dropdownChildren}
            </li>
        );
    }
}

export class MenuItemList extends React.Component<
    { className: string; items: INavigationItem[] },
    {}
> {
    render() {
        return (
            <ul className={this.props.className} role="menu">
                {this.props.items && this.props.items.length
                    ? this.props.items.map((item, key) => (
                          // @TODO: check if current location is matching target url
                          <MenuItem
                              key={key}
                              isActive={item.targetUrl === 'http://local.dreamlines.de/'}
                              {...item}
                          />
                      ))
                    : null}
            </ul>
        );
    }
}

export class HeaderMainNavigation extends React.Component<{
    items: IHeader;
    isCollapsed?: boolean;
    countryCode: string;
    apiHost: string;
    brandConfig: IBrandConfig;
}> {
    /** Collect the nav links, forms, and other content for toggling */
    static defaultProps = {
        isCollapsed: true,
    };

    render() {
        const wrapperClasses = classnames('collapse navbar-collapse', {
            in: !this.props.isCollapsed,
        });
        return (
            <div className={wrapperClasses} id="globalPage_navigation">
                <MenuItemList
                    className="nav navbar-nav"
                    items={this.props.items.mainNavigationDesktop}
                />
                <SearchCruise
                    className="visible-lg"
                    countryCode={this.props.countryCode}
                    apiHost={this.props.apiHost}
                    brandConfig={this.props.brandConfig}
                />
            </div>
        );
    }
}
