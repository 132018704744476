import * as React from 'react';
import { Icon } from '../Icon';
import { IIcon } from '../../interfaces/IIcon';

export function Youtube(props: IIcon) {
    return (
        <Icon
            width={props.width}
            height={props.height}
            viewBox={props.viewBox || '0 0 24 24'}
            style={props.style}
            className={props.className}
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.3903 4.12335C22.4182 4.4002 23.2288 5.21063 23.5055 6.23876C24.0197 8.11668 23.9999 12.0311 23.9999 12.0311C23.9999 12.0311 23.9999 15.9255 23.5057 17.8036C23.2288 18.8316 22.4184 19.6422 21.3903 19.9189C19.5122 20.4133 12 20.4133 12 20.4133C12 20.4133 4.50731 20.4133 2.60961 19.8993C1.58148 19.6224 0.771054 18.8118 0.4942 17.7839C0 15.9255 0 12.0113 0 12.0113C0 12.0113 0 8.11668 0.4942 6.23876C0.770871 5.21081 1.60125 4.38043 2.60943 4.10376C4.48753 3.60938 11.9998 3.60938 11.9998 3.60938C11.9998 3.60938 19.5122 3.60938 21.3903 4.12335ZM15.8549 12.0116L9.60791 15.6096V8.41357L15.8549 12.0116Z"
            />
        </Icon>
    );
}
