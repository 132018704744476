import * as React from 'react';
import { addToFavorites, removeFromFavorites } from '../../actions/favoritesPageActions';
import * as vars from '../../consts/variables';
import { __ } from '../../helpers/TranslationService';
import { CTA } from '../CTA';
import { Link } from '../Link';
import { IRecommendationTeaser, RecommendationSlider } from './RecommendationSlider';

export interface IProps {
    title?: string;
    offerUrl?: string;
    data: IRecommendationTeaser[];
    collapsibleComponent?: boolean;
    favorites?: number[];
    addToFavorites?: typeof addToFavorites;
    removeFromFavorites?: typeof removeFromFavorites;
    wrapperStyles?: any;
}

export const style: Record<string, React.CSSProperties> = {
    title: {
        fontSize: 18,
        fontWeight: 'bold',
        textAlign: 'center',
        margin: '30px 15px 10px 0',
    },
    card: {
        padding: '10px 10px 5px',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
    },
    cardInfo: {
        borderRight: `1px solid ${vars.gray}`,
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0%',
        minWidth: 0,
        minHeight: 48,
        justifyContent: 'center',
    },
    teaserText: {
        marginLeft: 5,
        display: 'block',
        paddingRight: 2,
        fontSize: 16,
        textAlign: 'left',
    },
    spacingBottom: {
        marginBottom: 6,
        display: 'flex',
    },
    shipListWrapper: {
        marginBottom: 6,
        display: 'flex',
    },
    iconStyle: {
        float: 'left',
        marginRight: 5,
        fill: vars.darkAcqa,
        flex: '0 0 auto',
    },
    wrapper: {},
    singleWrapper: {
        margin: '0 10px',
    },
    teaserTitle: {
        position: 'absolute',
        width: '100%',
        bottom: 0,
        margin: 0,
        textAlign: 'center',
        padding: '10px 6px',
        fontSize: 19,
        fontWeight: 'bold',
        color: vars.white,
        textShadow: `0 0 4px ${vars.black}`,
        background: 'linear-gradient(to bottom, rgba(8, 29, 46, 0), rgba(1, 47, 74, 0.71))',
    },
    shipListDescWrapper: {
        borderTop: `solid 1px ${vars.darkGray}`,
        margin: '0 5px',
        padding: 5,
        lineHeight: '20px',
        fontSize: 15,
        textAlign: 'left',
    },
    harbourListDescWrapper: {
        paddingRight: 5,
        fontSize: 15,
    },
};

class RecommendationSection extends React.Component<IProps> {
    render() {
        const wrapperStyle = this.props.collapsibleComponent
            ? {}
            : this.props.data.length === 1
            ? style.singleWrapper
            : style.wrapper;
        return this.props.data.length ? (
            <div
                id="offers"
                className="recommendation-section"
                style={{ ...wrapperStyle, ...this.props.wrapperStyles }}
            >
                {this.props.title && (
                    <h2
                        style={style.title}
                        dangerouslySetInnerHTML={{ __html: this.props.title }}
                    />
                )}
                <div className="headline" />
                <RecommendationSlider
                    data={this.props.data}
                    collapsibleComponent={this.props.collapsibleComponent}
                    favorites={this.props.favorites}
                    addToFavorites={this.props.addToFavorites}
                    removeFromFavorites={this.props.removeFromFavorites}
                />

                {this.props.offerUrl && (
                    <Link href={this.props.offerUrl} mask={true}>
                        <CTA
                            className="onlyDesktop recommendationSectionCTA"
                            wrapperStyle={{
                                width: '345px',
                                margin: '14px auto',
                                padding: '11px 5px',
                            }}
                        >
                            {__('show all offers', 'dreamlines')}
                        </CTA>
                    </Link>
                )}
            </div>
        ) : null;
    }
}

export default RecommendationSection;
